/* eslint-disable import/no-cycle */
import moment from 'moment'
import { momentConstants } from '../../constants'

const generateNewGraph = (
  element,
  key,
  value,
  text,
  dataSetType,
  keyType,
  clickChart,
  dateInterval,
  dataSetKey,
) => ({
  key: dataSetKey,
  dataSetType,
  keyType,
  name: key,
  refs: Object.entries(element.refs),
  periodIdList: element.periodId ? [element.periodId] : [],
  datasetIdList: element.datasetId ? [element.datasetId] : [],
  enabled: false,
  series: [{ name: key, data: [value] }],
  options: {
    title: {
      text,
      style: {
        fontSize: '10px',
        fontWeight: 'Bold',
        fontFamily: 'Comfortaa',
      },
    },
    dataLabels: {
      enabled: false,
    },

    chart: {
      id: key,
      group: 'social',
      type: 'line',
      height: 160,
      animations: {
        enabled: false,
      },
      events: {
        click(event, chartContext, config) {
          clickChart(event, chartContext, config)
        },
      },
    },
    markers: {
      size: 0,
    },
    yaxis: {
      labels: {
        minWidth: 40,
      },
    },

    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (v, timestamp) =>
          moment(timestamp).format(momentConstants.FORMAT_WITH_SC),
      },
      categories: dateInterval || [],
    },
  },
  width: '100%',
  height: 300,
  type: 'line',
})
const removeDuplicates = (arr) => [...new Set(arr)]

const getVariable = (element, dataSetType) => {
  let dataSet
  let text
  let keyType = null
  if (dataSetType === 'gps') {
    dataSet = element.refs.vehicle ? element.refs.vehicle : element.refs.gateway
    keyType = element.refs.vehicle ? 'vehicle' : 'gateway'
    text = `${dataSetType} for the ${keyType} ${dataSet}`
  } else {
    dataSet = element.refs[dataSetType]
    text = `${dataSetType} ${dataSet}`
  }
  return { keyType, text, dataSet }
}

const chartsModel = (
  data,
  dataSetType,
  excludedList,
  clickChart,
  dateInterval,
) => {
  const list = []
  if (data && dataSetType && data.length > 0) {
    data.forEach((element) => {
      const date = moment(element.date)
      if (!date.isValid()) {
        return
      }
      // categories.xaxis.categories
      const x = list.findIndex((o) => {
        if (dataSetType === 'gps') {
          return (
            o.key === element.refs.gateway || element.refs.vehicle === o.key
          )
        }
        return o.key === element.refs[dataSetType]
      })
      if (x !== -1) {
        // push data to model
        const objectArray = Object.entries(element.data)

        objectArray.forEach(([key, value]) => {
          // search variable with underscore
          if (key.includes('_')) {
            const nameList = key.split('_')
            const chartIndex = list[x].charts.findIndex(
              (s) => s.name === nameList[0],
            )
            if (chartIndex !== -1) {
              const dataIndex = list[x].charts[chartIndex].series.findIndex(
                (i) => i.name === key,
              )
              if (dataIndex !== -1) {
                if (value) {
                  list[x].charts[chartIndex].series[dataIndex].data.push(value)

                  // push
                  list[x].charts[chartIndex].periodIdList.push(element.periodId)
                  list[x].charts[chartIndex].periodIdList = removeDuplicates(
                    list[x].charts[chartIndex].periodIdList,
                  )
                  list[x].charts[chartIndex].datasetIdList.push(
                    element.datasetId,
                  )
                  list[x].charts[chartIndex].datasetIdList = removeDuplicates(
                    list[x].charts[chartIndex].datasetIdList,
                  )
                }
              }
            }
          } else {
            // create new
            const chartIndex = list[x].charts.findIndex((s) => s.name === key)
            if (chartIndex !== -1) {
              if (value) {
                //
                list[x].charts[chartIndex].series[0].data.push(value)

                list[x].charts[chartIndex].periodIdList.push(element.periodId)
                list[x].charts[chartIndex].periodIdList = removeDuplicates(
                  list[x].charts[chartIndex].periodIdList,
                )
                list[x].charts[chartIndex].datasetIdList.push(element.datasetId)
                list[x].charts[chartIndex].datasetIdList = removeDuplicates(
                  list[x].charts[chartIndex].datasetIdList,
                )
              }
            }
          }
        })
      } else {
        const { keyType, text, dataSet } = getVariable(element, dataSetType)
        const row = {
          key: dataSet,
          charts: [],
        }
        const objectArray = Object.entries(element.data)
        // push to old model contain underscore
        objectArray.forEach(([key, value]) => {
          if (
            !excludedList.includes(`${dataSetType}_${key}`) &&
            key.includes('_')
          ) {
            const nameList = key.split('_')
            const chartIndex = row.charts.findIndex(
              (s) => s.name === nameList[0],
            )
            if (chartIndex !== -1) {
              //
              // push to exist model
              if (value) {
                row.charts[chartIndex].series.push({ name: key, data: [value] })
                list[x].charts[chartIndex].periodIdList.push(element.periodId)
                list[x].charts[chartIndex].periodIdList = removeDuplicates(
                  list[x].charts[chartIndex].periodIdList,
                )
                list[x].charts[chartIndex].datasetIdList.push(element.datasetId)
                list[x].charts[chartIndex].datasetIdList = removeDuplicates(
                  list[x].charts[chartIndex].datasetIdList,
                )
              }
            } else if (value) {
              // create new model
              const graph = generateNewGraph(
                element,
                key,
                value,
                `${nameList[0]} ${text}`,
                dataSetType,
                keyType,
                clickChart,
                dateInterval,
                dataSet,
              )

              row.charts.push(graph)
            }
          } else if (!excludedList.includes(`${dataSetType}_${key}`)) {
            if (value) {
              const graph = generateNewGraph(
                element,
                key,
                value,
                `${key} ${text}`,
                dataSetType,
                keyType,
                clickChart,
                dateInterval,
                dataSet,
              )
              row.charts.push(graph)
            }
          }
        })

        list.push(row)
      }
    })
  }
  return list.map((o) => o.charts)
}

export const ChartHelpers = { chartsModel }
