/* eslint-disable import/no-cycle */
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { momentConstants } from '../../../constants'
import { vehiclesService } from '../../../services'
import { alertActions } from '../../../actions'

const useController = (props) => {
  const { onclose, id, vehicle, fetch, descriptionName } = props
  const { t } = useTranslation()
  const vehicleName =
    vehicle && vehicle.ref && vehicle.name
      ? t('v_detail.inputVehicleWithRef', {
          ref: vehicle.ref,
          name: vehicle.name,
        })
      : vehicle.ref
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      descriptionName,
      odometerValue: 0,
      date: moment(new Date()).format(momentConstants.FORMAT_text_field),
      name: vehicleName,
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const abortController = new AbortController()
    const checkVehicle = () => {
      if (vehicle.odometer) {
        form.setValue('odometerValue', Math.floor(vehicle.odometer))
        form.setValue(
          'date',
          moment(new Date()).format(momentConstants.FORMAT_text_field),
        )
      }
    }
    checkVehicle()
    return () => {
      abortController.abort()
    }
  }, [vehicle.odometer, form])

  const updateMaintenance = useCallback(
    async (data) => {
      const { date, odometerValue } = data
      if (id) {
        const body = {
          descriptionId: id,
          date: moment(date).toISOString(),
        }
        if (odometerValue && Number(odometerValue) >= 0) {
          body.odometer = Number(odometerValue)
        } else {
          dispatch(
            alertActions.info(t('v_detail.v_form_validation'), 6000, true),
          )
          return
        }
        const res = await vehiclesService.maintenanceOperations(
          vehicle.id,
          body,
        )
        if (!res || res.status !== 201) {
          dispatch(alertActions.error(res.content.message, 6000, true))
          return
        }
        form.reset()
        fetch()
        onclose()
        dispatch(alertActions.success(t('v_detail.v_update_msg'), 6000, true))
        return
      }
      dispatch(alertActions.info(t('v_detail.v_form_validation'), 6000, true))
    },
    [dispatch, fetch, id, onclose, t, vehicle.id, form],
  )

  return { updateMaintenance, onclose, t, form }
}
export default useController
