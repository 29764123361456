import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Remove from '@material-ui/icons/Remove'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Tooltip, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function ExpandButton(props) {
  const { t } = useTranslation()
  const renderIconButton = () =>
    props.expandableRowsHeader === false ? (
      <Button
        onClick={props.onExpand}
        style={{
          marginLeft: '10px',
          marginRight: '10px',
        }}
        disabled={props.expandableRowsHeader === false}
        className={props.buttonClass}
      >
        <KeyboardArrowRight
          id="expandable-button"
          className={props.iconClass}
        />
      </Button>
    ) : (
      <Tooltip title={t('table_trips.tooltipCarte')}>
        <Button
          onClick={props.onExpand}
          variant="outlined"
          style={{
            borderRadius: '70px',
            marginLeft: '10px',
            marginRight: '10px',
            backgroundColor: 'white',
          }}
          disabled={props.expandableRowsHeader === false}
          className={props.buttonClass}
        >
          <KeyboardArrowRight
            id="expandable-button"
            className={props.iconClass}
          />
        </Button>
      </Tooltip>
    )
  return (
    <>
      {props.isHeaderCell &&
      !props.areAllRowsExpanded() &&
      props.expandedRows &&
      props.expandedRows.data.length > 0 ? (
        <IconButton
          onClick={props.onExpand}
          style={{ padding: 0 }}
          disabled={props.expandableRowsHeader === false}
          className={props.buttonClass}
        >
          <Remove
            id="expandable-button"
            className={props.iconIndeterminateClass}
          />
        </IconButton>
      ) : (
        renderIconButton()
      )}
    </>
  )
}
