/* eslint-disable import/no-cycle */
import React, { createRef, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import StylesWithMaterialUi from '../../EditDocuments.styles'
import useController from './TechnicalControl.controller'
import ComboBox from '../../../../utils/MatrialUiComponents/ComboBox/ComboBox'
import { documentsConstants } from '../../../../../constants'

const TechnicalControl = (props) => {
  const {
    t,
    handleChange,
    numberProceLabel,
    kmLabel,
    errerLabel,
    handleChangeDate,
    startDateLabel,
    endDateLabel,
    dateError,
    nextLabel,
    resultLabel,
    natureLabel,
    setNatureLabel,
    setResultLabel,
    setNextLabel,
    centerAgre,
    campany,
    coord,
    agrTech,
    techFullName,
    immat,
    immatDate,
    dateFirstCir,
    marque,
    desg,
    vin,
    catg,
    genre,
    typeVehicle,
    Ener,
    doc,
    mesure,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  const InputModel = useCallback(
    (value, name, type, required, multiline) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={handleChange}
            type={type}
            name={name}
            fullWidth
            margin="normal"
            required={required}
            multiline={multiline}
          />
        </Grid>
      )
    },
    [classes.gridItem, handleChange, t],
  )
  const InputModelDate = useCallback(
    (value, name, change, required, error) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={change}
            InputLabelProps={{
              shrink: true,
            }}
            error={error}
            type="date"
            name={name}
            fullWidth
            margin="normal"
            required={required}
          />
        </Grid>
      )
    },
    [classes.gridItem, t],
  )
  return useMemo(
    () => (
      <>
        {InputModelDate(
          startDateLabel,
          'startDateLabel',
          handleChangeDate,
          true,
        )}
        {InputModelDate(
          endDateLabel,
          'endDateLabel',
          handleChangeDate,
          true,
          dateError,
        )}
        {InputModel(
          numberProceLabel,
          'numberProceLabel',
          'number',
          false,
          false,
        )}
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <ComboBox
            autoComplete
            fullWidth
            margin="normal"
            onChange={(event, newValue) => {
              setResultLabel(newValue)
            }}
            loadingText={t('global.loading')}
            value={resultLabel}
            name="resultLabel"
            options={documentsConstants.technicalControlResult}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.label
            }
            label={t('documents.resultLabel')}
            required
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <ComboBox
            autoComplete
            fullWidth
            margin="normal"
            onChange={(event, newValue) => {
              setNatureLabel(newValue)
            }}
            loadingText={t('global.loading')}
            value={natureLabel}
            name="natureLabel"
            options={documentsConstants.technicalControlTypes}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.label
            }
            label={t('documents.natureLabel')}
            required
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <ComboBox
            autoComplete
            fullWidth
            margin="normal"
            onChange={(event, newValue) => {
              setNextLabel(newValue)
            }}
            loadingText={t('global.loading')}
            value={nextLabel}
            name="nextLabel"
            options={documentsConstants.technicalControlTypes}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.label
            }
            label={t('documents.nextLabel')}
            required
          />
        </Grid>

        {InputModel(errerLabel, 'errerLabel', 'text', false, true)}
        {InputModel(kmLabel, 'kmLabel', 'number', false, false)}
        {InputModel(centerAgre, 'centerAgre', 'text', false, false)}
        {InputModel(campany, 'campany', 'text', false, false)}
        {InputModel(coord, 'coord', 'text', false, true)}
        {InputModel(agrTech, 'agrTech', 'text', false, false)}
        {InputModel(techFullName, 'techFullName', 'text', false, false)}
        {InputModel(immat, 'immat', 'text', false, false)}
        {InputModelDate(immatDate, 'immatDate', handleChange, true)}
        {InputModelDate(dateFirstCir, 'dateFirstCir', handleChange, true)}
        {InputModel(marque, 'marque', 'text', false, false)}
        {InputModel(desg, 'desg', 'text', false, false)}
        {InputModel(vin, 'vin', 'text', false, false)}
        {InputModel(catg, 'catg', 'text', false, false)}
        {InputModel(genre, 'genre', 'text', false, false)}
        {InputModel(typeVehicle, 'typeVehicle', 'text', false, false)}
        {InputModel(Ener, 'Ener', 'text', false, false)}
        {InputModel(doc, 'doc', 'text', false, true)}
        {InputModel(mesure, 'mesure', 'text', false, true)}
      </>
    ),
    [
      Ener,
      InputModel,
      InputModelDate,
      agrTech,
      campany,
      catg,
      centerAgre,
      classes.gridItem,
      coord,
      dateError,
      dateFirstCir,
      desg,
      doc,
      endDateLabel,
      errerLabel,
      genre,
      handleChange,
      handleChangeDate,
      immat,
      immatDate,
      kmLabel,
      marque,
      mesure,
      natureLabel,
      nextLabel,
      numberProceLabel,
      resultLabel,
      setNatureLabel,
      setNextLabel,
      setResultLabel,
      startDateLabel,
      t,
      techFullName,
      typeVehicle,
      vin,
    ],
  )
}
export default TechnicalControl
