import React from 'react'

const WarningComponents = (value, t, width, height) => {
  const getColor = () => {
    if (value === 300 || value === t('alerts.darkRed')) {
      return '#B22222'
    }
    if (value === 200 || value === t('alerts.red')) {
      return 'red'
    }
    return 'orange'
  }
  return (
    <div
      style={{
        backgroundColor: getColor(),
        width: width || '70px',
        height: height || '20px',
      }}
    />
  )
}
export default WarningComponents
