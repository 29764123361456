/* eslint-disable import/no-cycle */
import React from 'react'
import { drawerActions } from '../../actions/drawer.actions'
import VehicleIncident from '../../components/Vehicles/VehicleIncident/VehicleIncident'
import VehicleDetails from '../../components/Vehicles/VehiclesDetails/VehicleDetails'
const ShowVehicleDetails = (dispatch, t, vehicle) => {
  dispatch(
    drawerActions.show(
      <VehicleDetails vehicle={vehicle} />,
      t('v_detail.v_title'),
      'right',
      'persistent',
      'ltr',
    ),
  )
}
const ShowVehicleIncident = (vehicle, dispatch, t) => {
  const onClose = () => ShowVehicleDetails(dispatch, t, vehicle)
  dispatch(
    drawerActions.show(
      <VehicleIncident vehicle={vehicle} onclose={onClose} />,
      t('v_detail.btn_incident'),
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}

export const VehicleIncidentHelpers = {
  ShowVehicleIncident,
  ShowVehicleDetails,
}
