/* eslint-disable import/no-cycle */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { socketActions } from '../../../actions'
import { closeSocket } from '../../../helpers/socket/socket.helpers'

const useController = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const socket = useSelector((state) => state.socket)
  const user = useSelector((state) => state.authentication.user)
  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      if (socket.subscribe) {
        dispatch(socketActions.unsubscribe(socket.socket, socket.data))
      }
      closeSocket(socket)
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, socket])

  return { user, t }
}
export default useController
