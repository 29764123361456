/* eslint-disable import/no-cycle */
import moment from 'moment'
import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { ordersConstants } from '../../../../../../constants'
import StylesWithMaterialUi from '../../../VehicleDetails.styles'

const OrderExpand = (props) => {
  const { rowData } = props
  const { t } = useTranslation()
  const classes = StylesWithMaterialUi()
  const row = rowData[rowData.length - 1] || []
  const filterList = Object.keys(row).filter(
    (x) => !ordersConstants.ignoreList.includes(x),
  )

  const colSpan = rowData.length + 1
  return filterList.length > 0 ? (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {filterList.map((o) =>
            row[o] ? (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                key={o}
                xs
              >
                {o && o === 'closingDate' ? (
                  <>
                    <Grid item>
                      <Typography className={classes.expandTitle}>
                        {t('orders.closingDate')} :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.expandBody}
                        variant="body1"
                        align="center"
                      >
                        {moment(row[o]).format('lll')}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {o && o === 'closingMessage' ? (
                  <>
                    <Grid item>
                      <Typography className={classes.expandTitle}>
                        {t('orders.closingMessage')} :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.expandBody}
                        variant="body1"
                        align="center"
                      >
                        {row[o]}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            ) : null,
          )}
        </Grid>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell colSpan={colSpan} align="center" size="small">
        {t('orders.order204')}
      </TableCell>
    </TableRow>
  )
}
export default OrderExpand
