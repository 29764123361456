/* eslint-disable import/no-cycle */
import React from 'react'
import { drawerActions } from '../../actions'
import EditUser from '../../components/Users/EditUser/EditUser'
import UserDetails from '../../components/Users/UserDetails/UserDetails'
import UserReport from '../../components/Users/UserReport/UserReport'

const CloseDrawer = (dispatch, t, user) => {
  dispatch(
    drawerActions.show(
      <UserDetails user={user} />,
      t('users.drawerTitle'),
      'right',
      'persistent',
      'ltr',
    ),
  )
}
const HandleEdit = (user, dispatch, t) => {
  const close = () => CloseDrawer(dispatch, t, user)
  dispatch(
    drawerActions.show(
      <EditUser user={user} onclose={close} drawer={false} />,
      t('users.drawerTitle'),
      'right',
      'persistent',
      'rtl',
      null,
      close,
    ),
  )
}

const handleRapport = (user, dispatch, t) => {
  const close = () => CloseDrawer(dispatch, t, user)
  dispatch(
    drawerActions.show(
      <UserReport value={user} onclose={close} drawer />,
      t('users.drawerTitle'),
      'right',
      'persistent',
      'rtl',
      null,
      close,
    ),
  )
}

export const usersHelpers = {
  HandleEdit,
  handleRapport,
}
