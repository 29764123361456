import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useController = (props) => {
  const { user } = props
  const [expandedGeneral, setExpandedGeneral] = useState(true)
  const [expandedBtn, setExpandedBtn] = useState(false)
  const drawer = useSelector((state) => state.drawer)
  const settings = useSelector((state) => state.settings)

  const handleExpandedGeneral = useCallback(() => {
    if (user) {
      setExpandedGeneral(!expandedGeneral)
    }
  }, [expandedGeneral, user])

  const handleExpandedGeneralBtn = useCallback(() => {
    if (user) {
      setExpandedBtn(!expandedBtn)
    }
  }, [expandedBtn, user])
  useEffect(() => {
    const abortController = new AbortController()
    const drawerState = () => {
      if (drawer && drawer.open) {
        setExpandedGeneral(true)
        setExpandedBtn(false)
      }
    }
    drawerState()
    return () => {
      abortController.abort()
    }
  }, [drawer])

  return {
    handleExpandedGeneral,
    expandedGeneral,
    user,
    expandedBtn,
    handleExpandedGeneralBtn,
    settings,
  }
}
export default useController
