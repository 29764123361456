import React from 'react'
// eslint-disable-next-line import/no-cycle
import { fleetsService } from '../../services'
import { fullResponse } from '../system/response.helpers'
// eslint-disable-next-line import/no-cycle
import { alertActions, drawerActions } from '../../actions'
// eslint-disable-next-line import/no-cycle
import FleetDetails from '../../components/Fleets/FleetDetails/FleetDetails'
const OpenFleet = async (e, id, dispatch, t) => {
  const query = {
    id,
  }

  const res = await fleetsService.fleets(query)
  if (fullResponse(res, 200)) {
    dispatch(
      drawerActions.show(
        <FleetDetails fleet={res.content.data} />,
        t('table.v_fleet'),
        'right',
        'persistent',
        'ltr',
      ),
    )
    return
  }
  dispatch(alertActions.error(res.content.message, 6000, true))
}

export const FleetsHelpers = { OpenFleet }
