// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const getTrips = (query) => {
  const route = '/vehicleTrips'
  return axiosService.get(true, route, query)
}

const getTripsTracking = (body) => {
  const route = '/vehicleTrips/tracking'
  return axiosService.post(true, route, null, body)
}
export const vehiclesTripsService = {
  getTrips,
  getTripsTracking,
}
