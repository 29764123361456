/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import {
  Grid,
  Typography,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import moment from 'moment'
import { VehiclesHelpers, fullResponse } from '../../../../../helpers'
import StylesWithMaterialUi from './Header.styles'
import adresse from '../../../../../assets/images/pictos/adresse.png'
import trajet from '../../../../../assets/images/pictos/trajet.png'
import vitesse from '../../../../../assets/images/pictos/vitesse.png'
// import consommation from '../../../../../assets/images/pictos/consommation.png'
import odometre from '../../../../../assets/images/pictos/odometre.png'
import carte from '../../../../../assets/images/pictos/carte-grise.png'
import noCarte from '../../../../../assets/images/pictos/carte-grise-sans.png'
import greencarte from '../../../../../assets/images/pictos/carte-verte.png'
import noGreencarte from '../../../../../assets/images/pictos/carte-verte-sans.png'
import useController from './Header.controller'
import battery0 from '../../../../../assets/images/pictos/autonomie-0.png'
import battery1 from '../../../../../assets/images/pictos/autonomie-10.png'
import battery2 from '../../../../../assets/images/pictos/autonomie-50.png'
import battery3 from '../../../../../assets/images/pictos/autonomie-70.png'
import battery4 from '../../../../../assets/images/pictos/autonomie-90.png'
import battery5 from '../../../../../assets/images/pictos/autonomie-100.png'
import { vehiclesService } from '../../../../../services'
import Tracking from '../tracking/Tracking'
// import RatingComponent from '../../../../utils/MatrialUiComponents/Rating/RatingComponent'
const Header = (props) => {
  const { vehicle, lastTracking, openDocs, handleExpanded, expanded } = props
  // getNote, indices
  const { t, lastTrip, speed } = useController(props)
  const classes = StylesWithMaterialUi()
  const [loadingDocs, setLoadingdocs] = useState(true)
  const [registrationCard, setRegistrationCard] = useState(null)
  const [insuranceCard, setInsuranceCard] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()
    const fetchDocuments = async () => {
      try {
        if (vehicle && vehicle.id && expanded) {
          setLoadingdocs(true)
          const res = await vehiclesService.getDocuments(vehicle.id)
          if (fullResponse(res, 200)) {
            const reg = res.content.data.find(
              (x) => x.type === 'RegistrationCard',
            )
            if (reg) {
              setRegistrationCard(reg)
            } else {
              setRegistrationCard(null)
            }
            const ins = res.content.data.find((x) => x.type === 'InsuranceCard')

            if (ins) {
              setInsuranceCard(ins)
            } else {
              setInsuranceCard(null)
            }
          }
          setLoadingdocs(false)
        }
      } catch (e) {
        setLoadingdocs(false)
      }
    }
    fetchDocuments()

    return () => {
      abortController.abort()
    }
  }, [expanded, vehicle])

  const renderModelAvatarAndNumberplate = useCallback(
    () => (
      <>
        {vehicle.vehicleModel ? (
          <Grid item>
            <Typography className={classes.vehicleModel}>
              {vehicle.vehicleModel.name}
            </Typography>
          </Grid>
        ) : null}
        {vehicle.avatar ? (
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <img
              alt={vehicle.avatar.name}
              src={VehiclesHelpers.getAvatar(
                vehicle.avatar.id,
                vehicle.avatar.accessToken,
              )}
              className={classes.large}
            />
          </Grid>
        ) : null}
        {vehicle.numberplate ? (
          <Grid item>
            <div className={classes.logo}>
              <Typography className={classes.immatText}>
                {vehicle.numberplate}
              </Typography>
            </div>
          </Grid>
        ) : null}
      </>
    ),
    [
      classes.immatText,
      classes.large,
      classes.logo,
      classes.vehicleModel,
      vehicle.avatar,
      vehicle.numberplate,
      vehicle.vehicleModel,
    ],
  )
  const GetBatteryIcon = useCallback(
    (soc) => {
      if (soc) {
        let icon
        if (soc >= 0.9) {
          icon = <img src={battery5} alt="battery" className={classes.small} />
        } else if (soc >= 0.7 && soc < 0.9) {
          icon = <img src={battery4} alt="battery" className={classes.small} />
        } else if (soc >= 0.5 && soc < 0.7) {
          icon = <img src={battery3} alt="battery" className={classes.small} />
        } else if (soc >= 0.3 && soc < 0.5) {
          icon = <img src={battery2} alt="battery" className={classes.small} />
        } else if (soc >= 0.1 && soc < 0.3) {
          icon = <img src={battery1} alt="battery" className={classes.small} />
        }
        if (soc < 0.1) {
          icon = <img src={battery0} alt="battery" className={classes.small} />
        }

        return icon
      }
      return null
    },
    [classes.small],
  )

  const renderLastTracking = useCallback(
    () =>
      lastTracking ? (
        <>
          {lastTracking.address ? (
            <Grid item container direction="row">
              <Grid item className={classes.icon}>
                <img src={adresse} alt="adresse" className={classes.small} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={classes.eighty}
              >
                <Grid item>
                  <Typography className={classes.title}>
                    {t('vehicleHeader.lastTracking')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    {lastTracking.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : null,
    [
      classes.eighty,
      classes.icon,
      classes.small,
      classes.text,
      classes.title,
      lastTracking,
      t,
    ],
  )

  const renderLastTrip = useCallback(
    () =>
      lastTrip ? (
        <Grid item container direction="row">
          <Grid item className={classes.icon}>
            <img src={trajet} alt="trajet" className={classes.small} />
          </Grid>
          <Grid item container direction="column" className={classes.eighty}>
            {lastTrip ? (
              <>
                <Grid item>
                  <Typography className={classes.title}>
                    {t('vehicleHeader.lastTrip')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    {moment(lastTrip.endDate).format('LL')} /
                    {t('global.uniteKm', {
                      value: Math.round(lastTrip.distanceGps),
                    })}
                    / {moment(lastTrip.endDate).format('H:mm')}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {speed ? (
              <Grid item container direction="row">
                <Grid item className={classes.icon}>
                  <img src={vitesse} alt="vitesse" className={classes.small} />
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.eighty}
                >
                  <Grid item>
                    <Typography className={classes.title}>
                      {t('vehicleHeader.speed')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.text}>
                      {t('unit.kh_h', {
                        value: speed.average,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {/** <Grid item container direction="row">
          <Grid item style={{ width: '20%', maxWidth: 40 }}>
            <img
              src={consommation}
              alt="consommation"
              className={classes.small}
            />
          </Grid>
          <Grid item container direction="column" style={{ width: '80%' }}>
            <Grid item>
              <Typography style={{ fontFamily: 'Poppins', fontSize: '13px' }}>
                Consommation moyenne :
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                32 Wh/km
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
          </Grid>
        </Grid>
      ) : null,
    [
      classes.eighty,
      classes.icon,
      classes.small,
      classes.text,
      classes.title,
      lastTrip,
      speed,
      t,
    ],
  )
  /** const renderUsageQuality = useCallback(
    () => (
      <Grid
        item
        container
        style={{
          backgroundColor: '#c0c0c0',
          marginLeft: '10px',
          paddingLeft: '10px',
          borderRadius: 5,
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontFamily: 'Poppins',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
          >
            Indice d'éco conduite
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center">
          {indices && indices.ecoDiving ? (
            indices.ecoDiving.map((item, index) => (
              <Grid
                item
                container
                direction="row"
                className={classes.gridItem}
                key={`note-${index}`}
              >
                <Grid item className={classes.inputRating}>
                  {moment(item.month).isSame(new Date(), 'month') &&
                  moment(item.month).isSame(new Date(), 'year') ? (
                    <Typography
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('v_detail.usageQualityMonth')}
                    </Typography>
                  ) : (
                    <Typography
                      style={{ fontFamily: 'Poppins', fontSize: '13px' }}
                    >
                      {capitalize(moment(item.month).format('MMMM YYYY'))}
                    </Typography>
                  )}
                </Grid>
                <Grid item className={classes.rating} container direction="row">
                  <Grid item>
                    <RatingComponent value={item.note} readOnly size="small" />
                  </Grid>
                  <Grid item style={{ paddingLeft: '3px' }}>
                    <Typography
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                      }}
                    >
                      {getNote(item.note)}%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item container direction="row" className={classes.gridItem}>
              <Typography className={classes.input}>
                {t('v_detail.notFoundUsageQualite')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [
      classes.gridItem,
      classes.input,
      classes.inputRating,
      classes.rating,
      getNote,
      indices,
      t,
    ],
  )* */
  const renderGrayCarte = useCallback(
    () => (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.half}
      >
        {registrationCard ? (
          <img src={carte} alt="carte" className={classes.carte} />
        ) : (
          <img src={noCarte} alt="noCarte" className={classes.carte} />
        )}
        <Typography
          onClick={!registrationCard ? undefined : openDocs}
          className={classes.carteText}
        >
          {t('vehicleHeader.grayCarte')}
        </Typography>
      </Grid>
    ),
    [
      classes.carte,
      classes.carteText,
      classes.half,
      openDocs,
      registrationCard,
      t,
    ],
  )
  const renderAssurence = useCallback(
    () => (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.half}
      >
        {insuranceCard ? (
          <img src={greencarte} alt="Greencarte" className={classes.carte} />
        ) : (
          <img
            src={noGreencarte}
            alt="noGreencarte"
            className={classes.carte}
          />
        )}
        <Typography
          onClick={!insuranceCard ? undefined : openDocs}
          className={classes.carteText}
        >
          {t('vehicleHeader.assurence')}
        </Typography>
      </Grid>
    ),
    [
      classes.half,
      classes.carte,
      classes.carteText,
      insuranceCard,
      openDocs,
      t,
    ],
  )
  const renderAutoAndOdometre = useCallback(
    () => (
      <Grid item container direction="row" className={classes.autoAndOdometre}>
        {lastTracking && lastTracking.soc ? (
          <Grid item container direction="row" className={classes.half}>
            <Grid item className={classes.icon30}>
              {GetBatteryIcon(lastTracking.soc)}
            </Grid>
            <Grid item container direction="column" className={classes.seventy}>
              <Grid item>
                <Typography className={classes.title}>
                  {t('v_detail.batteryLevel')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {t('global.progress', {
                    value: Math.min(
                      Math.max(Math.round(lastTracking.soc * 100), 0),
                      100,
                    ),
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {vehicle.odometer >= 0 ? (
          <Grid
            item
            container
            direction="row"
            className={
              lastTracking && lastTracking.soc ? classes.half : undefined
            }
          >
            <Grid item className={classes.icon30}>
              <img src={odometre} alt="odometre" className={classes.small} />
            </Grid>
            <Grid item container direction="column" className={classes.seventy}>
              <Grid item>
                <Typography className={classes.title}>
                  {t('v_detail.odometer')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {t('global.uniteKm', {
                    value: Math.round(vehicle.odometer),
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    ),
    [
      GetBatteryIcon,
      classes.autoAndOdometre,
      classes.half,
      classes.icon30,
      classes.seventy,
      classes.small,
      classes.text,
      classes.title,
      lastTracking,
      t,
      vehicle.odometer,
    ],
  )
  return useMemo(
    () =>
      !lastTrip ? null : (
        <Accordion expanded={expanded} onChange={handleExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontFamily: 'Comfortaa',
              }}
            >
              {t('vehicleHeader.title')}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <Tracking />
              </Grid>
              <Grid item container direction="row" className={classes.root}>
                <Grid item className={classes.vehicle}>
                  <Grid item container direction="column" alignItems="center">
                    {renderModelAvatarAndNumberplate()}
                    {loadingDocs ? (
                      <CircularProgress />
                    ) : (
                      <Grid item container direction="row" alignItems="center">
                        {renderAssurence()}
                        {renderGrayCarte()}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!lastTrip && !lastTracking ? null : (
                  <>
                    <Grid item>
                      <div className={classes.hezLigne} />
                    </Grid>
                    <Grid item container className={classes.tracking}>
                      {renderLastTracking()}
                      {lastTrip ? (
                        <>
                          {renderLastTrip()}
                          {renderAutoAndOdometre()}
                        </>
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ),
    [
      classes.hezLigne,
      classes.root,
      classes.tracking,
      classes.vehicle,
      expanded,
      handleExpanded,
      lastTracking,
      lastTrip,
      loadingDocs,
      renderAssurence,
      renderAutoAndOdometre,
      renderGrayCarte,
      renderLastTracking,
      renderLastTrip,
      renderModelAvatarAndNumberplate,
      t,
    ],
  )
}
export default React.memo(Header)
