import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '15px',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridRoot: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3),
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3),
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))
export default StylesWithMaterialUi
