import mapConstants from '../constants/redux/map.constants '
const initialState = { zoom: null, bounds: null }

export function map(state = initialState, action) {
  switch (action.type) {
    case mapConstants.MAP:
      return {
        zoom: action.payload.zoom,
        bounds: action.payload.bounds,
      }
    case mapConstants.CLEAR:
      return initialState
    default:
      return state
  }
}
