export const vehiclesConstants = {
  INIT: 'FETCH_VEHICLES_INIT',
  SUCCESS: 'FETCH_VEHICLES_SUCCESS',
  ADD: 'FETCH_VEHICLES_ADD',
  UPDATE: 'FETCH_VEHICLES_UPDATE',
  UPDATE_WITH_SOCKET: 'FETCH_VEHICLES_UPDATE_WITH_SOCKET',
  MENU: 'FETCH_VEHICLES_MENU',
  FAILURE: 'FETCH_VEHICLES_FAILURE',
  REMOVE: 'FETCH_VEHICLES_REMOVE',
  HIDE: 'FETCH_VEHICLES_HIDE',
  CLEAR: 'FETCH_VEHICLES_CLEAR',
  TOOLTIP: 'SET_VEHICLES_TOOLTIP',
  TRIP: 'SET_VEHICLE_TRIP',
  LAST_TRAKING: 'SET_VEHICLE_LAST_TRAKING',
}
