/* eslint-disable import/no-cycle */
import { socketConstants } from '../constants'

const initialState = {
  socket: {},
  data: {},
  subscribe: false,
  status: false,
}

const socket = (state = initialState, action) => {
  switch (action.type) {
    case socketConstants.INIT:
      return {
        subscribe: false,
        socket: action.payload.socket,
        data: action.payload.data,
        creationDate: action.payload.creationDate,
        status: true,
      }
    case socketConstants.SUBSCRIBE:
      return {
        ...state,
        socket: action.payload.socket,
        data: action.payload.data,
        subscribe: true,
      }
    case socketConstants.RECONNECT:
      return {
        state,
      }
    case socketConstants.UNSUBSCRIBE:
      return {
        ...state,
        socket: action.payload.socket,
        subscribe: false,
      }
    case socketConstants.CLOSE:
      return initialState
    case socketConstants.FAILED:
      return initialState
    default:
      return state
  }
}

export { socket }
