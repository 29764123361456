import axios from 'axios'

export const externalService = {
  get,
  post,
}
const catchError = (err, source) => {
  if (err.response && err.response.status === 500) {
    // TO Do fix in page vehicle with account user
    throw Error(`${err.response.status} - ${JSON.stringify(err.response.data)}`)
  }
  if (axios.isCancel(err)) {
    source.cancel()
    return {
      content: { message: JSON.stringify(err.message) },
    }
  }
  if (err.response) {
    // TO do status 501 et 502 win api its down
    throw Error(` ${JSON.stringify(err.response)}`)
  }
  if (err) {
    return {
      content: { message: JSON.stringify(err.message) },
    }
  }
  return {}
}
async function request(method, baseURL, route, jwt, query, body, headers) {
  const source = axios.CancelToken.source()

  try {
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    const requestHeaders = {
      ...defaultHeaders,
      ...headers,
    }
    if (jwt) {
      requestHeaders.Authorization = `JWT ${jwt}`
    }
    const res = await axios.request({
      method,
      baseURL,
      url: route,
      headers: requestHeaders,
      params: query,
      data: body,
      validateStatus: (status) => status < 500,
    })

    return {
      status: res.status,
      content: res.data,
    }
  } catch (err) {
    // eslint-disable-next-line consistent-return
    return catchError(err, source)
  }
}

async function get(baseURL, route, jwt, query) {
  return request('get', baseURL, route, jwt, query)
}
async function post(baseURL, route, jwt, query, body) {
  return request('post', baseURL, route, jwt, query, body)
}
