/* eslint-disable import/no-cycle */
import React from 'react'
import { Tooltip, Grid, IconButton, Box } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteIcon from '@material-ui/icons/Delete'
import StylesWithMaterialUi from './EditDocuments.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import RegistrationCard from './components/registrationCard/RegistrationCard'
import InsuranceCard from './components/insuranceCard/InsuranceCard'
import DropZone from '../../DropZone/DropZone'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import FileView from '../../DropZone/components/fileView/FilesView'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import useController from './EditDocuments.controller'
import TechnicalControl from './components/technicalControl/TechnicalControl'
import PV from './components/PV/PV'

const EdiDocuments = (props) => {
  const { onclose, document, vehicleName } = props
  const {
    handleSubmit,
    t,
    docName,
    handleChange,
    selectedFiles,
    expirationDate,
    handleChangeDate,
    disabled,
    open,
    checkFilesId,
    addToRemoveList,
    setSelectedFiles,
    setData,
    setExpirationDate,
    setDisabled,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <form name="form" onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="filled"
              label={t('v_detail.nameInputVehicle')}
              value={vehicleName}
              type="text"
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="outlined"
              label={t('documents.name')}
              value={docName}
              onChange={handleChange}
              type="text"
              name="docName"
              fullWidth
              margin="normal"
              required
            />
          </Grid>

          {document &&
            document.files.map((o) => (
              <Grid
                key={o.id}
                item
                container
                direction="row"
                className={classes.gridItem}
              >
                <Grid
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  container
                  style={{ whiteSpace: 'nowrap', width: '80%' }}
                >
                  <Tooltip title={o.name ? o.name : o.id}>
                    <Box
                      component="div"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      style={checkFilesId(o.id) ? { color: 'red' } : null}
                    >
                      {o.name ? o.name : o.id}
                    </Box>
                  </Tooltip>
                </Grid>{' '}
                <Grid
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  item
                  container
                  style={{ width: '20%' }}
                >
                  <IconButton
                    aria-label="download"
                    edge="end"
                    onClick={() => addToRemoveList(o.id)}
                    style={{ marginRight: '-20px' }}
                  >
                    {checkFilesId(o.id) ? (
                      <DeleteForeverIcon />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          <Grid item container direction="row" className={classes.gridItem}>
            <DropZone
              multiple
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Grid>

          {selectedFiles && selectedFiles.length > 0 ? (
            <Grid item container direction="row" className={classes.gridItem}>
              <FileView
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
          ) : null}
          {document && document.type === 'Other' ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <InputComponent
                fullWidth
                margin="normal"
                variant="outlined"
                label={t('documents.expirationDate')}
                value={expirationDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeDate}
                type="date"
                name="expirationDate"
              />
            </Grid>
          ) : null}
          {document && document.type === 'RegistrationCard' ? (
            <RegistrationCard setData={setData} document={document} />
          ) : null}
          {document && document.type === 'PV' ? (
            <PV setData={setData} document={document} />
          ) : null}
          {document && document.type === 'InsuranceCard' ? (
            <InsuranceCard
              setData={setData}
              setExpirationDate={setExpirationDate}
              document={document}
              setDisabled={setDisabled}
            />
          ) : null}
          {document && document.type === 'TechnicalControl' ? (
            <TechnicalControl
              setData={setData}
              document={document}
              setExpirationDate={setExpirationDate}
              setDisabled={setDisabled}
            />
          ) : null}

          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_Disagree')}
                className={classes.btn}
                onClick={onclose}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                disabled={disabled}
                className={classes.btn}
                startIcon={<SaveIcon />}
                type="submit"
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </form>

        <BackdropComponent className={classes.backdrop} open={open} />
      </Grid>
    </Box>
  )
}

export default React.memo(EdiDocuments)
