const Localization = (t) => ({
  textLabels: {
    body: {
      noMatch: t('t_body.noMatch'),
      toolTip: t('t_body.toolTip'),
      columnHeaderTooltip: (column) =>
        `${t('t_body.columnHeaderTooltip')} ${column.label}`,
    },
    pagination: {
      next: t('t_pagination.next'),
      previous: t('t_pagination.toolTip'),
      rowsPerPage: t('t_pagination.previous'),
      displayRows: t('t_pagination.displayRows'),
    },
    toolbar: {
      search: t('t_toolbar.search'),
      downloadCsv: t('t_toolbar.downloadCsv'),
      print: t('t_toolbar.print'),
      viewColumns: t('t_toolbar.viewColumns'),
      filterTable: t('t_toolbar.filterTable'),
    },
    filter: {
      all: t('t_filter.all'),
      title: t('t_filter.title'),
      reset: t('t_filter.reset'),
    },
    viewColumns: {
      title: t('t_viewColumns.title'),
      titleAria: t('t_viewColumns.titleAria'),
    },
    selectedRows: {
      text: t('t_selectedRows.text'),
      delete: t('t_selectedRows.delete'),
      deleteAria: t('t_selectedRows.deleteAria'),
    },
  },
  datePicker: {
    startText: t('ranger_piker.start_date'),
    endText: t('ranger_piker.end_date'),
    to: t('ranger_piker.to'),
    cancelText: t('ranger_piker.cancel'),
    clearText: t('ranger_piker.clear'),
    todayText: t('ranger_piker.today'),
    toolbarTitle: t('ranger_piker.title'),
    okText: t('ranger_piker.ok'),
    btnText: t('table.v_lastTracking_date'),
  },
})
export default Localization
