/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useState, createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fullResponse, VehicleDocumentsHelpers } from '../../../../../helpers'
import { documentsService, vehiclesService } from '../../../../../services'
import { alertActions, routerActions } from '../../../../../actions'
import { routerConstants } from '../../../../../constants'

const useController = (props) => {
  const { vehicle, expanded } = props
  const user = useSelector((state) => state.authentication.user)

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [open, setOpen] = useState(false)
  const [doc, setDoc] = useState(null)
  const dispatch = useDispatch()
  const refDialog = createRef()

  const handleDelete = useCallback(async (id, docName) => {
    if (id && docName) {
      setDoc({ id, name: docName })
      setOpen(true)
    }
  }, [])

  const handleCancelPopup = useCallback(() => {
    setOpen(!open)
  }, [open])

  const fetchDocuments = useCallback(async () => {
    try {
      if (vehicle && vehicle.id) {
        setLoading(true)
        const res = await vehiclesService.getDocuments(vehicle.id)
        setData(res.content.data ? res.content.data : null)
        setLoading(false)
        return
      }
      setData(null)
    } catch (e) {
      setLoading(false)
    }
  }, [vehicle])

  const handlePopup = useCallback(async () => {
    if (doc) {
      const res = await documentsService.deleteDocuments(doc.id)
      if (!res || res.status !== 204) {
        dispatch(alertActions.error(res.content.message, 6000, true))
        setOpen(!open)
        return
      }
      setOpen(!open)

      dispatch(alertActions.success(t('v_detail.v_delete_msg'), 6000, true))
      await fetchDocuments()
    }
  }, [dispatch, doc, fetchDocuments, open, t])

  useEffect(() => {
    const abortController = new AbortController()
    const fetch = async () => {
      try {
        setData(null)
        if (vehicle && vehicle.id && expanded) {
          setLoading(true)
          const res = await vehiclesService.getDocuments(vehicle.id)
          if (fullResponse(res, 200)) {
            setData(res.content.data)
          }
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    }
    fetch()
    return () => {
      abortController.abort()
    }
  }, [vehicle, vehicle.id, expanded])

  const addDocument = useCallback(() => {
    dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
    VehicleDocumentsHelpers.AddVehicleDocuments(vehicle, dispatch, t)
  }, [dispatch, t, vehicle])

  const showDocumentDetail = useCallback(
    (docId) => {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehicleDocumentsHelpers.ShowVehicleDocumentDetails(
        vehicle,
        docId,
        dispatch,
        t,
      )
    },
    [dispatch, t, vehicle],
  )
  const editDocument = useCallback(
    (docId) => {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehicleDocumentsHelpers.EditVehicleDocuments(vehicle, docId, dispatch, t)
    },
    [dispatch, t, vehicle],
  )

  return {
    data,
    addDocument,
    editDocument,
    handleDelete,
    showDocumentDetail,
    handleCancelPopup,
    open,
    handlePopup,
    loading,
    doc,
    refDialog,
    t,
    user,
  }
}
export default useController
