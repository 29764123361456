import React, { forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
} from '@material-ui/core'
import ButtonComponent from '../Button/ButtonComponent'

const DialogBox = forwardRef((props, ref) => {
  const {
    contentText,
    handleCancelBox,
    handleClose,
    text,
    action,
    open,
    title,
    styleContent,
    ...other
  } = props
  const { t } = useTranslation()
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  return (
    <Dialog
      ref={resolvedRef}
      open={open}
      {...other}
      style={{ overflow: 'hidden' }}
    >
      <DialogTitle style={{ fontFamily: 'Comfortaa' }}>{title}</DialogTitle>
      <DialogContent
        style={styleContent || { fontFamily: 'Comfortaa', overflow: 'hidden' }}
      >
        {action ? (
          contentText
        ) : (
          <DialogContentText style={{ fontFamily: 'Poppins' }}>
            {text}
          </DialogContentText>
        )}
      </DialogContent>
      {action ? null : (
        <DialogActions style={{ overflow: 'hidden' }}>
          <Grid
            container
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <ButtonComponent
                className={{
                  fontSize: '12px',
                  fontWeight: 'Bold',
                  fontFamily: 'Comfortaa',
                  textTransform: 'none',
                  width: '110px',
                  height: '31px',
                }}
                variant="outlined"
                tooltiptitle={t('v_detail.box_Disagree')}
                onClick={() => handleCancelBox()}
                color="primary"
                styles
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                styles
                className={{
                  fontSize: '12px',
                  fontWeight: 'Bold',
                  fontFamily: 'Comfortaa',
                  textTransform: 'none',
                  width: '110px',
                  height: '31px',
                }}
                variant="outlined"
                tooltiptitle={t('v_detail.box_Agree')}
                onClick={() => handleClose()}
                color="primary"
                autoFocus
                id="box_Agree"
              >
                {t('v_detail.box_Agree')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
})

export default DialogBox
