import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchSorter } from 'match-sorter'

const useController = (props) => {
  const { model } = props
  const { t } = useTranslation()
  const [documentValue, setDocumentValue] = useState('')
  const [data, setData] = useState(model && model.length > 0 ? model : [])
  const inputRef = useRef()

  useEffect(() => {
    const abortController = new AbortController()
    const getDocument = () => {
      if (documentValue) {
        const list = matchSorter(model, documentValue, {
          keys: ['name', 'vehicle.ref', 'vehicle.vin', 'vehicle.name'],
        })
        setData(list)
      } else {
        setData(model)
      }
    }
    getDocument()
    return () => {
      abortController.abort()
    }
  }, [documentValue, model])

  const handleChange = useCallback((e) => {
    setDocumentValue(e.target.value)
  }, [])

  return { data, t, documentValue, handleChange, inputRef }
}

export default useController
