import React from 'react'
import { Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DialogBox from '../../utils/MatrialUiComponents/DialogBox/DialogBox'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import StylesWithMaterialUi from './DeleteVehicle.styles'
// eslint-disable-next-line import/no-cycle
import useController from './DeleteVehicle.controller'

const DeleteVehicle = (props) => {
  const {
    vehId,
    drawer,
    handleDelete,
    handleCancelPopup,
    open,
    handlePopup,
    t,
    refDialog,
  } = useController(props)
  const { name, color, variant } = props

  const classes = StylesWithMaterialUi()

  return (
    <>
      {drawer ? (
        <ButtonComponent
          startIcon={<DeleteIcon />}
          tooltiptitle={t('v_detail.btn_delete')}
          onClick={(e) => handleDelete(e, vehId)}
          variant={variant}
          color={color}
          className={classes.button}
        >
          {t('v_detail.btn_delete')}
        </ButtonComponent>
      ) : (
        <Typography
          onClick={(e) => handleDelete(e, vehId)}
          style={{
            fontFamily: 'Comfortaa',
            fontWeight: '12px',
          }}
        >
          {t('v_detail.btn_delete')}
        </Typography>
      )}
      <DialogBox
        onClose={handleCancelPopup}
        ref={refDialog}
        action={false}
        open={open}
        title=""
        text={` ${t('v_detail.box_text')} ${name}`}
        handleClose={handlePopup}
        handleCancelBox={handleCancelPopup}
      />
    </>
  )
}

export default DeleteVehicle
