/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

const RegistrationCard = (props) => {
  const { document } = props
  const { t } = useTranslation()

  return useMemo(
    () =>
      Object.keys(document.data).map((key, index) =>
        document.data[key] ? (
          <Grid item key={`${key}- ${index}`} container direction="column">
            <Grid item>
              <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                }}
              >
                {document.data[key]}
              </Typography>
            </Grid>
          </Grid>
        ) : null,
      ),
    [document.data, t],
  )
}
export default RegistrationCard
