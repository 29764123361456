/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
import { io } from 'socket.io-client'

import { authenticate } from '../system/auth.service'
import { getConfig } from '../../helpers/system/system.helpers'

const subscribe = (socket, data) => {
  if (!socket) {
    return
  }
  return socket.emit('tracking.subscribe', data)
}
const disconnectSocket = (socket) => {
  if (!socket) {
    return
  }
  socket.disconnect()
}

const unsubscribe = (socket, data) => {
  if (!socket) {
    return
  }
  return socket.emit('tracking.unsubscribe', data)
}
const connectSocket = async () => {
  const auth = await authenticate()
  if (!auth || !auth.authToken) {
    // ... ouvrir la page login ou msg d'avertissement
    return
  }

  const host = `${getConfig().jimini.socket_base_url}/client`
  if (auth && auth.authToken) {
    return io(host, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      auth: {
        jwttoken: `JWT ${auth.authToken}`,
      },
    })
  }
}
const listeningSocket = (socket, setResponse, setMessage) => {
  let interval = null

  interval = setInterval(() => {
    // -- ping toutes les 30 minutes
    socket.emit('ding')
  }, 600000)

  socket.on('tracking.data', (data) => {
    setResponse(data)
  })

  socket.on('tracking.subscribe.error', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: `tracking.subscribe.error `,
      type: 'error',
    })
  })

  socket.on('tracking.subscribe.ok', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: `tracking.subscribe.ok received`,
      type: 'success',
    })
  })

  socket.on('tracking.unsubscribe.error', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: `tracking.unsubscribe.error received`,
      type: 'error',
    })
  })

  socket.on('reconnect', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: 'reconnect',
      type: 'success',
    })
  })
  socket.on('reconnect_attempt', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: 'reconnect_attempt',
      type: 'error',
    })
  })
  socket.on('reconnect_failed', () => {
    if (!setMessage) {
      return
    }
    setMessage({
      text: 'reconnect_failed',
      type: 'error',
    })
  })
  socket.on('connect_error', (err) => {
    if (!setMessage) {
      return
    }
    setMessage({ text: `connect error ${err.message}`, type: 'error' })
    setTimeout(() => {
      socket.connect()
    }, 1000)
  })

  socket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect()
    }
    clearInterval(interval)
  })
}
export const socketService = {
  subscribe,
  connectSocket,
  listeningSocket,
  disconnectSocket,
  unsubscribe,
}
