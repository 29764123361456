/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { momentConstants } from '../../../../../constants'

const useController = (props) => {
  const { setData, document, setDisabled, setExpirationDate } = props

  const { t } = useTranslation()
  const [dateError, setDateError] = useState(false)
  const [inputs, setInputs] = useState({
    a3: '',
    a4: '',
    a5: '',
    a6: '',
    a7: '',
    a9: '',
    a10: '',
  })
  const { a4, a5, a6, a7, a9, a10 } = inputs

  const [inputsDate, setInputsDate] = useState({
    a3Start: '',
    a3End: '',
  })
  const { a3Start, a3End } = inputsDate

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  const handleChangeDate = useCallback((e) => {
    const { name, value } = e.target
    setInputsDate((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const checkWithMoment = () => {
      if (moment(a3End).isSameOrBefore(a3Start)) {
        setDateError(true)
        setDisabled(true)
      } else {
        setDateError(false)
        setDisabled(false)
      }
    }
    checkWithMoment()
    return () => {
      abortController.abort()
    }
  }, [a3End, a3Start, setDisabled])

  useEffect(() => {
    const abortController = new AbortController()
    const setInputsValue = () => {
      if (inputs || (a3Start && a3End)) {
        inputs.a3 = { start: a3Start, end: a3End }
        setExpirationDate(a3End)
        setData({ ...inputs })
      }
    }
    setInputsValue()
    return () => {
      abortController.abort()
    }
  }, [a3Start, inputs, a3End, setData, setExpirationDate])

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = async () => {
      if (document && document.data) {
        setInputs({
          a4: document.data.a4 || '',
          a5: '',
          a6: document.data.a6 || '',
          a7: document.data.a7 || '',
          a9: document.data.a9 || '',
          a10: document.data.a10 || '',
        })
        if (document.data.a3) {
          setInputsDate({
            a3Start:
              moment(document.data.a3.start).format(
                momentConstants.FORMAT_text_field,
              ) || '',
            a3End:
              moment(document.data.a3.end).format(
                momentConstants.FORMAT_text_field,
              ) || '',
          })
        }
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [document])

  return {
    a10,
    a3End,
    a3Start,
    a4,
    a5,
    a6,
    a7,
    a9,
    dateError,
    handleChangeDate,
    t,
    handleChange,
  }
}
export default useController
