const headerVehiclesNames = (t, download) => [
  {
    name: t('table.v_ref'),
    download: true,
  },
  {
    name: t('table.v_vin'),
    download: true,
  },
  {
    name: t('table.v_name'),
    download: true,
  },
  {
    name: t('table.v_numberplate'),
    download: true,
  },
  {
    name: t('table.v_model'),
    download: true,
  },
  {
    name: t('table.v_fleet'),
    download: true,
  },
  {
    name: t('table.v_owner'),
    download,
  },
  {
    name: t('table.v_boitier'),
    download: true,
  },
  {
    name: t('table.v_adresse'),
    download: true,
  },
  {
    name: t('table.v_odometerDownload'),
    download: true,
  },
  {
    name: t('table.v_lastTracking_date'),
    download: true,
  },
]
export { headerVehiclesNames }
