/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'
import BarChartIcon from '@material-ui/icons/BarChart'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'
import { getPermissions } from '../../../../../helpers'
import useController from './BtnGroup.controller'
import StylesWithMaterialUi from '../../UserDetails.styles'
import BackdropComponent from '../../../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import DialogBox from '../../../../utils/MatrialUiComponents/DialogBox/DialogBox'

const BtnGroup = (props) => {
  const { handleExpanded, expanded } = props

  const {
    accordionAction,
    auth,
    t,
    showUserEdit,
    sendResetPasswordEmail,
    user,
    sendInviteUserEmail,
    open,
    handleDelete,
    openDialog,
    handleCancelPopup,
    handlePopup,
    refDialog,
    showRapport,
    checkUser,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return (
    <Accordion
      ref={accordionAction}
      expanded={expanded}
      onChange={handleExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('v_detail.actions')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {auth && getPermissions(auth.type, 'write') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonSmall}
                startIcon={<EditIcon />}
                onClick={showUserEdit}
                tooltiptitle={t('v_detail.btn_edit')}
              >
                {t('v_detail.btn_edit')}
              </ButtonComponent>
            </Grid>
          ) : null}

          {user.status && user.status !== 'Active' ? null : (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonFull}
                startIcon={<VpnKeyIcon />}
                onClick={sendResetPasswordEmail}
                tooltiptitle={t('users.resetPasswordBtn')}
              >
                {t('users.resetPasswordBtn')}
              </ButtonComponent>
            </Grid>
          )}
          {user && user.type === 'Owner' && user.status === 'Active' ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonSmall}
                startIcon={<BarChartIcon />}
                onClick={showRapport}
                tooltiptitle={t('v_detail.btn_rapport')}
              >
                {t('v_detail.btn_rapport')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && user.status && user.status !== 'Pending' ? null : (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonFull}
                startIcon={<EmailIcon />}
                onClick={sendInviteUserEmail}
                tooltiptitle={t('users.inviteBtn')}
              >
                {t('users.inviteBtn')}
              </ButtonComponent>
            </Grid>
          )}
          {checkUser() ? null : (
            <Grid item>
              <ButtonComponent
                startIcon={<DeleteIcon />}
                tooltiptitle={t('v_detail.btn_delete')}
                onClick={handleDelete}
                className={classes.buttonSmall}
              >
                {t('v_detail.btn_delete')}
              </ButtonComponent>
            </Grid>
          )}
        </Grid>

        <BackdropComponent className={classes.backdrop} open={open} />
        <DialogBox
          onClose={handleCancelPopup}
          ref={refDialog}
          action={false}
          open={openDialog}
          title=""
          text={` ${t('users.box_text')} ${user.name}`}
          handleClose={handlePopup}
          handleCancelBox={handleCancelPopup}
        />
      </AccordionDetails>
    </Accordion>
  )
}
export default BtnGroup
