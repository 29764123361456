/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  vehiclesService,
  filesService,
  vehicleIncidentService,
} from '../../../services'
import { emptyResponse } from '../../../helpers'
import { momentConstants, incidentConstants } from '../../../constants'
import { alertActions } from '../../../actions'
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'

const useController = (props) => {
  const { vehicle, onclose } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [open, setOpen] = useState(false)
  const [init, setInit] = useState(true)
  const vehicleName =
    vehicle && vehicle.ref && vehicle.name
      ? t('v_detail.inputVehicleWithRef', {
          ref: vehicle.ref,
          name: vehicle.name,
        })
      : vehicle.ref
  const socket = useSelector((state) => state.socket)
  const location = useLocation()
  const [dataSwitch, setDataSwitch] = useState(incidentConstants.incidents)
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      date: moment(new Date()).format(momentConstants.FORMAT_text_field),
      comment: '',
      odometer: Math.floor(vehicle.odometer),
      name: vehicleName,
    },
  })

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])

  useEffect(() => {
    const abortController = new AbortController()
    const setSwitch = () => {
      if (!open && init) {
        setDataSwitch([
          {
            value: false,
            name: 'accidentWithReport',
            prop: 'accident_with_report',
          },
          {
            value: false,
            name: 'accidentWithoutReport',
            prop: 'accident_without_report',
          },
          { value: false, name: 'frontPuncture', prop: 'front_puncture' },
          { value: false, name: 'rearPuncture', prop: 'rear_puncture' },
          { value: false, name: 'rightSideFall', prop: 'right_ride_fall' },
          { value: false, name: 'leftSideFall', prop: 'left_side_fall' },
          { value: false, name: 'frontSideImpact', prop: 'front_side_impact' },
          { value: false, name: 'rightSideImpact', prop: 'right_side_impact' },
          { value: false, name: 'leftSideImpact', prop: 'left_side_impact' },
        ])
        setInit(false)
      }
    }
    setSwitch()

    return () => {
      abortController.abort()
    }
  }, [init, open])

  const handleChangeSwitch = useCallback(
    (event) => {
      setDataSwitch(
        dataSwitch.map((item) => {
          if (item.name === event.target.name) {
            // eslint-disable-next-line no-param-reassign
            item.value = event.target.checked
            return item
          }
          return item
        }),
      )
    },
    [dataSwitch],
  )

  const checkIncidentSwitch = useCallback(() => {
    const incidents = dataSwitch.filter((item) => item.value === true)
    if (incidents.length > 0) {
      const incidentObj = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const incident of incidents) {
        incidentObj[incident.prop] = incident.value
      }
      return incidentObj
    }
    return {}
  }, [dataSwitch])

  const submitIncidents = useCallback(
    async (data) => {
      try {
        const { date, odometer, comment } = data
        setOpen(true)
        let files = []
        if (selectedFiles.length > 0) {
          const formData = new FormData()
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i])
          }

          const sendFiles = await filesService.postFiles(formData)

          if (emptyResponse(sendFiles, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                sendFiles && sendFiles.content
                  ? sendFiles.content.message
                  : t('global.error'),
                6000,
                true,
              ),
            )
            return
          }
          files = sendFiles.content.data.files
        }
        const body = {
          type: 'Accident',
          date: moment(date).toISOString(),
        }
        if (odometer && Number(odometer) >= 0) {
          body.odometer = Number(odometer)
        }
        if (comment) {
          body.comment = comment
        }
        body.data = checkIncidentSwitch()
        const res = await vehiclesService.incidents(vehicle.id, body)
        if (emptyResponse(res, 201)) {
          setOpen(false)
          dispatch(
            alertActions.error(
              res && res.content ? res.content.message : t('global.error'),
              6000,
              true,
            ),
          )
          return
        }
        if (files.length > 0) {
          const addFilesToIncident = await vehicleIncidentService.addFiles(
            res.content.data.id,
            { files },
          )
          if (emptyResponse(addFilesToIncident, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                addFilesToIncident && addFilesToIncident.content
                  ? addFilesToIncident.content.message
                  : t('global.error'),
                6000,
                true,
              ),
            )
            return
          }
        }
        setOpen(false)
        onclose()
        dispatch(
          alertActions.success(t('incidents.incidentSubmit'), 6000, true),
        )
      } catch (e) {
        setOpen(false)
        dispatch(alertActions.error(e.message, 6000, true))
      }
    },
    [checkIncidentSwitch, dispatch, onclose, selectedFiles, t, vehicle.id],
  )

  return {
    onclose,
    selectedFiles,
    setSelectedFiles,
    submitIncidents,
    open,
    t,
    form,
    handleChangeSwitch,
    dataSwitch,
  }
}
export default useController
