import { makeStyles } from '@material-ui/core/styles'
import generic from '../../assets/images/upload/generic.png'

const StylesWithMaterialUi = makeStyles((theme) => ({
  gridRoot: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2),
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
    },
  },
  gridListItem: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridListItemBtn: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
  },

  dropContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    width: '100%',
    height: '114px',
    border: '2px dashed',
    borderColor: '#D3D3D3',
    cursor: 'pointer',
  },
  dropContainerHover: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    width: '100%',
    height: '114px',
    border: '2px dashed',
    borderColor: '#1D71B8',
    cursor: 'pointer',
    transition: theme.transitions.create('borderColor', {
      duration: theme.transitions.duration.complex,
    }),
  },
  dropMessage: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  gridItemUpload: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  btn: {
    fontSize: '15px',
    fontWeight: '300',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    color: 'black',
  },
  fileInput: {
    display: 'none',
  },
  icon: {
    fontSize: 20,
    color: 'black',
  },

  fileStatusBar: {
    width: '100%',
    display: 'flex',
  },

  fileType: {
    display: 'inline-block !important',
    position: 'absolute',
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '13px',
    marginTop: '13px',
    marginLeft: '2px',
    padding: '0 2px',
    borderRadius: '2px',
    boxShadow: '1px 1px 2px #abc',
    color: '#fff',
    backgroundColor: '#0080c8',
    textTransform: 'uppercase',
  },

  fileError: {
    color: 'red',
  },

  fileErrorMessage: {
    color: 'red',
  },
  fileName: {
    whiteSpace: 'nowrap',
  },

  fileTypeLogo: {
    width: '30px',
    height: '30px',
    background: `url(${generic}) no-repeat center center`,
    backgroundSize: '100%',
    position: 'absolute',
  },
  fileSize: {
    marginTop: theme.spacing(2),
  },
  fileRemove: {
    color: 'red',
  },
}))
export default StylesWithMaterialUi
