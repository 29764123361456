/* eslint-disable import/no-cycle */
import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'

import { FormProvider } from 'react-hook-form'
import StylesWithMaterialUi from './RestoreVehicleMaintenance.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import useController from './RestoreVehicleMaintenance.controller'
import FormController from '../../utils/FormController/FormController'

const RestoreVehicleMaintenance = (props) => {
  const { updateMaintenance, onclose, t, form } = useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <div className={classes.gridRoot}>
      <Grid
        container
        direction="column"
        spacing={1}
        alignItems="center"
        className={classes.gridContainer}
      >
        <FormProvider {...form}>
          <FormController
            name="name"
            render={({ value }) => (
              <InputComponent
                variant="filled"
                label={t('v_detail.nameInputVehicle')}
                value={value || ''}
                type="text"
                name="name"
                disabled
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="descriptionName"
            render={({ value }) => (
              <InputComponent
                variant="filled"
                label={t('v_detail.nameOperation')}
                value={value}
                type="text"
                name="descriptionName"
                disabled
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="odometerValue"
            render={({ value, onChange }) => (
              <InputComponent
                variant="outlined"
                label={t('v_detail.odometerInput')}
                value={value}
                type="number"
                name="odometerValue"
                fullWidth
                margin="normal"
                onChange={onChange}
              />
            )}
          />
          <FormController
            name="date"
            render={({ value, ref, onChange, onBlur, error }) => (
              <TextField
                ref={ref}
                onBlur={onBlur}
                error={error}
                fullWidth
                margin="normal"
                variant="outlined"
                type="date"
                name="date"
                defaultValue={value}
                onChange={onChange}
                label={t('v_detail.dateMaintenance')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                className={classes.btn}
                onClick={onclose}
                tooltiptitle={t('v_detail.box_Disagree')}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_AgreeSave')}
                onClick={form.handleSubmit(updateMaintenance)}
                startIcon={<SaveIcon />}
                className={classes.btn}
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </div>
  )
}
export default RestoreVehicleMaintenance
