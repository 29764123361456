import fleet from '../../assets/images/icons/fleet.png'
import trip from '../../assets/images/icons/trip.png'
import map from '../../assets/images/icons/map.png'
import gateway from '../../assets/images/icons/gateway.png'
import vehicle from '../../assets/images/icons/vehicle.png'
import login from '../../assets/images/icons/login.png'
import warning from '../../assets/images/icons/warning.png'
import incidents from '../../assets/images/icons/incidents.png'
import users from '../../assets/images/icons/group.png'
import onTime from '../../assets/images/icons/on-time.png'
import data from '../../assets/images/icons/extractData.png'

export const menuListConstants = {
  isLoggedList: [
    { name: 'map', route: 'router.home', icon: map, enabled: true },
    {
      name: 'vehicles',
      route: 'router.vehicles',
      icon: vehicle,
      enabled: true,
    },
    {
      name: 'vehicleTrips',
      route: 'router.trips',
      icon: trip,
      enabled: true,
    },
    {
      name: 'vehicleAlerts',
      route: 'router.alerts',
      icon: warning,
      enabled: true,
    },
    {
      name: 'vehicleFleets',
      route: 'router.fleets',
      icon: fleet,
      enabled: true,
    },
    {
      name: 'gateways',
      route: 'router.gateways',
      icon: gateway,
      enabled: true,
    },
    {
      name: 'vehicleIncidents',
      route: 'router.incidents',
      icon: incidents,
      enabled: true,
    },
    { name: 'users', route: 'router.users', icon: users, enabled: true },
    {
      name: 'quickAccess',
      route: 'router.quickAccess',
      icon: onTime,
      enabled: true,
    },
    {
      name: 'data',
      route: 'router.data',
      icon: data,
      style: {
        bottom: '5%',
        position: 'fixed',
        width: '10%',
      },
      enabled: true,
    },
  ],
  isLoggedListReadOnly: [
    { name: 'map', route: 'router.home', icon: map, enabled: true },
    {
      name: 'vehicles',
      route: 'router.vehicles',
      icon: vehicle,
      enabled: true,
    },
    {
      name: 'trips',
      route: 'router.trips',
      icon: trip,
      enabled: true,
    },
  ],
  isLoggedListCustom: [
    { name: 'map', route: 'router.home', icon: map, enabled: true },
    {
      name: 'vehicles',
      route: 'router.vehicles',
      icon: vehicle,
      enabled: true,
    },
    {
      name: 'trips',
      route: 'router.trips',
      icon: trip,
      enabled: true,
    },
    {
      name: 'alerts',
      route: 'router.alerts',
      icon: warning,
      enabled: true,
    },
    {
      name: 'flottes',
      route: 'router.fleets',
      icon: fleet,
      enabled: true,
    },
    {
      name: 'incidents',
      route: 'router.incidents',
      icon: incidents,
      enabled: true,
    },
    { name: 'users', route: 'router.users', icon: users, enabled: true },
  ],
  isLoggedOutList: [{ name: 'login', route: 'router.login', icon: login }],
}
