import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CancelIcon from '@material-ui/icons/Cancel'
import { FormProvider } from 'react-hook-form'
import CSVLinkComponent from '../utils/Csv/CSVComponent'
import StylesWithMaterialUi from './Report.styles'
import InputComponent from '../utils/MatrialUiComponents/InputComponent/InputComponent'
import ButtonComponent from '../utils/MatrialUiComponents/Button/ButtonComponent'
import BackdropComponent from '../utils/MatrialUiComponents/Backdrop/BackdropComponent'
// eslint-disable-next-line import/no-cycle
import useController from './Report.controller'
import FormController from '../utils/FormController/FormController'

const Report = (props) => {
  const {
    handleClick,
    onCloseDrawer,
    anchorRef,
    disabled,
    data,
    openLoading,
    handleCloseLoadingData,
    separator,
    csvLink,
    fileName,
    headers,
    form,
    getLabel,
  } = useController(props)

  const { t } = useTranslation()
  const classes = StylesWithMaterialUi()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <FormProvider {...form}>
          <Grid item container direction="row" className={classes.gridItem}>
            <FormController
              name="name"
              render={({ value }) => (
                <InputComponent
                  variant="filled"
                  label={getLabel()}
                  value={value}
                  type="text"
                  name="name"
                  disabled
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item container direction="row" className={classes.gridItem}>
            <FormController
              name="dateMin"
              render={({ value, ref, onChange, onBlur, error }) => (
                <InputComponent
                  fullWidth
                  onBlur={onBlur}
                  error={error}
                  ref={ref}
                  margin="normal"
                  variant="outlined"
                  label={t('ranger_piker.start_date')}
                  value={value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChange}
                  type="date"
                  name="dateMin"
                  required
                />
              )}
            />
          </Grid>
          <Grid item container direction="row" className={classes.gridItem}>
            <FormController
              name="dateMax"
              render={({ value, ref, onChange, onBlur, error }) => (
                <InputComponent
                  fullWidth
                  onBlur={onBlur}
                  error={error}
                  ref={ref}
                  margin="normal"
                  variant="outlined"
                  label={t('ranger_piker.end_date')}
                  value={value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChange}
                  type="date"
                  name="dateMax"
                  required
                />
              )}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            className={classes.gridItemSwitch}
          >
            <FormController
              name="radioValue"
              render={({ value, onChange }) => (
                <RadioGroup
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="semicolon"
                    control={<Radio color="primary" />}
                    label={t('vhReport.csvWithSemicolon')}
                  />
                  <FormControlLabel
                    value="comma"
                    control={<Radio color="primary" />}
                    label={t('vhReport.csv')}
                  />
                  <FormControlLabel
                    value="pdf"
                    control={<Radio color="primary" />}
                    label={t('vhReport.pdf')}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                onClick={onCloseDrawer}
                tooltiptitle={t('v_detail.box_Disagree')}
                startIcon={<CancelIcon style={{ fontSize: '20' }} />}
                className={classes.btn}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('vhReport.download')}
                startIcon={
                  <ArrowDownwardIcon
                    style={{ fontSize: '20', marginRight: '-5px' }}
                  />
                }
                className={classes.btn}
                ref={anchorRef}
                variant="outlined"
                disabled={disabled}
                type="submit"
                onClick={form.handleSubmit(handleClick)}
              >
                {t('vhReport.download')}
              </ButtonComponent>
            </Grid>
            <CSVLinkComponent
              data={data}
              headers={headers}
              filename={fileName}
              ref={csvLink}
              separator={separator}
            />
          </Grid>
        </FormProvider>
      </Grid>
      <BackdropComponent
        className={classes.backdrop}
        open={openLoading}
        onClick={handleCloseLoadingData}
      />
    </Box>
  )
}
export default Report
