/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Tooltip } from '@material-ui/core'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import RefreshIcon from '@material-ui/icons/Refresh'
import moment from 'moment'
import { vehiclesService } from '../../../../../services'
import { fullResponse } from '../../../../../helpers'
import { alertActions } from '../../../../../actions'
import Columns from './components/columns/Columns'
import MakeDate from './components/MakeData'
import CustomOption from './components/CustomOption'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import { momentConstants } from '../../../../../constants'

const useController = (props) => {
  const { data, expanded } = props
  const { t } = useTranslation()
  const [uses, setUses] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [vehicle, setVehicle] = useState(null)
  const dispatch = useDispatch()
  const classes = StylesWithMaterialUi()
  const refDialog = createRef()
  const [inputs, setInputs] = useState({
    endDate: moment(new Date()).format(momentConstants.FORMAT),
    startDate: moment(new Date()).format(momentConstants.FORMAT),
    startOdometer: 0,
    endOdometer: 0,
  })

  const handleCancelPopup = useCallback(() => {
    setOpenDialog(false)
  }, [])

  const handleLoadMore = useCallback(
    async (pageNumber) => {
      try {
        setIsLoading(true)
        if (vehicle && vehicle.id) {
          if (isRefresh) {
            return
          }
          setIsRefresh(true)
          const offset = pageNumber * 5

          const query = { limit: 5, offset }
          const res = await vehiclesService.getUses(vehicle.id, query)
          if (fullResponse(res, 200)) {
            if (offset > page) {
              setTotal((state) => state + res.content.data.length)
            } else {
              setTotal((state) => state - res.content.data.length)
            }
            setPage(offset)
            setUses(res.content.data)
          }
        }

        setIsRefresh(false)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        setIsRefresh(false)
        setPage(0)
      }
    },
    [isRefresh, page, vehicle],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const updateData = () => {
      if (data && expanded) {
        setVehicle(data)
      }
    }
    updateData()
    return () => {
      abortController.abort()
    }
  }, [data, expanded])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchUses = async () => {
      try {
        setLoading(true)
        setPage(0)
        if (data && data.id && expanded) {
          const query = { limit: 5 }
          const res = await vehiclesService.getUses(data.id, query)
          if (fullResponse(res, 200)) {
            setUses(res.content.data)
            setTotal(res.content.data.length)
            setLoading(false)
            return
          }
        }
        setTotal(0)
        setUses([])
        setLoading(false)
        setIsRefresh(false)
        setIsLoading(false)
      } catch (e) {
        setLoading(false)
        setUses([])
        setIsRefresh(false)
        setIsLoading(false)
        setPage(0)
      }
    }
    fetchUses()
    return () => {
      abortController.abort()
    }
  }, [data, expanded])

  const refresh = useCallback(
    async (e) => {
      try {
        setIsLoading(true)
        if (data && data.id && expanded) {
          const query = { limit: 5, offset: page }
          const res = await vehiclesService.getUses(data.id, query)
          if (fullResponse(res, 200)) {
            setUses(res.content.data)
          }
          if (e) {
            if (data.id) {
              const queryUpdate = { id: data.id }
              const resUpdate = await vehiclesService.getVehicles(queryUpdate)
              if (!resUpdate || resUpdate.status !== 200) {
                dispatch(
                  alertActions.error(
                    resUpdate.content.data.message,
                    6000,
                    true,
                  ),
                )
                return
              }
              const updateVehicle = resUpdate.content.data[0]
              updateVehicle.display = true
              setVehicle(updateVehicle)
            }
          }
          setIsLoading(false)
        }
      } catch (err) {
        dispatch(alertActions.error(err.message, 6000, true))
      }
    },
    [data, dispatch, expanded, page],
  )

  const updateState = useCallback(
    async (id) => {
      try {
        if (id) {
          const queryUpdate = { id }
          const resUpdate = await vehiclesService.getVehicles(queryUpdate)
          if (!resUpdate || resUpdate.status !== 200) {
            dispatch(
              alertActions.error(resUpdate.content.data.message, 6000, true),
            )
            return
          }
          const updateVehicle = resUpdate.content.data[0]
          updateVehicle.display = true
          setVehicle(updateVehicle)
          await refresh()
        }
      } catch (e) {
        dispatch(alertActions.error(e.message, 6000, true))
      }
    },
    [dispatch, refresh],
  )
  const checkOdometer = useCallback(
    (odometer) => Number(odometer) >= 0,

    [],
  )
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inp) => ({ ...inp, [name]: value }))
  }, [])

  const createUse = useCallback(
    async (e) => {
      try {
        e.preventDefault()
        if (vehicle && vehicle.id) {
          const body = {
            startDate: moment(new Date()).toISOString(),
          }
          if (inputs.startOdometer && checkOdometer(inputs.startOdometer)) {
            body.startOdometer = inputs.startOdometer
          }
          const res = await vehiclesService.createUse(vehicle.id, body)
          if (!res || res.status !== 204) {
            dispatch(alertActions.error(res.content.message, 6000, true))
            return
          }
          setOpenDialog(false)

          // update state
          await updateState(vehicle.id)
        }
      } catch (err) {
        dispatch(alertActions.error(err.message, 6000, true))
      }
    },
    [checkOdometer, dispatch, inputs.startOdometer, updateState, vehicle],
  )

  const deleteUse = useCallback(
    async (e) => {
      try {
        e.preventDefault()
        /**
      if (user && user.id !== vehicle.currentUse.user.id) {
        dispatch(alertActions.error(t('uses.notAllow'), 6000, true))
        return
      }* */
        if (vehicle && vehicle.id) {
          const body = {
            endDate: moment(new Date()).toISOString(),
          }
          if (inputs.endOdometer && checkOdometer(inputs.endOdometer)) {
            body.endOdometer = inputs.endOdometer
          }
          const res = await vehiclesService.deleteUse(vehicle.id, body)
          if (!res || res.status !== 204) {
            dispatch(alertActions.error(res.content.message, 6000, true))
            return
          }
          setOpenDialog(false)
          // update state
          await updateState(vehicle.id)
        }
      } catch (err) {
        dispatch(alertActions.error(err.message, 6000, true))
      }
    },
    [checkOdometer, dispatch, inputs.endOdometer, updateState, vehicle],
  )

  /**  const useVehicle = useCallback(async () => {
    try {
      if (vehicle) {
        if (!vehicle.currentUse) {
        let alertList = []
          const query = { vehicleId: vehicle.id }
          const getAlert = await jiminiAlertsService.getAlerts(query)
          if (fullResponse(getAlert, 200)) {
            alertList = getAlert.content.data.filter(
              (x) => x.type === 'Maintenance',
            )
          }
          if (alertList && alertList.length > 0) {
            setMessage(
              t('uses.message', {
                value: DateComponent(alertList[0].date, 'll'),
              }),
            )
            setOpenDialog(true)
            return
          }
          await createUse()
          await refresh()
          return
        }
        await deleteUse()
      }
    } catch (e) {
      dispatch(alertActions.error(e.message, 6000, true))
    }
  }, [createUse, deleteUse, dispatch, vehicle])* */

  const handlePopup = useCallback(async () => {
    if (vehicle) {
      if (vehicle.currentUse) {
        setInputs((inp) => ({
          ...inp,
          endDate: moment(new Date()).format(momentConstants.FORMAT),
          startOdometer: 0,
          endOdometer: 0,
        }))
      } else {
        setInputs((inp) => ({
          ...inp,
          startDate: moment(new Date()).format(momentConstants.FORMAT),
          startOdometer: 0,
          endOdometer: 0,
        }))
      }
      setOpenDialog(true)
    }
  }, [vehicle])

  const CustomToolBar = () => (
    <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}>
      <Tooltip
        title={
          vehicle && vehicle.currentUse
            ? t('v_detail.btnReturnVehicle')
            : t('v_detail.btnUseVehicle')
        }
      >
        <Button
          startIcon={
            vehicle && vehicle.currentUse ? (
              <KeyboardReturnIcon />
            ) : (
              <DirectionsRunIcon />
            )
          }
          onClick={handlePopup}
          color="primary"
          variant="outlined"
          className={classes.useBtn}
          size="small"
        >
          {vehicle && vehicle.currentUse
            ? t('v_detail.btnReturnVehicle')
            : t('v_detail.btnUseVehicle')}
        </Button>
      </Tooltip>

      <Tooltip title={t('global.refresh')}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          className={classes.refreshBtn}
          onClick={(e) => {
            refresh(e)
          }}
          startIcon={<RefreshIcon />}
        >
          {t('global.refresh')}
        </Button>
      </Tooltip>
    </Box>
  )
  const columnsTable = Columns()
  const tableData = useMemo(() => MakeDate(uses), [uses])
  const customOption = CustomOption(handleLoadMore, total, CustomToolBar)

  return {
    t,
    loading,
    columnsTable,
    tableData,
    customOption,
    isLoading,
    handleCancelPopup,
    openDialog,
    inputs,
    handleChange,
    vehicle,
    createUse,
    deleteUse,
    refDialog,
  }
}
export default useController
