/* eslint-disable import/no-cycle */
import React from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import BarChartIcon from '@material-ui/icons/BarChart'
import CancelIcon from '@material-ui/icons/Cancel'
import StylesWithMaterialUi from './FleetDetails.styles'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import DeleteFleet from '../DeleteFleet/DeleteFleet'
import useController from './FleetDetails.controller'
import { getPermissions } from '../../../helpers'

const FleetDetails = (props) => {
  const { fleet } = props
  const { onCloseDrawer, showFleetEdit, showFleetReport, t, settings } =
    useController(props)
  const classes = StylesWithMaterialUi()

  const user = useSelector((state) => state.authentication.user)

  return (
    <div className={classes.gridRoot}>
      {fleet && Array.isArray(fleet) ? (
        fleet.map((item) => (
          <Grid
            container
            direction="column"
            key={item.id}
            spacing={1}
            alignItems="flex-start"
            className={classes.gridContainer}
          >
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography className={classes.text}>
                  {t('v_detail.nameFleet')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.input}>{item.name}</Typography>
              </Grid>
            </Grid>
            {user && getPermissions(user.type, 'showOwner') ? (
              <Grid
                item
                container
                direction="row"
                spacing={1}
                className={classes.gridItem}
              >
                <Grid item>
                  <Typography className={classes.text}>
                    {t('v_detail.owner')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.input}>
                    {item.owner.name}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {settings.settings.drawers &&
            settings.settings.drawers.vehicleFleet &&
            settings.settings.drawers.vehicleFleet.actions ? (
              <Grid
                item
                container
                direction="row"
                className={classes.gridItemBtn}
                spacing={2}
              >
                <Grid item>
                  <ButtonComponent
                    onClick={onCloseDrawer}
                    tooltiptitle={t('v_detail.box_Disagree')}
                    startIcon={<CancelIcon />}
                    className={classes.button}
                  >
                    {t('v_detail.box_Disagree')}
                  </ButtonComponent>
                </Grid>
                {user && getPermissions(user.type, 'editFleet') ? (
                  <Grid item>
                    <ButtonComponent
                      className={classes.button}
                      startIcon={<EditIcon />}
                      tooltiptitle={t('v_detail.btn_edit')}
                      onClick={() => showFleetEdit(item)}
                    >
                      {t('v_detail.btn_edit')}
                    </ButtonComponent>
                  </Grid>
                ) : null}
                {user && getPermissions(user.type, 'read') ? (
                  <Grid item>
                    <ButtonComponent
                      className={classes.button}
                      startIcon={<BarChartIcon />}
                      onClick={showFleetReport}
                      tooltiptitle={t('v_detail.btn_rapport')}
                    >
                      {t('v_detail.btn_rapport')}
                    </ButtonComponent>
                  </Grid>
                ) : null}

                {user && getPermissions(user.type, 'deleteFleet') ? (
                  <Grid item>
                    <DeleteFleet drawer fleetId={item.id} name={item.name} />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        ))
      ) : (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default FleetDetails
