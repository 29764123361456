const MakeDate = (data) => {
  if (data && Array.isArray(data)) {
    return data.map((item) => {
      const container = {}
      container.user = item.user ? item.user.name : null
      container.status = item.status || null
      container.startOdometer =
        item.startOdometer >= 0 ? item.startOdometer : null
      container.endOdometer = item.endOdometer >= 0 ? item.endOdometer : null
      container.startDate = item.startDate || null
      container.endDate = item.endDate || null
      return container
    })
  }
  return null
}
export default MakeDate
