import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainerQrText: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
  },
  gridItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
  },
  textBody: { fontFamily: 'Poppins', fontSize: '15px' },
  button: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '190px',
    height: '31px',
  },
  buttonFull: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '100%',
    height: '31px',
  },
  buttonSmall: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: '31px',
  },
  btnGroup: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  dialogContent: { fontFamily: 'Comfortaa', overflow: 'hidden' },
  dialog: { overflow: 'hidden' },
  dialogContentText: { fontFamily: 'Poppins' },
  btnQr: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: '31px',
  },
  [`@media print`]: {
    btnQr: {
      display: 'none',
    },
  },
}))
export default StylesWithMaterialUi
