export const settingsConstants = {
  INIT: 'SETTING_INIT',
  ADD: 'SETTING_ADD',
  UPDATE: 'SETTING_UPDATE',
  UPDATE_MAP: 'SETTING_MAP_UPDATE',
  UPDATE_MAP_TOOLTIP: 'SETTING_MAP_TOOLTIP',
  FAILURE: 'SETTING_FAILURE',
  REMOVE: 'SETTING_REMOVE',
  CLEAR: 'SETTING_CLEAR',
}
