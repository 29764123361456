import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gridRoot: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonFull: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '100%',
    height: '31px',
  },
  buttonSmall: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: '31px',
  },
  button: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '190px',
    height: '31px',
  },
}))
export default StylesWithMaterialUi
