/* eslint-disable import/no-cycle */
import { axiosService } from '../system/axios.service'

const getInfo = () => {
  const route = '/me'
  return axiosService.get(true, route)
}
const updateProfile = async (body) => {
  const route = '/me'
  return axiosService.put(true, route, null, body)
}
const updateUiSettings = async (body) => {
  const route = '/me/settings/ui'
  return axiosService.put(true, route, null, body)
}

const getUiSettings = () => {
  const route = '/me/settings/ui'
  return axiosService.get(true, route)
}
const getAlertsSettings = () => {
  const route = '/me/settings/alerts'
  return axiosService.get(true, route)
}
const updateAlertsSettings = (body) => {
  const route = '/me/settings/alerts'
  return axiosService.put(true, route, null, body)
}

const createDocument = async (body) => {
  const route = '/me/documents'
  return axiosService.post(true, route, null, body)
}

const getDocuments = async (query) => {
  const route = '/me/documents'
  return axiosService.get(true, route, query)
}

export const meService = {
  getInfo,
  updateProfile,
  updateUiSettings,
  getUiSettings,
  getAlertsSettings,
  updateAlertsSettings,
  createDocument,
  getDocuments,
}
