import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-cycle
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'

const useController = (props) => {
  const { vehicle, onclose, drawer } = props
  const socket = useSelector((state) => state.socket)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const location = useLocation()

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])
  return { vehicle, onclose, drawer }
}
export default useController
