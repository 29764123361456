/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Text } from '@react-pdf/renderer'
const TableHeader = ({ item }) =>
  item && Array.isArray(item)
    ? item.map((element, index) => (
        <Text key={`header-${index}`} style={element.styleHeader}>
          {element.header}
        </Text>
      ))
    : null

export default TableHeader
