/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import React, { createRef, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import StylesWithMaterialUi from '../../EditDocuments.styles'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import useController from './InsuranceCard.controller'
const InsuranceCard = (props) => {
  const {
    a10,
    a3End,
    a3Start,
    a4,
    a5,
    a6,
    a7,
    a9,
    dateError,
    handleChangeDate,
    handleChange,
    t,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  const InputModel = useCallback(
    (value, name, required) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={handleChange}
            type="text"
            name={name}
            fullWidth
            margin="normal"
            required={required}
          />
        </Grid>
      )
    },
    [classes.gridItem, handleChange, t],
  )

  return useMemo(
    () => (
      <>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            fullWidth
            margin="normal"
            variant="outlined"
            label={t('documents.a3Start')}
            value={a3Start}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeDate}
            type="date"
            name="a3Start"
            required
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            fullWidth
            margin="normal"
            variant="outlined"
            label={t('documents.a3End')}
            value={a3End}
            error={dateError}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeDate}
            type="date"
            name="a3End"
            required
          />
        </Grid>
        {InputModel(a4, 'a4', false)}
        {InputModel(a5, 'a5', false)}
        {InputModel(a6, 'a6', false)}
        {InputModel(a7, 'a7', false)}
        {InputModel(a9, 'a9', false)}
        {InputModel(a10, 'a10', false)}
      </>
    ),
    [
      InputModel,
      a10,
      a3End,
      a3Start,
      a4,
      a5,
      a6,
      a7,
      a9,
      classes.gridItem,
      dateError,
      handleChangeDate,
      t,
    ],
  )
}
export default InsuranceCard
