import { makeStyles, createTheme } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  btn: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    fontFamily: 'Comfortaa',
  },
  addBtn: {
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
    },
  },
  refreshBtn: {
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginLeft: theme.spacing(2),
    },
  },
  refreshBtnWithoutMargin: {
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
    },
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
    },
  },
}))
const getMuiTheme = () =>
  createTheme({
    typography: {
      fontFamily: `'Comfortaa', cursive, 'Poppins', sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    overrides: {
      MuiToolbar: {
        root: {
          fontFamily: 'Comfortaa',
          fontSize: '17px',
          fontWeight: 'Bold',
        },
      },
      MuiTableCell: {
        head: {
          textAlign: 'left',
          fontFamily: 'Comfortaa',
          fontSize: '16px',
          fontWeight: 'Bold',
          maxWidth: '200px',
        },
        body: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          textAlign: 'left',
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: 'hidden',
        },
      },
    },
  })

export { StylesWithMaterialUi, getMuiTheme }
