import React, { forwardRef, useRef } from 'react'
import { CSVLink } from 'react-csv'

const CSVLinkComponent = forwardRef((props, ref) => {
  const { data, headers, filename, separator } = props
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef
  return (
    <CSVLink
      asyncOnClick
      data={data}
      headers={headers}
      filename={`${filename}.csv`}
      className="hidden"
      ref={resolvedRef}
      target="_blank"
      separator={separator}
    />
  )
})
export default CSVLinkComponent
