/* eslint-disable no-restricted-properties */
/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react'

const useController = (props) => {
  const { selectedFiles, setSelectedFiles } = props

  const removeFile = useCallback(
    (name) => {
      const index2 = selectedFiles.findIndex((e) => e.name === name)
      selectedFiles.splice(index2, 1)
      setSelectedFiles([...selectedFiles])
    },
    [selectedFiles, setSelectedFiles],
  )
  const fileSize = useCallback((size) => {
    if (size === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const sizes = ['Octets', 'ko', 'Mo', 'Go', 'To']
    const i = Math.floor(Math.log(size) / Math.log(k))
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
  }, [])

  const editName = useCallback(
    (index) => {
      selectedFiles[index].edit = true
      selectedFiles[index].newName = selectedFiles[index].name
        .split('.')
        .slice(0, -1)
        .join('.')
      setSelectedFiles(() => [...selectedFiles])
    },
    [selectedFiles, setSelectedFiles],
  )
  const changeName = useCallback(
    (e, index) => {
      selectedFiles[index].newName = e.target.value
      setSelectedFiles(() => [...selectedFiles])
    },
    [selectedFiles, setSelectedFiles],
  )
  const handleClickAway = useCallback(
    (index) => {
      if (selectedFiles[index].edit) {
        selectedFiles[index].edit = false
        setSelectedFiles(() => [...selectedFiles])
      }
    },
    [selectedFiles, setSelectedFiles],
  )

  const saveFile = useCallback(
    (index) => {
      const type = selectedFiles[index].type.split('/')
      if (selectedFiles[index].newName) {
        const newName = `${selectedFiles[index].newName.trim()}.${type[1]}`
        selectedFiles[index] = new File([selectedFiles[index]], newName, {
          type: selectedFiles[index].type,
          lastModified: selectedFiles[index].lastModified,
        })
        setSelectedFiles(() => [...selectedFiles])
      }
    },
    [selectedFiles, setSelectedFiles],
  )

  return {
    handleClickAway,
    changeName,
    editName,
    fileSize,
    saveFile,
    removeFile,
  }
}
export default useController
