// eslint-disable-next-line import/no-cycle,import/named
import { externalService } from '../system/external.service'
import { getConfig } from '../../helpers/system/system.helpers'

const search = async (query) => {
  const route = `/search`
  return externalService.get(
    getConfig().external_services.addok_base_url,
    route,
    null,
    query,
  )
}

const reverse = async (query) => {
  const route = `/reverse`
  return externalService.get(
    getConfig().external_services.addok_base_url,
    route,
    null,
    query,
  )
}

export const addressService = { search, reverse }
