import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, createRef } from 'react'
// eslint-disable-next-line import/no-cycle
import { vehiclesService } from '../../../services'
// eslint-disable-next-line import/no-cycle
import { alertActions, drawerActions, vehiclesActions } from '../../../actions'

const useController = (props) => {
  const { vehId, drawer } = props
  const dispatch = useDispatch()
  const refDialog = createRef()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [vehiculeId, setVehiculeId] = useState(null)

  const closeDrawer = useCallback(() => {
    if (drawer) {
      dispatch(drawerActions.close())
    }
  }, [dispatch, drawer])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateRedux = useCallback(
    async (id) => {
      try {
        dispatch(vehiclesActions.remove(id))
      } catch (error) {
        dispatch(vehiclesActions.failure())
      }
    },
    [dispatch],
  )
  const handleDelete = useCallback(
    (e, id) => {
      setOpen(!open)
      setVehiculeId(id)
    },
    [open],
  )

  const handlePopup = useCallback(async () => {
    if (vehiculeId) {
      const res = await vehiclesService.deleteVehicle(vehiculeId)
      if (!res || res.status !== 204) {
        dispatch(alertActions.error(res.content.message, 6000, true))
        setOpen(!open)
        return
      }
      setOpen(!open)
      dispatch(alertActions.success(t('v_detail.v_delete_msg'), 6000, true))
      closeDrawer()
      updateRedux(vehiculeId)
      return
    }
    setOpen(!open)
    dispatch(alertActions.error(t('v_detail.v_delete_msg_id'), 6000, true))
    closeDrawer()
  }, [closeDrawer, dispatch, open, t, updateRedux, vehiculeId])

  const handleCancelPopup = useCallback(() => {
    setOpen(!open)
  }, [open])

  return {
    vehId,
    drawer,
    handleDelete,
    handleCancelPopup,
    open,
    handlePopup,
    t,
    refDialog,
  }
}

export default useController
