// eslint-disable-next-line import/no-cycle
import { tripsConstants } from '../constants'
const initialState = {
  isLoading: false,
  isError: false,
}

const trips = (state = initialState, action) => {
  switch (action.type) {
    case tripsConstants.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case tripsConstants.SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: action.payload.trips,
      }

    case tripsConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case tripsConstants.CLEAR:
      return {}
    default:
      return state
  }
}

export { trips }
