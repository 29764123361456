/* eslint-disable import/no-cycle */
import moment from 'moment'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { useForm } from 'react-hook-form'
import PdfComponent from '../utils/Pdf/PdfComponent'
import { vehiclesService } from '../../services'
import { fullResponse, setHeaders, setCsvData, setPdfData } from '../../helpers'
import { alertActions, drawerActions } from '../../actions'
import { momentConstants } from '../../constants'

const useController = (props) => {
  const { value, fleetReport, onclose, drawer, isUser } = props
  const { t } = useTranslation()
  const csvLink = useRef()
  const anchorRef = useRef(null)
  const user = useSelector((state) => state.authentication.user)
  const [openLoading, setOpenLoading] = useState(false)
  const [separator, setSeparator] = useState(';')
  const dispatch = useDispatch()
  const headers = setHeaders(t, user)
  const [data, setData] = useState([])
  const [disabled, setDisabled] = useState(false)

  const getName = useCallback(() => {
    if (isUser) {
      return value.email
    }
    if (fleetReport) {
      return value.name
    }
    return value && value.ref && value.name
      ? t('v_detail.inputVehicleWithRef', {
          ref: value.ref,
          name: value.name,
        })
      : value.ref
  }, [fleetReport, isUser, t, value])

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      dateMax: moment(new Date()).format(momentConstants.FORMAT_text_field),
      dateMin: moment(new Date())
        .subtract(1, 'days')
        .format(momentConstants.FORMAT_text_field),
      name: getName(),
      radioValue: 'semicolon',
    },
  })
  const { watch } = form
  const getLabel = useCallback(() => {
    if (isUser) {
      return t('users.emailInput')
    }
    if (fleetReport) {
      return t('v_detail.v_fleetInput')
    }
    return t('v_detail.nameInputVehicle')
  }, [fleetReport, isUser, t])

  useEffect(() => {
    const abortController = new AbortController()
    const checkSemicolon = () => {
      const radioValue = form.watch('radioValue')
      if (radioValue === 'semicolon') {
        setSeparator(';')
      } else {
        setSeparator(',')
      }
    }
    checkSemicolon()
    return () => {
      abortController.abort()
    }
  }, [form])

  const onCloseDrawer = useCallback(() => {
    if (drawer) {
      onclose()
    } else {
      dispatch(drawerActions.close())
    }
  }, [dispatch, drawer, onclose])

  const handleCloseLoadingData = useCallback(() => {
    setOpenLoading(false)
  }, [])

  const [fileName, setFileName] = useState(
    `Jimini-${moment(new Date()).format(momentConstants.FORMAT_file)}`,
  )

  useEffect(() => {
    const checkMoment = () => {
      const dateMax = watch('dateMax')
      const dateMin = watch('dateMin')
      if (moment(dateMax).isSameOrBefore(dateMin)) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
    checkMoment()
  })

  const getReport = useCallback(
    async (d) => {
      try {
        const { dateMin, dateMax } = d
        let query
        if (dateMin && dateMax) {
          query = {
            dateMin: moment(dateMin).toISOString(),
            dateMax: moment(dateMax).toISOString(),
          }
          if (fleetReport) {
            query.vehicleFleetId = value.id
          } else if (isUser) {
            query.ownerId = value.id
          } else {
            query.vehicleId = value.id
          }
          const res = await vehiclesService.getUsageReport(query)
          if (fullResponse(res, 200)) {
            const name = getName()
            setFileName(name || 'data')
            return res.content.data
          }
          dispatch(alertActions.info(t('t_body.noMatch'), 6000, true))
          return []
        }
        dispatch(alertActions.info(t('vhReport.errorDate'), 6000, true))
        return []
      } catch (e) {
        dispatch(alertActions.error(e.toString(), 6000, true))
        return []
      }
    },
    [dispatch, fleetReport, getName, isUser, t, value.id],
  )

  const handleClick = useCallback(
    async (d) => {
      const { radioValue } = d
      setOpenLoading(true)
      const getData = await getReport(d)
      if (radioValue === 'pdf') {
        const formatPdfData = await setPdfData(getData, t, user)
        const doc = <PdfComponent data={formatPdfData} />
        const asPdf = pdf([])
        asPdf.updateContainer(doc)
        const blob = await asPdf.toBlob()
        saveAs(blob, `${fileName}.pdf`)
      } else {
        const formatCsvData = await setCsvData(getData, t, user)
        setData(formatCsvData)

        csvLink.current.link.click()
      }

      setOpenLoading(false)
    },
    [fileName, getReport, t, user],
  )

  return {
    handleClick,
    onCloseDrawer,
    anchorRef,
    disabled,
    data,
    openLoading,
    handleCloseLoadingData,
    separator,
    csvLink,
    fileName,
    headers,
    form,
    getLabel,
  }
}
export default useController
