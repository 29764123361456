import { Box, Typography } from '@material-ui/core'
import React from 'react'
import ProgressBar from './ProgressBar'
const ProgressBarWithLabel = (props) => {
  const { value, text } = props

  return (
    <Box display="flex" alignItems="center" style={{ width: '100%' }}>
      <Box width="100%" height="10px" mr={1}>
        <ProgressBar
          progress={value}
          warningThreshold={20}
          dangerThreshold={10}
          colors={['green', 'orange', 'red', '#c7c7c7']}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={Math.round(value) <= 0 ? { color: '#B22222' } : null}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
export default ProgressBarWithLabel
