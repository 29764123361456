/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Text } from '@react-pdf/renderer'

const TableRow = ({ item }) => (
  <>
    {item && Array.isArray(item)
      ? item.map((element, index) => (
          <Text key={`table-row-${index}`} style={element.styleBody}>
            {element.body}
          </Text>
        ))
      : null}
  </>
)

export default TableRow
