/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/named
import { alertConstants } from '../constants'

const alert = (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        open: action.payload.open,
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }
    case alertConstants.ERROR:
      return {
        open: action.payload.open,
        type: 'error',
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }

    case alertConstants.INFO:
      return {
        open: action.payload.open,
        type: 'info',
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }
    case alertConstants.CLEAR:
      return {
        message: null,
        type: null,
        autoHideDuration: null,
        open: action.payload.open,
      }
    default:
      return state
  }
}

export { alert }
