/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import * as turf from '@turf/turf'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { mapsConstants } from '../../../../../constants'
import { alertActions } from '../../../../../actions'

const useController = () => {
  const { t } = useTranslation()
  const trip = useSelector((state) => state.vehicles.trip)
  const tracking = useSelector((state) => state.vehicles.tracking)
  const [lines, setLines] = useState([])
  const [viewport, setViewport] = useState(mapsConstants.viewport)
  const dispatchRedux = useDispatch()

  useEffect(() => {
    const abortController = new AbortController()
    const fetchTracking = async () => {
      try {
        if (trip) {
          const list = []
          if (!tracking) {
            return
          }

          tracking.forEach((element) => {
            const point = turf.flip(turf.point(element.coords))
            return list.push(point.geometry.coordinates)
          })

          setLines(list)
          if (list.length > 1) {
            const line = turf.lineString(list)
            const bbox = turf.bbox(line)
            setViewport({
              bounds: [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]],
              ],
              zoomType: true,
              default: false,
            })
            return
          }
          const geo = list[0]
          setViewport({
            center: { lat: geo[0], lng: geo[1] },
            zoom: 13,
            zoomType: false,
            default: false,
          })
        }
      } catch (error) {
        dispatchRedux(alertActions.error(error.toString(), 6000, true))
      }
    }

    fetchTracking()
    return () => {
      abortController.abort()
    }
  }, [dispatchRedux, setViewport, t, tracking, trip])

  return { viewport, lines, trip, setViewport, tracking }
}

export default useController
