import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(11),
    border: '1px solid #dadde9',
    marginLeft: theme.spacing(6),
  },
}))(Tooltip)
export default HtmlTooltip
