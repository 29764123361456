/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { incidentConstants } from '../../constants'

const useController = (props) => {
  const { selectedFiles, setSelectedFiles, picture } = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const fileInputRef = useRef()
  const [errorMsg, setErrorMsg] = useState(null)
  const [hover, setHover] = useState(false)
  useEffect(() => {
    const abortController = new AbortController()
    const checkFiles = () => {
      if (selectedFiles.length === 0) {
        setErrorMsg(null)
      }
    }
    checkFiles()
    return () => {
      abortController.abort()
    }
  }, [selectedFiles.length])
  const dragOver = useCallback((e) => {
    setHover(true)
    e.preventDefault()
  }, [])

  const dragEnter = useCallback((e) => {
    e.preventDefault()
    setHover(true)
  }, [])

  const dragLeave = useCallback((e) => {
    e.preventDefault()
    setHover(false)
  }, [])
  const validateFile = useCallback(
    (file) =>
      picture
        ? incidentConstants.validTypesPicture.indexOf(file.type) !== -1
        : incidentConstants.validTypes.indexOf(file.type) !== -1,
    [picture],
  )
  const checkSize = useCallback(
    (size) => {
      const maxFilesize = 102400
      const fsize = Math.round(size / 1024)
      if (fsize > maxFilesize) {
        setErrorMsg(t('dropZone.size'))
        return false
      }
      return true
    },
    [t],
  )

  const handleFiles = useCallback(
    (files) => {
      setErrorMsg(null)
      setHover(false)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i]) && checkSize(files[i].size)) {
          if (picture) {
            setSelectedFiles([files[i]])
            return
          }
          const x = selectedFiles.find((item) => item.name === files[i].name)

          if (x) {
            setErrorMsg(t('dropZone.duplicate', { value: files[i].name }))
          } else {
            setSelectedFiles((prevArray) => [...prevArray, files[i]])
          }
        } else {
          setErrorMsg(t('dropZone.unsupportedTypes', { value: files[i].name }))
        }
      }
    },
    [checkSize, picture, selectedFiles, setSelectedFiles, t, validateFile],
  )
  const fileDrop = useCallback(
    (e) => {
      e.preventDefault()
      const { files } = e.dataTransfer
      if (files.length) {
        handleFiles(files)
      }
    },
    [handleFiles],
  )
  const filesSelected = useCallback(() => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
    }
  }, [handleFiles])

  const fileInputClicked = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  return {
    matches,
    errorMsg,
    fileInputClicked,
    t,
    fileInputRef,
    filesSelected,
    hover,
    dragOver,
    dragLeave,
    dragEnter,
    fileDrop,
  }
}
export default useController
