/* eslint-disable import/no-cycle */
import { drawerConstants } from '../constants'

export const drawerActions = {
  show,
  close,
}

function show(
  body,
  title,
  anchor,
  variant,
  direction,
  header,
  onClose,
  classes,
  className,
) {
  return {
    type: drawerConstants.SHOW,
    payload: {
      body,
      title,
      anchor,
      variant,
      direction,
      header,
      onClose,
      classes,
      className,
    },
  }
}

function close() {
  return {
    type: drawerConstants.CLOSE,
  }
}
