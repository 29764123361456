/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Box,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import { Link } from 'react-router-dom'
import React from 'react'
import { momentConstants } from '../../../../../constants'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import useController from './GeneralInformation.controller'
import {
  getPermissions,
  VehicleDetailsHelpers,
  VehiclesHelpers,
} from '../../../../../helpers'

const GeneralInformation = (props) => {
  const { vehicle, handleExpanded, expanded, lastTracking } = props
  const { accordion, user, t } = useController(props)
  const classes = StylesWithMaterialUi()

  const RenderInformation = () => (
    <Accordion expanded={expanded} onChange={handleExpanded} ref={accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('v_detail.info')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {!lastTracking && vehicle.avatar ? (
            <Grid
              item
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt={vehicle.avatar.name}
                src={VehiclesHelpers.getAvatar(
                  vehicle.avatar.id,
                  vehicle.avatar.accessToken,
                )}
                className={classes.large}
              />
            </Grid>
          ) : null}

          {vehicle.ref && vehicle.ref.trim() && vehicle.ref !== vehicle.vin ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.ref')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.ref}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {vehicle.vin && vehicle.vin.trim() ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.vin')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.vin}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {vehicle.name ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {vehicle.numberplate ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.v_numberplate')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.numberplate}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {vehicle.status === 'Blocked' ? (
            <>
              <Grid
                item
                container
                direction="row"
                spacing={1}
                className={classes.gridItem}
              >
                <Grid item>
                  <Typography
                    style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                  >
                    {t('v_detail.status')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                  >
                    {t(`v_detail.${vehicle.status}`)}
                  </Typography>
                </Grid>
              </Grid>
              {vehicle.vehicleBlocked ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography
                      style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                    >
                      {t('vehicleHeader.motif')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                    >
                      {t(`vehicleHeader.${vehicle.vehicleBlocked.type}`)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}
          {vehicle.vehicleModel ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.v_model')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.vehicleModel.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {vehicle.vehicleFleet && (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.v_fleet')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.vehicleFleet.name}
                </Typography>
              </Grid>
            </Grid>
          )}
          {user && getPermissions(user.type, 'showOwner') ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.owner')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.owner.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {vehicle.gateway && (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.boitier')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {vehicle.gateway.ref}
                </Typography>
              </Grid>
            </Grid>
          )}

          {lastTracking ? (
            <>
              {lastTracking.soc ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography
                      style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                    >
                      {t('v_detail.batteryLevel')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box display="flex" flexDirection="row">
                      <Box
                        component="div"
                        display="inline"
                        style={{ height: '20px' }}
                      >
                        {VehicleDetailsHelpers.GetBatteryIcon(lastTracking.soc)}
                      </Box>
                      <Box component="div" display="inline" ml={1}>
                        <Typography
                          style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                        >
                          {t('global.progress', {
                            value: Math.min(
                              Math.max(Math.round(lastTracking.soc * 100), 0),
                              100,
                            ),
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {lastTracking.vehicleTripId ? (
                <Grid
                  item
                  container
                  direction="row"
                  className={classes.gridItem}
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'Comfortaa',
                      }}
                    >
                      {t('v_detail.trip')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '15px',
                      }}
                      component={Link}
                      to={`${t('router.trips')}?vehicleId=${
                        vehicle.id
                      }&tripId= ${lastTracking.vehicleTripId}`}
                    >
                      {t('InProgress')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {lastTracking.date ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'Comfortaa',
                      }}
                    >
                      {t('v_detail.lastTracking_date')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                    >
                      {moment(lastTracking.date).format(
                        momentConstants.FORMAT_INIT,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {lastTracking.address ? (
                <Grid
                  item
                  container
                  direction="row"
                  className={classes.gridItem}
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'Comfortaa',
                      }}
                    >
                      {t('v_detail.adresse')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                    >
                      {lastTracking.address}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}

          {vehicle.odometer >= 0 ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('v_detail.odometer')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {t('global.uniteKm', {
                    value: Math.round(vehicle.odometer),
                  })}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )

  return <RenderInformation />
}
export default React.memo(GeneralInformation)
