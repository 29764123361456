/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/named
import { jiminiAlertsConstants } from '../constants'
const initialState = {
  isLoading: false,
  isError: false,
}
const jiminiAlerts = (state = initialState, action) => {
  switch (action.type) {
    case jiminiAlertsConstants.INIT:
      return {
        ...state,
        isLoading: true,
      }
    case jiminiAlertsConstants.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.alerts,
      }
    case jiminiAlertsConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }

    case jiminiAlertsConstants.REFRESH:
      return {
        ...state,
        data: action.payload.alerts,
      }
    case jiminiAlertsConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { jiminiAlerts }
