/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  Box,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SendIcon from '@material-ui/icons/Send'
import { FormProvider } from 'react-hook-form'
import StylesWithMaterialUi from './VehicleIncident.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import FileView from '../../DropZone/components/fileView/FilesView'
import DropZone from '../../DropZone/DropZone'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import useController from './VehicleIncident.controller'
import FormController from '../../utils/FormController/FormController'
const VehicleIncident = (props) => {
  const {
    onclose,
    selectedFiles,
    setSelectedFiles,
    submitIncidents,
    open,
    t,
    form,
    dataSwitch,
    handleChangeSwitch,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <FormProvider {...form}>
          <FormController
            name="name"
            render={({ value }) => (
              <InputComponent
                variant="filled"
                label={t('v_detail.nameInputVehicle')}
                value={value || ''}
                type="text"
                disabled
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="date"
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                error={error}
                fullWidth
                margin="normal"
                variant="outlined"
                type="date"
                name="date"
                defaultValue={value}
                onChange={onChange}
                label={t('incidents.date')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          <FormController
            name="odometer"
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                error={error}
                variant="outlined"
                label={t('v_detail.odometerInput')}
                value={value > 0 ? value : ''}
                type="number"
                name="odometer"
                fullWidth
                margin="normal"
                onChange={onChange}
              />
            )}
          />
          <FormController
            name="comment"
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                error={error}
                variant="outlined"
                label={t('incidents.comment')}
                placeholder={t('incidents.commentPlaceHolder')}
                value={value}
                type="text"
                onChange={onChange}
                multiline
                name="comment"
                fullWidth
                rows={4}
                margin="normal"
              />
            )}
          />
          <Grid
            item
            container
            direction="row"
            className={classes.gridItemSwitch}
          >
            <FormGroup>
              {dataSwitch.map((item, index) => (
                <FormControlLabel
                  key={`${item.name}--${index}`}
                  control={
                    <Switch
                      checked={item.value}
                      onChange={handleChangeSwitch}
                      name={item.name}
                      color="primary"
                    />
                  }
                  label={t(`incidents.${item.name}`)}
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid item container direction="row">
            <DropZone
              multiple
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Grid>
          {selectedFiles && selectedFiles.length > 0 ? (
            <Grid item container direction="row" className={classes.gridItem}>
              <FileView
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                className={classes.btn}
                onClick={onclose}
                tooltiptitle={t('v_detail.box_Disagree')}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('incidents.submit')}
                onClick={form.handleSubmit(submitIncidents)}
                startIcon={<SendIcon />}
                className={classes.btn}
              >
                {t('incidents.submit')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>

      <BackdropComponent className={classes.backdrop} open={open} />
    </Box>
  )
}
export default VehicleIncident
