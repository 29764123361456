/* eslint-disable import/extensions */
import React from 'react'
import { Box, Grid, Tooltip } from '@material-ui/core'
import { getConfig } from '../system/system.helpers'
import InputComponent from '../../components/utils/MatrialUiComponents/InputComponent/InputComponent'

const CreatData = (value, valueKey, t) => {
  if (valueKey === 'files' && value && Array.isArray(value)) {
    return value && value.length > 0 ? (
      <Grid container direction="column" spacing={1} alignItems="center">
        {value.map((o) => (
          <Grid key={o.id} item container direction="row">
            <Grid
              item
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <Tooltip title={o.name ? o.name : o.id}>
                <Box component="div" textOverflow="ellipsis" overflow="hidden">
                  -{' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      color: '#0000FF',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getConfig().jimini.api_base_url}/files/${
                      o.id
                    }?accessToken=${o.accessToken}`}
                    download={o.name}
                  >
                    {o.name ? o.name : o.id}
                  </a>
                </Box>
              </Tooltip>
            </Grid>{' '}
          </Grid>
        ))}
      </Grid>
    ) : null
  }
  if (valueKey === 'data' && value) {
    return (
      <Grid container direction="column" spacing={1} alignItems="flex-start">
        {Object.entries(value).map(([key]) => (
          <Grid item key={key}>
            - {t(`incidents.${key}`)}
          </Grid>
        ))}
      </Grid>
    )
  }
  if (valueKey === 'comment' && value) {
    return (
      <InputComponent
        variant="outlined"
        value={value}
        label={t('vehicleIncident.comment')}
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        name="comment"
        fullWidth
        margin="normal"
        multiline
      />
    )
  }
  return null
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export { CreatData, TabPanel }
