/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import HtmlToolTip from '../../../../utils/MatrialUiComponents/ToolTips/HtmlToolTip'
import ProgressBarWithLabel from '../../../../utils/MatrialUiComponents/ProgressBar/ProgressBarWithLabel'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'
import useController from './Maintenances.controller'
import { ToolTipTitle } from './vehicleMaintenances.helpers'
import { getPermissions } from '../../../../../helpers'

const Maintenances = (props) => {
  const { handleExpanded, expanded } = props
  const { maintenancesLoading, maintenances, restoreMaintenances, t, user } =
    useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('v_detail.maintenance')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {maintenancesLoading ? (
          <Grid
            container
            alignItems="center"
            className={classes.progressMaintenances}
          >
            <CircularProgress size={20} />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="flex-start"
            style={{ marginTop: '5px' }}
          >
            {maintenances ? (
              maintenances.map((item) => (
                <HtmlToolTip
                  key={item.description.id}
                  title={<ToolTipTitle value={item} t={t} />}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    alignContent="center"
                    justifyContent="flex-start"
                  >
                    {item.description ? (
                      <Grid item className={classes.gridItemName}>
                        <Typography
                          style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                        >
                          {item.description.name}
                        </Typography>
                      </Grid>
                    ) : null}
                    {item.nextOperation ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        className={classes.gridItemProgress}
                      >
                        <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          style={
                            user &&
                            getPermissions(user.type, 'restoreMaintenances')
                              ? { width: '70%' }
                              : { width: '100%' }
                          }
                        >
                          <ProgressBarWithLabel
                            value={Math.min(
                              Math.max(
                                item.nextOperation.wearProgression * 100,
                                0,
                              ),
                              100,
                            )}
                            text={t('global.progress', {
                              value: Math.min(
                                Math.max(
                                  Math.round(
                                    item.nextOperation.wearProgression * 100,
                                  ),
                                  0,
                                ),
                                100,
                              ),
                            })}
                          />
                        </Grid>
                        {user &&
                        getPermissions(user.type, 'restoreMaintenances') ? (
                          <Grid
                            item
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            container
                            style={{
                              width: '30%',
                            }}
                          >
                            <ButtonComponent
                              startIcon={<AddIcon />}
                              tooltiptitle={t('v_detail.operationTooltip')}
                              onClick={() =>
                                restoreMaintenances(
                                  item.description.id,
                                  item.description.name,
                                )
                              }
                            >
                              {t('v_detail.operation')}
                            </ButtonComponent>
                          </Grid>
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>
                </HtmlToolTip>
              ))
            ) : (
              <Grid item container direction="row" className={classes.gridItem}>
                <Typography className={classes.input}>
                  {t('v_detail.notFoundMaintenance')}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
export default Maintenances
