/* eslint-disable import/no-cycle */
import React from 'react'
import { CircularProgress, Grid, Box } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { FormProvider } from 'react-hook-form'
import StylesWithMaterialUi from './EditFleet.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import useController from './EditFleet.controller'
import FormController from '../../utils/FormController/FormController'

const EditFleet = (props) => {
  const { fleet } = props
  const { open, form, handleCancel, handleEdit, t, validateFleetName } =
    useController(props)
  const classes = StylesWithMaterialUi()

  const renderEditGrid = () =>
    fleet ? (
      <>
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <FormProvider {...form}>
            <FormController
              name="fleetName"
              validate={validateFleetName}
              render={({ value, error, onChange }) => (
                <InputComponent
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  label={t('v_detail.nameInputFleet')}
                  type="text"
                  name="fleetName"
                  error={error}
                  value={value}
                  onChange={onChange}
                  inputProps={{ size: 'small' }}
                  required
                />
              )}
            />
            <Grid
              item
              container
              direction="row"
              spacing={4}
              className={classes.gridItemBtn}
            >
              <Grid item>
                <ButtonComponent
                  className={classes.button}
                  tooltiptitle={t('v_detail.box_Disagree')}
                  size="medium"
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                  startIcon={<CancelIcon />}
                >
                  {t('v_detail.box_Disagree')}
                </ButtonComponent>
              </Grid>
              <Grid item>
                <ButtonComponent
                  className={classes.button}
                  color="primary"
                  size="medium"
                  variant="outlined"
                  tooltiptitle={t('v_detail.box_AgreeSave')}
                  onClick={form.handleSubmit(handleEdit)}
                  startIcon={<SaveIcon />}
                >
                  {t('v_detail.box_AgreeSave')}
                </ButtonComponent>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
        <BackdropComponent className={classes.backdrop} open={open} />
      </>
    ) : (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      {renderEditGrid()}
    </Box>
  )
}

export default EditFleet
