import React from 'react'
// eslint-disable-next-line import/no-cycle
import Report from '../../Report/Report'
// eslint-disable-next-line import/no-cycle
import useController from './VehicleReport.controller'

const VehicleReport = (props) => {
  const { vehicle, onclose, drawer } = useController(props)

  return <Report value={vehicle} onclose={onclose} drawer={drawer} />
}
export default VehicleReport
