/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/named
import { drawerNavigationConstants } from '../constants'
const initialState = {
  open: false,
}
const drawerNavigation = (state = initialState, action) => {
  switch (action.type) {
    case drawerNavigationConstants.SHOW:
      return {
        open: true,
      }
    case drawerNavigationConstants.CLOSE:
      return initialState

    default:
      return state
  }
}

export { drawerNavigation }
