import { useTranslation } from 'react-i18next'

import DateComponent from '../../../../../../DataTable/components/DateComponent'
import DistanceComponent from '../../../../../../DataTable/components/DistanceComponent'

const Columns = () => {
  const { t } = useTranslation()

  return [
    {
      label: t('uses.user'),
      name: 'user',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: t('uses.status'),
      name: 'status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? t(value) : null),
      },
    },
    {
      label: t('uses.startDate'),
      name: 'startDate',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '150px' },
        }),
        customBodyRender: (value) =>
          value ? DateComponent(value, 'lll') : null,
      },
    },
    {
      label: t('uses.endDate'),
      name: 'endDate',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: '150px' },
        }),
        customBodyRender: (value) =>
          value ? DateComponent(value, 'lll') : null,
      },
    },

    {
      label: t('uses.startOdometer'),
      name: 'startOdometer',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) =>
          value ? DistanceComponent(value, t) : null,
      },
    },
    {
      label: t('uses.endOdometer'),
      name: 'endOdometer',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) =>
          value ? DistanceComponent(value, t) : null,
      },
    },
  ]
}

export default Columns
