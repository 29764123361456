/* eslint-disable import/no-cycle */
import { usersConstants } from '../constants'

const request = () => ({
  type: usersConstants.REQUEST,
})
const update = (user) => ({
  type: usersConstants.UPDATE,
  payload: { user },
})
const success = (data) => ({
  type: usersConstants.SUCCESS,
  payload: { data },
})
const failure = () => ({ type: usersConstants.FAILURE })
const remove = (id) => ({ type: usersConstants.REMOVE, payload: { id } })

const clear = () => ({ type: usersConstants.CLEAR })

export const usersActions = {
  request,
  success,
  failure,
  update,
  clear,
  remove,
}
