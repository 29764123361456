import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(20),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(20),
    },
  },
}))

export default StylesWithMaterialUi
