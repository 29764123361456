/* eslint-disable import/no-cycle */
import React from 'react'
import { Grid, Box, Tooltip, IconButton } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormProvider } from 'react-hook-form'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'

import ComboBox from '../../utils/MatrialUiComponents/ComboBox/ComboBox'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import CircularProgressComponent from '../../utils/MatrialUiComponents/CircularProgress/CircularProgressComponent'
import DropZone from '../../DropZone/DropZone'
import FileView from '../../DropZone/components/fileView/FilesView'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import useController from './EditVehicle.controller'
import StylesWithMaterialUi from './EditVehicle.styles'
import { getPermissions } from '../../../helpers'
import FormController from '../../utils/FormController/FormController'

const EditVehicle = (props) => {
  const { vehicle, onclose } = props
  const {
    handleEdit,
    setOpenVehicleModels,
    openVehicleModels,
    vehicleModels,
    loadingVehicleModels,
    setVehicleFleetInput,
    setVehicleModelInput,
    fleets,
    openFleets,
    gateways,
    openGateways,
    open,
    handleDeleteAvatar,
    selectedFiles,
    setSelectedFiles,
    setOpenFleets,
    loadingFleets,
    vehicleModelInput,
    vehicleFleetInput,
    setOpenGateways,
    gatewayInput,
    loadingGateways,
    deleteAvatar,
    setGatewayInput,
    t,
    user,
    form,
    validateNumberPlate,
    validateVehicleName,
    validateValue,
  } = useController(props)
  // hook

  // style
  const classes = StylesWithMaterialUi()

  // render methode
  const renderEditGrid = () =>
    vehicle ? (
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <FormProvider {...form}>
          <FormController
            validate={validateValue}
            name="ref"
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                variant={
                  user && !getPermissions(user.type, 'editRef')
                    ? 'filled'
                    : 'outlined'
                }
                onBlur={onBlur}
                label={t('v_detail.refInput')}
                type="text"
                name="ref"
                error={error}
                value={value}
                onChange={onChange}
                disabled={user && !getPermissions(user.type, 'editRef')}
                required
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="vin"
            validate={validateValue}
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                required
                variant={
                  user && !getPermissions(user.type, 'editVin')
                    ? 'filled'
                    : 'outlined'
                }
                label={t('v_detail.vinInput')}
                type="text"
                name="vin"
                disabled={user && !getPermissions(user.type, 'editVin')}
                error={error}
                value={value}
                onChange={onChange}
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="vehicleName"
            validate={validateVehicleName}
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                variant="outlined"
                label={t('v_detail.nameInput')}
                type="text"
                name="vehicleName"
                error={error}
                value={value}
                onChange={onChange}
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="numberplate"
            validate={validateNumberPlate}
            render={({ value, ref, onChange, onBlur, error }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                variant={
                  user && !getPermissions(user.type, 'editVehicleImmat')
                    ? 'filled'
                    : 'outlined'
                }
                label={t('v_detail.v_numberplateInput')}
                value={value}
                type="text"
                disabled={
                  user && !getPermissions(user.type, 'editVehicleImmat')
                }
                error={error}
                onChange={onChange}
                name="numberplate"
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="vehicleModelId"
            render={({ value, ref, onChange, onBlur, error }) => (
              <ComboBox
                ref={ref}
                onBlur={onBlur}
                autoComplete
                margin="normal"
                fullWidth
                variant={
                  user && !getPermissions(user.type, 'editVehicleModel')
                    ? 'filled'
                    : 'outlined'
                }
                disabled={
                  user && !getPermissions(user.type, 'editVehicleModel')
                }
                open={openVehicleModels}
                onOpen={() => {
                  setOpenVehicleModels(true)
                }}
                onClose={() => {
                  setOpenVehicleModels(false)
                }}
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                onInputChange={(event, newInputValue) => {
                  setVehicleModelInput(newInputValue)
                }}
                value={value || ''}
                inputValue={vehicleModelInput}
                name="vehicleModelId"
                options={vehicleModels}
                getOptionSelected={(option, v) => option.name === v.name}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                loading={loadingVehicleModels}
                loadingText={t('global.loading')}
                label={t('v_detail.modeleSelect')}
                error={error}
              />
            )}
          />
          <FormController
            name="vehicleFleet"
            render={({ value, ref, onChange, onBlur, error }) => (
              <ComboBox
                ref={ref}
                onBlur={onBlur}
                autoComplete
                fullWidth
                error={error}
                variant={
                  user && !getPermissions(user.type, 'editVehicleFleet')
                    ? 'filled'
                    : 'outlined'
                }
                disabled={
                  user && !getPermissions(user.type, 'editVehicleFleet')
                }
                margin="normal"
                open={openFleets}
                onOpen={() => {
                  setOpenFleets(true)
                }}
                onClose={() => {
                  setOpenFleets(false)
                }}
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                onInputChange={(e, newInputValue) => {
                  setVehicleFleetInput(newInputValue)
                }}
                loadingText={t('global.loading')}
                value={value || ''}
                inputValue={vehicleFleetInput}
                name="vehicleFleet"
                options={fleets}
                getOptionSelected={(option, v) => option.name === v.name}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                loading={loadingFleets}
                label={t('v_detail.v_fleetInput')}
              />
            )}
          />
          <FormController
            name="gateway"
            render={({ value, ref, onChange, onBlur, error }) => (
              <ComboBox
                fullWidth
                margin="normal"
                autoComplete
                ref={ref}
                onBlur={onBlur}
                error={error}
                variant={
                  user && !getPermissions(user.type, 'editVehicleGateway')
                    ? 'filled'
                    : 'outlined'
                }
                disabled={
                  user && !getPermissions(user.type, 'editVehicleGateway')
                }
                open={openGateways}
                onOpen={() => {
                  setOpenGateways(true)
                }}
                onClose={() => {
                  setOpenGateways(false)
                }}
                onChange={(e, newValue) => {
                  onChange(newValue)
                }}
                onInputChange={(e, newInputValue) => {
                  setGatewayInput(newInputValue)
                }}
                value={value || ''}
                inputValue={gatewayInput}
                name="gateway"
                options={gateways}
                getOptionSelected={(option, v) => option.ref === v.ref}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.ref
                }
                loading={loadingGateways}
                loadingText={t('global.loading')}
                label={t('v_detail.boitierInput')}
              />
            )}
          />
          <FormController
            name="odometer"
            render={({ value, ref, onChange, onBlur }) => (
              <InputComponent
                ref={ref}
                onBlur={onBlur}
                variant={
                  user && !getPermissions(user.type, 'editVehicleOdometer')
                    ? 'filled'
                    : 'outlined'
                }
                label={t('v_detail.odometerInput')}
                value={value}
                onChange={onChange}
                name="odometer"
                disabled={
                  user && !getPermissions(user.type, 'editVehicleOdometer')
                }
                type="number"
                fullWidth
              />
            )}
          />
          {vehicle && vehicle.avatar ? (
            <Grid
              key={vehicle.avatar.id}
              item
              container
              direction="row"
              className={classes.gridItem}
            >
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                container
                style={{ whiteSpace: 'nowrap', width: '80%' }}
              >
                <Tooltip
                  title={
                    vehicle.avatar.name
                      ? vehicle.avatar.name
                      : vehicle.avatar.id
                  }
                >
                  <Box
                    component="div"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    style={deleteAvatar ? { color: 'red' } : null}
                  >
                    {vehicle.avatar.name
                      ? vehicle.avatar.name
                      : vehicle.avatar.id}
                  </Box>
                </Tooltip>
              </Grid>{' '}
              <Grid
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                container
                style={{ width: '20%' }}
              >
                <IconButton
                  aria-label="download"
                  edge="end"
                  style={{ marginRight: '-20px' }}
                  onClick={handleDeleteAvatar}
                >
                  {deleteAvatar ? <DeleteForeverIcon /> : <DeleteIcon />}
                </IconButton>
              </Grid>
            </Grid>
          ) : null}

          <Grid item container direction="row" className={classes.gridItem}>
            <DropZone
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              picture
              text={t('dropZone.msgAvatar')}
            />
          </Grid>
          {selectedFiles && selectedFiles.length > 0 ? (
            <Grid item container direction="row" className={classes.gridItem}>
              <FileView
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                className={classes.btn}
                tooltiptitle={t('v_detail.box_Disagree')}
                onClick={onclose}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_AgreeSave')}
                type="submit"
                startIcon={<SaveIcon />}
                className={classes.btn}
                onClick={form.handleSubmit(handleEdit)}
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </FormProvider>

        <BackdropComponent className={classes.backdrop} open={open} />
      </Grid>
    ) : (
      <CircularProgressComponent className={classes.circularProgress} />
    )
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      {renderEditGrid()}
    </Box>
  )
}

export default EditVehicle
