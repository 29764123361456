// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'
const gateway = (query) => {
  const route = '/gateways'
  return axiosService.get(true, route, query)
}
const gatewayOrders = (id, query) => {
  const route = `/gateways/${id}/gatewayOrders`
  return axiosService.get(true, route, query)
}
const postGatewayOrder = (id, type, data) => {
  const route = `/gateways/${id}/gatewayOrders`
  const body = { type, data }
  return axiosService.post(true, route, null, body)
}
export const gatewaysService = { gateway, gatewayOrders, postGatewayOrder }
