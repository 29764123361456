/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
const TimeComponent = (ms, t) => {
  let day
  let hour
  let minute
  let second

  second = Math.floor(ms / 1000)
  minute = Math.floor(second / 60)
  // second %= 60
  hour = Math.floor(minute / 60)
  minute %= 60
  day = Math.floor(hour / 24)
  hour %= 24
  // ${second > 0 ? t('unit.sc', { value: second }) : ''}
  return `${
    day === 1
      ? t('unit.day', { day })
      : day > 0
      ? t('unit.days', { days: day })
      : ''
  } ${hour > 0 ? t('unit.hour', { value: hour }) : ''} ${
    minute > 0 ? t('unit.min', { value: minute }) : ''
  } `
}
export default TimeComponent
