import { StyleSheet, Font } from '@react-pdf/renderer'
Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfed3FHGPc.ttf',
})
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    height: 60,
    marginLeft: 'auto',
    marginTop: 10,
    marginRight: 'auto',
  },
  arrayContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 10,
  },
  body: {
    fontSize: 12,
    marginLeft: 5,
  },
  header: {
    fontSize: 13,
    marginLeft: 5,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    marginBottom: 24,
    borderWidth: 1,
    borderColor: '#000000',
  },
})

export default styles
