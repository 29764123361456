/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { usersService } from '../../../services'
import { emptyResponse } from '../../../helpers'
import {
  alertActions,
  drawerActions,
  usersActions,
  vehiclesActions,
} from '../../../actions'

import { userPatternConstants } from '../../../constants'
import UserDetails from '../UserDetails/UserDetails'
import useHelpers from '../Helpers/useHelpers'
const useController = (props) => {
  const { user, onclose } = props
  const { t } = useTranslation()
  const auth = useSelector((state) => state.authentication.user)
  const [open, setOpen] = useState(false)
  const { validateValue, validateUserName, validateEmail } = useHelpers()
  const dispatch = useDispatch()

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      userName: '',
      email: '',
      ref: '',
      status: '',
    },
  })

  const updateRedux = useCallback(
    async (id) => {
      try {
        const res = await usersService.getUsers()
        if (!res || res.status !== 200) {
          dispatch(alertActions.error(res.content.data.message, 6000, true))
          return
        }

        dispatch(usersActions.success(res.content.data))
        const currentUser = res.content.data.find((e) => e.id === id)
        if (currentUser) {
          dispatch(
            drawerActions.show(
              <UserDetails user={currentUser} />,
              t('users.drawerTitle'),
              'right',
              'persistent',
              'ltr',
              null,
              null,
              null,
            ),
          )
        }
      } catch (error) {
        dispatch(vehiclesActions.failure())
      }
    },
    [dispatch, t],
  )

  const checkUserName = useCallback(
    (value) => {
      if (!value && user.name) {
        return true
      }
      return !!(
        value &&
        userPatternConstants.NAME_PATTERN.test(value) &&
        value !== user.name
      )
    },
    [user.name],
  )

  const checkEmail = useCallback(
    (value) => {
      if (!value && user.email) {
        return true
      }
      return !!(
        value &&
        userPatternConstants.EMAIL_PATTERN.test(value) &&
        value !== user.email
      )
    },
    [user.email],
  )

  const checkRef = useCallback(
    (value) => {
      if (!value && user.ref) {
        return true
      }
      return !!(value && value !== user.ref)
    },
    [user.ref],
  )
  const checkStatus = useCallback(
    (s) => {
      if (!s && user.status) {
        return true
      }
      return !!(s && s.value !== user.status)
    },
    [user.status],
  )

  const handleEdit = useCallback(
    async (data) => {
      try {
        const { userName, email, ref, status } = data
        setOpen(true)
        if (user.id) {
          const body = {}

          if (checkUserName(userName)) {
            body.name = userName || null
          }

          if (email && checkEmail(email)) {
            body.email = email || null
          }
          if (checkRef(ref)) {
            body.ref = ref || null
          }
          if (status && checkStatus(status)) {
            body.status = status.value ? status.value : null
          }

          if (JSON.stringify(body) === JSON.stringify({})) {
            dispatch(alertActions.info(t('v_detail.v_formChange'), 6000, true))
            setOpen(false)
            return
          }
          const res = await usersService.update(user.id, body)
          if (emptyResponse(res, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                res.content ? res.content.message : t('global.error'),
                6000,
                true,
              ),
            )
            return
          }
          setOpen(false)
          dispatch(alertActions.success(t('v_detail.v_update_msg'), 5000, true))
          await updateRedux(user.id)
          return
        }
        setOpen(false)

        dispatch(alertActions.info(t('v_detail.v_form_validation'), 6000, true))
      } catch (err) {
        dispatch(alertActions.error(err.toString(), 6000, true))
        setOpen(false)
      }
    },
    [
      checkEmail,
      checkRef,
      checkStatus,
      checkUserName,
      dispatch,
      t,
      updateRedux,
      user.id,
    ],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const checkInput = () => {
      if (user) {
        form.setValue('ref', user.ref || '')
        form.setValue('userName', user.name || '')
        form.setValue('email', user.email || '')
        if (user.status) {
          form.setValue('status', {
            name: t(`users.${user.status}`),
            value: user.status,
          })
        }
      }
    }
    checkInput()
    return () => {
      abortController.abort()
    }
  }, [form, t, user])

  const statusList = [
    { name: t('users.Pending'), value: 'Pending' },
    { name: t('users.Active'), value: 'Active' },
    { name: t('users.Blocked'), value: 'Blocked' },
  ]

  return {
    onclose,
    user,
    auth,
    t,
    open,
    handleEdit,
    statusList,
    form,
    validateValue,
    validateUserName,
    validateEmail,
  }
}
export default useController
