// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const getUsers = (query) => {
  const route = '/users'
  return axiosService.get(true, route, query)
}

const update = async (id, body) => {
  const route = `/users/${id}`
  return axiosService.put(true, route, null, body)
}

const deleteUser = async (id) => {
  const route = `/users/${id}/delete`
  return axiosService.put(true, route)
}
const create = (body) => {
  const route = '/users'
  return axiosService.post(true, route, null, body)
}
const sendInviteUserEmail = (id) => {
  const route = `/users/${id}/sendInviteEmail`
  return axiosService.post(true, route)
}

const sendResetPasswordUserEmail = (id) => {
  const route = `/users/${id}/sendResetPasswordEmail`
  return axiosService.post(true, route)
}
const getOwners = (query) => {
  const route = '/users/owners'
  return axiosService.get(true, route, query)
}

export const usersService = {
  create,
  sendResetPasswordUserEmail,
  sendInviteUserEmail,
  update,
  getUsers,
  deleteUser,
  getOwners,
}
