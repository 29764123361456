/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation'
import { Link } from 'react-router-dom'
import BarChartIcon from '@material-ui/icons/BarChart'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import DescriptionIcon from '@material-ui/icons/Description'
import React from 'react'

import CropFreeIcon from '@material-ui/icons/CropFree'
import MapIcon from '@material-ui/icons/Map'
import DeleteVehicle from '../../../DeleteVehicle/DeleteVehicle'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'
import { getPermissions } from '../../../../../helpers/users/permissions.helpers'
import useController from './BtnGroup.controller'
import StylesWithMaterialUi from './BtnGroup.styles'
import QrCodeComponents from './QrCode'

const BtnGroup = (props) => {
  const { handleExpanded, expanded, data } = props
  const {
    accordionAction,
    user,
    t,
    showVehicleEdit,
    showVehicleRapport,
    showVehicleIncident,
    addVehicleDocument,
    settings,
    showQr,
    qrState,
    openQr,
    handleCancelPopupQr,
    handlePrint,
    handleDownload,
    openGoogleMaps,
    vehicle,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <Accordion
      ref={accordionAction}
      expanded={expanded}
      onChange={handleExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.text}>
          {t('v_detail.actions')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {openQr ? (
          <QrCodeComponents
            data={data}
            open={openQr}
            cancel={handleCancelPopupQr}
            state={qrState}
            print={handlePrint}
            download={handleDownload}
          />
        ) : null}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {user && getPermissions(user.type, 'write') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonSmall}
                startIcon={<EditIcon />}
                onClick={showVehicleEdit}
                tooltiptitle={t('v_detail.btn_edit')}
              >
                {t('v_detail.btn_edit')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && getPermissions(user.type, 'read') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.button}
                startIcon={<NotListedLocationIcon />}
                tooltiptitle={t('v_detail.v_show_details')}
                component={Link}
                to={
                  vehicle ? `${t('router.trips')}?vehicleId=${vehicle.id}` : ''
                }
              >
                {t('v_detail.v_show_details')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && getPermissions(user.type, 'showQr') ? (
            <Grid item>
              {vehicle ? (
                <ButtonComponent
                  styles
                  onClick={showQr}
                  className={classes.button}
                  startIcon={<CropFreeIcon />}
                  tooltiptitle={t('btnGroup.showQr')}
                >
                  {t('btnGroup.showQr')}
                </ButtonComponent>
              ) : null}
            </Grid>
          ) : null}

          {vehicle && vehicle.lastTracking ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonSmall}
                startIcon={<MapIcon />}
                onClick={openGoogleMaps}
                tooltiptitle={t('btnGroup.maps')}
              >
                {t('btnGroup.maps')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && getPermissions(user.type, 'write') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.button}
                startIcon={<EmojiPeopleIcon />}
                onClick={showVehicleIncident}
                tooltiptitle={t('v_detail.btn_incident')}
              >
                {t('v_detail.btn_incident')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && getPermissions(user.type, 'read') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.buttonSmall}
                startIcon={<BarChartIcon />}
                onClick={showVehicleRapport}
                tooltiptitle={t('v_detail.btn_rapport')}
              >
                {t('v_detail.btn_rapport')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {settings.settings.drawers &&
          settings.settings.drawers.vehicle.documents &&
          user &&
          getPermissions(user.type, 'addDocument') ? (
            <Grid item>
              <ButtonComponent
                styles
                className={classes.button}
                startIcon={<DescriptionIcon />}
                onClick={addVehicleDocument}
                tooltiptitle={t('documents.add')}
              >
                {t('documents.add')}
              </ButtonComponent>
            </Grid>
          ) : null}
          {user && getPermissions(user.type, 'deleteVehicle') ? (
            <Grid item>
              {vehicle ? (
                <DeleteVehicle
                  color="primary"
                  vehId={vehicle.id}
                  drawer
                  name={vehicle.name}
                  variant="outlined"
                />
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
export default BtnGroup
