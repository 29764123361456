import { makeStyles } from '@material-ui/core/styles'
import plaque from '../../../../../assets/images/pictos/plaque-vide.png'
const StylesWithMaterialUi = makeStyles((theme) => ({
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(5),
  },
  large: {
    width: '90%',
    maxWidth: 100,
    height: '100%',
    maxHeight: 100,
    borderRadius: 10,
    overflow: 'hidden',
  },
  small: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: 40,
      maxWidth: 40,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: 30,
      maxWidth: 30,
    },
  },
  meduim: {
    width: '70px',
    height: '70px',
  },
  carte: {
    width: '90%',
    maxWidth: 70,
    height: '100%',
    maxHeight: 70,
  },
  gridItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRating: {
    width: '50%',
  },
  rating: {
    width: '50%',
  },
  gridContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      minWidth: '140px',
      height: '100%',
      minHeight: '50px',
      backgroundPosition: 'center',
      backgroundImage: `url(${plaque})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      backgroundImage: `url(${plaque})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      minWidth: '100px',
      height: '100%',
      minHeight: '60px',
      marginLeft: '-10px',
    },
  },
  vehicleModel: {
    [theme.breakpoints.up('sm')]: {
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  immatText: {
    [theme.breakpoints.up('sm')]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      position: 'relative',
      top: '15px',
      left: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontWeight: 'bold',
      textAlign: 'center',
      position: 'relative',
      top: '23px',
      left: '5px',
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: { fontFamily: 'Poppins', fontSize: '13px' },
    [theme.breakpoints.down('sm')]: { fontFamily: 'Poppins', fontSize: '12px' },
  },
  text: {
    [theme.breakpoints.up('sm')]: { fontFamily: 'Poppins', fontSize: '12px' },
    [theme.breakpoints.down('sm')]: { fontFamily: 'Poppins', fontSize: '11px' },
  },
  carteText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    marginTop: '-5px',
    textDecoration: 'underline',
    textAlign: 'center',
    cursor: 'pointer',
  },
  hezLigne: {
    borderLeft: '2px solid gray',
    height: '210px',
    width: '5%',
    marginTop: '15px',
  },
  tracking: { width: '60%', height: '260px' },
  vehicle: { width: '35%', height: '260px' },
  root: {
    marginTop: '10px',
    marginBottom: '5px',
    height: '260px',
  },
  half: { width: '50%' },
  icon: { width: '20%', maxWidth: 40 },
  eighty: { width: '80%' },
  seventy: { width: '70%' },
  icon30: { width: '30%', maxWidth: 40 },
  autoAndOdometre: { flex: 1, height: '40px' },
}))
export default StylesWithMaterialUi
