export const documentsConstants = {
  // 'Technical',

  types: [
    'RegistrationCard',
    'InsuranceCard',
    'TechnicalControl',
    'PV',
    'Other',
  ],
  user_documents_types: [
    'BusinessCard',
    'AmbassadorContract',
    'QRCode',
    'Other',
  ],
  alerts_types: ['Insurance', 'PV', 'TechnicalControl'],
  technicalControlTypes: [
    { value: 'periodic', label: 'Le contrôle technique périodique' },
    { value: 'deliberate', label: 'Le contrôle technique volontaire' },
    { value: 'anticipated', label: 'Le contrôle technique anticipé' },
    {
      value: 'anti_pollution',
      label: 'Le contrôle complémentaire anti-pollution',
    },
    { value: 'counter-visit', label: 'La contre-visite' },
  ],
  technicalControlResult: [
    { value: 'favorable', label: 'Favorable' },
    { value: 'unfavorable', label: 'Défavorable' },
  ],
  DateList: ['immatDate', 'dateFirstCir'],
  longTextList: ['mesure', 'doc', 'coord', 'errerLabel'],

  PVDateList: ['date', 'DateOfIncreased'],
  PVLongTextList: ['description', 'address', 'raison', 'effect'],
}
