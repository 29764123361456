/* eslint-disable import/no-cycle */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useController = () => {
  const { t } = useTranslation()
  const accordion = React.createRef()

  const user = useSelector((state) => state.authentication.user)

  return { accordion, user, t }
}
export default useController
