/* eslint-disable import/no-cycle */
import React, { useMemo, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { documentsConstants, momentConstants } from '../../../../constants'

const PV = (props) => {
  const { document } = props
  const { t } = useTranslation()

  const formatData = useCallback(
    (key) => {
      if (documentsConstants.PVDateList.includes(key)) {
        return (
          <Grid item key={`${key}-a`} container direction="column">
            <Grid item>
              <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                }}
              >
                {moment(document.data[key]).format(momentConstants.FORMAT_DATE)}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      if (documentsConstants.PVLongTextList.includes(key)) {
        return (
          <Grid item key={`${key}-a`} container direction="column">
            <Grid item>
              <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  whiteSpace: 'pre-line',
                }}
              >
                {document.data[key]}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      return (
        <Grid item key={`${key}-a`} container direction="column">
          <Grid item>
            <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              {document.data[key]}
            </Typography>
          </Grid>
        </Grid>
      )
    },
    [document.data, t],
  )
  return useMemo(
    () =>
      Object.keys(document.data).map((key) =>
        document.data[key] ? formatData(key) : null,
      ),
    [document, formatData],
  )
}
export default PV
