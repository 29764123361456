/* eslint-disable import/no-cycle */
import { extractDataConstants } from '../constants'

export const extractDataActions = {
  show,
  update,
  clear,
}

function show(chartsModel) {
  return {
    type: extractDataConstants.SHOW,
    payload: {
      chartsModel,
    },
  }
}

function update(chartsModel) {
  return {
    type: extractDataConstants.UPDATE,
    payload: {
      chartsModel,
    },
  }
}

function clear() {
  return {
    type: extractDataConstants.CLEAR,
  }
}
