/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import html2canvas from 'html2canvas'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { alertActions, routerActions } from '../../../../../actions'
import { routerConstants } from '../../../../../constants'
import {
  VehicleDocumentsHelpers,
  VehicleIncidentHelpers,
  VehiclesHelpers,
} from '../../../../../helpers'
import mobionLogo from '../../../../../assets/images/logo/logo-mobion.png'

const useController = (props) => {
  const { data } = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [vehicle, setVehicle] = useState(null)
  const [openQr, setOpenQr] = useState(false)
  const black = '#000000'
  const initState = useMemo(
    () => ({
      ecLevel: 'M',
      size: 250,
      quietZone: 10,
      logoImage: mobionLogo,
      logoWidth: 50,
      value: '',
      logoHeight: 0,
      bgColor: '#FFFFFF',
      fgColor: black,
      removeQrCodeBehindLogo: false,
      logoOpacity: 1,
      qrStyle: 'squares',
    }),
    [],
  )
  const [qrState, setQrState] = useState(initState)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const accordionAction = React.createRef()
  const user = useSelector((s) => s.authentication.user)
  const settings = useSelector((s) => s.settings)

  useEffect(() => {
    const abortController = new AbortController()
    const updateData = () => {
      if (data) {
        setVehicle(data)
      }
    }
    updateData()
    return () => {
      abortController.abort()
    }
  }, [data])
  const iOS = useCallback(
    () =>
      window.navigator.userAgent.indexOf('iPad') !== -1 ||
      window.navigator.userAgent.indexOf('iPhone') !== -1 ||
      window.navigator.userAgent.indexOf('iPod') !== -1,
    [],
  )
  const openGoogleMaps = useCallback(() => {
    if (vehicle && vehicle.lastTracking) {
      const vehicleLatlng = {
        lat: vehicle.lastTracking.coords[1],
        lng: vehicle.lastTracking.coords[0],
      }
      if (matches) {
        if (iOS()) {
          if (vehicle.lastTracking.address) {
            window.open(
              `http://maps.apple.com/?address=${vehicle.lastTracking.address}&sll={vehicleLatlng.lat},${vehicleLatlng.lng}&z=10`,
            )
          } else {
            window.open(
              `http://maps.apple.com/?address=${vehicleLatlng.lat},${vehicleLatlng.lng}&z=10`,
            )
          }
        } else {
          window.open(
            `geo:${vehicleLatlng.lat},${vehicleLatlng.lng}?q=${vehicle.lastTracking.address}`,
          )
        }
      } else {
        window.open(
          `https://maps.google.com?q=${vehicleLatlng.lat},${vehicleLatlng.lng}?q=${vehicle.lastTracking.address}`,
        )
      }
    }
  }, [iOS, matches, vehicle])

  const handlePrint = useCallback(() => {
    window.print()
  }, [])

  const handleDownload = useCallback(() => {
    try {
      html2canvas(document.querySelector('#canvas')).then((canvas) => {
        const link = document.createElement('a')
        link.download = `${vehicle && vehicle.ref ? vehicle.ref : 'qrcode'}.png`
        link.href = canvas.toDataURL()
        link.click()
      })
    } catch (e) {
      dispatch(alertActions.error(e.toString(), 6000, true))
    }
  }, [dispatch, vehicle])

  const showVehicleEdit = useCallback(() => {
    if (vehicle && vehicle.id) {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehiclesHelpers.HandleEdit(vehicle, dispatch, t)
    }
  }, [dispatch, t, vehicle])

  const showVehicleRapport = useCallback(() => {
    if (vehicle && vehicle.id) {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehiclesHelpers.HandleReportOpen(vehicle, t, dispatch)
    }
  }, [dispatch, t, vehicle])

  const showVehicleIncident = useCallback(() => {
    if (vehicle && vehicle.id) {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehicleIncidentHelpers.ShowVehicleIncident(vehicle, dispatch, t)
    }
  }, [dispatch, t, vehicle])

  const addVehicleDocument = useCallback(() => {
    if (vehicle && vehicle.id) {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      VehicleDocumentsHelpers.AddVehicleDocuments(vehicle, dispatch, t)
    }
  }, [dispatch, t, vehicle])

  const handleCancelPopupQr = useCallback(() => {
    setOpenQr(!openQr)
  }, [openQr])

  const showQr = useCallback(() => {
    if (vehicle && vehicle.id) {
      setOpenQr(true)
      setQrState((p) => ({
        ...p,
        value: JSON.stringify({
          ref: vehicle.ref || undefined,
          vin: vehicle.vin || undefined,
          name: vehicle.name || undefined,
          numberplate: vehicle.numberplate || undefined,
        }),
      }))
    }
  }, [vehicle])

  return {
    accordionAction,
    user,
    t,
    showVehicleEdit,
    showVehicleRapport,
    showVehicleIncident,
    addVehicleDocument,
    settings,
    showQr,
    qrState,
    openQr,
    handleCancelPopupQr,
    handlePrint,
    handleDownload,
    openGoogleMaps,
    vehicle,
  }
}
export default useController
