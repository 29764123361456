/* eslint-disable import/no-cycle */
import React from 'react'
import { alertActions, drawerActions, usersActions } from '../../actions'
import { usersService } from '../../services'
import { fullResponse } from '../system/response.helpers'
import UserDetails from '../../components/Users/UserDetails/UserDetails'

const ShowUserDetails = async (
  e,
  id,
  dispatch,
  t,
  onClose,
  header,
  classes,
  className,
) => {
  try {
    if (id) {
      const query = { id }
      const res = await usersService.getUsers(query)
      if (fullResponse(res, 200)) {
        const data = res.content.data[0]
        dispatch(
          drawerActions.show(
            <UserDetails user={data} />,
            t('users.drawerTitle'),
            'right',
            'persistent',
            'ltr',
            header,
            onClose,
            classes,
            className,
          ),
        )
        return
      }

      if (fullResponse(res, 204)) {
        dispatch(alertActions.info(t('users.notFound'), 6000, true))
        return
      }
      dispatch(alertActions.error(res.content.data.message, 6000, true))
    }
  } catch (error) {
    dispatch(usersActions.failure())
    dispatch(alertActions.error(error.message.toString(), 6000, true))
  }
}
export const UserDetailsHelpers = {
  ShowUserDetails,
}
