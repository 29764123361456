import iconBlack from '../../assets/images/map/map-pin-black.png'
import iconRed from '../../assets/images/map/map-pin-red.png'
import iconGreen from '../../assets/images/map/map-pin-green.png'
import iconBleu from '../../assets/images/map/map-pin-blue.png'
import points from '../../assets/images/map/points.png'
export const mapsConstants = {
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  positionClasses: {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  },
  blackIcon: {
    iconUrl: iconBlack,
    iconAnchor: [12, 23],
    iconSize: [24, 24],
  },

  redIcon: {
    iconUrl: iconRed,
    iconAnchor: [12, 23],
    iconSize: [24, 24],
  },
  greenIcon: {
    iconUrl: iconGreen,
    iconAnchor: [12, 23],
    iconSize: [24, 24],
  },
  blueIcon: {
    iconUrl: iconBleu,
    iconAnchor: [12, 23],
    iconSize: [24, 24],
  },
  pointsPolyline: {
    iconUrl: points,
    iconSize: [12, 12],
  },
  // center et bounds sur tt la france
  viewport: {
    center: { lat: 46.2276, lng: 2.2137 },
    bounds: [
      [-54.5247541978, 2.05338918702],
      [9.56001631027, 51.1485061713],
    ],
    zoomType: false,
    fly: false,
    zoom: 5,
    default: true,
  },
}
