import mapConstants from '../constants/redux/map.constants '

const map = (zoom, bounds) => ({
  type: mapConstants.MAP,
  payload: { zoom, bounds },
})
const clear = () => ({
  type: mapConstants.CLEAR,
})
export const mapActions = {
  map,
  clear,
}
