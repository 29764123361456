/* eslint-disable import/no-cycle */
import { routerConstants } from '../constants'
import { arrayPop } from '../helpers'

const initialState = {
  stack: [],
}
const router = (state = initialState, action) => {
  switch (action.type) {
    case routerConstants.PUSH:
      return {
        stack: [...state.stack, action.payload.stack],
      }
    case routerConstants.POP:
      return {
        stack: arrayPop(state.stack),
      }
    case routerConstants.CLEAR:
      return initialState

    default:
      return state
  }
}

export { router }
