/* eslint-disable import/no-cycle */
import React from 'react'
import BackupIcon from '@material-ui/icons/Backup'
import { Grid } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import StylesWithMaterialUi from './DropZone.styles'
import ButtonComponent from '../utils/MatrialUiComponents/Button/ButtonComponent'
import useController from './DropZone.controller'

const DropZone = (props) => {
  const { selectedFiles, multiple, picture, text } = props
  const {
    matches,
    errorMsg,
    fileInputClicked,
    t,
    fileInputRef,
    filesSelected,
    hover,
    dragOver,
    dragLeave,
    dragEnter,
    fileDrop,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={classes.gridItemUpload}
    >
      {matches ? (
        <Grid item>
          <ButtonComponent
            className={classes.btn}
            startIcon={<BackupIcon className={classes.icon} />}
            onClick={fileInputClicked}
          >
            {text || t('dropZone.msgBtn')}
          </ButtonComponent>
          <Grid item>
            <input
              ref={fileInputRef}
              className={classes.fileInput}
              type="file"
              multiple={multiple}
              onChange={filesSelected}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={
                hover ? classes.dropContainerHover : classes.dropContainer
              }
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              onClick={fileInputClicked}
            >
              <Grid
                item
                container
                direction="column"
                spacing={1}
                className={classes.dropMessage}
              >
                <Grid item>{hover ? <AttachFileIcon /> : <BackupIcon />}</Grid>
                <Grid item>{text || t('dropZone.msg')}</Grid>
                <Grid item>
                  <input
                    ref={fileInputRef}
                    className={classes.fileInput}
                    type="file"
                    multiple={multiple}
                    onChange={filesSelected}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        </Grid>
      )}
      {selectedFiles.length && !picture ? (
        <Grid
          item
          container
          direction="row"
          spacing={2}
          className={classes.gridListItem}
        >
          <Grid
            item
            container
            direction="column"
            spacing={1}
            justifyContent="center"
          >
            {selectedFiles.length > 0 ? (
              <Grid item>
                {t('dropZone.fileOk', {
                  value: selectedFiles.length,
                  count: selectedFiles.length,
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      {errorMsg ? (
        <Grid item>
          <p className={classes.fileErrorMessage}>{errorMsg}</p>
        </Grid>
      ) : null}
    </Grid>
  )
}
export default DropZone
