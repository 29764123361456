const headerUsersLabel = (t, download) => [
  {
    name: t('table.v_name'),
    download: true,
  },
  {
    name: t('profile.emailInput'),
    download: true,
  },
  {
    name: t('table.v_ref'),
    download: true,
  },
  {
    name: t('users.status'),
    download: true,
  },
  {
    name: t('table.v_owner'),
    download,
  },
  {
    name: t('users.t_type'),
    download: true,
  },
  {
    name: t('users.lastAuthenticationDate'),
    download: true,
  },
]
export { headerUsersLabel }
