import { settingsConstants } from '../../constants/settings/settings.constants'
// eslint-disable-next-line import/no-cycle
import { menuListConstants } from '../../constants'
const compare = (a, b) => {
  const bandA = a.toUpperCase()
  const bandB = b.toUpperCase()

  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}
const getAllSettings = (user, t) => {
  if (!user || !user.type) return {}
  let isMobionUser
  if (user.email) {
    isMobionUser = user.email.includes('mob-ion')
  }
  const type = user.type === 'User' && isMobionUser ? 'UserMobion' : user.type
  const o = settingsConstants.accounts[type]
  if (o && o.screens) {
    const sorted = Object.entries(o.screens).sort((a, b) =>
      compare(t(`treeView.${a[0]}`), t(`treeView.${b[0]}`)),
    )
    o.screens = Object.fromEntries(sorted)
  }
  if (o && o.drawers) {
    const sorted = Object.entries(o.drawers).sort((a, b) =>
      compare(t(`treeView.${a[0]}`), t(`treeView.${b[0]}`)),
    )
    o.drawers = Object.fromEntries(sorted)
  }
  return o
}
const getRouter = (settings) => {
  const map = new Map()
  const list = settings.screens ? Object.keys(settings.screens) : []

  list.forEach((item) => {
    map.set(item, settings.screens[item].enabled)
  })

  return menuListConstants.isLoggedList
    .filter((c) => list.includes(c.name))
    .map((x) => {
      // eslint-disable-next-line no-param-reassign
      x.enabled = map.get(x.name)
      return x
    })
}

export { getRouter, getAllSettings }
