/* eslint-disable import/no-cycle */
import { vehiclesConstants } from '../constants'

export const vehiclesActions = {
  success,
  init,
  failure,
  update,
  drawer,
  add,
  remove,
  clear,
  menuStatus,
  hide,
  updateWithSocket,
  setTooltip,
  trip,
  tracking,
}

function tracking(data) {
  return { type: vehiclesConstants.LAST_TRAKING, payload: { tracking: data } }
}

function trip(data) {
  return { type: vehiclesConstants.TRIP, payload: { trip: data } }
}

function success(vehicles) {
  return { type: vehiclesConstants.SUCCESS, vehicles }
}
function setTooltip(tooltip) {
  localStorage.setItem('toolTip', tooltip)
  return { type: vehiclesConstants.TOOLTIP, payload: { tooltip } }
}
function hide(id, props, display) {
  return { type: vehiclesConstants.HIDE, payload: { id, props, display } }
}

function add(vehicle) {
  return {
    type: vehiclesConstants.ADD,
    payload: {
      vehicle,
    },
  }
}

function update(vehicle) {
  return {
    type: vehiclesConstants.UPDATE,
    payload: {
      vehicle,
    },
  }
}
function updateWithSocket(data) {
  return {
    type: vehiclesConstants.UPDATE_WITH_SOCKET,
    payload: {
      data,
    },
  }
}
function remove(id) {
  return {
    type: vehiclesConstants.REMOVE,
    payload: {
      id,
    },
  }
}
function drawer(menu, vehicle) {
  return {
    type: vehiclesConstants.MENU,
    payload: {
      menu,
      vehicle,
    },
  }
}
function menuStatus(menu, vehicle) {
  return {
    type: vehiclesConstants.MENU,
    payload: {
      menu,
      vehicle,
    },
  }
}

function init() {
  return { type: vehiclesConstants.INIT }
}
function failure() {
  return { type: vehiclesConstants.FAILURE }
}
function clear() {
  return { type: vehiclesConstants.CLEAR }
}
