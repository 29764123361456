/* eslint-disable import/no-cycle */
import { fleetsConstants } from '../constants'
import { elementReplace, arrayRemove, elementPush } from '../helpers'
const initialState = {
  isLoading: false,
  isError: false,
}

const fleets = (state = initialState, action) => {
  switch (action.type) {
    case fleetsConstants.INIT:
      return {
        ...state,
        isLoading: true,
      }
    case fleetsConstants.SUCCESS:
      return {
        ...state,
        data: action.payload.fleets,
        isLoading: false,
      }
    case fleetsConstants.UPDATE:
      return {
        ...state,
        isLoading: false,
        data: elementReplace(state.data, action.payload.fleet),
      }
    case fleetsConstants.ADD:
      return {
        ...state,
        isLoading: false,
        data: elementPush(state.data, action.payload.fleet),
      }
    case fleetsConstants.REMOVE:
      return {
        ...state,
        data: arrayRemove(state.data, action.payload.id),
      }

    case fleetsConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case fleetsConstants.CLEAR:
      return {}
    default:
      return state
  }
}

export { fleets }
