/* eslint-disable import/no-cycle */
import { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'

const useController = () => {
  const { t } = useTranslation()

  const socket = useSelector((state) => state.socket)
  const dispatch = useDispatch()
  const location = useLocation()
  const [expandInfo, setExpandInfo] = useState(true)
  const [expandFile, setExpandFile] = useState(true)
  const [expandDocumentInfo, setExpandDocumentInfo] = useState(true)
  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])

  const expandedFiles = useCallback(() => {
    setExpandFile(!expandFile)
  }, [expandFile])

  const expandedInfo = useCallback(() => {
    setExpandInfo(!expandInfo)
  }, [expandInfo])
  const expandedDocumentInfo = useCallback(() => {
    setExpandDocumentInfo(!expandDocumentInfo)
  }, [expandDocumentInfo])

  return {
    expandInfo,
    expandedInfo,
    t,
    expandFile,
    expandedFiles,
    expandDocumentInfo,
    expandedDocumentInfo,
  }
}

export default useController
