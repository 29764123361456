/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import ContactlessIcon from '@material-ui/icons/Contactless'
import { useDispatch, useSelector } from 'react-redux'
import PowerIcon from '@material-ui/icons/Power'
import PowerOffIcon from '@material-ui/icons/PowerOff'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import BuildIcon from '@material-ui/icons/Build'
import { green } from '@material-ui/core/colors'
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote'
import { vehiclesService } from '../../../../../services'
import { socketCommandsService } from '../../../../../services/socket/socket.commands.service'
import { fullResponse } from '../../../../../helpers'
import Columns from './components/columns/Columns'
import MakeDate from './components/MakeData'
import CustomOption from './components/CustomOption'
import ExpandButton from '../../../../DataTable/components/ExpandButton'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import { alertActions } from '../../../../../actions'

const useController = (props) => {
  const { vehicle, expanded } = props

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [isRefresh, setIsRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [socket, setSocket] = useState(null)
  const [response, setResponse] = useState(null)
  const [message, setMessage] = useState(null)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const classes = StylesWithMaterialUi()
  const [open, setOpen] = useState(false)
  const [availableCommands, setAvailableCommands] = useState([])

  const user = useSelector((s) => s.authentication.user)
  const settings = useSelector((s) => s.settings)
  const dispatch = useDispatch()

  const getOrders = useCallback(
    async (pageNumber) => {
      try {
        if (vehicle && vehicle.id && expanded) {
          setIsLoading(true)
          if (isRefresh) {
            return
          }
          setIsRefresh(true)
          const offset = pageNumber * 5
          const query = { limit: 5, offset }
          const res = await vehiclesService.getCommands(vehicle.id, query)
          if (fullResponse(res, 200)) {
            if (offset > page) {
              setTotal((s) => s + res.content.data.length)
            } else {
              setTotal((s) => s - res.content.data.length)
            }
            setPage(offset)
            setData(res.content.data)
          }
          setIsRefresh(false)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        setIsRefresh(false)
        setPage(0)
      }
    },
    [expanded, vehicle, isRefresh, page],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const listeningSocket = () => {
      if (response) {
        setData((prevState) => [response, ...prevState])
      }
    }
    listeningSocket()
    return () => {
      abortController.abort()
    }
  }, [response])

  useEffect(() => {
    const abortController = new AbortController()
    const retrySocket = () => {
      if (socket) {
        socketCommandsService.listeningSocket(socket, setResponse, setMessage)
      }
    }
    retrySocket()

    return () => {
      abortController.abort()
      if (vehicle && vehicle.id && socket) {
        const unsubscribeData = {
          channels: {
            all: false,
            owners: [],
            vehicleFleets: [],
            vehicles: [vehicle.id],
          },
        }
        socketCommandsService.unsubscribe(socket, unsubscribeData)
        socketCommandsService.disconnectSocket(socket)
        setSocket(null)
      }
    }
  }, [vehicle, socket])

  useEffect(() => {
    const abortController = new AbortController()
    const fetch = async () => {
      try {
        setData([])
        setLoading(true)
        setTotal(0)
        setPage(0)
        if (vehicle && vehicle.id && expanded) {
          const io = await socketCommandsService.connectSocket()
          const d = {
            channels: {
              all: false,
              owners: [],
              vehicleFleets: [],
              vehicles: [vehicle.id],
            },
          }
          setSocket(io)
          socketCommandsService.subscribe(io, d)
          const query = { limit: 5 }
          const res = await vehiclesService.getCommands(vehicle.id, query)
          if (fullResponse(res, 200)) {
            setData(res.content.data)
            setTotal(res.content.data.length)
          }
        }
        setLoading(false)
        setIsRefresh(false)
        setIsLoading(false)
      } catch (e) {
        setLoading(false)
        setIsRefresh(false)
        setIsLoading(false)
        setPage(0)
      }
    }
    fetch()
    return () => {
      abortController.abort()
    }
  }, [vehicle, expanded])

  const refresh = useCallback(async () => {
    setIsLoading(true)
    if (vehicle && vehicle.id) {
      const query = { limit: 5, offset: page }
      const res = await vehiclesService.getCommands(vehicle.id, query)
      if (fullResponse(res, 200)) {
        setData(res.content.data)
      }
      setIsLoading(false)
    }
  }, [vehicle, page])

  const CustomToolBar = () => (
    <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}>
      <Tooltip title={t('global.refresh')}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          className={classes.refreshBtnWithoutMargin}
          onClick={refresh}
          startIcon={<RefreshIcon />}
        >
          {t('global.refresh')}
        </Button>
      </Tooltip>
      <Button
        style={{ backgroundColor: 'white' }}
        aria-label="socket"
        tooltiptitle={t('global.socket')}
      >
        <ContactlessIcon
          fontSize="small"
          id="socketBtn"
          style={
            message && message.type === 'success'
              ? { color: 'green', backgroundColor: 'white' }
              : { color: 'red', backgroundColor: 'white' }
          }
        />
      </Button>
    </Box>
  )
  const columnsTable = Columns()
  const tableData = useMemo(() => MakeDate(data), [data])
  const customOption = CustomOption(getOrders, total, CustomToolBar)
  const components = {
    ExpandButton(i) {
      return <ExpandButton {...i} />
    },
  }
  const excuteSimpleCommand = useCallback(
    async (type) => {
      try {
        if (vehicle && vehicle.id) {
          const body = {
            type,
          }
          setOpen(true)
          const res = await vehiclesService.createCommand(vehicle.id, body)
          if (res && res.status !== 201) {
            dispatch(alertActions.error(res.content.message, 6000, true))
          }
          dispatch(alertActions.success(t('v_detail.v_update_msg'), 5000, true))
          setOpen(false)
        }
      } catch (e) {
        dispatch(alertActions.error(e.message, 6000, true))
        setOpen(false)
      }
    },
    [dispatch, t, vehicle],
  )

  const getBtnProps = useCallback(
    (type) => {
      const notImplimented = {
        type,
        icon: <BuildIcon />,
        label: t(`orders.${type}`),
        color: '',
        className: classes.Button,
        onClick: undefined,
      }

      switch (type) {
        case 'unlock':
          return {
            type,
            icon: <LockOpenIcon style={{ color: green[500] }} />,
            label: t(`orders.${type}`),
            color: 'green',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }
        case 'lock':
          return {
            type,
            icon: <LockIcon color="secondary" />,
            label: t(`orders.${type}`),
            color: 'red',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }
        case 'powerOn':
          return {
            type,
            icon: <PowerIcon style={{ color: green[500] }} />,
            label: t(`orders.${type}`),
            color: 'green',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }
        case 'powerOff':
          return {
            type,
            icon: <PowerOffIcon color="secondary" />,
            label: t(`orders.${type}`),
            color: 'red',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }

        case 'setMaxSpeed':
          return notImplimented
        case 'openSaddle':
          return {
            type,
            icon: <SettingsRemoteIcon color="secondary" />,
            label: t(`orders.${type}`),
            color: 'red',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }
        case 'openTopCase':
          return {
            type,
            icon: <SettingsRemoteIcon color="secondary" />,
            label: t(`orders.${type}`),
            color: 'red',
            className: classes.Button,
            onClick: excuteSimpleCommand,
          }

        default:
          return notImplimented
      }
    },

    [classes.Button, excuteSimpleCommand, t],
  )
  useEffect(() => {
    const abortController = new AbortController()
    const updateData = async () => {
      try {
        if (vehicle) {
          if (
            settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.commands
          ) {
            const res = await vehiclesService.getCommandsAvailable(vehicle.id)
            if (fullResponse(res, 200)) {
              const d = res.content.data.map((x) => getBtnProps(x))
              setAvailableCommands(d)
            }
            /**
             * if (vehicle.states) {
              setState(vehicle.states.isLocked)
            }
             */
          }
        }
      } catch (e) {
        dispatch(alertActions.error(e.toString(), 6000, true))
      }
    }
    updateData()
    return () => {
      abortController.abort()
    }
  }, [vehicle, dispatch, settings, user, getBtnProps])

  return {
    loading,
    t,
    columnsTable,
    tableData,
    customOption,
    components,
    isLoading,
    availableCommands,
    open,
    user,
  }
}
export default useController
