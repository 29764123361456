/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { fleetsService, vehiclesService } from '../../../services'
import { fleetsPatternConstants } from '../../../constants'
import {
  alertActions,
  drawerActions,
  fleetsActions,
  socketActions,
  vehiclesActions,
} from '../../../actions'
import { emptyResponse, fullResponse } from '../../../helpers'
import { closeSocket } from '../../../helpers/socket/socket.helpers'
import useHelpers from '../Helpers/useHelpers'

const useController = (props) => {
  const { fleet, drawer, onclose } = props
  // hook
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const socket = useSelector((state) => state.socket)
  // style
  // useState
  const [open, setOpen] = useState(false)
  const { validateFleetName } = useHelpers()
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      fleetName: '',
    },
  })
  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      if (socket.subscribe) {
        dispatch(socketActions.unsubscribe(socket.socket, socket.data))
      }
      closeSocket(socket)
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, socket])

  // region handle
  const handleCancel = useCallback(() => {
    if (drawer) {
      onclose()
      return
    }
    dispatch(drawerActions.close())
  }, [dispatch, drawer, onclose])

  const updateReduxVehicle = useCallback(async () => {
    try {
      const res = await vehiclesService.getVehicles()
      if (fullResponse(res, 204)) {
        setOpen(false)
        dispatch(vehiclesActions.failure())
        return
      }
      if (emptyResponse(res, 200)) {
        setOpen(false)
        dispatch(vehiclesActions.failure())
        return
      }
      dispatch(vehiclesActions.success(res.content.data))
    } catch (error) {
      setOpen(false)
      dispatch(vehiclesActions.failure())
      dispatch(alertActions.error(error.toString(), 6000, true))
    }
  }, [dispatch])

  const updateRedux = useCallback(
    async (id) => {
      const query = { id }
      try {
        const res = await fleetsService.fleets(query)
        if (!res || res.status !== 200) {
          setOpen(false)
          dispatch(alertActions.error(res.content.data.message, 6000, true))
        }
        dispatch(fleetsActions.update(res.content.data[0]))
      } catch (error) {
        setOpen(false)
        dispatch(fleetsActions.failure())
        dispatch(alertActions.error(error.message, 6000, true))
      }
    },
    [dispatch],
  )

  const handleEdit = useCallback(
    async (data) => {
      const { fleetName } = data
      setOpen(true)
      if (fleetName && fleet && fleetName === fleet.name) {
        setOpen(false)
        dispatch(alertActions.info(t('v_detail.v_formChange'), 6000, true))
        return
      }
      if (
        fleetName &&
        fleetsPatternConstants.NAME_PATTERN.test(fleetName) &&
        fleet &&
        fleet.id
      ) {
        const body = {
          name: fleetName,
        }

        const res = await fleetsService.updateFleets(body, fleet.id)
        if (!res || res.status !== 201) {
          setOpen(false)
          dispatch(
            alertActions.error(
              res.content ? res.content.message : t('global.error'),
              6000,
              true,
            ),
          )
          return
        }
        await updateRedux(fleet.id)
        handleCancel()
        dispatch(alertActions.success(t('v_detail.v_update_msg'), 6000, true))
        if (location.pathname === t('router.vehicles')) {
          await updateReduxVehicle()
        }
        setOpen(false)
      }

      setOpen(false)
    },
    [
      dispatch,
      fleet,
      handleCancel,
      location.pathname,
      t,
      updateRedux,
      updateReduxVehicle,
    ],
  )
  // endregion

  useEffect(() => {
    const abortController = new AbortController()
    const checkFleet = () => {
      if (fleet && fleet.name) {
        form.setValue('fleetName', fleet.name)
      }
    }

    checkFleet()
    return () => {
      abortController.abort()
    }
  }, [fleet, form])

  return {
    open,
    form,
    handleCancel,
    handleEdit,
    t,
    validateFleetName,
  }
}

export default useController
