/* eslint-disable no-shadow */
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useController = (props) => {
  const { setData, document } = props
  const [inputs, setInputs] = useState({
    a: '',
    b: '',
    c1: '',
    c41: '',
    c3: '',
    d1: '',
    d2: '',
    d21: '',
    d3: '',
    e: '',
    f1: '',
    f2: '',
    f3: '',
    g: '',
    g1: '',
    h: '',
    i: '',
    j: '',
    j1: '',
    j2: '',
    j3: '',
    k: '',
    p1: '',
    p2: '',
    p3: '',
    p6: '',
    q: '',
    s1: '',
    s2: '',
    u1: '',
    u2: '',
    v7: '',
    v9: '',
    x1: '',
    y1: '',
    y2: '',
    y3: '',
    y4: '',
    y5: '',
    y6: '',
    z1: '',
    z2: '',
    z3: '',
    z4: '',
  })
  const {
    a,
    b,
    c1,
    c41,
    c3,
    d1,
    d2,
    d21,
    d3,
    e,
    f1,
    f2,
    f3,
    g,
    g1,
    h,
    i,
    j,
    j1,
    j2,
    j3,
    k,
    p1,
    p2,
    p3,
    p6,
    q,
    s1,
    s2,
    u1,
    u2,
    v7,
    v9,
    x1,
    y1,
    y2,
    y3,
    y4,
    y5,
    y6,
    z1,
    z2,
    z3,
    z4,
  } = inputs
  const { t } = useTranslation()

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = () => {
      if (inputs) {
        setData(inputs)
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [inputs, setData])

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = () => {
      if (document && document.data) {
        setInputs({
          a: document.data.a || '',
          b: document.data.b ? document.data.b : '',
          c1: document.data.c1 || '',
          c41: document.data.c41 || '',
          c3: document.data.c3 || '',
          d1: document.data.d1 || '',
          d2: document.data.d2 || '',
          d21: document.data.d21 || '',
          d3: document.data.d3 || '',
          e: document.data.e || '',
          f1: document.data.f1 || '',
          f2: document.data.f2 || '',
          f3: document.data.f3 || '',
          g: document.data.g || '',
          g1: document.data.g1 || '',
          h: document.data.h || '',
          i: document.data.i ? document.data.i : '',
          j: document.data.j || '',
          j1: document.data.j1 || '',
          j2: document.data.j2 || '',
          j3: document.data.j3 || '',
          k: document.data.k || '',
          p1: document.data.p1 || '',
          p2: document.data.p2 || '',
          p3: document.data.p3 || '',
          p6: document.data.p6 || '',
          q: document.data.q || '',
          s1: document.data.s1 || '',
          s2: document.data.s2 || '',
          u1: document.data.u1 || '',
          u2: document.data.u2 || '',
          v7: document.data.v7 || '',
          v9: document.data.v9 || '',
          x1: document.data.x1 ? document.data.x1 : '',
          y1: document.data.y1 || '',
          y2: document.data.y2 || '',
          y3: document.data.y3 || '',
          y4: document.data.y4 || '',
          y5: document.data.y5 || '',
          y6: document.data.y6 || '',
          z1: document.data.z1 || '',
          z2: document.data.z2 || '',
          z3: document.data.z3 || '',
          z4: document.data.z4 || '',
        })
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [document])

  return {
    a,
    b,
    c1,
    c3,
    c41,
    d1,
    d2,
    d21,
    d3,
    e,
    f1,
    f2,
    f3,
    g,
    g1,
    h,
    handleChange,
    i,
    j,
    j1,
    j2,
    j3,
    k,
    p1,
    p2,
    p3,
    p6,
    q,
    s1,
    s2,
    t,
    u1,
    u2,
    v7,
    v9,
    x1,
    y1,
    y2,
    y3,
    y4,
    y5,
    y6,
    z1,
    z2,
    z3,
    z4,
  }
}
export default useController
