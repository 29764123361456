/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import StylesWithMaterialUi from './Tracking.styles'
import Map from '../../../../Maps/Map'
import Trips from './components/trips/Trips'
import CircularProgressComponent from '../../../../utils/MatrialUiComponents/CircularProgress/CircularProgressComponent'
import ZoomControl from '../../../VehiclesMap/components/ZoomControl'
import useController from './Tracking.controller'

const Tracking = (props) => {
  const classes = StylesWithMaterialUi()
  const { viewport, lines, trip, setViewport, tracking } = useController(props)
  const renderMap = () =>
    viewport.default ? (
      <CircularProgressComponent className={classes.circularProgress} />
    ) : (
      <>
        <Map
          center={viewport.center}
          zoom={viewport.zoom}
          bounds={viewport.bounds}
          zoomType={viewport.zoomType}
          className={classes.leafletContainer}
          zoomControl={false}
        >
          <Trips trip={trip} tripLines={lines} setViewport={setViewport} />
          <ZoomControl position="topright" />
        </Map>
      </>
    )
  return tracking ? renderMap() : null
}

export default React.memo(Tracking)
