/* eslint-disable import/no-cycle */
import React from 'react'
import { drawerActions } from '../../actions/drawer.actions'
import EditDocuments from '../../components/AmbassadorDocuments/EditDocuments/EditDocuments'
import AddDocuments from '../../components/AmbassadorDocuments/AddDocuments/AddDocuments'
import DocumentsDetails from '../../components/AmbassadorDocuments/DocumentsDetails/DocumentsDetails'
import VehicleDetails from '../../components/Vehicles/VehiclesDetails/VehicleDetails'

const ShowVehicleDetails = (dispatch, t, vehicle) => {
  dispatch(
    drawerActions.show(
      <VehicleDetails vehicle={vehicle} />,
      t('v_detail.v_title'),
      'right',
      'persistent',
      'ltr',
    ),
  )
}
const ShowVehicleDocumentDetails = (vehicle, document, dispatch, t) => {
  const onClose = () => ShowVehicleDetails(dispatch, t, vehicle)
  const title = document.name
    ? t('documents.titleDoc', { name: document.name, type: t(document.type) })
    : t('documents.titleDocWithoutName', { type: t(document.type) })
  dispatch(
    drawerActions.show(
      <DocumentsDetails document={document} vehicle={vehicle} />,
      title,
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}
const AddVehicleDocuments = (vehicle, dispatch, t) => {
  const onClose = () => ShowVehicleDetails(dispatch, t, vehicle)
  dispatch(
    drawerActions.show(
      <AddDocuments id={vehicle.id} vehicle={vehicle} onclose={onClose} />,
      t('documents.titleAdd'),
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}

const EditVehicleDocuments = (vehicle, document, dispatch, t) => {
  const onClose = () => ShowVehicleDetails(dispatch, t, vehicle)
  const title = document.name
    ? t('documents.titleDoc', { name: document.name, type: t(document.type) })
    : t('documents.titleDocWithoutName', { type: t(document.type) })
  dispatch(
    drawerActions.show(
      <EditDocuments
        document={document}
        vehicleName={vehicle.name ? vehicle.name : vehicle.ref}
        onclose={onClose}
      />,
      title,
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}

export const VehicleDocumentsAmbassadorsClubHelpers = {
  AddVehicleDocuments,
  ShowVehicleDetails,
  EditVehicleDocuments,
  ShowVehicleDocumentDetails,
}
