// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const login = async (body) => {
  const route = '/auth/login/vehicle'
  return axiosService.post(false, route, null, body)
}
const getVehicles = async (query) => {
  const route = '/vehicles'
  return axiosService.get(true, route, query)
}
const updateVehicle = async (body, id) => {
  const route = `/vehicles/${id}`
  return axiosService.put(true, route, null, body)
}
const addVehicle = async (body) => {
  const route = '/vehicles'
  return axiosService.post(true, route, null, body)
}
const deleteVehicle = async (id) => {
  const route = `/vehicles/${id}`
  return axiosService.deleteFnc(true, route)
}
const getTracking = async (id, query) => {
  const route = `/vehicles/${id}/tracking`
  return axiosService.get(true, route, query)
}
const getUsageReport = async (query) => {
  const route = '/vehicles/usageReport'
  return axiosService.get(true, route, query)
}
const getMaintenances = async (id) => {
  const route = `/vehicles/${id}/maintenances`
  return axiosService.get(true, route)
}
const maintenanceOperations = async (id, body) => {
  const route = `/vehicles/${id}/maintenanceOperations`
  return axiosService.post(true, route, null, body)
}
const getUsageQuality = async (id) => {
  const route = `/vehicles/${id}/usageQuality`
  return axiosService.get(true, route)
}
const incidents = async (id, body) => {
  const route = `/vehicles/${id}/incidents`
  return axiosService.post(true, route, null, body)
}
const createDocument = async (id, body) => {
  const route = `/vehicles/${id}/documents`
  return axiosService.post(true, route, null, body)
}

const getDocuments = async (id, query) => {
  const route = `/vehicles/${id}/documents`
  return axiosService.get(true, route, query)
}

const createUse = async (id, body) => {
  const route = `/vehicles/${id}/use`
  return axiosService.post(true, route, null, body)
}
const getUses = async (id, query) => {
  const route = `/vehicles/${id}/uses`
  return axiosService.get(true, route, query)
}

const deleteUse = async (id, body) => {
  const route = `/vehicles/${id}/use`
  return axiosService.deleteFnc(true, route, null, body)
}
const createCommand = async (id, body) => {
  const route = `/vehicles/${id}/commands`
  return axiosService.post(true, route, null, body)
}
const getCommands = async (id, query) => {
  const route = `/vehicles/${id}/commands`
  return axiosService.get(true, route, query)
}
const getCommandsAvailable = async (id, query) => {
  const route = `/vehicles/${id}/commands/available`
  return axiosService.get(true, route, query)
}
export const vehiclesService = {
  login,
  getUsageReport,
  getTracking,
  updateVehicle,
  deleteVehicle,
  getVehicles,
  addVehicle,
  getMaintenances,
  getUsageQuality,
  maintenanceOperations,
  incidents,
  createDocument,
  getDocuments,
  getUses,
  createUse,
  deleteUse,
  createCommand,
  getCommands,
  getCommandsAvailable,
}
