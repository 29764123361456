// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const getIncidents = async (query) => {
  const route = '/vehicleIncidents'
  return axiosService.get(true, route, query)
}

export const vehiclesIncidentsService = {
  getIncidents,
}
