/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { alertActions, drawerActions, vehiclesActions } from '../../../actions'
import { vehiclesPatternConstants } from '../../../constants'
import { filesService, vehiclesService } from '../../../services'

import { filesHelpers } from '../../../helpers/files/files.helpers'

const useHelpers = (props) => {
  const { t } = useTranslation()
  const { selectedFiles } = props
  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    dispatch(drawerActions.close())
  }, [dispatch])

  const updateRedux = useCallback(async () => {
    try {
      const res = await vehiclesService.getVehicles()
      if (!res || res.status !== 200) {
        dispatch(alertActions.error(res.content.message, 6000, true))
        dispatch(vehiclesActions.failure())
        return
      }
      dispatch(vehiclesActions.success(res.content.data))
    } catch (error) {
      dispatch(alertActions.error(error.message, 6000, true))
      dispatch(vehiclesActions.failure())
    }
  }, [dispatch])

  const checkVehicleName = useCallback(
    (value) => value && vehiclesPatternConstants.NAME_PATTERN.test(value),
    [],
  )
  const checkRef = useCallback(
    (value) => value && vehiclesPatternConstants.REF_PATTERN.test(value),
    [],
  )
  const checkVin = useCallback(
    (value) => value && vehiclesPatternConstants.VIN_PATTERN.test(value),
    [],
  )
  const checkNumberPlate = useCallback(
    (value) =>
      value && !vehiclesPatternConstants.NUMBERPLATE_PATTERN.test(value),
    [],
  )
  const sendFiles = useCallback(async () => {
    const image = await filesHelpers.resizeFile(selectedFiles[0])
    const data = new FormData()
    data.append('files', image)
    return filesService.postFiles(data)
  }, [selectedFiles])

  const validateNumberPlate = useCallback(
    (value) => {
      if (!value) {
        return
      }
      if (!vehiclesPatternConstants.NUMBERPLATE_PATTERN.test(value)) {
        return t('table.v_numberplatePattern')
      }
    },
    [t],
  )
  const validateVehicleName = useCallback(
    (value) => {
      if (!value) {
        return
      }
      if (!vehiclesPatternConstants.NAME_PATTERN.test(value)) {
        return t('table.v_namePattern')
      }
    },
    [t],
  )

  const validateValue = useCallback(
    (value) => {
      if (!value) {
        return t('errors.valueRequired')
      }
    },
    [t],
  )
  return {
    sendFiles,
    checkNumberPlate,
    handleCancel,
    checkVehicleName,
    checkRef,
    updateRedux,
    checkVin,
    validateNumberPlate,
    validateVehicleName,
    validateValue,
  }
}
export default useHelpers
