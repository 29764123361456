import { CircularProgress } from '@material-ui/core'
import React from 'react'
import StylesWithMaterialUi from './CircularProgress.styles'

const CircularProgressComponent = (props) => {
  const { value, className, ...other } = props
  const classes = StylesWithMaterialUi()
  return (
    <div className={className || classes.root}>
      <CircularProgress value={value || 50} {...other} />
    </div>
  )
}
export default CircularProgressComponent
