/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
const arrayRemove = (arr, value) => {
  if (fullList(arr)) {
    let id
    if (typeof value === 'object' && value !== null) {
      id = value.id
    } else {
      id = value
    }

    return arr.filter((ele) => ele.id !== id)
  }
  return arr
}
const disableVehicle = (arr, id, props, value) => {
  if (fullList(arr)) {
    return arr.map((o) => {
      if ((o[props] && o[props].id === id) || o[props] === id) o.display = value
      return o
    })
  }
  return arr
}

const elementReplace = (data, value) => {
  if (fullList(data)) {
    let id
    if (typeof value === 'object' && value !== null) {
      id = value.id
    } else {
      id = value
    }
    const index = data.findIndex((e) => e.id === id)
    data[index] = value
  }
  return data
}
const elementReplaceWithSocket = (data, value) => {
  if (fullList(data)) {
    const id = value.vehicleId || null
    if (id) {
      const index = data.findIndex((e) => e.id === id)
      if (data[index] && data[index].lastTracking) {
        data[index].lastTracking = value
      }
    }
  }
  return data
}
const updateVhWithSocket = (vh, value) => {
  if (vh && value && vh.data) {
    if (vh.data.id === value.vehicleId) {
      vh.data.lastTracking = value
      return vh.data
    }
    return vh.data
  }
}
const elementReplaceVehicle = (data, value) => {
  if (fullList(data)) {
    let id
    if (typeof value === 'object' && value !== null) {
      id = value.id
    } else {
      id = value
    }

    if (!id) {
      // error
      return data
    }
    data.forEach((item) => {
      if (item.vehicle && item.vehicle.id === id) {
        item.vehicle = value
        return item
      }
    })
  }
  return data
}
const elementPush = (data, value) => {
  if (!value) {
    return data
  }
  if (!data) {
    return [value]
  }
  if (Array.isArray(data)) {
    data.push(value)
  }
  return data
}
const arrayPop = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    data.pop()
  }
  return data
}
const lastElement = (list) => {
  if (fullList(list)) {
    return list[list.length - 1]
  }
  return null
}
const firstElement = (list) => {
  if (fullList(list)) {
    return list[0]
  }
  return null
}

const fullList = (list) => Array.isArray(list) && list.length > 0

const arrayMoveMutable = (array, fromIndex, toIndex) => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex
    const [item] = array.splice(fromIndex, 1)
    array.splice(endIndex, 0, item)
  }
}
export {
  disableVehicle,
  arrayRemove,
  elementReplace,
  elementPush,
  elementReplaceVehicle,
  lastElement,
  firstElement,
  fullList,
  elementReplaceWithSocket,
  updateVhWithSocket,
  arrayPop,
  arrayMoveMutable,
}
