export const incidentConstants = {
  validTypesPicture: ['image/jpeg', 'image/jpg', 'image/png'],
  validTypes: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/x-icon',
    'application/pdf',
    'video/x-flv',
    'video/mp4',
    'application/x-mpegURL',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ],
  userValidTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
  incidents: [
    { value: false, name: 'accidentWithReport', prop: 'accident_with_report' },
    {
      value: false,
      name: 'accidentWithoutReport',
      prop: 'accident_without_report',
    },
    { value: false, name: 'frontPuncture', prop: 'front_puncture' },
    { value: false, name: 'rearPuncture', prop: 'rear_puncture' },
    { value: false, name: 'rightSideFall', prop: 'right_ride_fall' },
    { value: false, name: 'leftSideFall', prop: 'left_side_fall' },
    { value: false, name: 'frontSideImpact', prop: 'front_side_impact' },
    { value: false, name: 'rightSideImpact', prop: 'right_side_impact' },
    { value: false, name: 'leftSideImpact', prop: 'left_side_impact' },
  ],
  ignoreList: [
    'id',
    'type',
    'incidentDate',
    'creationDate',
    'vehicle',
    'author',
    'odometer',
  ],
}
