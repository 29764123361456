/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import { fullResponse } from '../../../../../helpers'
// import { vehiclesService } from '../../../../../services'
const useController = (props) => {
  const { t } = useTranslation()
  const { vehicle } = props
  // const user = useSelector((state) => state.authentication.user)
  const lastTrip = useSelector((state) => state.vehicles.trip)
  const tracking = useSelector((state) => state.vehicles.tracking)
  // const [indices, setIndices] = useState(null)
  const [speed, setSpeed] = useState(null)
  // const [registrationCard, setRegistrationCard] = useState(null)
  // const [insuranceCard, setInsuranceCard] = useState(null)
  // const [indicesLoading, setIndicesLoading] = useState(true)

  // const [loadingDocs, setLoadingdocs] = useState(true)
  useEffect(() => {
    const abortController = new AbortController()
    const fetchSpeed = async () => {
      if (vehicle && lastTrip && lastTrip.status === 'Ended' && tracking) {
        const s = tracking.map((o) => o.speed)
        const max = Math.max(...s)
        let sum = 0
        let average = 0
        s.forEach((element) => {
          sum += element
        })
        average = sum / s.length
        setSpeed({ max, average: Math.floor(average) })
      } else {
        setSpeed(null)
      }
    }
    fetchSpeed()

    return () => {
      abortController.abort()
    }
  }, [lastTrip, tracking, vehicle])

  /**  useEffect(() => {
    const abortController = new AbortController()
    const fetchUsageQuality = async () => {
      try {
        if (vehicle && vehicle.id) {
          const res = await vehiclesService.getUsageQuality(vehicle.id)
          if (fullResponse(res, 200)) {
            setIndices(res.content.data)
            setIndicesLoading(false)
            return
          }
          setIndices(null)
          setIndicesLoading(false)
        }
      } catch (e) {
        setIndicesLoading(false)
      }
    }
    fetchUsageQuality()

    return () => {
      abortController.abort()
    }
  }, [vehicle])

  const getNote = (note) => {
    const y = 1 - (5 - note) / 5
    return Math.min(Math.max(y * 100, 0), 100)
  } */
  return { t, lastTrip, speed }
}
export default useController
