/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React from 'react'
import { momentConstants } from '../../../../../constants'
import StylesWithMaterialUi from '../../UserDetails.styles'
// eslint-disable-next-line import/no-named-as-default-member
import useController from './GeneralInformation.controller'
import { getPermissions } from '../../../../../helpers'

const GeneralInformation = (props) => {
  const { user, handleExpanded, expanded } = props
  const { accordion, auth, t } = useController()
  const classes = StylesWithMaterialUi()

  const RenderInformation = () => (
    <Accordion expanded={expanded} onChange={handleExpanded} ref={accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('v_detail.info')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {user.name ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {user.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {user.email ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.email')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {user.ref ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.ref')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {user.ref}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {user.status ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.statusDetail')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {t(`users.${user.status}`)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}{' '}
          {user.type ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.type')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {t(user.type)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {auth && getPermissions(auth.type, 'showOwner') && user.owner ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 'bold', fontFamily: 'Comfortaa' }}
                >
                  {t('users.owner')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {user.owner.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {user.lastAuthenticationDate ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Comfortaa',
                  }}
                >
                  {t('users.lastAuthenticationDateDetail')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                  {moment(user.lastAuthenticationDate).format(
                    momentConstants.FORMAT_INIT,
                  )}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )

  return <RenderInformation />
}
export default React.memo(GeneralInformation)
