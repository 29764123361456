export const localStorageService = { get, set, remove, getObject }

function get(key) {
  const json = localStorage.getItem(key)

  if (!json) {
    return null
  }

  return JSON.parse(json)
}
function getObject(key) {
  const json = localStorage.getItem(key)

  if (!json) {
    return null
  }

  return json
}
function set(key, value) {
  localStorage.setItem(key, value)
}

function remove(key) {
  localStorage.removeItem(key)
}
