/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Page, Document, Image } from '@react-pdf/renderer'
import logo from '../../../assets/images/logo/logo-jimini-black.png'
import ItemsTable from './components/ItemsTable'
import ItemsArray from './components/ItemsArray'
import styles from './PdfComponent.styles'

const PdfComponent = ({ data }) => (
  <Document>
    {data && Array.isArray(data)
      ? data.map((element, index) => (
          <Page size="A4" style={styles.page} key={`component-${index}`}>
            <Image style={styles.logo} src={logo} />
            <ItemsTable data={element.table} />
            <ItemsArray data={element.arrays} />
          </Page>
        ))
      : null}
  </Document>
)
export default PdfComponent
