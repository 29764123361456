import moment from 'moment'

const DetailComponents = (value, type, t) => {
  if (type === 'Tracking') {
    return t('alerts.lastRemote', { value: moment(value).format('lll') })
  }
  if (type === 'Insurance') {
    return t('alerts.endDate', { value: moment(value).format('ll') })
  }
  if (type === 'TechnicalControl') {
    return t('alerts.endDate', { value: moment(value).format('ll') })
  }
  return value
}
export default DetailComponents
