/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { vehiclesPatternConstants } from '../../../constants'
import {
  arrayRemove,
  emptyResponse,
  fullResponse,
  getPermissions,
} from '../../../helpers'
import {
  fleetsService,
  gatewaysService,
  vehiclesModelService,
  vehiclesService,
} from '../../../services'
import {
  alertActions,
  drawerActions,
  gatewaysActions,
  vehiclesActions,
} from '../../../actions'
import VehicleDetails from '../VehiclesDetails/VehicleDetails'
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'
import Utils from '../Helpers/useHelpers'
const useController = (props) => {
  const { vehicle, onclose, drawer } = props
  // hook
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const gatewaysRedux = useSelector((state) => state.gateways)
  const user = useSelector((state) => state.authentication.user)
  const socket = useSelector((state) => state.socket)

  // useState
  const [gatewayInput, setGatewayInput] = useState('')
  const [gateways, setGateways] = useState([])
  const [openGateways, setOpenGateways] = useState(false)
  const loadingGateways = openGateways && gateways.length === 0

  const [vehicleFleetInput, setVehicleFleetInput] = useState('')
  const [openFleets, setOpenFleets] = useState(false)
  const [fleets, setFleets] = useState([])
  const loadingFleets = openFleets && fleets.length === 0

  const [deleteAvatar, setDeleteAvatar] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [open, setOpen] = useState(false)

  const [openVehicleModels, setOpenVehicleModels] = useState(false)
  const [vehicleModelInput, setVehicleModelInput] = useState('')
  const [vehicleModels, setVehicleModels] = useState([])
  const loadingVehicleModels = openVehicleModels && vehicleModels.length === 0

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      ref: '',
      vin: '',
      vehicleName: '',
      numberplate: '',
      vehicleFleet: '',
      odometer: 0,
      vehicleModelId: '',
      gateway: '',
    },
  })

  const location = useLocation()
  const { sendFiles, validateNumberPlate, validateVehicleName, validateValue } =
    Utils({ selectedFiles })

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])

  const updateRedux = useCallback(
    async (id) => {
      try {
        const res = await vehiclesService.getVehicles()
        if (!res || res.status !== 200) {
          dispatch(alertActions.error(res.content.data.message, 6000, true))
          return
        }
        const data = res.content.data.map((o) => {
          // eslint-disable-next-line no-param-reassign
          o.display = true
          return o
        })
        dispatch(vehiclesActions.success(data))

        const resVehicle = res.content.data.find((e) => e.id === id)
        if (resVehicle) {
          if (gatewaysRedux.data) {
            dispatch(gatewaysActions.update(resVehicle))
          }
        }

        if (drawer) {
          onclose()
          return
        }
        if (resVehicle) {
          dispatch(
            drawerActions.show(
              <VehicleDetails vehicle={resVehicle} />,
              t('v_detail.v_title'),
              'right',
              'persistent',
              'ltr',
              null,
              null,
              null,
            ),
          )
        }
      } catch (error) {
        dispatch(vehiclesActions.failure())
      }
    },
    [dispatch, drawer, gatewaysRedux.data, onclose, t],
  )

  const checkGateway = useCallback(
    (value) => {
      if (vehicle.gateway && !value) {
        return true
      }
      if (value && !vehicle.gateway && value.ref) {
        return true
      }
      return !!(vehicle.gateway && value && vehicle.gateway.ref !== value.ref)
    },
    [vehicle.gateway],
  )

  const checkVehicleFleet = useCallback(
    (value) => {
      if (!value && vehicle.vehicleFleet) {
        return true
      }

      if (value && !vehicle.vehicleFleet && value.name) {
        return !vehicle.vehicleFleet && value.name
      }
      return (
        vehicle.vehicleFleet &&
        value &&
        vehicle.vehicleFleet.name !== value.name
      )
    },
    [vehicle.vehicleFleet],
  )

  const checkVehicleModel = useCallback(
    (value) => {
      if (!value && vehicle.vehicleModel) {
        return true
      }

      if (value && !vehicle.vehicleModel && value.name) {
        return !vehicle.vehicleModel && value.name
      }
      return (
        vehicle.vehicleModel &&
        value &&
        vehicle.vehicleModel.name !== value.name
      )
    },
    [vehicle.vehicleModel],
  )

  const checkOdometer = useCallback(
    (value) =>
      !vehicle.odometer && Number(value) >= 0
        ? true
        : !!(
            vehicle.odometer &&
            value &&
            Number(value) >= 0 &&
            value !== vehicle.odometer
          ),
    [vehicle.odometer],
  )
  const checkVehicleName = useCallback(
    (value) => {
      if (!value && vehicle.name) {
        return true
      }
      return !!(
        value &&
        vehiclesPatternConstants.NAME_PATTERN.test(value) &&
        value !== vehicle.name
      )
    },
    [vehicle.name],
  )
  const checkNumberplate = useCallback(
    (value) => {
      if (!value) {
        return true
      }
      if (
        !vehicle.numberplate &&
        value &&
        vehiclesPatternConstants.NUMBERPLATE_PATTERN.test(value)
      ) {
        return true
      }
      return !!(
        value &&
        vehiclesPatternConstants.NUMBERPLATE_PATTERN.test(value) &&
        value !== vehicle.numberplate
      )
    },
    [vehicle.numberplate],
  )

  const checkRef = useCallback(
    (value) =>
      user &&
      getPermissions(user.type, 'editRef') &&
      value &&
      value !== vehicle.ref,
    [user, vehicle.ref],
  )

  const checkVin = useCallback(
    (value) =>
      user &&
      getPermissions(user.type, 'editVin') &&
      value &&
      value !== vehicle.vin,
    [user, vehicle.vin],
  )

  const handleEdit = useCallback(
    async (data) => {
      try {
        const {
          gateway,
          vehicleName,
          vin,
          ref,
          numberplate,
          odometer,
          vehicleModelId,
          vehicleFleet,
        } = data
        setOpen(true)

        if (vehicle.id) {
          const body = {}
          let files
          if (selectedFiles && selectedFiles.length > 0) {
            const res = await sendFiles()
            if (emptyResponse(res, 201)) {
              setOpen(false)
              dispatch(
                alertActions.error(
                  res && res.content ? res.content.message : t('global.error'),
                  6000,
                  true,
                ),
              )
              return
            }
            files = res.content.data.files
          }
          if (checkGateway(gateway)) {
            body.gatewayId = gateway && gateway.id ? gateway.id : gateway
          }
          if (checkRef(ref)) {
            body.ref = ref
          }
          if (checkVin(vin)) {
            body.vin = vin
          }
          if (checkVehicleFleet(vehicleFleet)) {
            body.vehicleFleetId =
              vehicleFleet && vehicleFleet.id
                ? vehicleFleet.id
                : vehicleFleet || null
          }
          if (checkVehicleModel(vehicleModelId)) {
            body.vehicleModelId =
              vehicleModelId && vehicleModelId.id
                ? vehicleModelId.id
                : vehicleModelId || null
          }
          if (checkOdometer(odometer) && odometer > 0) {
            body.odometer = Number(odometer)
          }

          if (checkVehicleName(vehicleName)) {
            body.name = vehicleName || null
          }

          if (checkNumberplate(numberplate)) {
            body.numberplate = numberplate || null
          }
          if (files && files.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            body.avatar = files[0]
          }
          if (deleteAvatar && !files) {
            body.avatar = null
          }
          if (JSON.stringify(body) === JSON.stringify({})) {
            dispatch(alertActions.info(t('v_detail.v_formChange'), 6000, true))
            setOpen(false)
            return
          }

          const res = await vehiclesService.updateVehicle(body, vehicle.id)
          if (emptyResponse(res, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                res.content ? res.content.message : t('global.error'),
                6000,
                true,
              ),
            )
            return
          }
          setOpen(false)
          dispatch(alertActions.success(t('v_detail.v_update_msg'), 5000, true))
          await updateRedux(vehicle.id)
          form.reset()
          return
        }
        setOpen(false)

        dispatch(alertActions.info(t('v_detail.v_form_validation'), 6000, true))
      } catch (err) {
        dispatch(alertActions.error(err.toString(), 6000, true))
        setOpen(false)
      }
    },
    [
      form,
      checkGateway,
      checkNumberplate,
      checkOdometer,
      checkRef,
      checkVehicleFleet,
      checkVehicleModel,
      checkVehicleName,
      checkVin,
      deleteAvatar,
      dispatch,
      selectedFiles,
      sendFiles,
      t,
      updateRedux,
      vehicle.id,
    ],
  )
  // endregionS

  useEffect(() => {
    const abortController = new AbortController()
    const checkInput = () => {
      if (vehicle) {
        form.setValue('ref', vehicle.ref || '')
        form.setValue('vin', vehicle.vin || '')
        form.setValue('vehicleName', vehicle.name || '')
        form.setValue('numberplate', vehicle.numberplate || '')
        form.setValue(
          'odometer',
          vehicle.odometer ? Math.floor(vehicle.odometer) : '',
        )

        if (vehicle.gateway) {
          setGatewayInput(vehicle.gateway.ref)
          form.setValue('gateway', vehicle.gateway || '')
        }
        if (vehicle.vehicleModel) {
          setVehicleModelInput(vehicle.vehicleModel.name)
          form.setValue('vehicleModelId', vehicle.vehicleModel || '')
        }
        if (vehicle.vehicleFleet) {
          setVehicleFleetInput(vehicle.vehicleFleet.name)
          form.setValue('vehicleFleet', vehicle.vehicleFleet || '')
        }
      }
    }
    checkInput()
    return () => {
      abortController.abort()
    }
  }, [vehicle, form])

  useEffect(() => {
    const abortController = new AbortController()
    const checkSelectedFiles = () => {
      if (selectedFiles && selectedFiles.length > 0) {
        setDeleteAvatar(true)
      } else {
        setDeleteAvatar(false)
      }
    }
    checkSelectedFiles()
    return () => {
      abortController.abort()
    }
  }, [selectedFiles])

  useEffect(() => {
    const abortController = new AbortController()
    const clear = () => {
      if (!openVehicleModels) {
        setVehicleModels([])
      }
      if (!openFleets) {
        setFleets([])
      }
      if (!openGateways) {
        setGateways([])
      }
    }
    clear()
    return () => {
      abortController.abort()
    }
  }, [openFleets, openGateways, openVehicleModels])

  useEffect(() => {
    let active = true

    if (!loadingGateways) {
      return undefined
    }
    ;(async () => {
      const query = {
        hasVehicle: false,
        ownerId: vehicle.owner.id,
      }

      const res = await gatewaysService.gateway(query)
      if (fullResponse(res, 200)) {
        form.clearErrors('gateway')
        const gatewaysList = arrayRemove(res.content.data, vehicle.gateway)
        if (active) {
          setGateways(gatewaysList)
        }
        return
      }
      setOpenGateways(false)

      if (active) {
        form.setError('gateway', {
          type: 'custom',
          message: t('table.errorSelectGateways'),
        })
        setGateways([])
      }
    })()
    return () => {
      active = false
    }
  }, [loadingGateways, vehicle, form, t])

  useEffect(() => {
    let active = true

    if (!loadingFleets) {
      return undefined
    }
    ;(async () => {
      const query = {
        ownerId: vehicle.owner.id,
      }
      const res = await fleetsService.fleets(query)
      if (fullResponse(res, 200)) {
        form.clearErrors('vehicleFleet')
        const fleetsList = arrayRemove(res.content.data, vehicle.vehicleFleet)
        if (active) {
          setFleets(fleetsList)
        }
        return
      }
      setOpenFleets(false)

      if (active) {
        form.setError('vehicleFleet', {
          type: 'custom',
          message: t('table.errorSelectFleet'),
        })
        setFleets([])
      }
    })()
    return () => {
      active = false
    }
  }, [loadingFleets, vehicle, form, t])

  const handleDeleteAvatar = useCallback(() => {
    if (selectedFiles && selectedFiles.length === 0) {
      setDeleteAvatar(!deleteAvatar)
    }
  }, [deleteAvatar, selectedFiles])

  useEffect(() => {
    let active = true

    if (!loadingVehicleModels) {
      return undefined
    }
    ;(async () => {
      const res = await vehiclesModelService.getVehicleModels()
      if (fullResponse(res, 200)) {
        form.clearErrors('vehicleModelId')
        let vehicleModelList
        if (vehicle.vehicleModel) {
          vehicleModelList = arrayRemove(res.content.data, vehicle.vehicleModel)
        } else {
          vehicleModelList = res.content.data
        }
        if (active) {
          setVehicleModels(vehicleModelList)
          if (vehicleModelList.length === 0) {
            setOpenVehicleModels(false)
          }
        }
        return
      }
      setOpenVehicleModels(false)

      if (active) {
        form.setError('vehicleModelId', {
          type: 'custom',
          message: t('table.errorSelectModel'),
        })
        setVehicleModels([])
      }
    })()
    return () => {
      active = false
    }
  }, [loadingVehicleModels, vehicle, form, t])

  return {
    handleEdit,
    setOpenVehicleModels,
    openVehicleModels,
    vehicleModels,
    loadingVehicleModels,
    setVehicleFleetInput,
    setVehicleModelInput,
    fleets,
    openFleets,
    gateways,
    openGateways,
    open,
    handleDeleteAvatar,
    selectedFiles,
    setSelectedFiles,
    setOpenFleets,
    loadingFleets,
    vehicleModelInput,
    vehicleFleetInput,
    setOpenGateways,
    gatewayInput,
    loadingGateways,
    deleteAvatar,
    setGatewayInput,
    t,
    user,
    form,
    validateNumberPlate,
    validateVehicleName,
    validateValue,
  }
}

export default useController
