/* eslint-disable import/no-cycle */
import moment from 'moment'
import { StyleSheet } from '@react-pdf/renderer'
import { momentConstants } from '../../constants'
import TimeComponent from '../../components/DataTable/components/TimeComponent'
import { getPermissions } from '../users/permissions.helpers'
const borderColor = '#000000'
const styles = StyleSheet.create({
  name: {
    width: '20%',
    textAlign: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 13,
  },
  nameBody: {
    width: '20%',
    fontSize: 12,
    textAlign: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  vinBody: {
    width: '20%',
    fontSize: 9,
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    flexWrap: 'wrap',
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  numberplate: {
    fontSize: 13,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
  },
  numberplateBody: {
    fontSize: 12,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  vehicleFleet: {
    fontSize: 13,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
  },
  vehicleFleetBody: {
    fontSize: 12,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  gateway: {
    fontSize: 13,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
  },
  gatewayBody: {
    fontSize: 12,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  owner: {
    fontSize: 13,
    width: '20%',
    textAlign: 'center',
    paddingRight: 8,
  },
  ownerBody: {
    fontSize: 12,
    width: '20%',
    textAlign: 'center',
    paddingRight: 8,
    paddingTop: 2,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
})

const setHeaders = (t, user) => {
  if (user && getPermissions(user.type, 'customReportExport')) {
    return [
      { label: t('csv.nameVh'), key: 'name' },
      { label: t('csv.nameVhRef'), key: 'ref' },
      { label: t('csv.nameVhVin'), key: 'vin' },
      { label: t('csv.numberplate'), key: 'numberplate' },
      { label: t('csv.vehicleFleet'), key: 'vehicleFleet' },
      { label: t('csv.gateway'), key: 'gateway' },
      { label: t('csv.startDate'), key: 'startDate' },
      { label: t('csv.startTracking'), key: 'firstTracking' },
      { label: t('csv.endDate'), key: 'endDate' },
      { label: t('csv.endTracking'), key: 'lastTracking' },
      { label: t('csv.distanceTraveled'), key: 'distanceTraveled' },
      { label: t('csv.distanceTripsGps'), key: 'tripsDistanceGps' },
      { label: t('csv.odometerTrips'), key: 'tripsDistanceOdometer' },
      { label: t('csv.duration'), key: 'tripsDuration' },
      { label: t('csv.numberOfTripsEnded'), key: 'numberOfTripsEnded' },
    ]
  }
  return [
    { label: t('csv.nameVh'), key: 'name' },
    { label: t('csv.nameVhRef'), key: 'ref' },
    { label: t('csv.nameVhVin'), key: 'vin' },
    { label: t('csv.numberplate'), key: 'numberplate' },
    { label: t('csv.vehicleFleet'), key: 'vehicleFleet' },
    { label: t('csv.gateway'), key: 'gateway' },
    { label: t('csv.owner'), key: 'owner' },
    { label: t('csv.startDate'), key: 'startDate' },
    { label: t('csv.startTracking'), key: 'firstTracking' },
    { label: t('csv.endDate'), key: 'endDate' },
    { label: t('csv.endTracking'), key: 'lastTracking' },
    { label: t('csv.distanceGps'), key: 'distanceGps' },
    { label: t('csv.odometer'), key: 'distanceOdometer' },
    { label: t('csv.distanceTripsGps'), key: 'tripsDistanceGps' },
    { label: t('csv.odometerTrips'), key: 'tripsDistanceOdometer' },
    { label: t('csv.duration'), key: 'tripsDuration' },
    { label: t('csv.numberOfTripsEnded'), key: 'numberOfTripsEnded' },
  ]
}

const setPdfData = async (data, t, user) => {
  const list = []
  if (user && getPermissions(user.type, 'customReportExport')) {
    if (data && Array.isArray(data)) {
      data.map((item) => {
        const element = {
          table: [
            [
              {
                header: t('csv.nameVh'),
                body: item.name ? item.name : '--',
                styleHeader: styles.name,
                styleBody: styles.nameBody,
              },
              {
                header: t('csv.nameVhVin'),
                body: item.vin ? item.vin : '--',
                styleHeader: styles.name,
                styleBody: styles.vinBody,
              },
              {
                header: t('csv.nameVhRef'),
                body: item.ref ? item.ref : '--',
                styleHeader: styles.name,
                styleBody: styles.nameBody,
              },
              {
                header: t('csv.numberplate'),
                body: item.numberplate ? item.numberplate : '--',
                styleHeader: styles.numberplate,
                styleBody: styles.numberplateBody,
              },
              {
                header: t('csv.vehicleFleet'),
                body: item.vehicleFleet ? item.vehicleFleet.name : '--',
                styleHeader: styles.vehicleFleet,
                styleBody: styles.vehicleFleetBody,
              },
            ],
          ],
          arrays: [
            {
              header: t('csv.gateway'),
              body: item.gateway ? item.gateway.ref : '--',
            },
            {
              header: t('csv.startTracking'),
              body: item.report.firstTracking
                ? item.report.firstTracking.address
                : '--',
            },
            {
              header: t('csv.startDate'),
              body: item.report.firstTracking
                ? moment(item.report.firstTracking.date).format(
                    momentConstants.FORMAT_INIT,
                  )
                : '--',
            },
            {
              header: t('csv.endTracking'),
              body: item.report.lastTracking
                ? item.report.lastTracking.address
                : '--',
            },
            {
              header: t('csv.endDate'),
              body: item.report.lastTracking
                ? moment(item.report.lastTracking.date).format(
                    momentConstants.FORMAT_INIT,
                  )
                : '--',
            },
            {
              header: t('csv.distanceTraveled'),
              body:
                item.report.distanceGps > 0 || item.report.distanceOdometer > 0
                  ? t('global.uniteKm', {
                      value:
                        item.report.distanceGps.toFixed(2) ||
                        item.report.distanceOdometer.toFixed(2),
                    })
                  : 0,
            },
            {
              header: t('csv.distanceTripsGps'),
              body:
                item.report && item.report.tripsDistanceGps > 0
                  ? t('global.uniteKm', {
                      value: item.report.tripsDistanceGps.toFixed(2),
                    })
                  : 0,
            },
            {
              header: t('csv.odometerTrips'),
              body:
                item.report && item.report.tripsDistanceOdometer > 0
                  ? t('global.uniteKm', {
                      value: item.report.tripsDistanceOdometer.toFixed(2),
                    })
                  : 0,
            },
            {
              header: t('csv.duration'),
              body:
                item.report && item.report.tripsDuration > 0
                  ? TimeComponent(item.report.tripsDuration, t)
                  : '--',
            },
            {
              header: t('csv.numberOfTripsEnded'),
              body:
                item.report && item.report.numberOfTripsEnded
                  ? item.report.numberOfTripsEnded
                  : 0,
            },
          ],
        }
        return list.push(element)
      })
    }
    return list
  }

  if (data && Array.isArray(data)) {
    data.map((item) => {
      const element = {
        table: [
          [
            {
              header: t('csv.nameVh'),
              body: item.name ? item.name : '--',
              styleHeader: styles.name,
              styleBody: styles.nameBody,
            },
            {
              header: t('csv.nameVhVin'),
              body: item.vin ? item.vin : '--',
              styleHeader: styles.name,
              styleBody: styles.vinBody,
            },
            {
              header: t('csv.nameVhRef'),
              body: item.ref ? item.ref : '--',
              styleHeader: styles.name,
              styleBody: styles.nameBody,
            },
            {
              header: t('csv.numberplate'),
              body: item.numberplate ? item.numberplate : '--',
              styleHeader: styles.numberplate,
              styleBody: styles.numberplateBody,
            },
            {
              header: t('csv.vehicleFleet'),
              body: item.vehicleFleet ? item.vehicleFleet.name : '--',
              styleHeader: styles.vehicleFleet,
              styleBody: styles.vehicleFleetBody,
            },
          ],
        ],
        arrays: [
          {
            header: t('csv.gateway'),
            body: item.gateway ? item.gateway.ref : '--',
          },
          {
            header: t('csv.owner'),
            body: item.owner ? item.owner.name : '--',
          },
          {
            header: t('csv.startTracking'),
            body: item.report.firstTracking
              ? item.report.firstTracking.address
              : '--',
          },
          {
            header: t('csv.startDate'),
            body: item.report.firstTracking
              ? moment(item.report.firstTracking.date).format(
                  momentConstants.FORMAT_INIT,
                )
              : '--',
          },
          {
            header: t('csv.endTracking'),
            body: item.report.lastTracking
              ? item.report.lastTracking.address
              : '--',
          },
          {
            header: t('csv.endDate'),
            body: item.report.lastTracking
              ? moment(item.report.lastTracking.date).format(
                  momentConstants.FORMAT_INIT,
                )
              : '--',
          },
          {
            header: t('csv.distanceGps'),
            body:
              item.report && item.report.distanceGps > 0
                ? t('global.uniteKm', {
                    value: item.report.distanceGps.toFixed(2),
                  })
                : 0,
          },
          {
            header: t('csv.odometer'),
            body:
              item.report.distanceOdometer && item.report.distanceOdometer > 0
                ? t('global.uniteKm', {
                    value: item.report.distanceOdometer.toFixed(2),
                  })
                : 0,
          },
          {
            header: t('csv.distanceTripsGps'),
            body:
              item.report && item.report.tripsDistanceGps > 0
                ? t('global.uniteKm', {
                    value: item.report.tripsDistanceGps.toFixed(2),
                  })
                : 0,
          },
          {
            header: t('csv.odometerTrips'),
            body:
              item.report && item.report.tripsDistanceOdometer > 0
                ? t('global.uniteKm', {
                    value: item.report.tripsDistanceOdometer.toFixed(2),
                  })
                : 0,
          },
          {
            header: t('csv.duration'),
            body:
              item.report && item.report.tripsDuration > 0
                ? TimeComponent(item.report.tripsDuration, t)
                : '--',
          },
          {
            header: t('csv.numberOfTripsEnded'),
            body:
              item.report && item.report.numberOfTripsEnded
                ? item.report.numberOfTripsEnded
                : 0,
          },
        ],
      }
      return list.push(element)
    })
  }
  return list
}
const setCsvData = async (data, t, user) => {
  const list = []
  if (user && getPermissions(user.type, 'customReportExport')) {
    if (data && Array.isArray(data)) {
      data.map((item) => {
        const element = {
          vin: item.vin ? item.vin : null,
          ref: item.ref ? item.ref : null,
          name: item.name ? item.name : null,
          numberplate: item.numberplate ? item.numberplate : null,
          vehicleFleet: item.vehicleFleet ? item.vehicleFleet.name : null,
          gateway: item.gateway ? item.gateway.ref : null,
          firstTracking: item.report.firstTracking
            ? item.report.firstTracking.address
            : null,
          startDate: item.report.firstTracking
            ? moment(item.report.firstTracking.date).format(
                momentConstants.FORMAT_INIT,
              )
            : null,
          lastTracking: item.report.lastTracking
            ? item.report.lastTracking.address
            : null,
          endDate: item.report.lastTracking
            ? moment(item.report.lastTracking.date).format(
                momentConstants.FORMAT_INIT,
              )
            : null,

          distanceTraveled:
            item.report.distanceGps > 0 || item.report.distanceOdometer > 0
              ? t('global.uniteKm', {
                  value:
                    item.report.distanceGps.toFixed(2) ||
                    item.report.distanceOdometer.toFixed(2),
                })
              : item.report.distanceGps,
          tripsDistanceGps:
            item.report.tripsDistanceGps > 0
              ? t('global.uniteKm', {
                  value: item.report.tripsDistanceGps.toFixed(2),
                })
              : item.report.tripsDistanceGps,
          tripsDistanceOdometer:
            item.report.tripsDistanceOdometer > 0
              ? t('global.uniteKm', {
                  value: item.report.tripsDistanceOdometer.toFixed(2),
                })
              : item.report.tripsDistanceOdometer,
          tripsDuration:
            item.report && item.report.tripsDuration > 0
              ? TimeComponent(item.report.tripsDuration, t)
              : null,
          numberOfTripsEnded: item.report.numberOfTripsEnded,
        }
        return list.push(element)
      })
    }
    return list
  }
  if (data && Array.isArray(data)) {
    data.map((item) => {
      const element = {
        vin: item.vin ? item.vin : null,
        ref: item.ref ? item.ref : null,
        name: item.name ? item.name : null,
        numberplate: item.numberplate ? item.numberplate : null,
        vehicleFleet: item.vehicleFleet ? item.vehicleFleet.name : null,
        gateway: item.gateway ? item.gateway.ref : null,
        owner: item.owner ? item.owner.name : null,
        firstTracking: item.report.firstTracking
          ? item.report.firstTracking.address
          : null,
        startDate: item.report.firstTracking
          ? moment(item.report.firstTracking.date).format(
              momentConstants.FORMAT_INIT,
            )
          : null,
        lastTracking: item.report.lastTracking
          ? item.report.lastTracking.address
          : null,
        endDate: item.report.lastTracking
          ? moment(item.report.lastTracking.date).format(
              momentConstants.FORMAT_INIT,
            )
          : null,

        distanceGps:
          item.report.distanceGps > 0
            ? t('global.uniteKm', {
                value: item.report.distanceGps.toFixed(2),
              })
            : item.report.distanceGps,
        distanceOdometer:
          item.report.distanceOdometer > 0
            ? t('global.uniteKm', {
                value: item.report.distanceOdometer.toFixed(2),
              })
            : item.report.distanceOdometer,
        tripsDistanceGps:
          item.report.tripsDistanceGps > 0
            ? t('global.uniteKm', {
                value: item.report.tripsDistanceGps.toFixed(2),
              })
            : item.report.tripsDistanceGps,
        tripsDistanceOdometer:
          item.report.tripsDistanceOdometer > 0
            ? t('global.uniteKm', {
                value: item.report.tripsDistanceOdometer.toFixed(2),
              })
            : item.report.tripsDistanceOdometer,
        tripsDuration:
          item.report && item.report.tripsDuration > 0
            ? TimeComponent(item.report.tripsDuration, t)
            : null,
        numberOfTripsEnded: item.report.numberOfTripsEnded,
      }
      return list.push(element)
    })
  }
  return list
}
export { setHeaders, setCsvData, setPdfData }
