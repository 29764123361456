import { Button, Tooltip } from '@material-ui/core'
import React, { forwardRef, useRef } from 'react'
import StylesWithMaterialUi from './ButtonComponent.theme'

const ButtonComponent = forwardRef((props, ref) => {
  const classes = StylesWithMaterialUi()
  const {
    children,
    tooltiptitle,
    size,
    variant,
    color,
    className,
    styles,
    disabled,
    onClick,
    component,
    ...other
  } = props
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  return tooltiptitle ? (
    <Tooltip title={tooltiptitle}>
      <Button
        ref={resolvedRef}
        {...other}
        component={disabled ? 'div' : component}
        onClick={disabled ? undefined : onClick}
        className={className || classes.btn}
        disabled={disabled}
        size={size || 'medium'}
        variant={variant || 'outlined'}
        color={color || 'primary'}
        style={
          styles
            ? {
                textTransform: 'none',
                fontSize: '12px',
                fontWeight: 'Bold',
                fontFamily: 'Comfortaa',
              }
            : null
        }
      >
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button
      ref={resolvedRef}
      {...other}
      component={component}
      disabled={disabled}
      onClick={onClick}
      className={className || classes.btn}
      size={size || 'medium'}
      variant={variant || 'outlined'}
      color={color || 'primary'}
      style={
        styles
          ? {
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 'Bold',
              fontFamily: 'Comfortaa',
            }
          : null
      }
    >
      {children}
    </Button>
  )
})
export default ButtonComponent
