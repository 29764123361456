/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import useController from './Orders.controller'
import DataTable from '../../../../DataTable/DataTable'
import BackdropComponent from '../../../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'
import { getPermissions } from '../../../../../helpers'

const Orders = (props) => {
  const { handleExpanded, expanded, vehicle } = props

  const {
    loading,
    t,
    columnsTable,
    tableData,
    customOption,
    components,
    isLoading,
    availableCommands,
    open,
    user,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  const getCommandsBtn = () =>
    user &&
    getPermissions(user.type, 'showCommandesBtn') &&
    availableCommands.map((o, i) => (
      <Grid item key={`${o.toString()}-${i}`}>
        <ButtonComponent
          styles
          onClick={() => o.onClick(o.type)}
          className={o.className}
          startIcon={o.icon}
          tooltiptitle={o.label}
          disabled={o.disabled}
        >
          {o.label}
        </ButtonComponent>
      </Grid>
    ))
  return vehicle ? (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('orders.title')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <BackdropComponent className={classes.backdrop} open={open} />
        {loading ? (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            {availableCommands && availableCommands.length > 0 ? (
              <Grid
                container
                direction="row"
                spacing={2}
                item
                alignItems="center"
                className={classes.gridContainer}
                style={{ marginBottom: '5px' }}
              >
                {getCommandsBtn()}
              </Grid>
            ) : null}
            {user && getPermissions(user.type, 'showCommandes') ? (
              <Grid container item direction="row" className={classes.gridItem}>
                <Box className={classes.table} elevation={0}>
                  <DataTable
                    title={
                      <Typography variant="h6">
                        {isLoading && (
                          <CircularProgress
                            size={24}
                            style={{
                              marginLeft: 15,
                              position: 'relative',
                              top: 4,
                            }}
                          />
                        )}
                      </Typography>
                    }
                    columns={columnsTable || undefined}
                    data={tableData || undefined}
                    options={customOption}
                    components={components}
                  />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null
}
export default React.memo(Orders)
