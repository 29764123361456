// eslint-disable-next-line import/no-cycle
import { localStorageService } from './local-storage.service'
// eslint-disable-next-line import/no-cycle
import { authenticationService } from '../users/authentication.service'
import { sleep } from '../../helpers/system/system.helpers'

let isTokenRefreshing = false

export const authenticate = async () => {
  const auth = await getAuth()
  if (!auth) {
    return null
  }
  const currentDate = new Date()
  const refreshTokenExpiration = new Date(auth.refreshExpiration).setSeconds(
    new Date(auth.refreshExpiration).getSeconds() - 30,
  )

  if (currentDate > refreshTokenExpiration) {
    await removeAuth('userAuth')
    return null
  }

  const authorizationTokenExpiration = new Date(auth.authExpiration).setSeconds(
    new Date(auth.authExpiration).getSeconds() - 30,
  )
  if (currentDate > authorizationTokenExpiration) {
    if (!isTokenRefreshing) {
      return refreshAuth()
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (isTokenRefreshing) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(100)
      } else {
        return getAuth()
      }
    }
  }

  return auth
}
export const removeAuth = async () => {
  await localStorageService.remove('userAuth')
}
export const setAuth = (data, vehicle) => {
  let user
  if (vehicle) {
    user = data.vehicle
    user.type = 'Vehicle'
  } else {
    user = data.user
  }

  const userAuth = {
    authExpiration: data.authExpiration,
    authToken: data.authToken,
    refreshExpiration: data.refreshExpiration,
    refreshToken: data.refreshToken,
  }
  localStorageService.set('userAuth', JSON.stringify(userAuth))
  localStorageService.set('user', JSON.stringify(user))
}

export const getAuth = async () => {
  const auth = localStorageService.get('userAuth')

  if (!auth) {
    return null
  }

  return auth
}

export const refreshAuth = async () => {
  isTokenRefreshing = true
  const auth = await getAuth()
  const res = await authenticationService.refreshAuthToken(auth.refreshToken)
  if (!res || res.status !== 201) {
    await removeAuth('userAuth')
    isTokenRefreshing = false
    return null
  }
  isTokenRefreshing = false
  setAuth(res.content.data, false)
  return res.content.data
}

export default { authenticate, setAuth, getAuth, removeAuth, refreshAuth }
