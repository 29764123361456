/* eslint-disable import/no-cycle */
import {
  Tooltip,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  Box,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useCallback } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import DescriptionIcon from '@material-ui/icons/Description'
import { capitalize, getPermissions } from '../../../../../helpers'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import DialogBox from '../../../../utils/MatrialUiComponents/DialogBox/DialogBox'
import { permissionsConstants } from '../../../../../constants'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'
import useController from './Documents.controller'

const Documents = (props) => {
  const { handleExpanded, expanded, vehicle } = props
  const {
    data,
    addDocument,
    editDocument,
    handleDelete,
    showDocumentDetail,
    handleCancelPopup,
    open,
    handlePopup,
    loading,
    doc,
    refDialog,
    t,
    user,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  const renderGrid = useCallback(
    () =>
      data ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {vehicle && vehicle.status === 'Blocked' ? null : (
            <>
              {user && getPermissions(user.type, 'addDocument') ? (
                <Grid
                  container
                  item
                  direction="row"
                  className={classes.gridItem}
                >
                  <ButtonComponent
                    styles
                    className={classes.buttonFull}
                    startIcon={<DescriptionIcon />}
                    onClick={addDocument}
                    tooltiptitle={t('documents.add')}
                  >
                    {t('documents.add')}
                  </ButtonComponent>
                </Grid>
              ) : null}
            </>
          )}

          {data.map((o) => (
            <Grid
              container
              item
              direction="row"
              key={o.id}
              className={classes.gridItem}
            >
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                container
                style={{
                  whiteSpace: 'nowrap',
                  width: '50%',
                }}
              >
                <Box component="div" textOverflow="ellipsis" overflow="hidden">
                  -{' '}
                  {o.name
                    ? t('documents.titleDoc', {
                        name: o.name,
                        type: t(o.type),
                      })
                    : t('documents.titleDocWithoutName', {
                        type: capitalize(t(o.type)),
                      })}
                </Box>
              </Grid>
              <Grid
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                container
                style={{ width: '50%' }}
              >
                {user && getPermissions(user.type, 'showDocument') ? (
                  <Grid item>
                    <Tooltip title={t('documents.show')}>
                      <IconButton
                        aria-label="Visibility"
                        onClick={() => {
                          showDocumentDetail(o)
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
                {(user &&
                  getPermissions(user.type, 'allowToEditDocument') &&
                  !permissionsConstants.restrictedTypesEditDocumentsList.includes(
                    o.author.type,
                  )) ||
                (user && user.type === 'Admin') ? (
                  <Grid item>
                    <Tooltip title={t('documents.edit')}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => {
                          editDocument(o)
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}

                {(user &&
                  getPermissions(user.type, 'allowToDeleteDocument') &&
                  !permissionsConstants.restrictedTypesDeleteDocumentsList.includes(
                    o.author.type,
                  )) ||
                (user && user.type === 'Admin') ? (
                  <Grid item>
                    <Tooltip title={t('documents.delete')}>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          handleDelete(o.id, o.name ? o.name : o.type)
                        }
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          {vehicle && vehicle.status === 'Blocked' ? null : (
            <>
              {user && getPermissions(user.type, 'addDocument') ? (
                <Grid
                  container
                  item
                  direction="row"
                  className={classes.gridItem}
                >
                  <ButtonComponent
                    styles
                    className={classes.buttonFull}
                    startIcon={<DescriptionIcon />}
                    onClick={addDocument}
                    tooltiptitle={t('documents.add')}
                  >
                    {t('documents.add')}
                  </ButtonComponent>
                </Grid>
              ) : null}
            </>
          )}
          <Grid
            item
            container
            direction="row"
            className={classes.gridItemNotFound}
          >
            <Typography className={classes.input}>
              {t('documents.notFround')}
            </Typography>
          </Grid>
        </Grid>
      ),
    [
      addDocument,
      classes.buttonFull,
      classes.gridContainer,
      classes.gridItem,
      classes.gridItemNotFound,
      classes.input,
      data,
      editDocument,
      handleDelete,
      showDocumentDetail,
      t,
      user,
      vehicle,
    ],
  )

  return (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('documents.title')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <DialogBox
          onClose={handleCancelPopup}
          ref={refDialog}
          action={false}
          open={open}
          title=""
          text={` ${t('documents.box_text')} "${doc ? doc.name : null}"`}
          handleClose={handlePopup}
          handleCancelBox={handleCancelPopup}
        />
        {loading ? (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        ) : (
          renderGrid()
        )}
      </AccordionDetails>
    </Accordion>
  )
}
export default React.memo(Documents)
