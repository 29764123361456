import { Controller } from 'react-hook-form'
import React from 'react'

const FormController = (props) => (
  <Controller
    name={props.name}
    rules={{
      validate: props.validate,
    }}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    }) => props.render({ onChange, onBlur, value, ref, error })}
  />
)

export default FormController
