/* eslint-disable import/no-cycle */
import React from 'react'
import { Grid, Box } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import StylesWithMaterialUi from './AddDocuments.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ComboBox from '../../utils/MatrialUiComponents/ComboBox/ComboBox'
import { documentsConstants } from '../../../constants'
import RegistrationCard from './components/registrationCard/RegistrationCard'
import InsuranceCard from './components/insuranceCard/InsuranceCard'
import DropZone from '../../DropZone/DropZone'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import FileView from '../../DropZone/components/fileView/FilesView'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import DialogBox from '../../utils/MatrialUiComponents/DialogBox/DialogBox'
import FillingModel from './components/fillingModel/FillingModel'
import useController from './AddDocuments.controller'
import TechnicalControl from './components/technicalControl/TechnicalControl'
import PV from './components/PV/PV'

const AddDocuments = (props) => {
  const { onclose } = props
  const {
    handleSubmit,
    model,
    openDialogBox,
    t,
    vehicleName,
    docName,
    handleChange,
    type,
    selectedFiles,
    expirationDate,
    handleChangeDate,
    document,
    disabled,
    open,
    handleCancelPopup,
    refDialog,
    openDialog,
    onFillUp,
    setData,
    setExpirationDate,
    setDisabled,
    setSelectedFiles,
    setType,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <form name="form" onSubmit={handleSubmit} style={{ width: '100%' }}>
          {model ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItemBtnFull}
            >
              <ButtonComponent
                styles
                className={classes.buttonFull}
                onClick={openDialogBox}
                startIcon={<ImportContactsIcon />}
              >
                {t('documents.duplicate')}
              </ButtonComponent>
            </Grid>
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="filled"
              label={t('v_detail.nameInputVehicle')}
              value={vehicleName || ''}
              type="text"
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="outlined"
              label={t('documents.name')}
              value={docName}
              onChange={handleChange}
              type="text"
              name="docName"
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <ComboBox
              autoComplete
              fullWidth
              margin="normal"
              onChange={(event, newValue) => {
                setType(newValue)
              }}
              loadingText={t('global.loading')}
              value={type}
              name="type"
              options={documentsConstants.types}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) =>
                typeof option === 'string' ? t(option) : ''
              }
              label={t('documents.type')}
              required
            />
          </Grid>
          <Grid item container direction="row" className={classes.gridItem}>
            <DropZone
              multiple
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Grid>
          {selectedFiles && selectedFiles.length > 0 ? (
            <Grid item container direction="row" className={classes.gridItem}>
              <FileView
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
          ) : null}
          {type === 'Other' ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <InputComponent
                fullWidth
                margin="normal"
                variant="outlined"
                label={t('documents.expirationDate')}
                value={expirationDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeDate}
                type="date"
                name="expirationDate"
              />
            </Grid>
          ) : null}
          {type === 'PV' ? <PV setData={setData} document={document} /> : null}
          {type === 'RegistrationCard' ? (
            <RegistrationCard setData={setData} document={document} />
          ) : null}
          {type === 'InsuranceCard' ? (
            <InsuranceCard
              setData={setData}
              document={document}
              setExpirationDate={setExpirationDate}
              setDisabled={setDisabled}
            />
          ) : null}
          {type === 'TechnicalControl' ? (
            <TechnicalControl
              setData={setData}
              document={document}
              setExpirationDate={setExpirationDate}
              setDisabled={setDisabled}
            />
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_Disagree')}
                className={classes.btn}
                onClick={onclose}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                disabled={disabled}
                className={classes.btn}
                startIcon={<SaveIcon />}
                type="submit"
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </form>

        <BackdropComponent className={classes.backdrop} open={open} />
        <DialogBox
          onClose={handleCancelPopup}
          ref={refDialog}
          action
          open={openDialog}
          styleContent={{ fontFamily: 'Comfortaa' }}
          title=""
          contentText={<FillingModel model={model} onFillUp={onFillUp} />}
          handleCancelBox={handleCancelPopup}
        />
      </Grid>
    </Box>
  )
}

export default React.memo(AddDocuments)
