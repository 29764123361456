/* eslint-disable import/no-cycle */
import { vehiclesIncidentsConstants } from '../constants'

export const vehiclesIncidentsActions = {
  success,
  init,
  failure,
  clear,
}

function success(data) {
  return {
    type: vehiclesIncidentsConstants.SUCCESS,
    payload: {
      data,
    },
  }
}

function init() {
  return { type: vehiclesIncidentsConstants.INIT }
}
function failure() {
  return { type: vehiclesIncidentsConstants.FAILURE }
}
function clear() {
  return { type: vehiclesIncidentsConstants.CLEAR }
}
