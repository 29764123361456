/* eslint-disable import/no-cycle */
import { usersConstants } from '../constants'
import { arrayRemove, elementReplace } from '../helpers'
const initialState = { isLoading: false, isError: false, data: [] }

export function users(state = initialState, action) {
  switch (action.type) {
    case usersConstants.REQUEST:
      return {
        isLoading: true,
        isError: false,
        data: [],
      }
    case usersConstants.UPDATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: elementReplace(state.data, action.payload.user),
      }
    case usersConstants.SUCCESS:
      return {
        isLoading: false,
        isError: false,
        data: action.payload.data,
      }
    case usersConstants.FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case usersConstants.REMOVE:
      return {
        ...state,
        data: arrayRemove(state.data, action.payload.id),
      }
    case usersConstants.CLEAR:
      return initialState
    default:
      return state
  }
}
