import React, { forwardRef, useRef } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import StylesWithMaterialUi from './ComboBox.styles'

const ComboBox = forwardRef((props, ref) => {
  const { label, loading, error, margin, variant, required } = props
  const defaultRef = useRef()
  const textFieldRef = useRef()
  const resolvedRef = ref || defaultRef
  const classes = StylesWithMaterialUi()
  return (
    <Autocomplete
      ref={resolvedRef}
      {...props}
      renderInput={(params) => (
        <TextField
          required={required}
          margin={margin}
          ref={textFieldRef}
          {...params}
          helperText={
            props.error ? (
              <Typography
                variant="caption"
                className={classes.helperText}
                display="block"
              >
                {props.error.message}
              </Typography>
            ) : undefined
          }
          error={error ? true : undefined}
          label={label}
          variant={variant || 'outlined'}
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
})

export default ComboBox
