/* eslint-disable import/no-cycle */
import React from 'react'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { momentConstants } from '../../../../../constants'

const ToolTipTitle = (props) => {
  const { value, t } = props
  return (
    <>
      {value.lastOperation && value.lastOperation.date ? (
        <Typography>
          {t('incidents.lastOperation', {
            value: moment(value.lastOperation.date).format(
              momentConstants.FORMAT_DATE,
            ),
          })}
        </Typography>
      ) : null}

      {value.nextOperation ? (
        <>
          {value.nextOperation.remainingDistance ? (
            <Typography>
              {t('incidents.remainingDistance', {
                value: Math.round(value.nextOperation.remainingDistance),
              })}
            </Typography>
          ) : null}{' '}
          {value.nextOperation.traveledDistance >= 0 ? (
            <Typography>
              {t('incidents.traveledDistance', {
                value: Math.round(value.nextOperation.traveledDistance),
              })}
            </Typography>
          ) : null}
        </>
      ) : null}
      {value.description && value.description.threshold ? (
        <Typography>
          {t('incidents.threshold', {
            value: Math.round(value.description.threshold),
          })}
        </Typography>
      ) : null}
    </>
  )
}
function compare(a, b) {
  const bandA = a.description.name.toUpperCase()
  const bandB = b.description.name.toUpperCase()
  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}
export { ToolTipTitle, compare }
