/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/named
import { extractDataConstants } from '../constants'
const initialState = {
  chartsModel: [],
}
const extractData = (state = initialState, action) => {
  switch (action.type) {
    case extractDataConstants.SHOW:
      return {
        ...state,
        chartsModel: action.payload.chartsModel,
      }

    case extractDataConstants.UPDATE:
      return {
        ...state,
        chartsModel: action.payload.chartsModel,
      }

    case extractDataConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { extractData }
