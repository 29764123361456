import React, { forwardRef, useRef } from 'react'
import { TextField, Typography } from '@material-ui/core'
import StylesWithMaterialUi from './InputComponent.styles'
const InputComponent = forwardRef((props, ref) => {
  const { margin, type, variant, style, color, ...other } = props
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef
  const classes = StylesWithMaterialUi()
  return (
    <TextField
      ref={resolvedRef}
      {...other}
      margin={margin || 'normal'}
      type={type || 'text'}
      color={color}
      error={props.error ? true : undefined}
      style={style || { fontFamily: 'Poppins' }}
      variant={variant || 'outlined'}
      helperText={
        props.error ? (
          <Typography
            variant="caption"
            className={classes.helperText}
            display="block"
          >
            {props.error.message}
          </Typography>
        ) : null
      }
    />
  )
})
export default InputComponent
