/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import DateComponent from '../../../../DataTable/components/DateComponent'
import DetailComponents from '../../../../JiminiAlerts/AlertsTable/components/helpers/DetailComponents'
import WarningComponents from '../../../../JiminiAlerts/AlertsTable/components/helpers/WarningComponents'
import useController from './Alerts.controller'
import { getPermissions } from '../../../../../helpers'

const Alerts = (props) => {
  const { handleExpanded, expanded } = props
  const { data, loading, t, user } = useController(props)

  const classes = StylesWithMaterialUi()

  const renderGrid = useCallback(
    () =>
      data && data.length > 0 ? (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ marginTop: '5px' }}
        >
          <List className={classes.rootList} component="nav">
            {data.map((o) => (
              <ListItem alignItems="flex-start" key={o.id}>
                <ListItemIcon>
                  {WarningComponents(o.level, t, '30px', '20px')}
                </ListItemIcon>
                <ListItemText
                  primary={`${DateComponent(o.date, 'll')} - ${t(
                    `alerts.${o.type}`,
                  )}`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {DetailComponents(o.detail, o.type, t)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          <Grid
            item
            container
            direction="row"
            className={classes.gridItemNotFound}
          >
            <Typography className={classes.input}>
              {t('v_detail.notFoundAlerts')}
            </Typography>
          </Grid>
        </Grid>
      ),
    [
      classes.gridContainer,
      classes.gridItemNotFound,
      classes.inline,
      classes.input,
      classes.rootList,
      data,
      t,
    ],
  )

  return user && getPermissions(user.type, 'alerts') ? (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('drawer.vehicleAlerts')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {loading ? (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        ) : (
          renderGrid()
        )}
      </AccordionDetails>
    </Accordion>
  ) : null
}
export default React.memo(Alerts)
