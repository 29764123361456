import React from 'react'
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Localization from './helper/Localization'
import { getMuiTheme } from './DataTable.styles'
function DataTable(props) {
  const { t } = useTranslation()
  const classes = getMuiTheme()
  const { columns, data, title, options, components } = props
  return (
    <MuiThemeProvider theme={classes}>
      <MUIDataTable
        title={title || undefined}
        data={data}
        columns={columns}
        components={components}
        options={{
          ...Localization(t),
          ...options,
        }}
      />
    </MuiThemeProvider>
  )
}

export default DataTable
