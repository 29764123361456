// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const postFiles = (data) => {
  const route = '/files'
  return axiosService.postMultipartFormData(true, route, null, data)
}

const getFile = (id, accessToken) => {
  const route = `files/${id}`
  const query = { accessToken }
  return axiosService.getFile(route, query)
}

export const filesService = {
  postFiles,
  getFile,
}
