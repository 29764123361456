import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useController = (props) => {
  const { setData, document } = props
  const { t } = useTranslation()
  const [inputs, setInputs] = useState({
    date: '',
    number: '',
    DateOfIncreased: '',
    time: '',
    raison: '',
    effect: '',
    description: '',
    address: '',
    amountMinor: '',
    amountNormal: '',
    amountIncreased: '',
  })
  const {
    date,
    DateOfIncreased,
    time,
    raison,
    number,
    description,
    address,
    effect,
    amountMinor,
    amountNormal,
    amountIncreased,
  } = inputs

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((i) => ({ ...i, [name]: value }))
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const setInputsValue = () => {
      setData({
        date,
        DateOfIncreased,
        time,
        number,
        raison,
        description,
        effect,
        address,
        amountNormal,
        amountMinor,
        amountIncreased,
      })
    }
    setInputsValue()
    return () => {
      abortController.abort()
    }
  }, [
    date,
    DateOfIncreased,
    time,
    number,
    raison,
    description,
    effect,
    address,
    amountNormal,
    amountMinor,
    amountIncreased,
    setData,
  ])

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = async () => {
      if (document && document.data) {
        setInputs({
          description: document.data.description || '',
          amountMinor: document.data.amountMinor || '',
          amountNormal: document.data.amountNormal || '',
          amountIncreased: document.data.amountIncreased || '',
          raison: document.data.raison || '',
        })
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [document])

  return {
    t,
    handleChange,
    number,
    raison,
    description,
    address,
    amountMinor,
    amountNormal,
    amountIncreased,
    date,
    DateOfIncreased,
    time,
    effect,
  }
}
export default useController
