/* eslint-disable import/no-cycle */
import { drawerNavigationConstants } from '../constants'

export const drawerNavigationActions = {
  show,
  close,
}

function show() {
  return {
    type: drawerNavigationConstants.SHOW,
  }
}

function close() {
  return {
    type: drawerNavigationConstants.CLOSE,
  }
}
