import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(5),
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      paddingRight: theme.spacing(25),
    },
  },
  gridItem: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  titre: { fontWeight: 'bold', fontFamily: 'Comfortaa' },
  sousTittre: { fontFamily: 'Poppins', fontSize: '15px', marginLeft: '5px' },
  mapContainerFullScreen: {
    height: '100%',
    width: '100%',
  },
  leafletContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '150px',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '200px',
      width: 'auto',
    },
  },
}))
export default StylesWithMaterialUi
