import React from 'react'

const ProgressBar = (props) => {
  const { progress, colors, warningThreshold, dangerThreshold } = props
  let width
  const getBackgroundColor = () => {
    if (Math.round(progress) <= 0) {
      width = `${100}%`
      return colors[3]
    }
    if (Math.round(progress) > dangerThreshold + warningThreshold) {
      width = `${progress}%`
      return colors[0]
    }

    if (Math.round(progress) > dangerThreshold) {
      width = `${progress}%`
      return colors[1]
    }
    width = `${progress}%`
    return colors[2]
  }
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        backgroundColor: '#C5C5C5',
        height: '5px',
      }}
    >
      <div
        style={{
          backgroundColor: getBackgroundColor(),
          width,
          height: '5px',
          position: 'absolute',
        }}
      />
    </div>
  )
}
export default ProgressBar
