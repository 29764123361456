/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react'
import CircularProgressComponent from '../../utils/MatrialUiComponents/CircularProgress/CircularProgressComponent'
import StylesWithMaterialUi from './VehicleDetails.styles'
import GeneralInformation from './components/generalInformation/GeneralInformation'
// import VehicleMap from './components/map/VehicleMap'
import Maintenances from './components/maintenances/Maintenances'
// import UsageQuality from './components/usageQuality/UsageQuality'
import Documents from './components/documents/Documents'
import Alerts from './components/alerts/Alerts'
import useController from './VehicleDetails.controller'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
// import { getConfig } from '../../../helpers/system/system.helpers'
import Orders from './components/orders/Orders'
import Uses from './components/uses/Uses'
import BtnGroup from './components/btnGroup/BtnGroup'
import AvaibleStates from './components/avaibleStates/AvaibleStates'
import Header from './components/header/Header'
// import BtnControle from './components/controle/BtnControle'
// import HistoryAdministrative from './components/historyAdministrative/HistoryAdministrative'
import AmbassadorsClub from './components/ambassadorsClub/AmbassadorsClub'
const VehicleDetails = (props) => {
  const {
    vehicle,
    expandedDocument,
    handleExpandedGeneral,
    lastTracking,
    expandedGeneral,
    open,
    expandedMaintenance,
    handleExpandedMaintenance,
    handleExpandedDocument,
    handleExpandedUses,
    expandedUses,
    handleExpandedOrders,
    expandedOrders,
    handleExpandedAlerts,
    expandedAlerts,
    handleExpandedGeneralBtn,
    expandedBtn,
    settings,
    handleExpandedAvaibleStates,
    expandedAvaibleStates,
    openDocs,
    expandedHeader,
    handleExpandedHeader,
    handleExpandedDocAmbassador,
    expandedDocAmbassador,
  } = useController(props)
  const classes = StylesWithMaterialUi()
  //     <BtnControle data={vehicle} />
  return useMemo(
    () =>
      vehicle ? (
        <>
          <BackdropComponent className={classes.backdrop} open={open} />
          <div className={classes.gridRoot}>
            <Header
              vehicle={vehicle}
              handleExpanded={handleExpandedHeader}
              lastTracking={lastTracking}
              expanded={expandedHeader}
              openDocs={openDocs}
            />

            <GeneralInformation
              vehicle={vehicle}
              handleExpanded={handleExpandedGeneral}
              lastTracking={lastTracking}
              expanded={expandedGeneral}
            />

            {/** settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.history ? (
              <HistoryAdministrative
                vehicle={vehicle}
                handleExpanded={handleExpandedHistory}
                expanded={expandedHistory}
              />
            ) : null */}
            {vehicle.status === 'Blocked' ? null : (
              <>
                {settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.actions ? (
                  <BtnGroup
                    data={vehicle}
                    handleExpanded={handleExpandedGeneralBtn}
                    expanded={expandedBtn}
                  />
                ) : null}
                {settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.maintenances ? (
                  <Maintenances
                    vehicle={vehicle}
                    handleExpanded={handleExpandedMaintenance}
                    expanded={expandedMaintenance}
                  />
                ) : null}
              </>
            )}

            {/** getConfig().env_type !== 'prod' &&
            settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.usageQuality ? (
              <UsageQuality
                vehicle={vehicle}
                handleExpanded={handleExpandedUsageQuality}
                expanded={expandedUsageQuality}
              />
            ) : null* */}
            {settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.documents ? (
              <Documents
                vehicle={vehicle}
                handleExpanded={handleExpandedDocument}
                expanded={expandedDocument}
              />
            ) : null}
            {settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.ambassador ? (
              <AmbassadorsClub
                handleExpanded={handleExpandedDocAmbassador}
                expanded={expandedDocAmbassador}
                vehicle={vehicle}
              />
            ) : null}
            {vehicle.status === 'Blocked' ? null : (
              <>
                {settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.alerts ? (
                  <Alerts
                    vehicle={vehicle}
                    handleExpanded={handleExpandedAlerts}
                    expanded={expandedAlerts}
                  />
                ) : null}
                {vehicle.gateway &&
                settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.commands ? (
                  <Orders
                    vehicle={vehicle}
                    handleExpanded={handleExpandedOrders}
                    expanded={expandedOrders}
                  />
                ) : null}
                {settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.uses ? (
                  <Uses
                    data={vehicle}
                    expanded={expandedUses}
                    handleExpanded={handleExpandedUses}
                  />
                ) : null}
                {settings.settings.drawers &&
                settings.settings.drawers.vehicle &&
                settings.settings.drawers.vehicle.avaibleStates &&
                vehicle &&
                vehicle.states ? (
                  <AvaibleStates
                    vehicle={vehicle}
                    handleExpanded={handleExpandedAvaibleStates}
                    expanded={expandedAvaibleStates}
                  />
                ) : null}
              </>
            )}
            {/** settings.settings.drawers &&
            settings.settings.drawers.vehicle &&
            settings.settings.drawers.vehicle.map ? (
              <VehicleMap
                vehicleModel={vehicle.vehicleModel}
                message={message}
                lastTracking={lastTracking}
                click={connect}
                handleExpanded={handleExpandedMap}
                expanded={expandedMap}
              />
            ) : null* */}
          </div>
        </>
      ) : (
        <CircularProgressComponent />
      ),
    [
      classes.backdrop,
      classes.gridRoot,
      expandedAlerts,
      expandedAvaibleStates,
      expandedBtn,
      expandedDocAmbassador,
      expandedDocument,
      expandedGeneral,
      expandedHeader,
      expandedMaintenance,
      expandedOrders,
      expandedUses,
      handleExpandedAlerts,
      handleExpandedAvaibleStates,
      handleExpandedDocAmbassador,
      handleExpandedDocument,
      handleExpandedGeneral,
      handleExpandedGeneralBtn,
      handleExpandedHeader,
      handleExpandedMaintenance,
      handleExpandedOrders,
      handleExpandedUses,
      lastTracking,
      open,
      openDocs,
      settings.settings.drawers,
      vehicle,
    ],
  )
}

export default VehicleDetails
