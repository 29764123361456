/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { momentConstants } from '../../../../../constants'

const useController = (props) => {
  const { setData, document, setDisabled, setExpirationDate } = props
  const { t } = useTranslation()

  const [dateError, setDateError] = useState(false)
  const [inputs, setInputs] = useState({
    numberProceLabel: '',
    kmLabel: '',
    errerLabel: '',
    centerAgre: '',
    campany: '',
    coord: '',
    agrTech: '',
    techFullName: '',
    immat: '',
    immatDate: '',
    dateFirstCir: '',
    marque: '',
    desg: '',
    vin: '',
    catg: '',
    genre: '',
    typeVehicle: '',
    Ener: '',
    doc: '',
    mesure: '',
  })

  const {
    numberProceLabel,
    kmLabel,
    errerLabel,
    centerAgre,
    campany,
    coord,
    agrTech,
    techFullName,
    immat,
    immatDate,
    dateFirstCir,
    marque,
    desg,
    vin,
    catg,
    genre,
    typeVehicle,
    Ener,
    doc,
    mesure,
  } = inputs

  const [inputsDate, setInputsDate] = useState({
    startDateLabel: '',
    endDateLabel: '',
  })
  const [natureLabel, setNatureLabel] = useState('')
  const [resultLabel, setResultLabel] = useState('')
  const [nextLabel, setNextLabel] = useState('')

  const { startDateLabel, endDateLabel } = inputsDate
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  const handleChangeDate = useCallback((e) => {
    const { name, value } = e.target
    setInputsDate((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const checkWithMoment = () => {
      if (moment(endDateLabel).isSameOrBefore(startDateLabel)) {
        setDateError(true)
        setDisabled(true)
      } else {
        setDateError(false)
        setDisabled(false)
      }
    }
    checkWithMoment()
    return () => {
      abortController.abort()
    }
  }, [endDateLabel, startDateLabel, setDisabled])

  useEffect(() => {
    const abortController = new AbortController()
    const setInputsValue = () => {
      if (inputs || (startDateLabel && endDateLabel)) {
        inputs.a3 = { start: startDateLabel, end: endDateLabel }
        setExpirationDate(endDateLabel)
        inputs.natureLabel = natureLabel.label || natureLabel
        inputs.resultLabel = resultLabel.label || resultLabel
        inputs.nextLabel = nextLabel.label || nextLabel
        setData({
          ...inputs,
        })
      }
    }
    setInputsValue()
    return () => {
      abortController.abort()
    }
  }, [
    startDateLabel,
    inputs,
    endDateLabel,
    setData,
    setExpirationDate,
    natureLabel,
    resultLabel,
    nextLabel,
  ])

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = async () => {
      if (document && document.data) {
        setInputs({
          numberProceLabel: document.data.numberProceLabel || '',
          kmLabel: document.data.kmLabel || '',
          errerLabel: document.data.errerLabel || '',
          centerAgre: document.data.centerAgre || '',
          campany: document.data.campany || '',
          coord: document.data.coord || '',
          agrTech: document.data.agrTech || '',
          techFullName: document.data.techFullName || '',
          immatDate:
            moment(document.data.immatDate).format(
              momentConstants.FORMAT_text_field,
            ) || '',
          dateFirstCir:
            moment(document.data.dateFirstCir).format(
              momentConstants.FORMAT_text_field,
            ) || '',
          marque: document.data.marque || '',
          desg: document.data.desg || '',
          vin: document.data.vin || '',
          catg: document.data.catg || '',
          genre: document.data.genre || '',
          typeVehicle: document.data.typeVehicle || '',
          Ener: document.data.Ener || '',
          doc: document.data.doc || '',
          mesure: document.data.mesure || '',
        })
        setNextLabel(document.data.nextLabel || '')
        setResultLabel(document.data.resultLabel || '')
        setNatureLabel(document.data.natureLabel || '')
        if (document.data.a3) {
          setInputsDate({
            startDateLabel:
              moment(document.data.a3.start).format(
                momentConstants.FORMAT_text_field,
              ) || '',
            endDateLabel:
              moment(document.data.a3.end).format(
                momentConstants.FORMAT_text_field,
              ) || '',
          })
        }
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [document])

  return {
    t,
    handleChange,
    numberProceLabel,
    kmLabel,
    errerLabel,
    handleChangeDate,
    startDateLabel,
    endDateLabel,
    dateError,
    nextLabel,
    resultLabel,
    natureLabel,
    setNatureLabel,
    setResultLabel,
    setNextLabel,
    centerAgre,
    campany,
    coord,
    agrTech,
    techFullName,
    immat,
    immatDate,
    dateFirstCir,
    marque,
    desg,
    vin,
    catg,
    genre,
    typeVehicle,
    Ener,
    doc,
    mesure,
  }
}
export default useController
