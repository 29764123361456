/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react'
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import StylesWithMaterialUi from './DocumentsDetails.styles'
import { momentConstants } from '../../../constants'
import RegistrationCard from './components/RegistrationCard'
import InsuranceCard from './components/InsuranceCard'
import useController from './DocumentsDetails.controller'
import { getConfig } from '../../../helpers/system/system.helpers'
import TechnicalControl from './components/TechnicalControl'
import PV from './components/Pv'

const DocumentsDetails = (props) => {
  const { document, vehicle } = props
  const {
    expandInfo,
    expandedInfo,
    t,
    expandFile,
    expandedFiles,
    expandDocumentInfo,
    expandedDocumentInfo,
  } = useController()
  const classes = StylesWithMaterialUi()

  return useMemo(
    () => (
      <div className={classes.gridRoot}>
        <Accordion expanded={expandInfo} onChange={expandedInfo}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontFamily: 'Comfortaa',
              }}
            >
              {t('documents.infoG')}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              style={{ marginTop: '5px' }}
            >
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body2">
                    {t('v_detail.nameInputVehicle')} :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    {vehicle && vehicle.ref && vehicle.name
                      ? t('v_detail.inputVehicleWithRef', {
                          ref: vehicle.ref,
                          name: vehicle.name,
                        })
                      : vehicle.ref}
                  </Typography>
                </Grid>
              </Grid>
              {document.name ? (
                <Grid item container direction="column">
                  <Grid item>
                    <Typography variant="body2">
                      {t('documents.name')} :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                      }}
                    >
                      {document.name}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body2">
                    {t('documents.type')} :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    {t(document.type)}
                  </Typography>
                </Grid>
              </Grid>{' '}
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body2">
                    {t('documents.creationDate')} :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    {moment(document.creationDate).format(
                      momentConstants.FORMAT_INIT,
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandFile} onChange={expandedFiles}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontFamily: 'Comfortaa',
              }}
            >
              {t('documents.files')}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            {document.files && document.files.length > 0 ? (
              <Grid
                container
                direction="column"
                spacing={1}
                alignItems="center"
                style={{ marginTop: '5px' }}
              >
                {document.files.map((o) => (
                  <Grid
                    key={o.id}
                    item
                    container
                    direction="row"
                    spacing={1}
                    className={classes.gridItem}
                  >
                    <Grid
                      item
                      container
                      style={{
                        whiteSpace: 'nowrap',
                        width: '80%',
                      }}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Tooltip title={o.name ? o.name : o.id}>
                        <Box
                          component="div"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          {o.name ? o.name : o.id}
                        </Box>
                      </Tooltip>
                    </Grid>{' '}
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ width: '20%' }}
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${getConfig().jimini.api_base_url}/files/${
                          o.id
                        }?accessToken=${o.accessToken}`}
                        download={o.name}
                      >
                        <IconButton aria-label="download">
                          <GetAppIcon />
                        </IconButton>
                      </a>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                container
                direction="column"
                spacing={1}
                alignItems="center"
                className={classes.gridContainer}
                style={{ marginTop: '5px' }}
              >
                <Grid item>
                  <Typography
                    style={{ fontFamily: 'Poppins', fontSize: '15px' }}
                  >
                    {t('documents.notHaveFile')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
        {document.expirationDate ||
        (document.data &&
          JSON.stringify(document.data) !== JSON.stringify({})) ? (
          <Accordion
            expanded={expandDocumentInfo}
            onChange={expandedDocumentInfo}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {' '}
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Comfortaa',
                }}
              >
                {t('documents.info')}
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: '5px' }}
              >
                {document.expirationDate && document.type === 'Other' ? (
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography variant="body2">
                        {t('documents.expirationDate')} :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: 'bold',
                        }}
                      >
                        {moment(document.expirationDate).format(
                          momentConstants.FORMAT_DATE,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
                {document.data && document.type === 'RegistrationCard' ? (
                  <RegistrationCard document={document} />
                ) : null}{' '}
                {document.data && document.type === 'PV' ? (
                  <PV document={document} />
                ) : null}
                {document.data && document.type === 'InsuranceCard' ? (
                  <InsuranceCard document={document} />
                ) : null}
                {document.data && document.type === 'TechnicalControl' ? (
                  <TechnicalControl document={document} />
                ) : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </div>
    ),
    [
      classes.gridRoot,
      classes.gridContainer,
      classes.gridItem,
      expandInfo,
      expandedInfo,
      t,
      vehicle,
      document,
      expandFile,
      expandedFiles,
      expandDocumentInfo,
      expandedDocumentInfo,
    ],
  )
}

export default DocumentsDetails
