/* eslint-disable import/no-cycle */
import { axiosService } from '../system/axios.service'

const register = (body) => {
  const route = '/register'
  return axiosService.post(false, route, null, body)
}

const registerGoogle = (body) => {
  const route = '/register/google'
  return axiosService.post(false, route, null, body)
}

export const registerService = { register, registerGoogle }
