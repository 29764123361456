/* eslint-disable import/no-cycle */
import { fleetsConstants } from '../constants'

export const fleetsActions = {
  success,
  init,
  failure,
  update,
  remove,
  add,
  clear,
}

function success(fleets) {
  return { type: fleetsConstants.SUCCESS, payload: { fleets } }
}

function update(fleet) {
  return {
    type: fleetsConstants.UPDATE,
    payload: {
      fleet,
    },
  }
}
function add(fleet) {
  return {
    type: fleetsConstants.ADD,
    payload: {
      fleet,
    },
  }
}
function remove(id) {
  return {
    type: fleetsConstants.REMOVE,
    payload: {
      id,
    },
  }
}

function init() {
  return { type: fleetsConstants.INIT }
}

function failure() {
  return { type: fleetsConstants.FAILURE }
}
function clear() {
  return { type: fleetsConstants.CLEAR }
}
