/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
import axios from 'axios'
import { fullResponse, history } from '../../helpers'
import { authenticationService } from '../users/authentication.service'
import { authenticate } from './auth.service'
import { getConfig } from '../../helpers/system/system.helpers'

export const axiosService = {
  get,
  post,
  put,
  deleteFnc,
  postMultipartFormData,
  getFile,
}
const logOut = async () => {
  await authenticationService.logout()
  history.push(localStorage.getItem('login'))
  history.go(0)
  return null
}
const catchError = (err, source) => {
  if (err.response && err.response.status === 500) {
    // TO Do fix in page vehicle with account user
    throw Error(`${err.response.status} - ${JSON.stringify(err.response.data)}`)
  }
  if (axios.isCancel(err)) {
    source.cancel()
    return {
      content: { message: JSON.stringify(err.message) },
    }
  }
  if (err.response) {
    // TO do status 501 et 502 win api its down
    throw Error(` ${JSON.stringify(err.response)}`)
  }
  if (err) {
    return {
      content: { message: JSON.stringify(err.message) },
    }
  }
  return {}
}
async function request(method, needAuth, route, query, body, headers) {
  const source = axios.CancelToken.source()
  try {
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    const requestHeaders = {
      ...defaultHeaders,
      ...headers,
    }

    if (needAuth) {
      const auth = await authenticate()
      if (!auth || !auth.authToken) {
        // ... ouvrir la page login ou msg d'avertissement
        await logOut()
        return
      }
      requestHeaders.Authorization = `JWT ${auth.authToken}`
    }

    const res = await axios.request({
      method,
      baseURL: getConfig().jimini.api_base_url,
      url: route,
      headers: requestHeaders,
      params: query,
      data: body,
      validateStatus: (status) => status < 500,
    })

    if (fullResponse(res, 401)) {
      await logOut()
    }
    return {
      status: res.status,
      content: res.data,
    }
  } catch (err) {
    return catchError(err, source)
  }
}

async function get(needAuth, route, query) {
  return request('get', needAuth, route, query)
}
async function getFile(route, query) {
  const source = axios.CancelToken.source()

  try {
    return axios.get(`${getConfig().jimini.api_base_url}/${route}`, {
      cancelToken: source.token,
      responseType: 'blob',
      params: query,
    })
  } catch (err) {
    return catchError(err, source)
  }
}

async function post(needAuth, route, query, body) {
  return request('post', needAuth, route, query, body)
}

async function put(needAuth, route, query, body) {
  return request('put', needAuth, route, query, body)
}

async function deleteFnc(needAuth, route, query, body) {
  return request('delete', needAuth, route, query, body)
}

async function postMultipartFormData(needAuth, route, query, body) {
  const headers = {
    'Content-type': 'multipart/form-data',
  }

  return request('post', needAuth, route, query, body, headers)
}
