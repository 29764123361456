/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import useController from './AvaibleStates.controller'
import { getPermissions } from '../../../../../helpers/users/permissions.helpers'
const AvaibleStates = (props) => {
  const { handleExpanded, expanded, vehicle } = props

  const { loading, t, user, data } = useController(props)

  const classes = StylesWithMaterialUi()
  const TableComponent = () => (
    <Table elevation={0}>
      <TableBody>
        {data.map(([key, value]) => (
          <TableRow key={`tablevalue-${key}`}>
            <TableCell
              key={`tablevalue-${key}`}
              variant="head"
              align="left"
              style={{ fontWeight: 'Bold' }}
              size="small"
            >
              {key}
            </TableCell>
            <TableCell key={`tablevalue-${value}`} align="left" size="small">
              {String(value)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
  return vehicle &&
    user &&
    getPermissions(user.type, 'avaibleStates') &&
    data &&
    data.length > 0 ? (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('avaibleStates.title')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {loading ? (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.gridContainer}
            style={{ marginTop: '5px' }}
          >
            <TableComponent />
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null
}
export default React.memo(AvaibleStates)
