/* eslint-disable import/no-cycle */
import { vehiclesIncidentsConstants } from '../constants'

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
}

const vehiclesIncidents = (state = initialState, action) => {
  switch (action.type) {
    case vehiclesIncidentsConstants.INIT:
      return {
        ...state,
        isLoading: true,
      }

    case vehiclesIncidentsConstants.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      }

    case vehiclesIncidentsConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: [],
      }
    case vehiclesIncidentsConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { vehiclesIncidents }
