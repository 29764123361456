import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  gridRoot: {
    margin: theme.spacing(2),
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
    fontSize: '15px',
  },
  btn: {
    fontSize: '12px',
    fontWeight: '300',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: 'auto',
  },
  gridItemBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
}))
export default StylesWithMaterialUi
