import React from 'react'
// eslint-disable-next-line import/no-cycle
import { alertActions, drawerActions } from '../../actions'
// eslint-disable-next-line import/no-cycle
import FleetDetails from '../../components/Fleets/FleetDetails/FleetDetails'
// eslint-disable-next-line import/no-cycle
import EditFleet from '../../components/Fleets/EditFleet/EditFleet'
// eslint-disable-next-line import/no-cycle
import { fleetsService } from '../../services'
import { fullResponse } from '../system/response.helpers'
const ShowFleetDetails = async (dispatch, t, fleet) => {
  const query = {
    id: fleet.id,
  }
  const res = await fleetsService.fleets(query)
  if (fullResponse(res, 200)) {
    dispatch(
      drawerActions.show(
        <FleetDetails fleet={res.content.data} />,
        t('table.v_fleet'),
        'right',
        'persistent',
        'ltr',
      ),
    )
    return
  }
  dispatch(alertActions.error(res.content.message, 6000, true))
}
const ShowFleetEdit = (fleet, dispatch, t) => {
  const onClose = () => ShowFleetDetails(dispatch, t, fleet)
  dispatch(
    drawerActions.show(
      <EditFleet fleet={fleet} onclose={onClose} drawer />,
      t('table.v_fleet'),
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}

export const EditFleetHelpers = {
  ShowFleetEdit,
  ShowFleetDetails,
}
