// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const getVehicleModels = async (query) => {
  const route = '/vehicleModels'
  return axiosService.get(true, route, query)
}
export const vehiclesModelService = {
  getVehicleModels,
}
