// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const fleets = (query) => {
  const route = '/vehicleFleets'
  return axiosService.get(true, route, query)
}
const addFleets = (body) => {
  const route = '/vehicleFleets'
  return axiosService.post(true, route, null, body)
}
const updateFleets = (body, id) => {
  const route = `/vehicleFleets/${id}`
  return axiosService.put(true, route, null, body)
}
const deleteFleets = (id) => {
  const route = `/vehicleFleets/${id}`
  return axiosService.deleteFnc(true, route)
}
export const fleetsService = { fleets, updateFleets, deleteFleets, addFleets }
