import React from 'react'
// eslint-disable-next-line import/no-cycle
import { gatewaysService } from '../../services'
import { fullResponse } from '../system/response.helpers'
// eslint-disable-next-line import/no-cycle
import { alertActions, drawerActions } from '../../actions'
// eslint-disable-next-line import/no-cycle
import GatewaysDetails from '../../components/Gateways/GatewaysDetails/GatewaysDetails'
const OpenGateways = async (e, id, dispatch, t) => {
  try {
    const query = {
      id,
    }
    const res = await gatewaysService.gateway(query)
    if (fullResponse(res, 200)) {
      const { data } = res.content
      dispatch(
        drawerActions.show(
          <GatewaysDetails gateway={data[0]} />,
          t('table.v_boitier'),
          'right',
          'persistent',
          'ltr',
        ),
      )
      return
    }
    dispatch(alertActions.error(res.content.message, 6000, true))
  } catch (error) {
    dispatch(alertActions.error(error.message.toString(), 6000, true))
  }
}
export const GatewaysHelpers = { OpenGateways }
