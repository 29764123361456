import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

const BackdropComponent = (props) => (
  <Backdrop {...props}>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default BackdropComponent
