/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { userPatternConstants } from '../../../constants'
import { usersActions, alertActions, drawerActions } from '../../../actions'
import { usersService } from '../../../services'
const useHelpers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const validateValue = useCallback(
    (value) => {
      if (!value) {
        return t('errors.valueRequired')
      }
    },
    [t],
  )
  const validateUserName = useCallback(
    (value) => {
      if (!value) {
        return t('errors.valueRequired')
      }
      if (value && !userPatternConstants.NAME_PATTERN.test(value)) {
        return t('errors.error_input_usernameProfile')
      }
    },
    [t],
  )
  const validateEmail = useCallback(
    (value) => {
      if (!value) {
        return null
      }
      if (
        value &&
        !userPatternConstants.EMAIL_PATTERN.test(String(value).toLowerCase())
      ) {
        return t('errors.error_input_emailProfile')
      }
    },
    [t],
  )

  const updateRedux = useCallback(async () => {
    try {
      const res = await usersService.getUsers()
      if (!res || res.status !== 200) {
        dispatch(alertActions.error(res.content.message, 6000, true))
        dispatch(usersActions.failure())
        return
      }
      dispatch(usersActions.success(res.content.data))
    } catch (error) {
      dispatch(alertActions.error(error.message, 6000, true))
      dispatch(usersActions.failure())
    }
  }, [dispatch])
  const handleCancel = useCallback(() => {
    dispatch(drawerActions.close())
  }, [dispatch])

  return {
    validateValue,
    validateUserName,
    validateEmail,
    handleCancel,
    updateRedux,
  }
}
export default useHelpers
