/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import React from 'react'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import CancelIcon from '@material-ui/icons/Cancel'
import useController from './Uses.controller'
import StylesWithMaterialUi from '../../VehicleDetails.styles'
import DataTable from '../../../../DataTable/DataTable'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'

const Uses = (props) => {
  const { handleExpanded, expanded } = props
  const {
    t,
    loading,
    columnsTable,
    tableData,
    customOption,
    isLoading,
    handleCancelPopup,
    openDialog,
    inputs,
    handleChange,
    vehicle,
    createUse,
    deleteUse,
    refDialog,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Comfortaa',
          }}
        >
          {t('uses.title')}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {loading ? (
          <Grid
            container
            alignItems="center"
            className={classes.progressMaintenances}
          >
            <CircularProgress size={20} />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.gridContainer}
            style={{
              marginTop: '5px',
            }}
          >
            <Box className={classes.table}>
              <DataTable
                title={
                  <Typography variant="h6" component="span">
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                columns={columnsTable || undefined}
                data={tableData || undefined}
                options={customOption}
              />
            </Box>
            {vehicle && vehicle.currentUse ? (
              <Dialog
                ref={refDialog}
                open={openDialog}
                onClose={handleCancelPopup}
                className={classes.dialog}
              >
                <DialogContent className={classes.dialogContent}>
                  <form name="form" onSubmit={deleteUse}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      className={classes.gridContainer}
                      style={{
                        marginTop: '5px',
                      }}
                      spacing={2}
                    >
                      <Grid item xs>
                        <InputComponent
                          variant="filled"
                          label={t('uses.endDate')}
                          type="datetime"
                          name="date"
                          defaultValue={inputs.endDate}
                          disabled
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs>
                        <InputComponent
                          variant="outlined"
                          label={t('uses.endOdometer')}
                          value={
                            inputs.endOdometer > 0 ? inputs.endOdometer : ''
                          }
                          type="number"
                          name="endOdometer"
                          fullWidth
                          margin="normal"
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          justifyContent: 'center',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <Grid item>
                          <ButtonComponent
                            className={classes.buttonSmall}
                            variant="outlined"
                            tooltiptitle={t('v_detail.box_Disagree')}
                            onClick={handleCancelPopup}
                            color="primary"
                            styles
                            startIcon={<CancelIcon />}
                          >
                            {t('v_detail.box_Disagree')}
                          </ButtonComponent>
                        </Grid>
                        <Grid item>
                          <ButtonComponent
                            styles
                            className={classes.buttonSmall}
                            variant="outlined"
                            tooltiptitle={t('v_detail.btnReturnVehicle')}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="box_Agree"
                            startIcon={<KeyboardReturnIcon />}
                          >
                            {t('v_detail.btnReturnVehicle')}
                          </ButtonComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
            ) : (
              <Dialog
                ref={refDialog}
                open={openDialog}
                onClose={handleCancelPopup}
                className={classes.dialog}
              >
                <DialogContent className={classes.dialogContent}>
                  <form name="form" onSubmit={createUse}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      className={classes.gridContainer}
                      style={{
                        marginTop: '5px',
                      }}
                    >
                      <Grid item xs>
                        <InputComponent
                          variant="filled"
                          label={t('uses.startDate')}
                          type="datetime"
                          name="date"
                          defaultValue={inputs.startDate}
                          disabled
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs>
                        <InputComponent
                          variant="outlined"
                          label={t('uses.startOdometer')}
                          value={
                            inputs.startOdometer > 0 ? inputs.startOdometer : ''
                          }
                          type="number"
                          name="startOdometer"
                          fullWidth
                          margin="normal"
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          justifyContent: 'center',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <Grid item>
                          <ButtonComponent
                            className={classes.buttonSmall}
                            variant="outlined"
                            tooltiptitle={t('v_detail.box_Disagree')}
                            onClick={handleCancelPopup}
                            color="primary"
                            styles
                            startIcon={<CancelIcon />}
                          >
                            {t('v_detail.box_Disagree')}
                          </ButtonComponent>
                        </Grid>
                        <Grid item>
                          <ButtonComponent
                            styles
                            className={classes.buttonSmall}
                            variant="outlined"
                            tooltiptitle={t('v_detail.btnUseVehicle')}
                            type="submit"
                            color="primary"
                            startIcon={<DirectionsRunIcon />}
                          >
                            {t('v_detail.btnUseVehicle')}
                          </ButtonComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Uses
