/* eslint-disable import/no-cycle */
import { vehiclesConstants } from '../constants'
import {
  elementReplace,
  arrayRemove,
  elementPush,
  disableVehicle,
  elementReplaceWithSocket,
  updateVhWithSocket,
} from '../helpers'
const tooltip = localStorage.getItem('toolTip')
const initialState = {
  isLoading: false,
  isError: false,
  menu: null,
  tooltip: tooltip === 'true',
  trip: null,
  tracking: null,
}

const vehicles = (state = initialState, action) => {
  switch (action.type) {
    case vehiclesConstants.INIT:
      return {
        ...state,
        detail: false,
        isLoading: true,
        vehicle: {
          data: null,
        },
      }
    case vehiclesConstants.TOOLTIP:
      return {
        ...state,
        tooltip: action.payload.tooltip,
      }
    case vehiclesConstants.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.vehicles,
      }
    case vehiclesConstants.ADD:
      return {
        ...state,
        isLoading: false,
        data: elementPush(state.data, action.payload.vehicle),
      }
    case vehiclesConstants.HIDE:
      return {
        ...state,
        isLoading: false,
        data: disableVehicle(
          state.data,
          action.payload.id,
          action.payload.props,
          action.payload.display,
        ),
      }
    case vehiclesConstants.UPDATE:
      return {
        ...state,
        isLoading: false,
        vehicle: {
          data: action.payload.vehicle,
        },
        data: elementReplace(state.data, action.payload.vehicle),
      }
    case vehiclesConstants.UPDATE_WITH_SOCKET:
      return {
        ...state,
        vehicle: {
          data: updateVhWithSocket(state.vehicle, action.payload.data),
        },
        isLoading: false,
        data: elementReplaceWithSocket(state.data, action.payload.data),
      }
    case vehiclesConstants.REMOVE:
      return {
        ...state,
        data: arrayRemove(state.data, action.payload.id),
      }
    case vehiclesConstants.MENU:
      return {
        ...state,
        menu: action.payload.menu,
        vehicle: action.payload.vehicle,
        data: state.data,
      }
    case vehiclesConstants.TRIP:
      return {
        ...state,
        trip: action.payload.trip,
      }
    case vehiclesConstants.LAST_TRAKING:
      return {
        ...state,
        tracking: action.payload.tracking,
      }
    case vehiclesConstants.FAILURE:
      return {
        ...state,
        data: false,
        isError: true,
        isLoading: false,
      }
    case vehiclesConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { vehicles }
