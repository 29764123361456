/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { createRef, useCallback, useState } from 'react'
import {
  alertActions,
  drawerActions,
  routerActions,
  usersActions,
} from '../../../../../actions'
import { routerConstants } from '../../../../../constants'
import { usersHelpers } from '../../../../../helpers/users/users.helpers'
import { usersService } from '../../../../../services'
import { emptyResponse } from '../../../../../helpers'

const useController = (props) => {
  const { user } = props
  const refDialog = createRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const accordionAction = React.createRef()
  const auth = useSelector((state) => state.authentication.user)
  const [open, setOpen] = useState(false)

  const sendResetPasswordEmail = useCallback(async () => {
    try {
      if (user.id) {
        const res = await usersService.sendResetPasswordUserEmail(user.id)
        if (emptyResponse(res, 201)) {
          setOpen(false)
          dispatch(
            alertActions.error(
              res.content ? res.content.message : t('global.error'),
              6000,
              true,
            ),
          )
          return
        }
        setOpen(false)
        dispatch(alertActions.success(t('v_detail.v_update_msg'), 5000, true))
      }
    } catch (err) {
      dispatch(alertActions.error(err.toString(), 6000, true))
      setOpen(false)
    }
  }, [dispatch, t, user.id])

  const sendInviteUserEmail = useCallback(async () => {
    try {
      if (user.id) {
        const res = await usersService.sendInviteUserEmail(user.id)
        if (emptyResponse(res, 201)) {
          setOpen(false)
          dispatch(
            alertActions.error(
              res.content ? res.content.message : t('global.error'),
              6000,
              true,
            ),
          )
          return
        }
        setOpen(false)
        dispatch(alertActions.success(t('v_detail.v_update_msg'), 5000, true))
      }
    } catch (err) {
      dispatch(alertActions.error(err.toString(), 6000, true))
      setOpen(false)
    }
  }, [dispatch, t, user.id])

  const showUserEdit = useCallback(() => {
    if (user && user.id) {
      dispatch(routerActions.push(routerConstants.USERS, user.id))
      usersHelpers.HandleEdit(user, dispatch, t)
    }
  }, [dispatch, t, user])

  const handleDelete = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const handleCancelPopup = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const closeDrawer = useCallback(() => {
    dispatch(drawerActions.close())
  }, [dispatch])

  const updateRedux = useCallback(
    async (id) => {
      try {
        dispatch(usersActions.remove(id))
      } catch (error) {
        dispatch(usersActions.failure())
      }
    },
    [dispatch],
  )

  const handlePopup = useCallback(async () => {
    try {
      if (user.id) {
        const res = await usersService.deleteUser(user.id)
        if (!res || res.status !== 200) {
          dispatch(alertActions.error(t('users.deleteMsg'), 6000, true))
          setOpenDialog(false)
          return
        }
        setOpenDialog(false)
        dispatch(alertActions.success(t('v_detail.v_delete_msg'), 6000, true))
        closeDrawer()
        await updateRedux(user.id)
        return
      }
      setOpenDialog(false)
      dispatch(alertActions.error(t('v_detail.v_delete_msg_id'), 6000, true))
      closeDrawer()
    } catch (e) {
      dispatch(alertActions.error(e.toString(), 6000, true))
      setOpenDialog(false)
    }
  }, [closeDrawer, dispatch, t, updateRedux, user.id])

  const showRapport = useCallback(() => {
    if (user && user.id) {
      dispatch(routerActions.push(routerConstants.USERS, user.id))
      usersHelpers.handleRapport(user, dispatch, t)
    }
  }, [dispatch, t, user])

  const checkUser = useCallback(
    () => user && auth && user.email === auth.email,
    [auth, user],
  )

  return {
    accordionAction,
    auth,
    t,
    showUserEdit,
    sendResetPasswordEmail,
    user,
    sendInviteUserEmail,
    open,
    handleDelete,
    openDialog,
    handleCancelPopup,
    handlePopup,
    refDialog,
    showRapport,
    checkUser,
  }
}
export default useController
