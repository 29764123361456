export default {
  locales: {
    fr: 'FR',
    en: 'EN',
  },
  alerts: {
    orange: 'Orange',
    red: 'Rouge',
    darkRed: 'Critique',
    date: 'Date de création',
    type: 'Type',
    level: 'Niveau',
    detail: 'Détail',
    reference: 'Référence',
    vehicle: 'Véhicule',
    vehicleFleet: 'Flotte',
    owner: 'Propriétaire',
    Insurance: 'Assurance',
    Tracking: 'Localisation',
    Maintenance: 'Maintenance',
    TechnicalControl: 'Contrôle technique',
    VehicleDocument: 'véhicule document',
    lastRemote: 'Dernière remontée le {{value}}',
    endDate: 'Expiration du document le {{value}}',
    titleSub:
      'Les alertes sont recalculées toutes les heures, elles peuvent ne pas prendre en compte les dernières opérations',
    title: 'Alertes',
    startDateMin: 'Date de début  Min',
    endDateMax: 'Date de fin  Max',
    niveau: "A partir du niveau d'alerte",
    emails: 'Envoyer aux adresses email suivantes',
    titleNot: "Notification d'alertes",
  },
  drawer: {
    map: 'Carte',
    vehicleFleets: 'Flottes',
    gateways: 'Boitiers Jimini',
    vehicles: 'Véhicules',
    report: 'Rapport',
    vehicleTrips: 'Trajets',
    vehicleAlerts: 'Alertes',
    admin: 'Administrateur',
    vehicleIncidents: 'Incidents',
    home: 'Accueil ',
    login: 'Connexion',
    log_out: 'Déconnexion',
    data: 'Données',
    users: 'Utilisateurs',
    quickAccess: 'Accès rapide',
    log_outDialog: 'Voulez-vous vraiment vous déconnecter ?',
  },
  btnGroup: {
    print: 'Imprimer',
    download: 'Télécharger',
    showQr: "Voir l'étiquette",
    maps: 'Itinéraire',
    v_numberplate: 'Immat. : ',
    ref: 'Réf. :',
    vin: 'VIN :',
    name: 'Nom :',
  },
  login: {
    username: 'Email',
    password: 'Mot de passe',
    titleUser: 'Connexion au compte utilisateur',
    titleVehicle: 'Accès rapide à un véhicule',
    owner: 'Référence client',
    auth: 'Les informations saisies sont incorrectes',
    authVehicle: 'Les informations saisies sont incorrectes',
    title: 'Connexion par : ',
    identifier: 'Référence ou immatriculation',
    error_input_vehicle:
      "Référence du véhicule ou plaque d'immatriculation requis",
    login: 'Se connecter',
    vehicle: 'Véhicule',
    user: 'Utilisateur',
    loginVehicle: 'Consulter',
    btn: 'Accès via le compte Google Workspace Mob-ion',
  },
  quickAccess: {
    vehicle: 'Accès au véhicule',
    qrcodeScanner: 'Scannez le QR code du véhicule',
  },
  table: {
    v_nameFleet: 'Nom',
    v_name: 'Nom',
    v_ref: 'Référence',
    v_vin: 'VIN',
    v_nameRequire: 'Le nom du véhicule est requis',
    v_namePattern: "Le nom du véhicule n'est pas valide",
    v_numberplate: "Plaque d'immatriculation",
    v_numberplatePattern: "L'immatriculation du véhicule n'est pas valide",
    v_fleet: 'Flotte',
    v_model: 'Modèle',
    v_fleetRequired: 'Le nom de la flotte est requis',
    v_fleetPattern: "Le nom de la flotte n'est pas valide",
    v_fleetReport: 'Rapport de flotte',
    v_owner: 'Propriétaire',
    v_boitier: ' Boîtier Jimini',
    v_adresse: 'Dernière adresse ',
    v_odometer: 'Odomètre',
    odometerMin: 'Odomètre Min : {{value}} km',
    odometerMax: 'Odomètre Max : {{value}} km',
    v_odometerDownload: 'Odomètre (km)',
    v_lastTracking_date: 'Date dernière remontée',
    input_search: 'Chercher',
    v_actions: 'Actions',
    error_msg_getv: 'Problème de connexion... :)',
    errorSelectGateways: 'Aucun boîtier disponible',
    errorSelectFleet: 'Aucune flotte disponible',
    errorSelectModel: 'Aucune modèle disponible',
    errorSelectVehicle: 'Aucune Véhicule disponible',
    v_tracking: 'Aucun trajet trouvé dans cet intervalle de dates',
    type: 'Type',
    incidentDate: "Date d'incident",
    author: 'Auteur',
    comment: 'Commentaire',
    creationDate: 'Date de création ',
  },
  table_trips: {
    tooltipCarte: 'Afficher  le trajet',
    offset: 'Passer X résultats',
    limit: 'Limiter à X résultats',
    dateMinLabel: 'Date de début min',
    dateMaxLabel: 'Date de début max',
    search: 'Rechercher',
    reset: 'Réinitialiser',
    tableFilter: 'Filtres',
    status: 'Statut',
    duration: 'Durée',
    durationMin: 'Durée Min : {{value}}',
    durationMax: 'Durée Max : {{value}}',
    durationDownloaded: 'Durée (ms)',
    usageQuality: 'Éco-conduite',
    usageQualityMin: 'Éco-conduite Min :',
    usageQualityMax: 'Éco-conduite Max :',
    distanceGps: 'Distance Gps',
    distanceTraveled: 'Distance parcourue',
    distanceGpsMin: 'Distance Gps Min : {{value}} km',
    distanceTraveledMin: 'Distance parcourue Min : {{value}} km',
    distanceGpsMax: 'Distance Gps Max : {{value}} km',
    distanceTraveledMax: 'Distance parcourue Max : {{value}} km',
    startDateR: 'Date de début remontée',
    endDateR: 'Date de fin remontée',
    distanceGpsDownloaded: 'Distance Gps (km)',
    distanceTraveledDownloaded: 'Distance parcourue (km)',
    odometer: 'Distance Odomètre',
    odometerMin: 'Distance Odomètre Min : {{value}} km',
    odometerMax: 'Distance Odomètre Max : {{value}} km',
    checkBoxDistance: 'Valeurs séparées',
    odometerDownloaded: 'Distance Odomètre (km)',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    startTracking: 'Adresse de début',
    endTracking: 'Adresse de fin',
    tracking_start: 'Départ',
    tracking_end: 'Arrivé',
    vehicle: 'Véhicule',
    vehicleRef: 'Véhicule Référence',
    vehicleFleet: 'Flotte',
    owner: 'Propriétaire',
    gateway: 'Boîtier  Jimini',
    errer_id: "Merci vérifier l'identifiant du véhicule",
    errer_204: 'Ce véhicule ne contient aucun trajet',
    speed: 'Vitesse : {{value}} km/h',
    adresse: 'Adresse : {{value}}',
    date: 'Date : {{value}}',
    startDateControlLayer: 'Date de début : {{value}}',
    ref: 'Référence : {{value}}',
    vin: 'VIN : {{value}}',
    name: 'Nom : {{value}}',
    max: 'Vitesse max :',
    average: 'Vitesse moyenne :',
    speed_trip:
      'Vitesse max : {{max}} km/h Vitesse moyenne : {{average}}  km/h',
  },
  csv: {
    name: 'Nom',
    nameVh: 'Nom',
    nameVhVin: 'Vin',
    nameVhRef: 'Référence',
    numberplate: "Plaque d'immatriculation",
    duration: 'Durée des trajets',
    distanceGps: 'Distance Gps',
    distanceTraveled: 'Distance parcourue',
    distanceTripsGps: 'Distance Gps des trajets',
    odometerTrips: 'Distance Odomètre des trajets',
    odometer: 'Distance Odomètre',
    startDate: 'Date de début remontée',
    endDate: 'Date de fin remontée',
    startDateMin: 'Date de début remontée Min',
    startDateMax: 'Date de début remontée Max',
    endDateMin: 'Date de fin remontée Min',
    endDateMax: 'Date de fin remontée Max',
    startTracking: 'Adresse de début remontée',
    endTracking: 'Dernière adresse ',
    vehicleFleet: 'Flotte',
    owner: 'Propriétaire',
    gateway: 'Boîtier Jimini',
    numberOfTripsEnded: 'Nombre des trajets terminés',
  },
  ranger_piker: {
    start_date: 'Date de début',
    end_date: 'Date de fin',
    start_dateMin: 'DDR Min',
    start_dateMax: 'DDR Max',
    min: 'Min',
    max: 'Max',
    end_dateMin: 'DFR Min',
    end_dateMax: 'DFR Max',
    to: 'à',
    ok: 'Valider',
    cancel: 'Annuler',
    title: 'CHOISIR LES  DATE',
    today: "Aujourd'hui",
    clear: 'Vider',
  },
  profile: {
    title: 'Mon profil',
    name: 'Nom :',
    nameInput: 'Nom',
    type: 'Type :',
    email: 'Email :',
    emailInput: 'Email',
    btn_update: 'Modifier',
    conf_pwd: 'Confirmer le mot de passe',
    password: 'Nouveau mot de passe',
    docs: 'Mes documents',
  },
  ambassador: {
    title: 'Club ambassadeur',
  },
  error: {
    pageNotFound: 'Page introuvable 404 :)',
  },
  header: {
    jimini: 'Jimini',
    profile: 'Profile',
    language: 'Langue',
  },
  incidents: {
    lastOperation: 'Dernière opération : {{value}} ',
    remainingDistance: 'Distance restante avant opération : {{value}} km',
    traveledDistance:
      'Distance parcourue depuis dernière opération: {{value}} km',
    wearProgression: "Progression d'usure : {{value}} ",
    threshold: 'Intervalle entre chaque opération : {{value}} km',
    comment: 'Commentaire',
    commentPlaceHolder: 'Veuillez laisser un commentaire',
    date: "Date de l'incident",
    incidentSubmit: 'Incident crée avec succès  ',
    submit: 'Soumettre',
    accidentWithReport: 'Accident avec constat',
    accident_with_report: 'Accident avec constat',
    accidentWithoutReport: 'Accident sans constat',
    accident_without_report: 'Accident sans constat',
    frontPuncture: 'Crevaison avant',
    front_puncture: 'Crevaison avant',
    rearPuncture: 'Crevaison arriére',
    rear_puncture: 'Crevaison arriére',
    rightSideFall: 'Chute latérale droite',
    right_ride_fall: 'Chute latérale droite',
    leftSideFall: 'Chute latérale gauche',
    left_side_fall: 'Chute latérale gauche',
    frontSideImpact: 'Choc avant',
    front_side_impact: 'Choc avant',
    rightSideImpact: 'Choc latéral droit',
    right_side_impact: 'Choc latéral droit',
    leftSideImpact: 'Choc latéral gauche',
    left_side_impact: 'Choc latéral gauche',
  },
  data: {
    title: 'Extraction de données Jimini',
    csv: 'csv',
    graph: 'graphique',
    json: 'json',
    token: 'Token',
    datasetId: 'Dataset Id',
    datasetIds: 'Dataset Ids : ',
    datasetType: 'Dataset Type',
    periodId: 'Period Id',
    periodIds: 'Period Ids : ',
    offset: 'Offset',
    limit: 'Limit',
    reference: 'References',
    refKey: 'Ref Key',
    refValue: 'Ref value',
    dateMin: 'Date min',
    dateMax: 'Date max',
    extract: 'Extraire les données',
    exportFormat: "Format d'export",
    comboBoxLoading: 'Chargement ...',
    noOption: 'Aucun résultat',
    btn: 'Accéder au formulaire',
    btnDelete: 'Effacer le Token',
    placeholderDate: "Voir 'momentjs' pour le format de date",
    setOrder: 'Appliquer',
    refs: '{{key}} : {{value}}',
    ref: 'Refs',
    data: 'Data',
    getToken: 'Token sauvegardé avec succès',
    periodIdTooltip: 'Period Id : {{key}}',
    datasetIdTooltip: 'Dataset Id : {{key}}',
    chartBtn: 'Graphique...',
    chartDrawerTitle: 'Affichage des graphiques',
    count: 'Count :',
  },
  v_detail: {
    batteryLevel: 'Batterie :',
    orderPendingErr: 'Ordre en cours de réalisation.',
    withoutFleet: 'Pas de flotte',
    lastTracking_date: 'Dernière remontée : ',
    lastTracking_dateInput: 'Dernière remontée',
    ref: 'Référence :',
    refWithValue: 'Référence : {{value}}',
    refInput: 'Référence',
    vin: 'VIN :',
    vinInput: 'VIN',
    name: 'Nom :',
    v_model: 'Modèle :',
    nameWithValue: 'Nom : {{value}}',
    nameFleet: 'Nom :',
    nameOperation: 'Maintenance',
    nameInput: 'Nom',
    nameInputFleet: 'Nom',
    nameInputVehicle: 'Véhicule',
    inputVehicleWithRef: '{{ref}} ({{name}})',
    inputVehicleWithRefModel: 'Véhicule : {{ref}} ({{name}})',
    inputVehicleWithRefWithoutName: 'Véhicule : {{ref}}',
    v_numberplate: 'Immatriculation : ',
    v_numberplateInput: 'Immatriculation ',
    v_fleet: 'Flotte :',
    v_fleetInput: 'Flotte',
    owner: 'Propriétaire : ',
    ownerSelect: 'Propriétaire',
    modeleSelect: 'Modèle',
    boitier: 'Boitier Jimini :',
    boitierInput: 'Boitier Jimini',
    lastTracking_date_value: ' à ',
    adresse: 'Dernière adresse : ',
    adresseInput: 'Dernière adresse',
    info: 'Informations générales',
    actions: 'Actions',
    maps: 'Carte',
    maintenance: 'Maintenance prédictive',
    maintenanceOperation: 'Opération de maintenance',
    dateMaintenance: "Date de l'opération",
    notFoundMaintenance: 'Aucune maintenance prédictive',
    notFoundAlerts: 'Aucune alerte',
    operation: 'Opération',
    operationTooltip: 'Nouvelle opération de maintenance',
    usageQuality: 'Éco-conduite',
    usageQualityMonth: 'Mois en cours',
    notFoundUsageQualite: "Aucune indice d'eco conduite",
    odometer: 'Odomètre :',
    odometerInput: 'Odomètre',
    box_Disagree: 'Annuler',
    box_Agree: 'Valider',
    box_AgreeSave: 'Enregistrer',
    box_update: 'Modifie',
    v_title: 'Véhicule',
    v_titleReport: 'Rapport de véhicule',
    btn_edit: 'Modifier',
    btn_Show: 'Afficher',
    btn_hide: 'Cacher',
    btn_rapport: 'Rapport',
    btn_tracking: 'Suivi',
    btn_incident: 'Signaler un incident',
    btn_actions_menu: 'Actions',
    btn_immo: 'Immobilisation',
    btn_delete: 'Supprimer',
    box_text: 'Confirmer la suppression du véhicule ',
    v_update_msg: 'Action effectuée',
    v_form_validation: "S'il-vous-plaît complétez tous les champs requis!",
    v_formChange: 'Aucune modification détectée ',
    v_form_validationOwner: "S'il-vous-plaît choisir un propriétaire!",
    validationOwnerFleet: 'Le propriétaire de la flotte est requis',
    v_validationOwner: 'Le propriétaire du véhicule est requis',
    v_delete_msg_id: 'Erreur véhicule  id ',
    v_delete_msg: 'supprimer avec succès',
    v_show_details: 'Afficher les trajets',
    notFound: 'Véhicule non trouvé',
    trip: 'Trajet : ',
    status: 'Statut :',
    use: 'Utilisation',
    Blocked: 'Bloquée',
    btnUseVehicle: 'Utiliser',
    btnReturnVehicle: 'Rendre',
    UsesNotFround: 'Aucun utilisation',
  },
  gateways: {
    firmware_version: 'Version du Firmware:',
    t_ref: 'Référence',
    t_v: 'Véhicule',
    t_firmware: 'Firmware',
    t_firmwareMin: 'Firmware Min : {{value}}',
    t_firmwareMax: 'Firmware Max : {{value}}',
    page_errer:
      "Cette page n'est disponible que pour  de compte de type : Propriétaire / Administrateur",
  },
  fleets: {
    box_text: 'Confirmer la suppression du  flotte',
  },
  unit: {
    min: '{{value}} min',
    sc: '{{value}} s',
    km: '{{value}} km',
    kh_h: '{{value}} km/h',
    hour: '{{value}} h',
    time: '{{hour}} h {{min}} min',
    day: '{{day}} jour',
    days: '{{days}} jours',
    daysAndTime: '{{days}} jours  {{hour}} h {{min}} min',
  },
  global: {
    unite_metre: 'm',
    uniteKm: '{{value}} km',
    ok: 'Ok',
    no: 'Non',
    title: 'Mob-ion Jimini',
    cancel: 'Annuler',
    add: 'Ajouter',
    apiErrer: "Impossible d'atteindre l'API",
    loading: 'Chargement...',
    error: 'Erreur',
    save: 'Enregistrer',
    v_not_found: "Vous n'avez aucun véhicule sur votre compte",
    progress: '{{value}}%',
    refresh: 'Rafraîchir',
    socket: 'Tracking en temps réel',
    fullScreen: 'Activer le mode plein écran',
    exitFullScreen: 'Désactiver le mode plein écran',
    browserDetect:
      "Nous recommandons l'utilisation des navigateurs Google Chrome et Mozilla Firefox.",
  },
  errors: {
    valueRequired: 'Veuillez renseigner ce champ',
    error_occurred: "Une erreur c'est produite",
    report_error: "Reporter l'erreur",
    error_input_username: 'Email requis',
    error_input_usernameProfile:
      "Le nom d'utilisateur n'est pas valide (entre 3 et 30 caractères)",
    error_input_pwdProfile:
      'Le mot de passe doit contenir au moins 6 caractères avec des lettres et des chiffres',
    error_input_email: 'Email est nécessaire',
    error_input_emailProfile: "L'email n'est pas valide",
    error_input_pwd: 'Mot de passe requis',
    error_input_pwd_match: 'Le mot de passe de confirmation de correspond pas',
    error_compte_type:
      "Cette ressource n'est disponible que pour les utilisateurs de type: Propriétaire, Administrateur",
    error_gateways: 'veuillez ajouter des boitier',
    error_fleets: 'veuillez ajouter des flottes',
  },
  t_body: {
    noMatch: 'Aucun résultat',
    toolTip: 'Trier',
    columnHeaderTooltip: 'Trier par',
  },
  t_pagination: {
    next: 'Page suivante',
    previous: 'Page précédente',
    toolTip: 'Page précédente',
    rowsPerPage: 'Lignes par page:',
    displayRows: 'de',
  },
  t_toolbar: {
    search: 'Chercher',
    downloadCsv: 'Télécharger CSV',
    print: 'Impression',
    viewColumns: 'Afficher les colonnes',
    filterTable: 'Tableau des filtres',
  },
  t_filter: {
    all: 'Toute',
    title: 'FILTRES',
    reset: 'RÉINITIALISER',
  },
  t_viewColumns: {
    title: 'Afficher les colonnes',
    titleAria: 'Afficher / masquer les colonnes du tableau',
  },
  t_selectedRows: {
    text: 'ligne(s) sélectionnée(s)',
    delete: 'Effacer',
    deleteAria: 'Supprimer les lignes sélectionnées',
  },
  vhReport: {
    csv: ' Csv avec  virgule',
    csvWithSemicolon: ' Csv avec point virgule',
    pdf: 'Pdf',
    downloadPdfTooltip: 'Télécharger en format PDF',
    download: 'Télécharger',
    errorDate: 'La date de début ou  la date de fin  sont requis',
  },
  router: {
    register: '/register',
    alerts: '/alerts',
    vehicles: '/vehicules',
    profile: '/profil',
    gateways: '/boitier',
    fleets: '/flottes',
    trips: '/trajets',
    login: '/connexion',
    data: '/data',
    resetPwd: '/connexion/identifier',
    home: '/',
    incidents: '/incidents',
    users: '/utilisateurs',
    quickAccess: '/acces_rapide',
    vehicleAccess: '/utiliser_vehicule',
  },
  dropZone: {
    msg: ' Joindre un fichier - Glisser  (image, vidéo, pdf)',
    msgPicture: ' Joindre un fichier - Glisser',
    msgBtn: 'Choisir un fichier',
    msgAvatar: 'Choisir un avatar',
    unsupportedTypes: 'Fichier non supporté : {{value}}',
    fileError: '{{value}} fichier non pris en charge',
    fileError_plural: '{{value}} fichiers non pris en charge',
    fileOk: '{{value}} fichier joint',
    fileOk_plural: '{{value}} fichiers joints',
    upload: 'Upload Files',
    size: 'Fichier trop volumineux (20mo max)',
    duplicate: 'Fichier déjà ajouté : {{value}}',
  },
  userDropZone: {
    msg: ' Joindre un fichier - Glisser  (image, pdf)',
  },
  network: {
    offline: 'Vous êtes actuellement hors ligne. Vérifiez votre connexion.',
  },
  map: {
    searchLabel: 'Rechercher',
    setting: 'Paramètres',
    tooltip: 'Identifier les véhicules',
    cluster: 'Regrouper les véhicules',
    immat: "Plaque d'immatriculation",
    name: 'Nom',
    vin: 'VIN',
  },
  pwa: {
    title: 'Mob-ion Jimini',
    description: 'Interface web du service Jimini',
    install: '  Install Mob-ion Jimini',
    msg: "Ajouter à l'écran d'accueil",
    close: 'Fermer',
    reload: 'Rafraîchir',
    message:
      "Nouvelle mise à jour de l'application, la page va se recharger automatiquement dans quelques secondes",
  },
  customSearch: {
    address: 'Adresse: {{value}}',
    flyTo: 'FlyTo: {{value}}',
    addressTooltip:
      "Pour la recherche par adresse  utiliser le format 'a: adresse' ",
    flyToTooltip:
      "Pour la recherche par position géographique  utiliser le format 'flyto: lat,lon' ",
    vhToTooltip:
      'Pour la recherche par véhicule utiliser : nom, vin, référence, propriétaire, flotte, boitier, dernière adresse remontée ',
  },
  resetPwd: {
    txt: 'Mot de passe oublié ?',
    pwd: 'Nouveau mot de passe',
    cnfPwd: 'Confirmer le mot de passe',
    title: 'Réinitialiser votre mot de passe',
    requestTitle: 'Vous recevrez un mail pour réinitialiser votre mot de passe',
    send: 'Envoyer',
    errEmail: 'Saisie invalide',
    successMsg: 'Email envoyé',
    token: 'Token invalide',
    successMsgResetPwd: 'Réinitialisation du mot de passe avec succès',
    status400: "Cette adresse mail n'est pas associée à un utilisateur",
  },
  register: {
    title: "S'inscrire",
    creat: "Vous n'avez pas encore de compte, voulez vous en créer un ?",
    creatBtn: 'Créer',
    successMsgRegister: 'Invitation correctement créée et envoyée par email.',
    successMsg: 'Invitation envoyée par email.',
  },
  invited: {
    title: 'Activer votre compte',
    pwd: 'Choisir le mot de passe',
    cnfPwd: 'Confirmer le mot de passe',
    legalTilte: "J'accepte les mentions légales suivantes  :",
    linkNotice: 'Mentions légales',
    linkPolitique: 'Conditions générales de vente',
    legalNotice: 'Vous devez accepter les mentions légales',
    subtitle1: "Votre compte Jimini vous permet d'accéder aux",
    subtitle2: 'données de vos véhicules et de gérer vos flottes',
  },
  pictures: {
    tooltip: 'Modifier la photo',
  },
  documents: {
    add: 'Ajouter un document',
    edit: 'Modifier le Document',
    delete: 'Supprimer le Document',
    show: 'Afficher le Document',
    notHaveFile: 'Aucune pièce jointe',
    infoG: 'Informations générales',
    title: 'Documents',
    titleAdd: 'Nouveau document',
    name: 'Nom',
    nameModel: 'Nom : {{value}}',
    vehicleNameModel: 'Véhicule nom : {{value}}',
    VinModel: 'Véhicule VIN : {{value}}',
    vehicleNmeModel: 'Véhicule référence : {{value}}',
    creationDateModel: 'Date de création : {{value}}',
    type: 'Type',
    v_numberplate: "A. Numéro d'immatriculation",
    v_dateNumberplate: 'B. Date de la première immatriculation du véhicule',
    owner: 'C. Informations sur le(s) propriétaire(s) du véhicule',
    vehicle: 'D. Informations générales concernant le véhicule',
    v_ident: 'E. Numéro d’identification du véhicule',
    v_masse: 'F. Information sur les masses maximales du véhicule',
    v_masseBody:
      'G. Masse du véhicule en service avec carrosserie et dispositif d’attelage',
    v_immaDate: 'H. Période de validité du Certificat d’Immatriculation',
    v_dateCertificate: 'I. Date du certificat',
    v_category: 'J. Catégorie du véhicule (CE)',
    v_receptionNum: 'K. Numéro de réception par type (si disponible)',
    v_energy: 'P. Informations sur l’énergie et la puissance du véhicule',
    v_powerRatio:
      'Q. Rapport puissance/masse en kW/kg (uniquement pour les motocyclettes)',
    v_seatsNum: 'S. Informations sur le nombre de places du véhicule',
    v_variousInformation: 'U. Informations diverses',
    v_environmentalInfo: 'V. Informations environnementales',
    v_technicalControl: 'X. Contrôle technique',
    v_taxes: 'Y. Détail des taxes de carte grise',
    v_specificMentions: 'Z. Mentions spécifiques',
    btnNext: 'Suivant',
    btnFinished: 'Terminé',
    btnBack: 'Précédent',
    a: "A - Numéro d'immatriculation",
    aLabel: "A (Numéro d'immatriculation)",
    b: 'B - Date de la première immatriculation',
    bLabel: 'B (Date de la première immatriculation)',
    c1: 'C.1 - Nom et prénom du propriétaire',
    c1Label: 'C.1 (Nom et prénom du propriétaire)',
    c41: 'C.4.1 - Nombre des personnes étant titulaires',
    c41Label: 'C.4.1 (Nombre des personnes étant titulaires)',
    c3: 'C.3 - Adresse',
    c3Label: 'C.3 (Adresse)',
    d1: 'D.1 - Marque du véhicule',
    d1Label: 'D.1 (Marque du véhicule)',
    d2: 'D.2 - Type, Variante, Version',
    d2Label: 'D.2 (Type, Variante, Version)',
    d21: 'D.2.1 - Code National d’Identification du type',
    d21Label: 'D.2.1 (Code National d’Identification du type)',
    d3: 'D.3 - Dénomination commerciale',
    d3Label: 'D.3 (Dénomination commerciale)',
    e: 'E - Numéro d’identification du véhicule',
    eLabel: 'E (Numéro d’identification du véhicule)',
    f1: 'F.1 - Masse en charge (en kg)',
    f1Label: 'F.1 (Masse en charge (en kg))',
    f2: 'F.2 - Masse en charge (en kg)',
    f2Label: 'F.2 (Masse en charge (en kg))',
    f3: 'F.3 - Masse en charge (en kg)',
    f3Label: 'F.3 (Masse en charge (en kg))',
    g: 'G - Masse du véhicule en service',
    gLabel: 'G (Masse du véhicule en service)',
    g1: 'G.1 - Poids à vide national ',
    g1Label: 'G.1 (Poids à vide national)',
    h: 'H - Période de validité du Certificat d’Immatriculation',
    hLabel: 'H (Période de validité du Certificat d’Immatriculation)',
    i: 'I - Date du certificat',
    iLabel: 'I (Date du certificat)',
    j: 'J - Catégorie du véhicule (CE)',
    jLabel: 'J (Catégorie du véhicule (CE))',
    j1: 'J.1 - Genre National',
    j1Label: 'J.1 (Genre National)',
    j2: 'J.2 - Carrosserie (CE)',
    j2Label: 'J.2 (Carrosserie (CE))',
    j3: 'J.3 - Carrosserie (désignation nationale)',
    j3Label: 'J.3 (Carrosserie désignation nationale)',
    k: 'K - Numéro de réception par type',
    kLabel: 'K (Numéro de réception par type)',
    p1: 'P.1 - Cylindrée (en cm3)',
    p1Label: 'P.1 (Cylindrée en cm3)',
    p2: 'P.2 - Puissance nette maximale (en kW)',
    p2Label: 'P.2 (Puissance nette maximale en kW)',
    p3: 'P.3 - Type de carburant ou source d’énergie',
    p3Label: 'P.3 (Type de carburant ou source d’énergie)',
    p6: 'P.6 - Puissance administrative nationale',
    p6Label: 'P.6 (Puissance administrative nationale)',
    q: 'Q - Rapport puissance/masse en kW/kg',
    qLabel: 'Q (Rapport puissance/masse en kW/kg)',
    s1: 'S.1 - Nombre de places assises',
    s1Label: 'S.1 (Nombre de places assises)',
    s2: 'S.2 - Nombre de places debout',
    s2Label: 'S.2 (Nombre de places debout)',
    u1: 'U.1 - Niveau sonore à l’arrêt (en dB(A))',
    u1Label: 'U.1 (Niveau sonore à l’arrêt en dB(A))',
    u2: 'U.2 - Vitesse du moteur (en min^-1)',
    u2Label: 'U.2 (Vitesse du moteur en min^-1)',
    v7: 'V.7 - Rejet de CO2 (en g/km)',
    v7Label: 'V.7 (Rejet de CO2 en g/km)',
    v9: 'V.9 - Indication CE',
    v9Label: 'V.9 (Indication CE)',
    x1: 'X.1 - Date d’échéance du Contrôle Technique',
    x1Label: 'X.1 (Date d’échéance du Contrôle Technique)',
    y1: 'Y.1 - Taxe régionale',
    y1Label: 'Y.1 (Taxe régionale)',
    y2: 'Y.2 - Taxe pour le développement',
    y2Label: 'Y.2 (Taxe pour le développement)',
    y3: 'Y.3 - Taxe additionnelle CO',
    y3Label: 'Y.3 (Taxe additionnelle CO)',
    y4: 'Y.4 - Taxe pour gestion du certificat d’immatriculation',
    y4Label: 'Y.4 (Taxe pour gestion du certificat d’immatriculation)',
    y5: 'Y.5 - Montant de la redevance pour acheminement ',
    y5Label: 'Y.5 (Montant de la redevance pour acheminement)',
    y6: 'Y.6 - Montant total des taxes ',
    y6Label: 'Y.6 (Montant total des taxes)',
    z1: 'Z.1 - Mentions spécifiques',
    z1Label: 'Z.1 (Mentions spécifiques)',
    z2: 'Z.2 - Mentions spécifiques',
    z2Label: 'Z.2 (Mentions spécifiques)',
    z3: 'Z.3 - Mentions spécifiques',
    z3Label: 'Z.3 (Mentions spécifiques)',
    z4: 'Z.4 - Mentions spécifiques',
    z4Label: 'Z.4 (Mentions spécifiques)',
    a3: '3. Validité',
    a3Label: '3 (Validité)',
    a4: '4. Code Pays/ Code Assureur',
    a4Label: '4 (Code Pays/ Code Assureur)',
    a5: "5. Numéro d'immatriculation",
    a5Label: "5 (Numéro d'immatriculation)",
    a6: '6. Catégorie du véhicule',
    a6Label: '6 (Catégorie du véhicule)',
    a7: '7. Marque du véhicule',
    a7Label: '7 (Marque du véhicule)',
    a9: "9. L'utilisateur du véhicule",
    a9Label: "9 (L'utilisateur du véhicule)",
    a10: '10. Délivrée par',
    a10Label: '10 (Délivrée par)',
    error: 'Identifiant ou type introuvable',
    notFround: 'Aucun document',
    creationDate: 'Date de création',
    files: 'Pièces jointes',
    documentsDetailTitle: 'informations',
    box_text: 'Confirmer la suppression du document',
    duplicate: 'Copier les informations depuis un autre véhicule',
    remplirBtn: 'Remplir',
    closeBtn: 'Fermer',
    a3Start: '3. Date de début',
    start_date: '3. Date de début',
    end_date: '3. Date de fin',
    a3End: '3. Date de fin',
    expirationDate: "Date d'expiration",
    dateRanger: ' du {{start}} au {{end}}',
    info: 'Données du document',
    titleDoc: '{{name}} ({{type}})',
    titleDocWithoutName: '({{type}})',
    natureLabel: 'Nature du contrôle',
    startDateLabel: 'Date du contrôle',
    numberProceLabel: 'N° du procès verbal',
    resultLabel: 'Résultat du contrôle',
    endDateLabel: 'Limite de validité du contrôle réalisé',
    errerLabel: 'Défaillances et niveaux de gravité',
    kmLabel: 'Kilométrage relevé',
    nextLabel: 'Nature du prochaine contrôle',
    centerAgre: "N° d'agrément du center",
    campany: 'Raison sociale',
    coord: 'Coordonnées',
    agrTech: "N° d'agrément du contrôlleur",
    techFullName: 'Nom et Prénon',
    immat: 'Immatriculation et pays',
    immatDate: "Date d'immatriculation",
    dateFirstCir: 'Date de première mise en circulation  ',
    marque: 'Marque',
    desg: 'Désignation commerciale',
    vin: 'N° dans la série du type (VIN)',
    catg: 'Catégorie internationale',
    genre: 'Genre',
    typeVehicle: 'Type/CNIT',
    Ener: 'Énergie',
    doc: 'Document(s) présenté(s)',
    mesure: 'Mesures réalisées et valeurs limites correspondantes',
    raison: "Motif de l'infraction",
    date: 'Date du PV',
    description: "Description de l'infraction",
    number: 'Numéro du Pv',
    DateOfIncreased: "Date de l'infraction",
    time: "Heure de l'infraction",
    address: "Adresse de l'infraction",
    amountMinor: 'Montant  PV minorée (€) ',
    amountNormal: 'Montant  PV (€) ',
    amountIncreased: 'Montant PV majoré (€) ',
    effect: 'Effet(s) sue le permis de conduire',
  },
  orders: {
    title: 'Commandes',
    type: 'Type',
    status: 'Statut',
    lock: 'Immobiliser',
    unlock: 'Désimmobiliser',
    setMaxSpeed: 'Définir la vitesse maximum',
    openSaddle: 'Ouvrir la selle',
    openTopCase: 'Ouvrir le top case',
    creationDate: 'Date de création',
    Pending: 'en attente',
    Sent: 'Expédié',
    Completed: 'Terminé',
    Failed: 'Échec',
    InProgress: 'En cours',
    powerOff: 'Mettre hors tension',
    powerOn: 'Mettre sous tension',
    order204: 'Aucun ordre ne trouve',
    ping: 'Ping',
    pong: 'Pong',
    startRemoteSsh: 'démarrer Ssh à distance',
    sendingDate: "Date d'envoi",
    closingData: 'Données de fermeture',
    closingDate: 'Date de fin',
    closingMessage: 'Message de fin',
    data: 'Data :',
  },
  uses: {
    title: 'Carnet de bord',
    status: 'Statut',
    startOdometer: 'Odomètre de début',
    endOdometer: 'Odomètre de fin',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    user: 'Utilisateur',
    notAllow: "Ce véhicule et déjà occupe par d'autre utilisateur",
    message: "L'assurance et expire pour ce véhicule le {{value}}",
  },
  vehicleIncident: {
    files: 'Pièces jointes',
    data: 'Détail',
    comment: 'Commentaire',
    incident204: 'Aucune information supplémentaire retrouvée.',
    startDateMin: "Date d'incident Min",
    endDateMax: "Date d'incident Max",
    startDateMinCreation: 'Date de création Min',
    endDateMaxCreation: 'Date de création Max',
  },
  users: {
    name: 'Nom :',
    nameInput: 'Nom',
    ref: 'Référence :',
    refInput: 'Référence',
    t_type: 'Type',
    type: 'Type : ',
    owner: 'Propriétaire : ',
    lastAuthenticationDate: 'Dernière authentification',
    lastAuthenticationDateDetail: 'Dernière authentification :',
    lastAuthenticationDateRanger: 'Date de la dernière authentification',
    status: 'Status',
    statusDetail: 'Status :',
    email: 'Email :',
    emailInput: 'Email',
    Pending: 'En attente',
    Active: 'Active',
    Blocked: 'Bloqué',
    startDateMin: 'Date de la dernière authentification Min',
    endDateMax: 'Date de la dernière authentification Max',
    notFound: 'Utilisateur inactif',
    drawerTitle: 'Utilisateur',
    sendEmail:
      "Cocher cette case pour envoyer un email d'invitation à la création du compte",
    inviteBtn: " Envoyer un email d'invitation",
    resetPasswordBtn: 'Envoyer un email de changement de mot de passe',
    box_text: "Confirmer la suppression de l'utilisateur",
    deleteMsg: "Détachez l'utilisateur avant de le supprimer.",
  },
  ac: 'accuracy',
  be: 'bearing',
  sp: 'speed',
  al: 'alt',
  lo: 'long',
  la: 'lat',
  T: 'time',
  V: 'voltage',
  I: 'current',
  so: 'soc',
  vmi: 'minIdV',
  vm: 'minV',
  vMi: 'maxIdV',
  vM: 'maxV',
  v_0: 'v_0',
  v_1: 'v_1',
  v_n: 'v_n',
  t0_0: 't0_0',
  t0_n: 't0_n',
  tn_0: 'tn_0',
  tn_n: 'tn_n',
  dv: 'dcBusVoltage',
  dc: 'dcBusCurrent',
  dp: 'dcBusPower',
  tp: 'throttlePercent',
  cu: 'consignCurrentUser',
  cf: 'consignCurrentFinal',
  rm: 'rpmMotor',
  rp: 'remotePort',
  im: 'imsi',
  ic: 'iccid',
  cst: 'status',
  cv: 'voltage',
  ct: 'temp',
  cso: 'soc',
  ms: 'status',
  mi: 'ip',
  mr: 'rxPackets',
  mt: 'txPackets',
  nr: 'networkRoute',
  aX: 'aX',
  aY: 'aY',
  aZ: 'aZ',
  gX: 'gX',
  gY: 'gY',
  gZ: 'gZ',
  mX: 'mX',
  mY: 'mY',
  mZ: 'mZ',
  User: 'Utilisateur',
  Owner: 'Propriétaire',
  Admin: 'Administrateur',
  Ended: 'Terminé',
  InProgress: 'En cours',
  Ending: 'Finaliser...',
  RegistrationCard: 'Carte grise',
  InsuranceCard: 'Assurance',
  Technical: 'Technique',
  Other: 'Autre',
  TechnicalControl: 'Contrôle technique',
  BusinessCard: 'Carte de visite',
  AmbassadorContract: 'Contrat ambassadeur',
  QRCode: 'QR Code',
  settings: {
    vehicleDetailsShowOrders: 'Commandes',
    vehicleDetailsShowUses: 'Carnet de bord',
    settings: 'Réglages',
    removedRouter: 'Page désactivée :',
    router: 'Page activée :',
    permissions: 'Options supplémentaires :',
  },
  treeView: {
    map: 'Carte',
    vehicleFleets: 'Flottes',
    gateways: 'Boitiers Jimini',
    vehicles: 'Véhicules',
    report: 'Rapport',
    vehicleTrips: 'Trajets',
    vehicleAlerts: 'Alertes',
    vehicleIncidents: 'Incidents',
    data: 'Données',
    users: 'Utilisateurs',
    tableFilter: 'Tableau des filtres',
    download: 'Télécharger',
    apiFilter: 'Api filtres',
    settings: 'Paramètres',
    search: 'Recherche',
    filter: 'Filtres',
    screens: 'Pages',
    drawers: 'Volet latéral',
    vehicle: 'Véhicule',
    actions: 'Actions',
    maintenances: 'Maintenance prédictive',
    usageQuality: 'Éco-conduite',
    documents: 'Documents',
    alerts: 'Alertes',
    commands: 'Commandes',
    uses: 'Carnet de bord',
    vehicleFleet: 'Flotte',
    user: 'Utilisateur',
    gateway: 'Boitier Jimini',
    quickAccess: 'Accès rapide',
    avaibleStates: 'États',
    generalInfo: 'Informations générales',
    history: 'Historique',
    ambassador: 'Club ambassadeur',
  },
  customToolbarSelect: {
    map: 'Afficher la carte',
    deselect: 'Tout déselectionner',
    inverse: 'Sélection inverse',
  },
  avaibleStates: {
    title: 'États',
  },
  vehicleHeader: {
    lastTracking: 'Dernière adresse :',
    lastTrip: 'Dernier trajet :',
    speed: 'Vitesse moyenne :',
    grayCarte: 'Carte grise',
    assurence: 'Carte verte',
    btnControl: 'Arrêt administratif',
    btnUnbloking: 'Déblocage administratif',
    title: 'Détails du trajet',
    PaymentProblem: 'Problème de paiemen',
    Administrative: 'Problème administratif',
    Stolen: 'Volé',
    Accident: 'Accidenté',
    Wreck: 'Déclaré épave',
    Active: 'En cours',
    Inactive: 'Terminé',
    titleAcordion: 'Historique des procédures administratives',
    author: 'Créé par',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    motif: 'Motif de bloquage :',
    '': '',
  },
  searchInput: {
    ref: 'Référence : {{ref}}',
    vin: 'VIN : {{vin}}',
    name: 'Nom : {{name}}',
    numberplate: 'Immat. : {{numberplate}}',
  },
}
