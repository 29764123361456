import { makeStyles } from '@material-ui/core/styles'

const StylesWithMaterialUi = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gridItem: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItemBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  btn: {
    fontSize: '12px',
    fontWeight: '300',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: 'auto',
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
}))
export default StylesWithMaterialUi
