import { useTranslation } from 'react-i18next'

import DateComponent from '../../../../../../DataTable/components/DateComponent'

const Columns = () => {
  const { t } = useTranslation()

  return [
    {
      label: t('orders.type'),
      name: 'type',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? t(`orders.${value}`) : null),
      },
    },
    {
      label: t('orders.status'),
      name: 'status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? t(`orders.${value}`) : null),
      },
    },

    {
      label: t('orders.creationDate'),
      name: 'creationDate',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) =>
          value ? DateComponent(value, 'lll') : null,
      },
    },
    {
      label: 'order',
      name: 'order',
      options: {
        filter: false,
        sort: false,
        download: false,
        display: false,
        viewColumns: false,
        print: false,
        searchable: false,
      },
    },
  ]
}

export default Columns
