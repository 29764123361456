/* eslint-disable import/no-cycle */
import React from 'react'
import { drawerActions } from '../../actions'
import VehicleDetails from '../../components/Vehicles/VehiclesDetails/VehicleDetails'
import VehicleReport from '../../components/Vehicles/VehicleReport/VehicleReport'
import EditVehicle from '../../components/Vehicles/EditVehicle/EditVehicle'
import { getConfig } from '../system/system.helpers'

const CloseReportDrawer = (t, dispatch, vehicle) =>
  dispatch(
    drawerActions.show(
      <VehicleDetails vehicle={vehicle} />,
      t('v_detail.v_title'),
      'right',
      'persistent',
      'ltr',
    ),
  )
const HandleReportOpen = (vehicle, t, dispatch) => {
  const close = () => CloseReportDrawer(t, dispatch, vehicle)
  dispatch(
    drawerActions.show(
      <VehicleReport vehicle={vehicle} onclose={close} drawer />,
      t('v_detail.v_titleReport'),
      'right',
      'persistent',
      'rtl',
      null,
      close,
    ),
  )
}
const CloseEditDrawer = (dispatch, t, vehicle) => {
  dispatch(
    drawerActions.show(
      <VehicleDetails vehicle={vehicle} />,
      t('v_detail.v_title'),
      'right',
      'persistent',
      'ltr',
    ),
  )
}
const HandleEdit = (vehicle, dispatch, t) => {
  const close = () => CloseEditDrawer(dispatch, t, vehicle)
  dispatch(
    drawerActions.show(
      <EditVehicle vehicle={vehicle} onclose={close} drawer={false} />,
      t('v_detail.v_title'),
      'right',
      'persistent',
      'rtl',
      null,
      close,
    ),
  )
}
const getAvatar = (id, accessToken) =>
  `${getConfig().jimini.api_base_url}/files/${id}?accessToken=${accessToken}`
export const VehiclesHelpers = {
  CloseReportDrawer,
  HandleReportOpen,
  HandleEdit,
  getAvatar,
}
