import React, { useMemo } from 'react'
import StylesWithMaterialUi from './UserDetails.styles'
// eslint-disable-next-line import/no-cycle
import GeneralInformation from './components/generalInformation/GeneralInformation'
import useController from './UserDetails.controller'
// eslint-disable-next-line import/no-cycle
import BtnGroup from './components/btnGroup/BtnGroup'

const UserDetails = (props) => {
  const {
    handleExpandedGeneral,
    expandedGeneral,
    user,
    expandedBtn,
    handleExpandedGeneralBtn,
    settings,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return useMemo(
    () =>
      user ? (
        <div className={classes.gridRoot}>
          <GeneralInformation
            handleExpanded={handleExpandedGeneral}
            expanded={expandedGeneral}
            user={user}
          />
          {settings.settings.drawers &&
          settings.settings.drawers.user.actions ? (
            <BtnGroup
              handleExpanded={handleExpandedGeneralBtn}
              expanded={expandedBtn}
              user={user}
            />
          ) : null}
        </div>
      ) : null,
    [
      classes.gridRoot,
      expandedBtn,
      expandedGeneral,
      handleExpandedGeneral,
      handleExpandedGeneralBtn,
      settings.settings.drawers,
      user,
    ],
  )
}
export default UserDetails
