/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { jiminiAlertsService } from '../../../../../services'
import { fullResponse, getPermissions } from '../../../../../helpers'

const useController = (props) => {
  const { vehicle, expanded } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const { t } = useTranslation()
  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    const abortController = new AbortController()
    const fetch = async () => {
      try {
        if (user && getPermissions(user.type, 'alerts')) {
          setData(null)
          if (vehicle && vehicle.id && expanded) {
            setLoading(true)
            const query = { vehicleId: vehicle.id }
            const res = await jiminiAlertsService.getAlerts(query)
            if (fullResponse(res, 200)) {
              setData(res.content.data)
            }
            setLoading(false)
          }
        }
      } catch (e) {
        setLoading(false)
      }
    }
    fetch()
    return () => {
      abortController.abort()
    }
  }, [expanded, user, vehicle, vehicle.id])

  return { loading, data, t, user }
}
export default useController
