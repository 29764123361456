/* eslint-disable import/no-cycle */
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import StylesWithMaterialUi from './GatewaysDetails.styles'
import { momentConstants } from '../../../constants'
import useController from './GatewaysDetails.controller'
import { getPermissions } from '../../../helpers'

const GatewaysDetails = (props) => {
  const { gateway } = props
  const { user, t } = useController()
  const classes = StylesWithMaterialUi()

  return (
    <div className={classes.gridRoot}>
      {gateway ? (
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="flex-start"
          className={classes.gridContainer}
        >
          {gateway.ref ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography className={classes.text}>
                  {t('v_detail.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.input}>{gateway.ref}</Typography>
              </Grid>
            </Grid>
          ) : null}
          {gateway.owner && user && getPermissions(user.type, 'showOwner') ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography className={classes.text}>
                  {t('v_detail.owner')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.input}>
                  {gateway.owner.name}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {gateway.vehicle ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography className={classes.text}>
                  {t('v_detail.nameInputVehicle')} :
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.input}>
                  {gateway.vehicle.ref && gateway.vehicle.name
                    ? t('v_detail.inputVehicleWithRef', {
                        ref: gateway.vehicle.ref,
                        name: gateway.vehicle.name,
                      })
                    : gateway.vehicle.ref}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          {gateway.lastTracking && gateway.lastTracking.date ? (
            <Grid
              item
              container
              direction="row"
              spacing={1}
              className={classes.gridItem}
            >
              <Grid item>
                <Typography className={classes.text}>
                  {t('v_detail.lastTracking_date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.input}>
                  {moment(gateway.lastTracking.date).format(
                    momentConstants.FORMAT_INIT,
                  )}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </div>
  )
}
export default React.memo(GatewaysDetails)
