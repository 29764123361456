/* eslint-disable import/no-cycle */
import { tripsConstants } from '../constants'

export const tripsActions = {
  success,
  init,
  failure,
  clear,
}

function success(trips) {
  return {
    type: tripsConstants.SUCCESS,
    payload: {
      trips,
    },
  }
}

function init() {
  return { type: tripsConstants.INIT }
}

function failure() {
  return { type: tripsConstants.FAILURE }
}
function clear() {
  return { type: tripsConstants.CLEAR }
}
