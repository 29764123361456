/* eslint-disable import/no-cycle */
import React from 'react'
import { Grid, Box } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import StylesWithMaterialUi from './AddDocuments.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import ComboBox from '../../utils/MatrialUiComponents/ComboBox/ComboBox'
import { documentsConstants } from '../../../constants'
import DropZone from '../../AmbassadorDropZone/AmbassadorDropZone'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import FileView from '../../DropZone/components/fileView/FilesView'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import useController from './AddDocuments.controller'

const AddDocuments = (props) => {
  const { onclose } = props
  const {
    handleSubmit,
    t,
    vehicleName,
    docName,
    handleChange,
    type,
    selectedFiles,
    open,
    setSelectedFiles,
    setType,
  } = useController(props)

  const classes = StylesWithMaterialUi()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <form name="form" onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="filled"
              label={t('v_detail.nameInputVehicle')}
              value={vehicleName || ''}
              type="text"
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <InputComponent
              variant="outlined"
              label={t('documents.name')}
              value={docName}
              onChange={handleChange}
              type="text"
              name="docName"
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            className={classes.gridItem}
          >
            <ComboBox
              autoComplete
              fullWidth
              margin="normal"
              onChange={(event, newValue) => {
                setType(newValue)
              }}
              loadingText={t('global.loading')}
              value={type}
              name="type"
              options={documentsConstants.user_documents_types}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) =>
                typeof option === 'string' ? t(option) : ''
              }
              label={t('documents.type')}
              required
            />
          </Grid>
          <Grid item container direction="row" className={classes.gridItem}>
            <DropZone
              multiple
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Grid>
          {selectedFiles && selectedFiles.length > 0 ? (
            <Grid item container direction="row" className={classes.gridItem}>
              <FileView
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
          ) : null}

          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_Disagree')}
                className={classes.btn}
                onClick={onclose}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                className={classes.btn}
                startIcon={<SaveIcon />}
                type="submit"
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </form>

        <BackdropComponent className={classes.backdrop} open={open} />
      </Grid>
    </Box>
  )
}

export default React.memo(AddDocuments)
