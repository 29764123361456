/* eslint-disable import/no-cycle */
import React from 'react'
import { Marker, Polyline, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import useController from './Trips.controller'
import { markerIcon } from '../../../../../../../helpers/map/map.helpers'
// dashArray: '5 10'
const Trips = (props) => {
  const { lines, startMarker, lastMarker, t, renderIcon, trip } =
    useController(props)

  return trip ? (
    <>
      {lines.coords.length > 0 ? (
        <Polyline positions={lines.coords} pathOptions={{ color: '#1D71B8' }} />
      ) : null}
      {startMarker ? (
        <Marker
          position={startMarker}
          icon={markerIcon('black', '#1D71B8', 'play_arrow')}
        >
          <Tooltip>{t('table_trips.tracking_start')}</Tooltip>
        </Marker>
      ) : null}
      {lastMarker ? (
        <Marker
          position={lastMarker}
          icon={markerIcon('black', '#1D71B8', renderIcon())}
        >
          <Tooltip>{t('table_trips.tracking_end')} </Tooltip>
        </Marker>
      ) : null}
    </>
  ) : null
}

export default React.memo(Trips)
