/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { alertActions, drawerActions, fleetsActions } from '../../../actions'

import { fleetsService } from '../../../services'
import { fleetsPatternConstants } from '../../../constants'
const useHelpers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    dispatch(drawerActions.close())
  }, [dispatch])

  const updateRedux = useCallback(
    async (id) => {
      const query = { id }
      try {
        const res = await fleetsService.fleets(query)
        if (!res || res.status !== 200) {
          dispatch(alertActions.error(res.content.message, 6000, true))
          return
        }
        dispatch(fleetsActions.add(res.content.data[0]))
      } catch (error) {
        dispatch(fleetsActions.failure())
      }
    },
    [dispatch],
  )

  const validateFleetName = useCallback(
    // eslint-disable-next-line consistent-return
    (value) => {
      if (!value) {
        return t('table.v_fleetRequired')
      }
      if (!fleetsPatternConstants.NAME_PATTERN.test(value)) {
        return t('table.v_fleetPattern')
      }
    },
    [t],
  )

  const validateValue = useCallback(
    (value) => {
      if (!value) {
        return t('errors.valueRequired')
      }
    },
    [t],
  )
  return {
    handleCancel,
    validateValue,
    updateRedux,
    validateFleetName,
  }
}
export default useHelpers
