/* eslint-disable import/no-cycle */
import { Box, Grid } from '@material-ui/core'
import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import { FormProvider } from 'react-hook-form'
import StylesWithMaterialUi from './EditUser.styles'
import InputComponent from '../../utils/MatrialUiComponents/InputComponent/InputComponent'
import { getPermissions } from '../../../helpers'
import ButtonComponent from '../../utils/MatrialUiComponents/Button/ButtonComponent'
import BackdropComponent from '../../utils/MatrialUiComponents/Backdrop/BackdropComponent'
import CircularProgressComponent from '../../utils/MatrialUiComponents/CircularProgress/CircularProgressComponent'
import useController from './EditUser.controller'
import ComboBox from '../../utils/MatrialUiComponents/ComboBox/ComboBox'
import FormController from '../../utils/FormController/FormController'

const EditUser = (props) => {
  const {
    onclose,
    user,
    auth,
    t,
    open,
    handleEdit,
    statusList,
    form,
    validateValue,
    validateUserName,
    validateEmail,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  // render methode
  const renderEditGrid = () =>
    user ? (
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <FormProvider {...form}>
          <FormController
            name="userName"
            validate={validateUserName}
            render={({ value, error, onChange }) => (
              <InputComponent
                variant={
                  auth && !getPermissions(auth.type, 'editUserName')
                    ? 'filled'
                    : 'outlined'
                }
                label={t('users.nameInput')}
                type="text"
                name="userName"
                error={error}
                value={value}
                onChange={onChange}
                disabled={auth && !getPermissions(auth.type, 'editUserName')}
                required
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="email"
            validate={validateEmail}
            render={({ value, error, onChange }) => (
              <InputComponent
                variant={
                  auth && !getPermissions(auth.type, 'editUserEmail')
                    ? 'filled'
                    : 'outlined'
                }
                label={t('users.emailInput')}
                type="email"
                name="email"
                error={error}
                value={value}
                onChange={onChange}
                disabled={auth && !getPermissions(auth.type, 'editUserEmail')}
                fullWidth
                margin="normal"
              />
            )}
          />
          <FormController
            name="status"
            render={({ value, error, onChange }) => (
              <ComboBox
                autoComplete
                margin="normal"
                fullWidth
                error={error}
                variant={
                  auth && !getPermissions(auth.type, 'editStatus')
                    ? 'filled'
                    : 'outlined'
                }
                disabled={auth && !getPermissions(auth.type, 'editStatus')}
                onChange={(e, newValue) => {
                  onChange(newValue)
                }}
                value={value || ''}
                name="status"
                options={statusList}
                getOptionSelected={(option, v) => option.name === v.name}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                label={t('users.status')}
              />
            )}
          />
          {user && user.type && user.type === 'Owner' ? (
            <FormController
              name="ref"
              validate={validateValue}
              render={({ value, error, onChange }) => (
                <InputComponent
                  variant={
                    auth && !getPermissions(auth.type, 'editUserRef')
                      ? 'filled'
                      : 'outlined'
                  }
                  label={t('users.refInput')}
                  type="text"
                  name="ref"
                  error={error}
                  value={value}
                  onChange={onChange}
                  disabled={auth && !getPermissions(auth.type, 'editUserRef')}
                  fullWidth
                  margin="normal"
                />
              )}
            />
          ) : null}
          <Grid
            item
            container
            direction="row"
            spacing={4}
            alignItems="center"
            className={classes.gridItemBtn}
          >
            <Grid item>
              <ButtonComponent
                className={classes.btn}
                tooltiptitle={t('v_detail.box_Disagree')}
                onClick={onclose}
                startIcon={<CancelIcon />}
              >
                {t('v_detail.box_Disagree')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                tooltiptitle={t('v_detail.box_AgreeSave')}
                type="submit"
                startIcon={<SaveIcon />}
                className={classes.btn}
                onClick={form.handleSubmit(handleEdit)}
              >
                {t('v_detail.box_AgreeSave')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </FormProvider>

        <BackdropComponent className={classes.backdrop} open={open} />
      </Grid>
    ) : (
      <CircularProgressComponent className={classes.circularProgress} />
    )
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      m={2}
      width="auto"
    >
      {renderEditGrid()}
    </Box>
  )
}
export default EditUser
