import React from 'react'
// eslint-disable-next-line import/no-cycle
import { drawerActions } from '../../actions'
// eslint-disable-next-line import/no-cycle
import FleetDetails from '../../components/Fleets/FleetDetails/FleetDetails'
// eslint-disable-next-line import/no-cycle
import FleetsReport from '../../components/Fleets/FleetsReport/FleetsReport'
const ShowFleetDetails = async (dispatch, t, fleet) => {
  dispatch(
    drawerActions.show(
      <FleetDetails fleet={fleet} />,
      t('table.v_fleet'),
      'right',
      'persistent',
      'ltr',
    ),
  )
}
const ShowFleetReport = (fleet, dispatch, t) => {
  const onClose = () => ShowFleetDetails(dispatch, t, fleet)
  dispatch(
    drawerActions.show(
      <FleetsReport fleet={fleet[0]} onclose={onClose} drawer />,
      t('table.v_fleetReport'),
      'right',
      'persistent',
      'rtl',
      null,
      onClose,
    ),
  )
}

export const FleetReportHelpers = {
  ShowFleetReport,
  ShowFleetDetails,
}
