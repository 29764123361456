import React, { createRef, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import StylesWithMaterialUi from '../../EditDocuments.styles'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import useController from './RegistrationCard.controller'

const RegistrationCard = (props) => {
  const {
    a,
    b,
    c1,
    c3,
    c41,
    d1,
    d2,
    d21,
    d3,
    e,
    f1,
    f2,
    f3,
    g,
    g1,
    h,
    handleChange,
    i,
    j,
    j1,
    j2,
    j3,
    k,
    p1,
    p2,
    p3,
    p6,
    q,
    s1,
    s2,
    t,
    u1,
    u2,
    v7,
    v9,
    x1,
    y1,
    y2,
    y3,
    y4,
    y5,
    y6,
    z1,
    z2,
    z3,
    z4,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  const InputModel = useCallback(
    (value, name, required) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={handleChange}
            type="text"
            name={name}
            fullWidth
            margin="normal"
            required={required}
          />
        </Grid>
      )
    },
    [classes.gridItem, handleChange, t],
  )

  return useMemo(
    () => (
      <>
        {InputModel(a, 'a', false)}
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            fullWidth
            margin="normal"
            variant="outlined"
            label={t(`documents.b`)}
            value={b}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            type="date"
            name="b"
          />
        </Grid>
        {InputModel(c1, 'c1', false)}
        {InputModel(c41, 'c41', false)}
        {InputModel(c3, 'c3', false)}
        {InputModel(d1, 'd1', false)}
        {InputModel(d2, 'd2', false)}
        {InputModel(d21, 'd21', false)}
        {InputModel(d3, 'd3', false)}
        {InputModel(e, 'e', false)}
        {InputModel(f1, 'f1', false)}
        {InputModel(f2, 'f2', false)}
        {InputModel(f3, 'f3', false)}
        {InputModel(g, 'g', false)}
        {InputModel(g1, 'g1', false)}
        {InputModel(h, 'h', false)}
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            fullWidth
            margin="normal"
            variant="outlined"
            label={t(`documents.i`)}
            value={i}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            type="date"
            name="i"
          />
        </Grid>
        {InputModel(j, 'j', false)}
        {InputModel(j1, 'j1', false)}
        {InputModel(j2, 'j2', false)}
        {InputModel(j3, 'j3', false)}
        {InputModel(k, 'k', false)}
        {InputModel(p1, 'p1', false)}
        {InputModel(p2, 'p2', false)}
        {InputModel(p3, 'p3', false)}
        {InputModel(p6, 'p6', false)}
        {InputModel(q, 'q', false)}
        {InputModel(s1, 's1', false)}
        {InputModel(s2, 's2', false)}
        {InputModel(u1, 'u1', false)}
        {InputModel(u2, 'u2', false)}
        {InputModel(v7, 'v7', false)}
        {InputModel(v9, 'v9', false)}
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            fullWidth
            margin="normal"
            variant="outlined"
            label={t(`documents.x1`)}
            value={x1}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            type="date"
            name="x1"
          />
        </Grid>
        {InputModel(y1, 'y1', false)}
        {InputModel(y2, 'y2', false)}
        {InputModel(y3, 'y3', false)}
        {InputModel(y4, 'y4', false)}
        {InputModel(y5, 'y5', false)}
        {InputModel(y6, 'y6', false)}
        {InputModel(z1, 'z1', false)}
        {InputModel(z2, 'z2', false)}
        {InputModel(z3, 'z3', false)}
        {InputModel(z4, 'z4', false)}
      </>
    ),
    [
      InputModel,
      a,
      b,
      c1,
      c3,
      c41,
      classes.gridItem,
      d1,
      d2,
      d21,
      d3,
      e,
      f1,
      f2,
      f3,
      g,
      g1,
      h,
      handleChange,
      i,
      j,
      j1,
      j2,
      j3,
      k,
      p1,
      p2,
      p3,
      p6,
      q,
      s1,
      s2,
      t,
      u1,
      u2,
      v7,
      v9,
      x1,
      y1,
      y2,
      y3,
      y4,
      y5,
      y6,
      z1,
      z2,
      z3,
      z4,
    ],
  )
}
export default RegistrationCard
