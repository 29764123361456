// eslint-disable-next-line import/no-cycle
import { localStorageService } from '../system/local-storage.service'
// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const refreshAuthToken = (refreshToken) => {
  const route = '/auth/refreshToken'

  const body = {
    refreshToken,
  }
  return axiosService.post(false, route, null, body)
}
const login = (email, password) => {
  const route = '/auth/login'

  const body = {
    email: email.toLowerCase(),
    password,
  }
  return axiosService.post(false, route, null, body)
}
const loginGoogle = (body) => {
  const route = '/auth/login/google'

  return axiosService.post(false, route, null, body)
}

const resetPassword = (body) => {
  const route = '/auth/resetPassword'
  return axiosService.post(false, route, null, body)
}
const activateUser = (body) => {
  const route = '/auth/activateUser'
  return axiosService.post(false, route, null, body)
}

const logout = () => {
  localStorageService.remove('userAuth')
  localStorageService.remove('user')
}

const requestResetPassword = (query) => {
  const route = 'auth/resetPassword'
  return axiosService.get(false, route, query)
}

export const authenticationService = {
  login,
  loginGoogle,
  logout,
  refreshAuthToken,
  requestResetPassword,
  resetPassword,
  activateUser,
}
