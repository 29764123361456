import React from 'react'
import ReactDOM from 'react-dom'
import ReactPWAInstallProvider from 'react-pwa-install'
import { Provider } from 'react-redux'
import { frFR } from '@material-ui/core/locale'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import config from './initializers/_config'
import App from './pages/App/App'
import './services/system/I18n.service'
import './index.css'

import { persistor, store } from './helpers'
config.init()

const theme = createTheme(
  {
    typography: {
      fontFamily: ['Comfortaa', 'cursive', 'Poppins', 'sans-serif'].join(','),
    },
    fontWeight: ['normal', 'bold'].join(','),
  },
  frFR,
)

ReactDOM.render(
  <React.StrictMode>
    <ReactPWAInstallProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ReactPWAInstallProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
