/* eslint-disable import/no-cycle */
import { settingsConstants } from '../constants'
import { getRouter } from '../helpers/settings/settings.helpers'

function add(settings) {
  const router = getRouter(settings)
  return {
    type: settingsConstants.ADD,
    payload: {
      settings,
      router,
    },
  }
}

function update(settings) {
  const router = getRouter(settings)

  return {
    type: settingsConstants.UPDATE,
    payload: {
      settings,
      router,
    },
  }
}

function updateMapSettings(label, value) {
  return {
    type: settingsConstants.UPDATE_MAP,
    payload: {
      label,
      value,
    },
  }
}
function updateMapTooltip(tooltip) {
  return {
    type: settingsConstants.UPDATE_MAP_TOOLTIP,
    payload: {
      tooltip,
    },
  }
}
export const settingsActions = {
  add,
  update,
  updateMapSettings,
  updateMapTooltip,
}
