/* eslint-disable import/no-cycle */
import React from 'react'
import battery0 from '../../assets/images/map/battery_0.png'
import battery1 from '../../assets/images/map/battery_1.png'
import battery2 from '../../assets/images/map/battery_2.png'
import battery3 from '../../assets/images/map/battery_3.png'
import battery4 from '../../assets/images/map/battery_4.png'
import battery5 from '../../assets/images/map/battery_5.png'
import VehicleDetails from '../../components/Vehicles/VehiclesDetails/VehicleDetails'
import { fullResponse } from '../system/response.helpers'
import { vehiclesService } from '../../services/vehicles/vehicles.service'
import { alertActions, drawerActions, vehiclesActions } from '../../actions'
/** < 0.1  -> #B22C37 red 0
>= 0.1 && < 0.3 -> #EE2C37 red 1 
>= 0.3 && soc< 0.5 -> #F36832 orange 2
>= 0.5 && soc< 0.7 -> #FCD90D jaune 3 
>= 70 &&soc < 0.9 -> #AACE37 green clair 4
>= 0.9 -> #46b648 green  5 */
const GetBatteryIcon = (soc) => {
  const style = { width: '22px', height: '22px' }
  if (soc) {
    let icon
    if (soc >= 0.9) {
      icon = <img src={battery5} alt="battery" style={style} />
    } else if (soc >= 0.7 && soc < 0.9) {
      icon = <img src={battery4} alt="battery" style={style} />
    } else if (soc >= 0.5 && soc < 0.7) {
      icon = <img src={battery3} alt="battery" style={style} />
    } else if (soc >= 0.3 && soc < 0.5) {
      icon = <img src={battery2} alt="battery" style={style} />
    } else if (soc >= 0.1 && soc < 0.3) {
      icon = <img src={battery1} alt="battery" style={style} />
    }
    if (soc < 0.1) {
      icon = <img src={battery0} alt="battery" style={style} />
    }

    return icon
  }
  return null
}

const ShowVehicleDetails = async (
  e,
  id,
  dispatch,
  t,
  onClose,
  header,
  classes,
  className,
  alertsType,
) => {
  try {
    if (id) {
      const query = { id }
      const res = await vehiclesService.getVehicles(query)
      if (fullResponse(res, 200)) {
        const data = res.content.data[0]
        dispatch(
          drawerActions.show(
            <VehicleDetails vehicle={data} alerts={alertsType} />,
            t('v_detail.v_title'),
            'right',
            'persistent',
            'ltr',
            header,
            onClose,
            classes,
            className,
          ),
        )
        return
      }

      if (fullResponse(res, 204)) {
        dispatch(alertActions.info(t('v_detail.notFound'), 6000, true))
        return
      }
      dispatch(alertActions.error(res.content.data.message, 6000, true))
    }
  } catch (error) {
    dispatch(vehiclesActions.failure())
    dispatch(alertActions.error(error.message.toString(), 6000, true))
  }
}
const ShowVehicleDetailsBySearch = async (
  e,
  search,
  dispatch,
  t,
  onClose,
  header,
  classes,
  className,
  alertsType,
) => {
  try {
    if (search) {
      const query = { search }
      const res = await vehiclesService.getVehicles(query)
      if (fullResponse(res, 200)) {
        const data = res.content.data[0]
        dispatch(
          drawerActions.show(
            <VehicleDetails vehicle={data} alerts={alertsType} />,
            t('v_detail.v_title'),
            'right',
            'persistent',
            'ltr',
            header,
            onClose,
            classes,
            className,
          ),
        )
        return
      }

      if (fullResponse(res, 204)) {
        dispatch(alertActions.info(t('v_detail.notFound'), 6000, true))
        return
      }
      dispatch(alertActions.error(res.content.data.message, 6000, true))
    }
  } catch (error) {
    dispatch(vehiclesActions.failure())
    dispatch(alertActions.error(error.message.toString(), 6000, true))
  }
}
export const VehicleDetailsHelpers = {
  ShowVehicleDetails,
  ShowVehicleDetailsBySearch,
  GetBatteryIcon,
}
