export const permissionsConstants = {
  accounts: {
    Vehicle: {
      // vehicle trips
      distanceTraveled: true,
      // vehicle
      customReportExport: true,
      // fleet
      // Documents
      showDocument: true,
      // drawer
      openUserDrawer: true,
      // socket
      subscribeAsVehicle: true,
      // alert
      // header
      showVehicleIconInHeader: true,
      // global
      showDrawerInMap: true,
      hasOwnerId: true,
      refreshBtn: true,
      read: true,
      readAndWrite: true,
      mapSettingBtn: true,
      // users
    },
    User: {
      // vehicle trips
      distanceTraveled: true,
      // vehicle
      customReportExport: true,
      showCommandesBtn: true,
      showCommandes: true,

      // fleet
      createFleet: true,
      editFleet: true,
      deleteFleet: true,
      // Documents
      allowToEditDocument: true,
      showDocument: true,
      allowToDeleteDocument: true,
      // drawer
      openVehicleDrawer: true,
      // socket
      subscribeWithOwner: true,
      // alert
      alerts: true,
      // header
      // global
      gotoProfilePage: true,
      hasOwnerId: true,
      refreshBtn: true,
      showFilterInMap: true,
      read: true,
      write: true,
      readAndWrite: true,
      mapSettingBtn: true,
      // users
    },
    Owner: {
      // vehicle trips
      serverFilter: true,
      distanceTraveled: true,
      // vehicle
      showCommandesBtn: true,
      showCommandes: true,
      showQr: true,
      createVehicle: true,
      useVehicle: true,
      customReportExport: true,
      immobilize: true,
      avaibleStates: true,
      restoreMaintenances: true,
      editVin: true,
      editRef: true,
      editVehicleFleet: true,
      editVehicleImmat: true,
      editVehicleModel: true,
      editVehicleGateway: true,
      editVehicleOdometer: true,
      controlBtn: true,

      // fleet
      createFleet: true,
      editFleet: true,
      deleteFleet: true,
      // Documents
      addDocument: true,
      allowToEditDocument: true,
      showDocument: true,
      allowToDeleteDocument: true,
      // drawer
      openGatewaysDrawer: true,
      openVehicleDrawer: true,
      openUserDrawer: true,
      openFleetDrawer: true,
      // socket
      subscribeAsOwner: true,
      // alert
      alerts: true,
      // header
      // global
      gotoProfilePage: true,
      refreshBtn: true,
      showFilterInMap: true,
      read: true,
      write: true,
      readAndWrite: true,
      mapSettingBtn: true,
      // users
      editUserName: true,
      editUserEmail: true,
      editUserRef: true,
      createUser: true,
      CustomList: true,
      alertsSettings: true,
    },
    Admin: {
      // vehicle trips
      serverFilter: true,
      distanceGps: true,
      distanceOdometer: true,
      // vehicle
      ambassador: true,
      showQr: true,
      useVehicle: true,
      showCommandes: true,
      showCommandesBtn: true,
      immobilize: true,
      deleteVehicle: true,
      restoreMaintenances: true,
      avaibleStates: true,
      createVehicle: true,
      createVehicleWithOwner: true,
      editVin: true,
      editRef: true,
      editVehicleFleet: true,
      editVehicleImmat: true,
      editVehicleModel: true,
      editVehicleGateway: true,
      editVehicleOdometer: true,
      controlBtn: true,
      // fleet
      createFleet: true,
      createFleetWithOwner: true,
      editFleet: true,
      deleteFleet: true,
      // Documents
      addDocument: true,
      allowToEditDocument: true,
      showDocument: true,
      allowToDeleteDocument: true,
      // drawer
      GatewaysPage: true,
      openGatewaysDrawer: true,
      openVehicleDrawer: true,
      openUserDrawer: true,
      openFleetDrawer: true,
      // socket
      subscribeAll: true,
      // alert
      alerts: true,
      // header
      // global
      gotoProfilePage: true,
      refreshBtn: true,
      showFilterInMap: true,
      read: true,
      write: true,
      showOwner: true,
      readAndWrite: true,
      mapSettingBtn: true,
      // users
      editUserName: true,
      editUserEmail: true,
      editUserRef: true,
      editStatus: true,
      editType: true,
      editUserOwner: true,
      createUserWithOwner: true,
      createUser: true,
    },
  },
  ReadAndWriteList: ['Owner', 'Admin', 'User'],
  restrictedTypesEditDocumentsList: ['Admin'],
  restrictedTypesWebMaster: ['Admin', 'Owner'],
  restrictedTypesDeleteDocumentsList: ['Admin'],
  gatewaysPage: ['Admin'],
}
