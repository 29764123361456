import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles((theme) => ({
  expandTitle: {
    fontFamily: 'Comfortaa',
    fontSize: '14px',
    fontWeight: 'Bold',
  },
  expandBody: { fontFamily: 'Poppins', fontSize: '12px' },
  useBtn: {
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
    },
  },
  refreshBtnWithoutMargin: {
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
    },
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
    },
  },
  refreshBtn: {
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'Bold',
      fontFamily: 'Comfortaa',
      marginLeft: theme.spacing(2),
    },
  },
  horizontalLine: {
    borderLeft: '1px solid gray',
    height: '40px',
  },
  border: {
    [theme.breakpoints.down('sm')]: {
      borderTop: ' 2px solid #b3b3b3',
      borderBottom: ' 2px solid #b3b3b3',
    },
  },
  table: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  large: {
    width: '90%',
    maxWidth: 140,
    height: '100%',
    maxHeight: 140,
    borderRadius: 10,
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
    fontSize: theme.typography.pxToRem(16),
  },
  gridRoot: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressMaintenances: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridItemNotFound: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItemName: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  gridItemProgress: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  gridItemDelete: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  LinearProgress: {
    width: '70%',
  },
  iconSize: { fontSize: 30 },

  iconGrid: {
    width: '30%',
  },
  gridMap: {
    display: 'flex',
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '15px',
  },
  inputBold: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: '15px',
  },
  inputRating: {
    width: '50%',
  },
  rating: {
    width: '50%',
  },

  title: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Comfortaa',
  },

  mapContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      height: '300px',
      width: '100%',
    },
  },
  mapContainerFullScreen: {
    height: '100%',
    width: '100%',
  },
  btnGroup: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.between('md', 'xl')]: {
      marginRight: theme.spacing(5),
    },
  },
  button: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '190px',
    height: '31px',
  },
  buttonFull: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '100%',
    height: '31px',
  },
  buttonSmall: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: '31px',
  },
  inlineList: {
    display: 'block',
  },
  gridList: {
    overflow: 'auto',
    width: '100%',
    height: '200px',
  },
}))
export default StylesWithMaterialUi
