/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useController = (props) => {
  const { vehicle } = props

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const user = useSelector((s) => s.authentication.user)

  useEffect(() => {
    const abortController = new AbortController()
    const getStates = () => {
      setLoading(true)
      if (vehicle && vehicle.states) {
        setData(Object.entries(vehicle.states))
      }
      setLoading(false)
    }
    getStates()

    return () => {
      abortController.abort()
    }
  }, [vehicle])

  return {
    loading,
    t,
    user,
    data,
  }
}
export default useController
