/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useReducer, useState } from 'react'
import * as turf from '@turf/turf'
import { useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import {
  firstElement,
  fullList,
  lastElement,
} from '../../../../../../../helpers'
import { linesReducer } from '../redux'

const useController = (props) => {
  const { response, tripLines, setViewport, trip } = props

  const map = useMap()
  const { t } = useTranslation()

  const [startMarker, setStartMarker] = useState(null)
  const [lastMarker, setLastMarker] = useState(null)
  const [lines, setLines] = useReducer(linesReducer, {
    coords: tripLines,
  })

  const fetchBound = useCallback(
    (point) => {
      const mapBBox = map.getBounds()
      const bbox = [
        mapBBox._northEast.lat,
        mapBBox._northEast.lng,
        mapBBox._southWest.lat,
        mapBBox._southWest.lng,
      ]
      const poly = turf.bboxPolygon(bbox)
      const pt = turf.point(point)

      if (!turf.booleanPointInPolygon(pt, poly)) {
        const lineString = turf.lineString(lines.coords)
        const newBbox = turf.bbox(lineString)
        setViewport({
          bounds: [
            [newBbox[0], newBbox[1]],
            [newBbox[2], newBbox[3]],
          ],
          zoomType: true,
          default: false,
        })
        map.fitBounds([
          [newBbox[0], newBbox[1]],
          [newBbox[2], newBbox[3]],
        ])
      }
    },
    [lines.coords, map, setViewport],
  )
  useEffect(() => {
    const abortController = new AbortController()
    const fetchMarker = () => {
      if (fullList(lines.coords)) {
        setStartMarker(lastElement(lines.coords))
        setLastMarker(firstElement(lines.coords))
        fetchBound(firstElement(lines.coords))
      }
    }
    fetchMarker()
    return () => {
      abortController.abort()
    }
  }, [fetchBound, lines])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchLines = () => {
      if (
        response &&
        response.coords &&
        trip.vehicle.id === response.vehicleId
      ) {
        setLines({
          type: 'UPDATE_LINES',
          coords: [response.coords[1], response.coords[0]],
        })
      }
    }
    fetchLines()
    return () => {
      abortController.abort()
    }
  }, [response, trip])
  const renderIcon = useCallback(() => {
    if (trip.status === 'InProgress') {
      const { vehicle } = trip
      return vehicle && vehicle.model
        ? vehicle.model.icon
        : 'fiber_manual_record'
    }
    return 'flag'
  }, [trip])

  return { lines, startMarker, lastMarker, t, renderIcon, trip }
}

export default useController
