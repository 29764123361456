/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { routerConstants } from '../../../../../constants'
import { drawerActions, routerActions } from '../../../../../actions'
import RestoreVehicleMaintenance from '../../../RestoreVehicleMaintenance/RestoreVehicleMaintenance'
import { vehiclesService } from '../../../../../services'
import { fullResponse, VehicleDetailsHelpers } from '../../../../../helpers'
import { compare } from './vehicleMaintenances.helpers'

const useController = (props) => {
  const { vehicle, expanded } = props
  const { t } = useTranslation()
  const user = useSelector((state) => state.authentication.user)
  const dispatch = useDispatch()
  const [maintenances, setMaintenances] = useState(null)
  const [maintenancesLoading, setMaintenancesLoading] = useState(true)
  const fetchMaintenances = useCallback(async () => {
    if (vehicle && expanded) {
      setMaintenancesLoading(true)
      const res = await vehiclesService.getMaintenances(vehicle.id)
      if (fullResponse(res, 200)) {
        setMaintenances(res.content.data.sort(compare))
        setMaintenancesLoading(false)
        return
      }
      setMaintenances(null)
      setMaintenancesLoading(false)
    }
  }, [expanded, vehicle])

  useEffect(() => {
    const abortController = new AbortController()
    const fetch = async () => {
      await fetchMaintenances()
    }
    fetch()
    return () => {
      abortController.abort()
    }
  }, [fetchMaintenances])

  const onClose = useCallback(
    (e) => {
      VehicleDetailsHelpers.ShowVehicleDetails(e, vehicle.id, dispatch, t)
    },
    [dispatch, t, vehicle],
  )
  const restoreMaintenances = useCallback(
    (id, descriptionName) => {
      dispatch(routerActions.push(routerConstants.VEHICLE, vehicle.id))
      dispatch(
        drawerActions.show(
          <RestoreVehicleMaintenance
            vehicle={vehicle}
            onclose={onClose}
            descriptionName={descriptionName}
            id={id}
            fetch={fetchMaintenances}
          />,
          t('v_detail.maintenanceOperation'),
          'right',
          'persistent',
          'rtl',
          null,
          onClose,
        ),
      )
    },
    [dispatch, vehicle, onClose, fetchMaintenances, t],
  )

  return { maintenancesLoading, maintenances, restoreMaintenances, user, t }
}
export default useController
