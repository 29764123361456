/* eslint-disable import/no-cycle */
import { alertConstants } from '../constants'

export const alertActions = {
  success,
  error,
  clear,
  info,
}

function success(message, autoHideDuration, open) {
  return {
    type: alertConstants.SUCCESS,
    payload: { message, autoHideDuration, open },
  }
}

function error(message, autoHideDuration, open) {
  return {
    type: alertConstants.ERROR,
    payload: { message, autoHideDuration, open },
  }
}
function info(message, autoHideDuration, open) {
  return {
    type: alertConstants.INFO,
    payload: { message, autoHideDuration, open },
  }
}

function clear(open) {
  return { type: alertConstants.CLEAR, payload: { open } }
}
