/* eslint-disable import/no-cycle */
import { drawerConstants } from '../constants'
const initialState = {
  open: false,
  title: null,
  anchor: null,
  variant: null,
  direction: null,
  header: null,
  body: null,
  otherProps: null,
}
const drawer = (state = initialState, action) => {
  switch (action.type) {
    case drawerConstants.SHOW:
      return {
        open: true,
        body: action.payload.body,
        anchor: action.payload.anchor,
        variant: action.payload.variant,
        direction: action.payload.direction,
        title: action.payload.title,
        header: action.payload.header,
        onClose: action.payload.onClose,
        classes: action.payload.classes,
        className: action.payload.className,
      }
    case drawerConstants.CLOSE:
      return initialState

    default:
      return state
  }
}

export { drawer }
