import { makeStyles } from '@material-ui/core/styles'
const StylesWithMaterialUi = makeStyles(() => ({
  button: {
    fontSize: '12px',
    fontWeight: 'Bold',
    fontFamily: 'Comfortaa',
    textTransform: 'none',
    width: '110px',
    height: '31px',
  },
}))
export default StylesWithMaterialUi
