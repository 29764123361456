import { Dialog, DialogContent, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { QRCode } from 'react-qrcode-logo'
import { useTranslation } from 'react-i18next'
import PrintIcon from '@material-ui/icons/Print'
import GetAppIcon from '@material-ui/icons/GetApp'
import StylesWithMaterialUi from './BtnGroup.styles'
import ButtonComponent from '../../../../utils/MatrialUiComponents/Button/ButtonComponent'

const QrCodeComponents = (props) => {
  const { data, open, cancel, state, print, download } = props

  const { t } = useTranslation()
  const classes = StylesWithMaterialUi()

  return (
    <Dialog open={open} onClose={cancel} className={classes.dialog}>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          className={classes.gridContainer}
          style={{ marginTop: '5px' }}
        >
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            id="canvas"
          >
            <Grid
              item
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <QRCode
                {...{
                  ...state,
                }}
              />
            </Grid>

            <Grid
              item
              container
              direction="column"
              spacing={1}
              alignItems="center"
              className={classes.gridContainerQrText}
            >
              {data.ref ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography className={classes.text}>
                      {t('btnGroup.ref')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textBody}>
                      {data.ref}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {data.vin ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography className={classes.text}>
                      {t('btnGroup.vin')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textBody}>
                      {data.vin}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {data.name ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography className={classes.text}>
                      {t('btnGroup.name')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textBody}>
                      {data.name}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {data.numberplate ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  className={classes.gridItem}
                >
                  <Grid item>
                    <Typography className={classes.text}>
                      {t('btnGroup.v_numberplate')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textBody}>
                      {data.numberplate}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            className={classes.btnGroup}
          >
            <Grid item>
              <ButtonComponent
                styles
                className={classes.btnQr}
                startIcon={<PrintIcon />}
                onClick={() => {
                  print()
                }}
                tooltiptitle={t('btnGroup.print')}
              >
                {t('btnGroup.print')}
              </ButtonComponent>
            </Grid>
            <Grid item>
              <ButtonComponent
                styles
                className={classes.btnQr}
                startIcon={<GetAppIcon />}
                onClick={() => {
                  download()
                }}
                tooltiptitle={t('btnGroup.download')}
              >
                {t('btnGroup.download')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default QrCodeComponents
