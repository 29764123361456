/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'
import { documentsService, filesService } from '../../../services'
import { alertActions } from '../../../actions'
import { emptyResponse } from '../../../helpers'

const useController = (props) => {
  const { t } = useTranslation()
  const { onclose, document } = props
  const [inputs, setInputs] = useState({
    docName: '',
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const [removedFiles, setRemovedFiles] = useState([])
  const [open, setOpen] = useState(false)
  const { docName } = inputs
  const socket = useSelector((state) => state.socket)
  const dispatch = useDispatch()
  const location = useLocation()

  const addToRemoveList = useCallback(
    (id) => {
      const x = removedFiles.find((item) => item.id === id)
      if (x) {
        setRemovedFiles(removedFiles.filter((item) => item.id !== x.id))
        return
      }
      setRemovedFiles((filesIds) => [...filesIds, { id }])
    },
    [removedFiles],
  )

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }, [])

  const removeFilesFromDocuments = useCallback(async () => {
    if (document.id) {
      const body = { fileIds: removedFiles }
      const removeFiles = await documentsService.deleteFiles(document.id, body)
      if (emptyResponse(removeFiles, 204)) {
        setOpen(false)
        dispatch(
          alertActions.error(
            removeFiles && removeFiles.content
              ? removeFiles.content.message
              : t('global.error'),
            6000,
            true,
          ),
        )
      }
    }
  }, [dispatch, document.id, removedFiles, t])

  const addFilesToDocuments = useCallback(
    async (files) => {
      if (document.id) {
        const res = await documentsService.addFiles(document.id, { files })
        if (emptyResponse(res, 201)) {
          setOpen(false)
          dispatch(
            alertActions.error(
              res && res.content ? res.content.message : t('global.error'),
              6000,
              true,
            ),
          )
        }
      }
    },
    [dispatch, document.id, t],
  )

  const updateDocuments = useCallback(async () => {
    const body = {}
    if (docName) {
      body.name = docName
    }

    const res = await documentsService.updateDocuments(document.id, body)
    if (!res || res.status !== 201) {
      setOpen(false)
      dispatch(alertActions.error(res.content.message, 6000, true))
    }
  }, [dispatch, docName, document.id])

  const postFiles = useCallback(async () => {
    const formData = new FormData()
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i])
    }

    return filesService.postFiles(formData)
  }, [selectedFiles])

  const checkFilesId = useCallback(
    (id) => {
      if (removedFiles.length > 0) {
        const x = removedFiles.find((o) => o.id === id)
        if (x) {
          return true
        }
      }

      return false
    },
    [removedFiles],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const setDoc = () => {
      if (document) {
        setInputs({ docName: document.name })
      }
    }
    setDoc()
    return () => {
      abortController.abort()
    }
  }, [document])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (document) {
        setOpen(true)
        let files = []
        if (selectedFiles.length > 0) {
          const res = await postFiles()
          if (emptyResponse(res, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                res && res.content ? res.content.message : t('global.error'),
                6000,
                true,
              ),
            )

            return
          }
          files = res.content.data.files
        }

        await updateDocuments()
        if (files.length > 0) {
          await addFilesToDocuments(files)
        }
        if (removedFiles.length > 0) {
          await removeFilesFromDocuments()
        }
        setOpen(false)
        onclose()

        return
      }
      setOpen(false)
      dispatch(alertActions.error(t('documents.error'), 5000, true))
    },
    [
      addFilesToDocuments,
      dispatch,
      document,
      onclose,
      postFiles,
      removeFilesFromDocuments,
      removedFiles.length,
      selectedFiles.length,
      t,
      updateDocuments,
    ],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])

  return {
    handleSubmit,
    t,
    docName,
    handleChange,
    selectedFiles,
    open,
    checkFilesId,
    addToRemoveList,
    setSelectedFiles,
  }
}

export default useController
