import React, { Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import StylesWithMaterialUi from './App.styles'
import CircularProgressComponent from '../../components/utils/MatrialUiComponents/CircularProgress/CircularProgressComponent'
const HeaderComponent = lazy(() => import('../../layouts/Header/Header'))

const App = () => {
  const classes = StylesWithMaterialUi()

  return (
    <BrowserRouter>
      <Suspense
        fallback={<CircularProgressComponent className={classes.root} />}
      >
        <HeaderComponent />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
