import L from 'leaflet'
import './vehiclesMarker.styles.css'

const pointsPolyline = (color) =>
  L.divIcon({
    className: 'custom-div-icon',
    html: `<span class='material-icons' style='color:${color};font-size: 10px'>circle</span>`,
    iconAnchor: [4, 8],
  })

const markerIcon = (iconColor, background, icon) =>
  L.divIcon({
    className: 'custom-div-icon',
    html: `<div style='background-color:${background};' class='marker-pin'></div><i class='material-icons' style='color:${iconColor};' >  ${icon} </i>`,
    iconAnchor: [12, 30],
    iconSize: [24, 24],
  })

const iconWithSoc = (soc, socColor, icon, iconColor) =>
  L.divIcon({
    className: 'custom-div-icon',
    html: `<div class="circle-wrap"><div  class="progressbar"
  aria-valuenow="${soc}"
  aria-valuemin="0"
  aria-valuemax="100"
  style="--value:${soc};--soc-color: ${socColor};">
  <i class='material-icons' id="icon" style='color:${iconColor};' > ${icon} </i>
  </div></div> `,
    iconSize: [30, 42],
    iconAnchor: [15, 36],
  })

const getSoc = (value) => {
  if (value && value.soc) {
    const { soc } = value
    return Math.min(Math.max(Math.round(soc * 100), 0), 100)
  }
  return Math.min(Math.max(Math.round(1 * 100), 0), 100)
}

const getSocColor = (value) => {
  if (value && value.soc) {
    const { soc } = value
    if (soc >= 0.9) {
      return '#46b648'
    }
    if (soc >= 0.7 && soc < 0.9) {
      return '#AACE37'
    }
    if (soc >= 0.5 && soc < 0.7) {
      return '#FCD90D'
    }
    if (soc >= 0.3 && soc < 0.5) {
      return '#F36832'
    }
    if (soc >= 0.1 && soc < 0.3) {
      return '#EE2C37'
    }
    if (soc < 0.1) {
      return '#B22C37'
    }
  }
  return '#000000'
}

const randomHexColor = (index) => {
  const colorList = [
    '#1D71B8',
    '#FF0000',
    '#FFFF00',
    '#00EAFF',
    '#AA00FF',
    '#FF7F00',
    '#0095FF',
    '#FF00AA',
    '#6AFF00',
    '#000000',
    '#8F2323',
    '#8F6A23',
    '#4F8F23',
    '#737373',
    '#795548',
    '#a457ed',
    '#bb5218',
    '#f032e6',
    '#f58231',
    '#808000',
  ]
  return colorList[index % colorList.length]
}

export {
  pointsPolyline,
  markerIcon,
  iconWithSoc,
  getSoc,
  getSocColor,
  randomHexColor,
}
