/* eslint-disable import/no-cycle */
import { getewaysConstants } from '../constants'

export const gatewaysActions = {
  success,
  init,
  failure,
  update,
  clear,
}

function success(gateways) {
  return { type: getewaysConstants.SUCCESS, payload: { gateways } }
}

function update(vehicle) {
  return {
    type: getewaysConstants.UPDATE,
    payload: {
      vehicle,
    },
  }
}

function init() {
  return { type: getewaysConstants.INIT }
}

function failure() {
  return { type: getewaysConstants.FAILURE }
}
function clear() {
  return { type: getewaysConstants.CLEAR }
}
