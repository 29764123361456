/* eslint-disable import/no-cycle */
import React from 'react'
import StylesWithMaterialUi from '../../../VehicleDetails.styles'
import OrderExpand from './OrdersExapnd'

const CustomOption = (handleLoadMore, count, CustomToolBar) => {
  const classes = StylesWithMaterialUi()
  return {
    responsive: 'vertical',
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    rowHover: true,
    fixedHeader: false,
    fixedSelectColumn: true,
    pagination: true,
    search: false,
    print: false,
    count,
    rowsPerPage: 4,
    rowsPerPageOptions: [],
    tableBodyMaxHeight: '400px',
    serverSide: true,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    filter: false,
    setRowProps: () => ({
      className: classes.border,
    }),
    customToolbar: () => <CustomToolBar />,
    renderExpandableRow: (rowData) => <OrderExpand rowData={rowData} />,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleLoadMore(tableState.page)
          break
        default:
      }
    },
  }
}
export default CustomOption
