/* eslint-disable import/no-cycle */
import { socketConstants } from '../constants'
import { socketService } from '../services'
import { getPermissions } from '../helpers'

export const socketActions = {
  initWithVehicle,
  init,
  subscribe,
  unsubscribe,
  close,
  setData,
  failed,
  reconnect,
}
function failed() {
  return { type: socketConstants.FAILED }
}
function setData(user) {
  const data = {
    clear: false,
    channels: {},
  }
  if (user && getPermissions(user.type, 'subscribeAll')) {
    data.channels.all = true
    return data
  }
  if (user && getPermissions(user.type, 'subscribeAsOwner')) {
    data.channels.owners = [user.id]
    return data
  }
  if (user && getPermissions(user.type, 'subscribeWithOwner')) {
    data.channels.owners = [user.ownerId]
    return data
  }
  if (user && getPermissions(user.type, 'subscribeAsVehicle')) {
    data.channels.vehicles = [user.id]
  }
  return data
}

function init(socket, data) {
  if (!socket) {
    return { type: socketConstants.FAILED }
  }
  const creationDate = new Date().toISOString()
  return { type: socketConstants.INIT, payload: { socket, data, creationDate } }
}

function initWithVehicle(socket, id) {
  if (!socket || !id) {
    return { type: socketConstants.FAILED }
  }
  const data = {
    clear: false,
    channels: { vehicles: [id] },
  }
  const creationDate = new Date().toISOString()
  return { type: socketConstants.INIT, payload: { socket, data, creationDate } }
}
function subscribe(socket, data) {
  if (!socket) {
    return { type: socketConstants.FAILED }
  }
  socketService.subscribe(socket, data)
  return { type: socketConstants.SUBSCRIBE, payload: { socket, data } }
}
function reconnect(socket, data) {
  if (!socket) {
    return { type: socketConstants.FAILED }
  }
  socketService.subscribe(socket, data)
  return { type: socketConstants.RECONNECT }
}

function unsubscribe(socket, data) {
  if (!socket) {
    return { type: socketConstants.FAILED }
  }
  socketService.unsubscribe(socket, data)
  return { type: socketConstants.UNSUBSCRIBE, payload: { socket } }
}

function close(socket) {
  socketService.disconnectSocket(socket)
  return { type: socketConstants.CLOSE }
}
