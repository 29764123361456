/* eslint-disable import/no-cycle */
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createRef, useCallback, useState } from 'react'
import { alertActions, drawerActions, fleetsActions } from '../../../actions'
import { fleetsService } from '../../../services'
import { emptyResponse } from '../../../helpers'

const useController = (props) => {
  const { fleetId, drawer } = props
  const refDialog = createRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const closeDrawer = useCallback(() => {
    if (drawer) {
      dispatch(drawerActions.close())
    }
  }, [dispatch, drawer])

  const updateRedux = useCallback(
    async (id) => {
      try {
        dispatch(fleetsActions.remove(id))
      } catch (error) {
        dispatch(fleetsActions.failure())
      }
    },
    [dispatch],
  )

  const handleDelete = useCallback(() => {
    setOpen(true)
  }, [])

  const handlePopup = useCallback(async () => {
    if (fleetId) {
      const res = await fleetsService.deleteFleets(fleetId)
      if (emptyResponse(res, 204)) {
        dispatch(alertActions.error(res.content.message, 6000, true))
        setOpen(false)
        return
      }
      setOpen(true)
      dispatch(alertActions.success(t('v_detail.v_delete_msg'), 6000, true))
      closeDrawer()
      await updateRedux(fleetId)

      return
    }
    setOpen(false)
    dispatch(alertActions.error(t('v_detail.v_delete_msg_id'), 6000, true))
    closeDrawer()
  }, [closeDrawer, dispatch, fleetId, t, updateRedux])

  const handleCancelPopup = useCallback(() => {
    setOpen(false)
  }, [])

  return { handleDelete, handleCancelPopup, open, handlePopup, refDialog, t }
}

export default useController
