/* eslint-disable import/no-cycle */
import { routerConstants } from '../constants'

export const routerActions = {
  push,
  pop,
  clear,
}

function push(page, id) {
  const stack = { page, id }
  return {
    type: routerConstants.PUSH,
    payload: {
      stack,
    },
  }
}

function pop() {
  return {
    type: routerConstants.POP,
  }
}
function clear() {
  return {
    type: routerConstants.CLEAR,
  }
}
