/* eslint-disable import/no-cycle */
import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { mapsConstants } from '../../constants'

const MapPlaceholder = () => (
  <p>
    <noscript>You need to enable JavaScript to see this map.</noscript>
  </p>
)
const Map = (props) => {
  const {
    children,
    zoomType,
    zoom,
    center,
    bounds,

    ...other
  } = props

  return zoomType ? (
    <MapContainer {...other} bounds={bounds} placeholder={<MapPlaceholder />}>
      <TileLayer
        attribution={mapsConstants.attribution}
        url={mapsConstants.url}
      />
      {children}
    </MapContainer>
  ) : (
    <MapContainer
      {...other}
      center={center}
      zoom={zoom}
      placeholder={<MapPlaceholder />}
    >
      <TileLayer
        attribution={mapsConstants.attribution}
        url={mapsConstants.url}
      />
      {children}
    </MapContainer>
  )
}

export default Map
