// eslint-disable-next-line import/no-cycle
import { socketService } from '../../services'
// eslint-disable-next-line import/no-cycle
import { socketActions } from '../../actions'

const closeSocket = (socket) => {
  const subscribe = localStorage.getItem('subscribe')
  const subscribeType = localStorage.getItem('subscribeType')
  if (subscribe === 'true') {
    const id = localStorage.getItem('subscribeId')
    if (socket.status && socket.socket && id) {
      const data = {
        clear: false,
      }
      if (subscribeType === 'Trip') {
        data.channels = {
          vehicleTrips: [id],
        }
      } else {
        data.channels = {
          vehicles: [id],
        }
      }
      socketService.unsubscribe(socket.socket, data)
      localStorage.setItem('subscribe', false)
      localStorage.removeItem('subscribeId')
      localStorage.removeItem('subscribeType')
    }
  }
}

const closeSocketForMap = (socket, dispatch, location, home) => {
  if (socket.subscribe && location !== home) {
    dispatch(socketActions.unsubscribe(socket.socket, socket.data))
  }
  closeSocket(socket)
}
const checkValidationSocket = (socketCreationDate) => {
  const currentDate = new Date()
  return currentDate > socketCreationDate
}
export { closeSocket, closeSocketForMap, checkValidationSocket }
