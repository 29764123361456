/* eslint-disable import/no-cycle */
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EditFleetHelpers, FleetReportHelpers } from '../../../helpers'
import { drawerActions, routerActions, socketActions } from '../../../actions'
import { closeSocket } from '../../../helpers/socket/socket.helpers'
import { routerConstants } from '../../../constants'

const useController = (props) => {
  const { fleet } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const socket = useSelector((state) => state.socket)
  const settings = useSelector((s) => s.settings)

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      if (socket.subscribe) {
        dispatch(socketActions.unsubscribe(socket.socket, socket.data))
      }
      closeSocket(socket)
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, socket])

  const onCloseDrawer = useCallback(() => {
    dispatch(drawerActions.close())
  }, [dispatch])

  const showFleetEdit = useCallback(
    (item) => {
      dispatch(routerActions.push(routerConstants.FLEET, item.id))
      EditFleetHelpers.ShowFleetEdit(item, dispatch, t)
    },
    [dispatch, t],
  )
  const showFleetReport = useCallback(() => {
    dispatch(routerActions.push(routerConstants.FLEET, fleet[0].id))
    FleetReportHelpers.ShowFleetReport(fleet, dispatch, t)
  }, [dispatch, fleet, t])

  return { onCloseDrawer, showFleetEdit, showFleetReport, t, settings }
}

export default useController
