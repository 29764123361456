const MakeDate = (data) => {
  if (data && Array.isArray(data)) {
    return data.map((item) => {
      const container = {}
      container.type = item.type || null
      container.status = item.status || null
      container.creationDate = item.creationDate || null
      container.order = item

      return container
    })
  }
  return null
}
export default MakeDate
