// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const getAlerts = async (query) => {
  const route = `/alerts`
  return axiosService.get(true, route, query)
}
export const jiminiAlertsService = {
  getAlerts,
}
