import Resizer from 'react-image-file-resizer'

const resizeFile = (
  file,
  maxWidth,
  maxHeight,
  compressFormat,
  quality,
  rotation,
  outputType,
  minWidth,
  minHeight,
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth || 800,
      maxHeight || 800,
      compressFormat || 'png',
      quality || 100,
      rotation || 0,
      (uri) => {
        resolve(uri)
      },
      outputType || 'file',
      minWidth,
      minHeight,
    )
  })

export const filesHelpers = { resizeFile }
