// eslint-disable-next-line import/no-cycle
import { axiosService } from '../system/axios.service'

const updateDocuments = async (id, body) => {
  const route = `/vehicleDocuments/${id}`
  return axiosService.put(true, route, null, body)
}
const deleteDocuments = async (id) => {
  const route = `/vehicleDocuments/${id}`
  return axiosService.deleteFnc(true, route)
}
const addFiles = async (id, body) => {
  const route = `/vehicleDocuments/${id}/files`
  return axiosService.post(true, route, null, body)
}

const deleteFiles = async (id, body) => {
  const route = `/vehicleDocuments/${id}/files`
  return axiosService.deleteFnc(true, route, null, body)
}

const getDocuments = async (id, query) => {
  const route = `/vehicleDocuments`
  return axiosService.get(true, route, query)
}
export const documentsService = {
  getDocuments,
  updateDocuments,
  deleteDocuments,
  addFiles,
  deleteFiles,
}
