/* eslint-disable react/no-array-index-key */
import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles from '../PdfComponent.styles'

const ItemsArray = ({ data }) =>
  data && Array.isArray(data)
    ? data.map((element, index) => (
        <View key={`item-table-${index}`} style={styles.arrayContainer}>
          <Text style={styles.header}>{element.header} : </Text>
          <Text style={styles.body}> {element.body} </Text>
        </View>
      ))
    : null

export default ItemsArray
