/* eslint-disable import/no-cycle */
import {
  Grid,
  List,
  InputAdornment,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import React, { useCallback } from 'react'
import DescriptionIcon from '@material-ui/icons/Description'
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search'
import StylesWithMaterialUi from '../../AddDocuments.styles'
import { momentConstants } from '../../../../../constants'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import useController from './FillingModel.controller'

const FillingModel = (props) => {
  const { onFillUp } = props
  const { data, t, documentValue, handleChange, inputRef } =
    useController(props)
  const classes = StylesWithMaterialUi()

  const renderArray = useCallback(
    () =>
      data.map((o) => (
        <ListItem
          key={o.id}
          onClick={() => onFillUp(o)}
          alignItems="flex-start"
          style={{ cursor: 'pointer' }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {o.vehicle && o.vehicle.ref && o.vehicle.name ? (
                  <Typography className={classes.inline} color="textPrimary">
                    {t('v_detail.inputVehicleWithRefModel', {
                      ref: o.vehicle.ref,
                      name: o.vehicle.name,
                    })}
                  </Typography>
                ) : (
                  t('v_detail.inputVehicleWithRefWithoutName', {
                    ref: o.vehicle.ref,
                  })
                )}
                {o.name ? (
                  <Typography className={classes.inline} color="textPrimary">
                    {t('documents.nameModel', { value: o.name })}
                  </Typography>
                ) : null}
                {o.creationDate ? (
                  <Typography className={classes.inline} color="textPrimary">
                    {t('documents.creationDateModel', {
                      value: moment(o.creationDate).format(
                        momentConstants.FORMAT_INIT,
                      ),
                    })}
                  </Typography>
                ) : null}
              </>
            }
            style={{ marginLeft: '-20px' }}
          />
        </ListItem>
      )),
    [classes.inline, data, onFillUp, t],
  )

  return (
    <div className={classes.gridRoot}>
      <Grid
        container
        direction="column"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid
          container
          className={classes.gridItem}
          direction="row"
          alignItems="center"
        >
          <InputComponent
            id="combo-box"
            value={documentValue}
            onChange={(e) => handleChange(e)}
            fullWidth
            variant="outlined"
            autoFocus
            ref={inputRef}
            placeholder={t('map.searchLabel')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            name="document"
          />
        </Grid>

        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <List>
            {data && Array.isArray(data) && data.length > 0 ? (
              renderArray()
            ) : (
              <p> {t('t_body.noMatch')}</p>
            )}
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(FillingModel)
