/* eslint-disable import/no-cycle */
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { closeSocketForMap } from '../../../helpers/socket/socket.helpers'
import {
  documentsService,
  filesService,
  vehiclesService,
} from '../../../services'
import { alertActions } from '../../../actions'
import { emptyResponse } from '../../../helpers'

const useController = (props) => {
  const { t } = useTranslation()
  const { id, onclose, vehicle } = props
  const vehicleName =
    vehicle && vehicle.ref && vehicle.name
      ? t('v_detail.inputVehicleWithRef', {
          ref: vehicle.ref,
          name: vehicle.name,
        })
      : vehicle.ref
  const [inputs, setInputs] = useState({
    docName: '',
  })
  const [type, setType] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [open, setOpen] = useState(false)
  const { docName } = inputs
  const socket = useSelector((state) => state.socket)
  const dispatch = useDispatch()
  const location = useLocation()
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setInputs((inp) => ({ ...inp, [name]: value }))
  }, [])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (id && type) {
        setOpen(true)
        let files = []
        if (selectedFiles.length > 0) {
          const formData = new FormData()
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i])
          }

          const sendFiles = await filesService.postFiles(formData)
          if (emptyResponse(sendFiles, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                sendFiles && sendFiles.content
                  ? sendFiles.content.message
                  : t('global.error'),
                6000,
                true,
              ),
            )
            return
          }
          files = sendFiles.content.data.files
        }
        const body = {
          type,
          ambassador: true,
        }
        if (docName) {
          body.name = docName
        }
        const res = await vehiclesService.createDocument(id, body)
        if (!res || res.status !== 201) {
          setOpen(false)
          dispatch(alertActions.error(res.content.message, 6000, true))
          return
        }
        if (files.length > 0 && res.content.data.id) {
          const addFilesToDocuments = await documentsService.addFiles(
            res.content.data.id,
            { files },
          )
          if (emptyResponse(addFilesToDocuments, 201)) {
            setOpen(false)
            dispatch(
              alertActions.error(
                addFilesToDocuments && addFilesToDocuments.content
                  ? addFilesToDocuments.content.message
                  : t('global.error'),
                6000,
                true,
              ),
            )
          }
        }
        setOpen(false)

        onclose()

        return
      }
      setOpen(false)
      dispatch(alertActions.error(t('documents.error'), 5000, true))
    },
    [dispatch, docName, id, onclose, selectedFiles, t, type],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      closeSocketForMap(socket, dispatch, location.pathname, t('router.home'))
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, socket, t])

  return {
    handleSubmit,
    t,
    vehicleName,
    docName,
    handleChange,
    type,
    selectedFiles,
    open,
    setSelectedFiles,
    setType,
  }
}

export default useController
