/* eslint-disable import/no-cycle */
import React, { useMemo, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { momentConstants } from '../../../../constants'

const InsuranceCard = (props) => {
  const { document } = props
  const { t } = useTranslation()

  const getInput = useCallback(
    (key) =>
      key === 'a3' && document.data[key].start && document.data[key].end ? (
        <Grid item key={`${key}-a`} container direction="column">
          <Grid item>
            <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                marginLeft: '5px',
              }}
            >
              {t('documents.dateRanger', {
                start: moment(document.data[key].start).format(
                  momentConstants.FORMAT_DATE,
                ),
                end: moment(document.data[key].end).format(
                  momentConstants.FORMAT_DATE,
                ),
              })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid item key={`${key}-a`} container direction="column">
          <Grid item>
            <Typography variant="body2">{t(`documents.${key}`)} :</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              {document.data[key]}
            </Typography>
          </Grid>
        </Grid>
      ),
    [document, t],
  )

  return useMemo(
    () =>
      Object.keys(document.data).map((key) =>
        document.data[key] ? getInput(key) : null,
      ),
    [document, getInput],
  )
}
export default InsuranceCard
