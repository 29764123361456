/* eslint-disable react/no-array-index-key */
import React from 'react'

import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  ClickAwayListener,
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SaveIcon from '@material-ui/icons/Save'
import StylesWithMaterialUi from '../../DropZone.styles'
import useController from './FilesView.controller'

const FileView = (props) => {
  const { selectedFiles } = props
  const {
    handleClickAway,
    changeName,
    editName,
    fileSize,
    saveFile,
    removeFile,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  return (
    <>
      {selectedFiles.map((data, i) => (
        <ClickAwayListener
          key={i}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            handleClickAway(i)
          }}
        >
          <Grid
            item
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
              spacing={1}
              style={data.edit ? { width: '75%' } : { width: '90%' }}
            >
              <Grid
                item
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {data.edit ? (
                  <input
                    type="text"
                    value={data.newName || ''}
                    onChange={(event) => {
                      changeName(event, i)
                    }}
                  />
                ) : (
                  <Tooltip title={data.name}>
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography
                        noWrap
                        variant="caption"
                        onClick={() => {
                          editName(i)
                        }}
                      >
                        {data.name}
                      </Typography>
                    </div>
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Typography noWrap variant="caption">
                  ({fileSize(data.size)})
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={data.edit ? { width: '25%' } : { width: '10%' }}
            >
              {data.edit ? (
                <Grid item>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      saveFile(i)
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Grid>
              ) : null}
              <Grid item>
                <IconButton
                  onClick={() => removeFile(data.name)}
                  edge="end"
                  style={{ marginRight: '-20px' }}
                >
                  <HighlightOffIcon className={classes.fileRemove} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      ))}
    </>
  )
}
export default FileView
