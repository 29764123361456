/* eslint-disable import/no-cycle */
import { getewaysConstants } from '../constants'
import { elementReplaceVehicle } from '../helpers'
const initialState = {
  isLoading: false,
  isError: false,
}

const gateways = (state = initialState, action) => {
  switch (action.type) {
    case getewaysConstants.INIT:
      return {
        ...state,
        isLoading: true,
      }
    case getewaysConstants.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.gateways,
      }

    case getewaysConstants.UPDATE:
      return {
        ...state,
        data: elementReplaceVehicle(state.data, action.payload.vehicle),
        isLoading: false,
      }
    case getewaysConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case getewaysConstants.CLEAR:
      return {}
    default:
      return state
  }
}

export { gateways }
