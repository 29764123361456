/* eslint-disable import/no-cycle */
import { getPermissions } from '../../helpers'

const headerNames = (t, user) => [
  {
    name: t('table_trips.vehicle'),
    download: true,
  },
  {
    name: t('table_trips.vehicleFleet'),
    download: true,
  },
  {
    name: t('table_trips.owner'),
    download: user && getPermissions(user.type, 'showOwner'),
  },
  {
    name: t('table_trips.gateway'),
    download: true,
  },
  {
    name: t('table_trips.status'),
    download: true,
  },
  {
    name: t('table_trips.durationDownloaded'),
    download: true,
  },
  {
    name: t('table_trips.distanceGpsDownloaded'),
    download: user && getPermissions(user.type, 'distanceGps'),
  },
  {
    name: t('table_trips.odometerDownloaded'),
    download: user && getPermissions(user.type, 'distanceOdometer'),
  },
  {
    name: t('table_trips.distanceTraveledDownloaded'),
    download: user && getPermissions(user.type, 'distanceTraveled'),
  },
  {
    name: t('table_trips.startDate'),
    download: true,
  },
  {
    name: t('table_trips.endDate'),
    download: true,
  },
  {
    name: t('table_trips.startTracking'),
    download: true,
  },
  {
    name: t('table_trips.endTracking'),
    download: true,
  },
  {
    name: t('table_trips.usageQuality'),
    download: true,
  },
]
const tripStatus = [
  {
    value: 'Ended',
    label: 'Terminé',
  },
  {
    value: 'InProgress',
    label: 'En cours',
  },
]
export { headerNames, tripStatus }
