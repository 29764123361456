/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { socketActions, vehiclesActions, alertActions } from '../../../actions'
import {
  socketService,
  vehiclesTripsService,
  vehiclesService,
} from '../../../services'
import { documentsConstants } from '../../../constants'
import { fullResponse } from '../../../helpers'
const useController = (props) => {
  const { vehicle, alerts } = props

  const dispatch = useDispatch()
  const trip = useSelector((state) => state.vehicles.trip)
  const drawer = useSelector((state) => state.drawer)
  const socket = useSelector((state) => state.socket)
  const settings = useSelector((state) => state.settings)
  const [expandedGeneral, setExpandedGeneral] = useState(false)
  const [expandedAlerts, setExpandedAlerts] = useState(false)
  const [expandedOrders, setExpandedOrders] = useState(false)
  const [expandedUses, setExpandedUses] = useState(false)
  const [expandedMaintenance, setExpandedMaintenance] = useState(false)
  const [expandedDocument, setExpandedDocument] = useState(false)

  const [expandedDocAmbassador, setExpandedDocAmbassador] = useState(false)
  // const [expandedHistory, setExpandedHistory] = useState(false)
  const [expandedAvaibleStates, setExpandedAvaibleStates] = useState(false)
  const [expandedBtn, setExpandedBtn] = useState(false)
  const [expandedHeader, setExpandedHeader] = useState(false)
  const [response, setResponse] = useState(null)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()
  const [lastTracking, setLastTracking] = useState(null)

  const handleExpandedGeneralBtn = useCallback(() => {
    if (vehicle) {
      setExpandedBtn(!expandedBtn)
    }
  }, [expandedBtn, vehicle])

  /** const handleExpandedHistory = useCallback(() => {
    if (vehicle) {
      setExpandedHistory(!expandedHistory)
    }
  }, [expandedHistory, vehicle])* */

  const handleExpandedGeneral = useCallback(() => {
    if (vehicle) {
      setExpandedGeneral(!expandedGeneral)
    }
  }, [expandedGeneral, vehicle])

  const handleExpandedAlerts = useCallback(() => {
    if (vehicle) {
      setExpandedAlerts(!expandedAlerts)
    }
  }, [expandedAlerts, vehicle])

  const handleExpandedOrders = useCallback(() => {
    if (vehicle) {
      setExpandedOrders(!expandedOrders)
    }
  }, [expandedOrders, vehicle])

  const handleExpandedUses = useCallback(() => {
    if (vehicle) {
      setExpandedUses(!expandedUses)
    }
  }, [expandedUses, vehicle])

  const handleExpandedMaintenance = useCallback(() => {
    if (vehicle) {
      setExpandedMaintenance(!expandedMaintenance)
    }
  }, [expandedMaintenance, vehicle])

  const handleExpandedDocument = useCallback(() => {
    if (vehicle) {
      setExpandedDocument(!expandedDocument)
    }
  }, [expandedDocument, vehicle])

  const handleExpandedDocAmbassador = useCallback(() => {
    if (vehicle) {
      setExpandedDocAmbassador(!expandedDocAmbassador)
    }
  }, [expandedDocAmbassador, vehicle])

  const handleExpandedAvaibleStates = useCallback(() => {
    if (vehicle) {
      setExpandedAvaibleStates(!expandedAvaibleStates)
    }
  }, [expandedAvaibleStates, vehicle])
  const handleExpandedHeader = useCallback(() => {
    if (vehicle) {
      setExpandedHeader(!expandedHeader)
    }
  }, [expandedHeader, vehicle])
  const unsubscribeSocket = useCallback(() => {
    if (socket.status && socket.socket) {
      const subscribe = localStorage.getItem('subscribe')
      const subscribeType = localStorage.getItem('subscribeType')
      const subscribeId = localStorage.getItem('subscribeId')
      if (
        subscribe === 'true' &&
        subscribeType === 'Vehicle' &&
        subscribeId &&
        subscribeId !== vehicle.id
      ) {
        const unsubscribeData = {
          clear: false,
          channels: {
            vehicles: [subscribeId],
          },
        }
        socketService.unsubscribe(socket.socket, unsubscribeData)
      }
    }
  }, [vehicle, socket.socket, socket.status])

  useEffect(() => {
    const abortController = new AbortController()
    const getTracking = async () => {
      try {
        if (trip && vehicle && vehicle.id && expandedHeader) {
          const dateMax = new Date()
          const query = {
            dateMin: trip.startTracking.date,
            dateMax: trip.endTracking ? trip.endTracking.date : dateMax,
          }
          const res = await vehiclesService.getTracking(trip.vehicle.id, query)
          if (fullResponse(res, 200)) {
            if (res.content && res.content.data) {
              dispatch(vehiclesActions.tracking(res.content.data))
              return
            }
          }
          dispatch(vehiclesActions.tracking(null))
        }
      } catch (e) {
        dispatch(alertActions.error(e.message, 6000, true))
      }
    }
    getTracking()
    return () => {
      abortController.abort()
    }
  }, [trip, vehicle, expandedHeader])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchTrip = async () => {
      try {
        if (vehicle && vehicle.id && expandedHeader) {
          dispatch(vehiclesActions.tracking(null))
          dispatch(vehiclesActions.trip(null))
          const query = {
            vehicleId: vehicle.id,
            limit: 1,
            status: 'Ended',
          }
          const res = await vehiclesTripsService.getTrips(query)
          if (fullResponse(res, 200)) {
            dispatch(vehiclesActions.trip(res.content.data[0]))
            return
          }
          dispatch(vehiclesActions.trip(null))
        }
      } catch (e) {
        dispatch(alertActions.error(e.message, 6000, true))
      }
    }
    fetchTrip()
    return () => {
      abortController.abort()
    }
  }, [vehicle, expandedHeader])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchSocket = () => {
      if (vehicle) {
        if (vehicle && vehicle.lastTracking) {
          setLastTracking(vehicle.lastTracking)
        } else {
          setLastTracking(null)
        }

        const data = {
          clear: false,
          channels: {
            vehicles: [vehicle.id],
          },
        }

        if (socket.socket && socket.status) {
          if (location.pathname === t('router.home')) {
            const subscribe = localStorage.getItem('subscribe')
            if (subscribe === 'true') {
              unsubscribeSocket()
            }
            socketService.subscribe(socket.socket, data)
            localStorage.setItem('subscribeType', 'Vehicle')
            localStorage.setItem('subscribeId', vehicle.id)
            localStorage.setItem('subscribe', true)
            return
          }

          if (socket.data.channels) {
            if (
              socket.data.channels.vehicles &&
              socket.data.channels.vehicles.length > 0 &&
              socket.data.channels.vehicles[0] !== vehicle.id
            ) {
              const unsubscribeData = {
                clear: false,
                channels: {
                  vehicles: [socket.data.channels.vehicles[0]],
                },
              }
              dispatch(
                socketActions.unsubscribe(socket.socket, unsubscribeData),
              )
            }
          }
          dispatch(socketActions.subscribe(socket.socket, data))
        }
      }
    }
    fetchSocket()
    return () => {
      abortController.abort()
    }
  }, [
    dispatch,
    location.pathname,
    socket.socket,
    socket.status,
    t,
    vehicle,
    unsubscribeSocket,
  ])

  useEffect(() => {
    const abortController = new AbortController()
    const listeningSocket = () => {
      if (socket.status && socket.socket) {
        socketService.listeningSocket(socket.socket, setResponse)
      }
    }
    listeningSocket()
    return () => {
      abortController.abort()
    }
  }, [socket])

  useEffect(() => {
    const abortController = new AbortController()
    const listeningSocket = () => {
      if (response) {
        setOpen(true)
        if (
          location.pathname === t('router.vehicles') &&
          response.vehicleId === vehicle.id
        ) {
          dispatch(vehiclesActions.updateWithSocket(response))
        }
        if (response.vehicleId === vehicle.id) {
          setLastTracking(response)
        }
        setOpen(false)
      }
    }
    listeningSocket()
    return () => {
      abortController.abort()
    }
  }, [dispatch, location.pathname, response, t, vehicle.id])

  useEffect(() => {
    const abortController = new AbortController()
    const drawerState = () => {
      if (drawer && drawer.open) {
        if (alerts === 'Maintenance') {
          setExpandedGeneral(false)
          setExpandedDocument(false)
          setExpandedUses(false)
          setExpandedOrders(false)
          setExpandedAlerts(false)
          setExpandedBtn(false)
          setExpandedHeader(false)
          setExpandedMaintenance(true)

          return
        }
        if (alerts === 'Tracking') {
          setExpandedMaintenance(false)
          setExpandedDocument(false)
          setExpandedUses(false)
          setExpandedOrders(false)
          setExpandedAlerts(false)
          setExpandedBtn(false)
          setExpandedHeader(false)
          setExpandedGeneral(true)

          return
        }
        if (documentsConstants.alerts_types.includes(alerts)) {
          setExpandedMaintenance(false)
          setExpandedUses(false)
          setExpandedOrders(false)
          setExpandedAlerts(false)
          setExpandedGeneral(false)
          setExpandedBtn(false)
          setExpandedHeader(false)
          setExpandedDocument(true)
          return
        }

        setExpandedMaintenance(false)
        setExpandedDocument(false)
        setExpandedUses(false)
        setExpandedOrders(false)
        setExpandedAlerts(false)
        setExpandedBtn(false)
        setExpandedAvaibleStates(false)
        setExpandedDocAmbassador(false)
        setExpandedGeneral(true)
        // setExpandedHistory(false)
        setExpandedHeader(true)
      }
    }
    drawerState()
    return () => {
      abortController.abort()
    }
  }, [alerts, drawer])

  const openDocs = useCallback(() => {
    setExpandedGeneral(false)
    setExpandedDocument(true)
    setExpandedUses(false)
    setExpandedOrders(false)
    setExpandedAlerts(false)
    setExpandedBtn(false)
    setExpandedMaintenance(false)
  }, [])

  return {
    vehicle,
    expandedDocument,
    handleExpandedGeneral,
    lastTracking,
    expandedGeneral,
    open,
    expandedMaintenance,
    handleExpandedMaintenance,
    handleExpandedDocument,
    handleExpandedUses,
    expandedUses,
    handleExpandedOrders,
    expandedOrders,
    handleExpandedAlerts,
    expandedAlerts,
    handleExpandedGeneralBtn,
    expandedBtn,
    settings,
    handleExpandedAvaibleStates,
    expandedAvaibleStates,
    openDocs,
    expandedHeader,
    handleExpandedHeader,
    handleExpandedDocAmbassador,
    expandedDocAmbassador,
  }
}

export default useController
