/* eslint-disable import/no-cycle */
import React, { createRef, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import StylesWithMaterialUi from '../../AddDocuments.styles'
import InputComponent from '../../../../utils/MatrialUiComponents/InputComponent/InputComponent'
import useController from './PV.controller'

const PV = (props) => {
  const {
    t,
    handleChange,
    number,
    raison,
    description,
    address,
    amountMinor,
    amountNormal,
    amountIncreased,
    date,
    DateOfIncreased,
    time,
    effect,
  } = useController(props)
  const classes = StylesWithMaterialUi()

  const InputModel = useCallback(
    (value, name, type, required, multiline) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={handleChange}
            type={type}
            name={name}
            fullWidth
            margin="normal"
            required={required}
            multiline={multiline}
          />
        </Grid>
      )
    },
    [classes.gridItem, handleChange, t],
  )
  const InputModelDate = useCallback(
    (value, name, required, error, type) => {
      const ref = createRef()

      return (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          className={classes.gridItem}
        >
          <InputComponent
            ref={ref}
            variant="outlined"
            value={value}
            label={t(`documents.${name}`)}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={error}
            type={type || 'date'}
            name={name}
            fullWidth
            margin="normal"
            required={required}
          />
        </Grid>
      )
    },
    [classes.gridItem, t, handleChange],
  )
  return useMemo(
    () => (
      <>
        {InputModelDate(date, 'date', true, undefined, undefined)}
        {InputModelDate(
          DateOfIncreased,
          'DateOfIncreased',
          true,
          undefined,
          undefined,
        )}
        {InputModelDate(time, 'time', true, undefined, 'time')}
        {InputModel(raison, 'raison', 'text', true, true)}
        {InputModel(description, 'description', 'text', false, true)}
        {InputModel(effect, 'effect', 'text', false, true)}
        {InputModel(number, 'number', 'number', true, false)}
        {InputModel(address, 'address', 'text', true, true)}
        {InputModel(amountNormal, 'amountNormal', 'number', false, false)}
        {InputModel(amountMinor, 'amountMinor', 'number', false, false)}
        {InputModel(amountIncreased, 'amountIncreased', 'number', false, false)}
      </>
    ),
    [
      raison,
      DateOfIncreased,
      InputModel,
      InputModelDate,
      address,
      amountMinor,
      amountNormal,
      amountIncreased,
      date,
      description,
      number,
      time,
      effect,
    ],
  )
}
export default PV
