/* eslint-disable react/no-array-index-key */
import React from 'react'
import { View } from '@react-pdf/renderer'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import styles from '../PdfComponent.styles'

const ItemsTable = ({ data }) =>
  data && Array.isArray(data)
    ? data.map((element, index) => (
        <View key={`item-table-${index}`} style={styles.tableContainer}>
          <TableHeader item={element} />
          <TableRow item={element} />
        </View>
      ))
    : null

export default ItemsTable
