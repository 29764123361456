/* eslint-disable import/no-cycle */
import { jiminiAlertsConstants } from '../constants'

export const jiminiAlertsActions = {
  success,
  failure,
  refresh,
  init,
  clear,
}
function init() {
  return { type: jiminiAlertsConstants.INIT }
}
function success(alerts) {
  return {
    type: jiminiAlertsConstants.SUCCESS,
    payload: { alerts },
  }
}

function failure() {
  return {
    type: jiminiAlertsConstants.FAILURE,
  }
}
function refresh() {
  return {
    type: jiminiAlertsConstants.REFRESH,
  }
}
function clear() {
  return {
    type: jiminiAlertsConstants.CLEAR,
  }
}
