export const settingsConstants = {
  accounts: {
    Vehicle: {
      screens: {
        map: {
          enabled: true,
          filter: true,
          search: true,
          settings: true,
        },
        vehicles: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleTrips: {
          enabled: true,
          apiFilter: false,
          tableFilter: true,
          download: true,
        },
      },
      drawers: {
        vehicle: {
          enabled: true,
          // generalInfo: true,
          actions: true,
          maintenances: true,
          // usageQuality: true,
          documents: true,
          alerts: true,
          // map: true,
        },
        vehicleFleet: {
          enabled: true,
          actions: false,
        },
      },
    },
    User: {
      screens: {
        map: {
          enabled: true,
          filter: true,
          search: true,
          settings: true,
        },
        vehicles: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleTrips: {
          enabled: true,
          apiFilter: false,
          tableFilter: true,
          download: true,
        },
        quickAccess: {
          enabled: true,
        },
      },
      drawers: {
        vehicle: {
          enabled: true,
          // generalInfo: true,
          actions: true,
          maintenances: true,
          documents: true,
          alerts: true,
          commands: true,
          // map: true,
        },
      },
    },
    UserMobion: {
      screens: {
        map: {
          enabled: false,
          filter: false,
          search: false,
          settings: false,
        },
        vehicles: {
          enabled: false,
          tableFilter: false,
          download: false,
        },
        vehicleTrips: {
          enabled: false,
          apiFilter: false,
          tableFilter: false,
          download: false,
        },
        quickAccess: {
          enabled: true,
        },
      },
      drawers: {
        vehicle: {
          enabled: true,
          // generalInfo: true,
          actions: false,
          maintenances: false,
          // usageQuality: false,
          documents: false,
          alerts: false,
          commands: false,
          uses: true,
          // map: false,
        },
        vehicleFleet: {
          enabled: true,
          actions: false,
        },
      },
    },
    Owner: {
      screens: {
        map: {
          enabled: true,
          filter: true,
          search: true,
          settings: true,
        },
        vehicles: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleTrips: {
          enabled: true,
          apiFilter: true,
          tableFilter: true,
          download: true,
        },
        vehicleAlerts: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleFleets: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleIncidents: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        gateways: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        users: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        quickAccess: {
          enabled: true,
        },
      },
      drawers: {
        vehicle: {
          enabled: true,
          // generalInfo: true,
          // history: true,
          actions: true,
          maintenances: true,
          // usageQuality: true,
          documents: true,
          ambassador: true,
          alerts: true,
          commands: true,
          uses: true,
          avaibleStates: false,
          // map: true,
        },
        vehicleFleet: {
          enabled: true,
          actions: true,
        },
        user: {
          enabled: true,
          actions: true,
        },
        gateway: {
          enabled: true,
        },
      },
    },
    Admin: {
      screens: {
        map: {
          enabled: true,
          filter: true,
          search: true,
          settings: true,
        },
        vehicles: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleTrips: {
          enabled: true,
          apiFilter: true,
          tableFilter: true,
          download: true,
        },
        vehicleAlerts: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleFleets: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        vehicleIncidents: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        gateways: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        users: {
          enabled: true,
          tableFilter: true,
          download: true,
        },
        quickAccess: {
          enabled: true,
        },
        data: {
          enabled: true,
        },
      },
      drawers: {
        vehicle: {
          enabled: true,
          // generalInfo: true,
          // history: true,
          actions: true,
          maintenances: true,
          // usageQuality: true,
          documents: true,
          ambassador: true,
          alerts: true,
          commands: true,
          uses: false,
          avaibleStates: false,
          // map: true,
        },
        vehicleFleet: {
          enabled: true,
          actions: true,
        },
        user: {
          enabled: true,
          actions: true,
        },
        gateway: {
          enabled: true,
        },
      },
    },
  },
}
