const linesReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_LINES':
      return { coords: action.coords }
    case 'UPDATE_LINES':
      return { coords: [action.coords, ...state.coords] }
    default:
      throw new Error('Invalid Action Type')
  }
}

export { linesReducer }
